import { useDispatch } from 'react-redux';
import { editPatientPrescription } from 'store/thunks/patientThunks';
import PropTypes from 'prop-types';

import PrescriptionTypes from 'enums/prescription.enum';
import SwitchField from 'common/forms/SwitchField';
import { useState } from 'react';
import useRoles from 'common/hooks/useRoles';
import PrescriptionStatus from '../../../../common/constants/prescriptionStatus';

const PrescriptionStatusSwitch = ({ checked, prescriptionId, mpi }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const hasAccess = useRoles();

  const handleEditPrescription = () => {
    setLoading(true);
    dispatch(
      editPatientPrescription({
        action: checked ? PrescriptionStatus.inactive : PrescriptionStatus.active,
        mpi,
        prescriptionId,
      })
    ).then(() => setLoading(false));
  };

  return (
    <SwitchField
      size='small'
      checked={checked}
      onClick={handleEditPrescription}
      label='Switch to manage prescription state'
      disabled={!hasAccess.editPatientPrescription || isLoading}
    />
  );
};

PrescriptionStatusSwitch.propTypes = {
  mpi: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  prescriptionId: PropTypes.string.isRequired,
};

const PrescriptionAutoFillSwitch = ({ checked, prescriptionId, mpi, type }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const hasAccess = useRoles();

  const handleEditPrescriptionAutofill = () => {
    setIsLoading(true);
    dispatch(
      editPatientPrescription({
        autofillValue: !checked,
        mpi,
        prescriptionId,
      })
    ).then(() => setIsLoading(false));
  };

  return (
    <SwitchField
      size='small'
      checked={checked}
      onClick={handleEditPrescriptionAutofill}
      label='Toggle to update autofill'
      disabled={
        !hasAccess.editPrescriptionAutofill || type === PrescriptionTypes.RX_ENTRY || isLoading
      }
    />
  );
};

PrescriptionAutoFillSwitch.propTypes = {
  mpi: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  prescriptionId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

PrescriptionAutoFillSwitch.defaultProps = {
  type: undefined,
};

export { PrescriptionStatusSwitch, PrescriptionAutoFillSwitch };
