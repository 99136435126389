import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, omit, pick, values } from 'lodash';
import { filterTransactions } from 'store/thunks/transactionsThunk';

const TransactionsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ transactions }) => transactions?.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterTransactions({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(filterTransactions({ filter: pick(filter, ['transactionType']) }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.patientName && (
        <Chip
          label={`Patient = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.externalTransactionId && (
        <Chip
          label={`Transaction Id = ${filter.externalTransactionId}`}
          variant='contained'
          onDelete={removeFilter('externalTransactionId')}
        />
      )}

      {filter.transactionDate && (
        <Chip
          label={`Transaction Date = ${format(new Date(filter.transactionDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('transactionDate')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, ['transactionType'])))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default TransactionsFilterStatusBar;
