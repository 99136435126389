/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listPaymentTransactionsGql = gql`
  query listPaymentTransactions(
    $limit: Int = 25
    $offset: Int = 0
    $filter: PaymentTransactionFilter
    $sortBy: PaymentTransactionSortBy
  ) {
    listPaymentTransactions(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: result {
        pomOrderId
        externalTransactionId
        transactionId
        amount
        transactionType
        transactionTimestamp
        paymentMethod {
          alternateAccountId
          cardholderName
          cardIssuer
          expiration
          isDefault
          last4digit
          paymentMethodId
          paymentMethodName
          psAccountId
        }
        user {
          userId
          firstName
          lastName
        }
        mpi
        patientDob
        patientFirstName
        patientLastName
        patientGender
      }
    }
  }
`;

export const capturePaymentGql = gql`
  mutation capturePayment(
    $userId: String!
    $patientId: String!
    $externalTransactionId: String!
    $amount: Float
  ) {
    capturePayment(
      userId: $userId
      patientId: $patientId
      externalTransactionId: $externalTransactionId
      amount: $amount
    )
  }
`;

export const voidPaymentGql = gql`
  mutation voidPayment($userId: String!, $patientId: String!, $externalTransactionId: String!) {
    voidPayment(
      userId: $userId
      patientId: $patientId
      externalTransactionId: $externalTransactionId
    )
  }
`;

export const refundPaymentGql = gql`
  mutation refundPayment(
    $userId: String!
    $patientId: String!
    $externalTransactionId: String!
    $amount: Float!
  ) {
    refundPayment(
      userId: $userId
      patientId: $patientId
      externalTransactionId: $externalTransactionId
      amount: $amount
    )
  }
`;

export const getTransactionHistoryGql = gql`
  query getTransactionHistory($externalTransactionId: String!) {
    getTransactionHistory(externalTransactionId: $externalTransactionId) {
      transactionId
      externalTransactionId
      amount
      transactionType
      transactionTimestamp
    }
  }
`;

export const getFailedOrderDetailsGql = gql`
  query getFailedOrderDetails($orderId: String!, $mpi: UUID!) {
    getFailedOrderDetails(mpi: $mpi, orderId: $orderId) {
      mpi
      patient {
        patientDemographics {
          firstName
          lastName
          dob
          gender
        }
        patientContact {
          addresses {
            tags
            patientAddressId
            createdTimestamp
            address {
              addressLine1: line1
              addressLine2: line2
              city
              state
              zip
            }
          }
        }
      }
      orderId
      requestId
      orderStatus
      orderReferenceId
      lineItems {
        itemName
        rxNumber
        form
        autoFill
        itemType
      }
    }
  }
`;

export const saveAndAuthorizePaymentGql = gql`
  mutation saveAndAuthorizePayment(
    $cardData: String!
    $userId: String!
    $patientId: String!
    $paymentMethodName: String
    $amount: Float!
    $orderId: String!
    $failedTransactionId: String!
    $orderReferenceId: String!
  ) {
    saveAndAuthorizePayment(
      amount: $amount
      cardData: $cardData
      orderId: $orderId
      patientId: $patientId
      paymentMethodName: $paymentMethodName
      userId: $userId
      failedTransactionId: $failedTransactionId
      orderReferenceId: $orderReferenceId
    ) {
      externalTransactionId
    }
  }
`;

export const getPublicKeyGql = gql`
  query getPublicKey {
    getPublicKey
  }
`;
