import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useRoles from 'common/hooks/useRoles';
import { activatePatientProvider, deactivatePatientProvider } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

import EditProviderDialog from './EditProviderDialog';

const PatientProviderOptions = ({ patientProviderId, mpi, isActive }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };
  const handleCloseEditDialog = () => setShowEditDialog(false);

  const handleDeactivateProvider = () => {
    handleMenuClose();
    showInfoDialog({
      title: 'Confirm Deactivation',
      message: 'Are you sure you want to deactivate this provider?',
      callback: () => dispatch(deactivatePatientProvider({ mpi, patientProviderId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const handleActivateProvider = () => {
    handleMenuClose();
    dispatch(activatePatientProvider({ mpi, patientProviderId }));
  };

  return (
    <>
      <InfoDialog />
      <EditProviderDialog
        mpi={mpi}
        patientProviderId={patientProviderId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
      />

      <IconButton
        id={`PatientView-PatientProviderOptions-${patientProviderId}options-button`}
        aria-label={`Provider options ${patientProviderId}`}
        aria-controls={
          menuOpen
            ? `PatientView-PatientProviderOptions-${patientProviderId}options-menu`
            : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientProviderOptions-${patientProviderId}options-menu`}
        aria-labelledby={`PatientView-PatientProviderOptions-${patientProviderId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog} disabled={!hasAccess.updateProvider}>
          Edit
        </MenuItem>

        {!isActive && (
          <MenuItem onClick={handleActivateProvider} disabled={!hasAccess.updateProvider}>
            Activate
          </MenuItem>
        )}
        {isActive && (
          <MenuItem onClick={handleDeactivateProvider} disabled={!hasAccess.updateProvider}>
            Deactivate
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

PatientProviderOptions.propTypes = {
  patientProviderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default PatientProviderOptions;
