/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import {
  createLineItemNoteApi,
  getPatientsApi,
  listFailedAdjudicationsApi,
  listLineItemNotesApi,
  noteActionApi,
  updateRefillAdjudicationStateApi,
} from 'api/patient';
import {
  listBulkLabelsApi,
  listEVisitRequestsApi,
  listLineItemsApi,
  listOrdersForProductFulfillmentApi,
} from 'api/requests';
import { debounce, find, map, some } from 'lodash';
import { workQueueActions } from 'store/slices/workQueueSlice';

import { listOrderLineItemsApi } from 'api/orderLineItems';
import { listOrdersApi, listOrdersForShipmentApi } from 'api/order';
import { NoteState } from 'common/constants/notes';
import { PatientStatus } from 'enums/patient.enum';

import {
  listPrescriptionsForReviewApi,
  overridePrescriptionAPI,
  resolveDrugProfileApi,
  resolveDuplicatePrescriptionApi,
  syncSigApi,
} from 'api/prescription';
import { applyFulfillingPharmacyFilter } from 'api/utils';

import { handleError } from './errorHandlerThunks';

export const getOnboardedPatientsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return getPatientsApi({ page, sortBy, filter })
      .then(({ count, patients }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: patients, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedOnboardedPatients = debounce(({ dispatch }) => {
  return dispatch(getOnboardedPatientsByPage({ page: 1 }));
}, 1000);

export const sortOnboardedPatients =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedOnboardedPatients({ preset, dispatch });
  };

export const filterOnboardedPatients =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    // debounced to avoid making multiple api calls when removing individual filter too quickly
    debouncedOnboardedPatients({ preset, dispatch });
  };

export const getInClaimEligibilityRequestsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listLineItemsApi({ page, sortBy, filter })
      .then(({ count, lineItems }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: lineItems, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedInClaimEligibilityRequests = debounce(({ dispatch }) => {
  return dispatch(getInClaimEligibilityRequestsByPage({ page: 1 }));
}, 1000);

export const sortInClaimEligibilityRequestsWorkQueue =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedInClaimEligibilityRequests({ preset, dispatch });
  };

export const filterInClaimElgibilityRequests =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    // debounced to avoid making multiple api calls when removing individual filter too quickly
    debouncedInClaimEligibilityRequests({ preset, dispatch });
  };

export const getReviewInClaimEligibilityLineItemsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listLineItemsApi({ sortBy, filter, page })
      .then(({ count, lineItems }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: lineItems, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedReviewInClaimEligibilityLineItems = debounce(({ dispatch }) => {
  return dispatch(getReviewInClaimEligibilityLineItemsByPage({ page: 1 }));
}, 1000);

export const sortReviewInClaimEligibilityLineItemsWorkQueue =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedReviewInClaimEligibilityLineItems({ preset, dispatch });
  };

export const filterReviewInClaimEligibilityLineItems =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    // debounced to avoid making mulitple api calls when removing individual filter too quickly
    debouncedReviewInClaimEligibilityLineItems({ preset, dispatch });
  };

export const listOrderLineItemsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listOrderLineItemsApi({ filter: updatedFilter, page, sortBy })
      .then(({ count, orderLineItems }) => {
        dispatch(
          workQueueActions.loadWorkQueueToPage({ preset, page, items: orderLineItems, count })
        );
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedOrderLineItems = debounce(({ dispatch }) => {
  return dispatch(listOrderLineItemsByPage({ page: 1 }));
}, 1000);

export const sortOrderLineItemsWorkQueue =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedOrderLineItems({ preset, dispatch });
  };

export const filterOrderLineItems =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedOrderLineItems({ preset, dispatch });
  };

export const listBulkLabelsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listBulkLabelsApi({ filter: updatedFilter, page, sortBy })
      .then(({ count, bulkLabels }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: bulkLabels, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedBulkLabels = debounce(({ dispatch }) => {
  return dispatch(listBulkLabelsByPage({ page: 1 }));
}, 1000);

export const filterBulkLabels =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedBulkLabels({ preset, dispatch });
  };

export const sortBulkLabelsWorkQueue =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedBulkLabels({ preset, dispatch });
  };

export const listOrdersForProductFulfillmentByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listOrdersForProductFulfillmentApi({ filter: updatedFilter, page, sortBy })
      .then(({ count, orders }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: orders, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedListOrdersForProductFulfillment = debounce(({ dispatch }) => {
  return dispatch(listOrdersForProductFulfillmentByPage({ page: 1 }));
}, 1000);

export const sortListOrdersForProductFulfillmentWorkQueue =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedListOrdersForProductFulfillment({ preset, dispatch });
  };

export const filterListOrdersForProductFulfillment =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedListOrdersForProductFulfillment({ preset, dispatch });
  };

export const listOrdersByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listOrdersApi({
      page,
      sortBy,
      filter: updatedFilter,
    })
      .then(({ count, orders }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: orders, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedListOrders = debounce(({ dispatch }) => {
  return dispatch(listOrdersByPage({ page: 1 }));
}, 1000);

export const filterOrders =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedListOrders({ preset, dispatch });
  };

export const sortOrders =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedListOrders({ preset, dispatch });
  };

export const listOrdersForShipmentByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listOrdersForShipmentApi({
      page,
      sortBy,
      filter,
    })
      .then(({ count, orders }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: orders, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedListOrdersForShipment = debounce(({ dispatch }) => {
  return dispatch(listOrdersForShipmentByPage({ page: 1 }));
}, 1000);

export const filterOrdersForShipment =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedListOrdersForShipment({ preset, dispatch });
  };

export const sortOrdersForShipment =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedListOrdersForShipment({ preset, dispatch });
  };

export const sortShipmentOrders =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedListOrdersForShipment({ preset, dispatch });
  };

export const listFailedAdjudicationsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listFailedAdjudicationsApi({
      page,
      sortBy,
      filter,
    })
      .then(({ count, results }) =>
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: results, count }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedlistAdjudication = debounce(({ dispatch }) => {
  return dispatch(listFailedAdjudicationsByPage({ page: 1 }));
}, 1000);

export const filterReviewAdjudications =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedlistAdjudication({ preset, dispatch });
  };

export const sortReviewAdjudications =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedlistAdjudication({ preset, dispatch });
  };

const hasPinnedNote = (notes) => some(notes, ['noteState', NoteState.PIN]);

export const addFailedAdjudicationNotes =
  ({ mpi, content, commentContext, contextId }) =>
  (dispatch, getState) => {
    const { currentPage, pages } = getState().workQueue.reviewAdjudication;
    const existingNotes = find(pages[currentPage], { prescriptionId: contextId })?.notes || [];

    return createLineItemNoteApi({ mpi, content, commentContext })
      .then((newNote) => {
        const updatedNotes = [newNote, ...existingNotes];

        dispatch(
          workQueueActions.loadFailedAdjudicationNotes({
            notes: updatedNotes,
            contextId,
            hasPinnedNotes: hasPinnedNote(updatedNotes),
          })
        );

        return !!newNote;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const listFailedAdjdicatioNotes =
  ({ contextId, contextType, mpi }) =>
  (dispatch) => {
    const filter = { noteContext: { noteContextIds: [contextId], type: contextType } };

    return listLineItemNotesApi({ mpi, contextType, filter })
      .then((notes) => {
        dispatch(
          workQueueActions.loadFailedAdjudicationNotes({
            notes,
            contextId,
            hasPinnedNotes: hasPinnedNote(notes),
          })
        );

        return !!notes;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const updateFailedAdjudicationNoteStatus =
  ({ contextId, noteId, action: actionState }) =>
  (dispatch, getState) => {
    const { currentPage, pages } = getState().workQueue.reviewAdjudication;
    const existingNotes = find(pages[currentPage], { prescriptionId: contextId })?.notes || [];
    const updatedNotes = map(existingNotes, (note) =>
      note.noteId === noteId ? { ...note, noteState: actionState } : note
    );

    dispatch(
      workQueueActions.loadFailedAdjudicationNotes({
        notes: updatedNotes,
        contextId,
        hasPinnedNotes: hasPinnedNote(updatedNotes),
      })
    );

    return noteActionApi({ noteId, action: actionState }).catch((error) => {
      dispatch(
        workQueueActions.loadFailedAdjudicationNotes({
          notes: updatedNotes,
          contextId,
          hasPinnedNotes: hasPinnedNote(existingNotes),
        })
      );

      dispatch(handleError({ error }));
    });
  };

export const updateRefillAdjudicationState =
  ({ rxRequestId, state }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy } = getState().workQueue[preset];

    return updateRefillAdjudicationStateApi({ rxRequestId, state })
      .then(() => {
        dispatch(sortReviewAdjudications({ sortBy }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const getProfileIncompletePatientsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];

    const profileIncompleteFilter = { ...filter, status: PatientStatus.PROFILE_INCOMPLETE };

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return getPatientsApi({ page, sortBy, filter: profileIncompleteFilter })
      .then(({ count, patients }) => {
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: patients, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedProfileIncompletePatients = debounce(({ dispatch }) => {
  return dispatch(getProfileIncompletePatientsByPage({ page: 1 }));
}, 1000);

export const sortProfileIncompletePatients =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));
    debouncedProfileIncompletePatients({ preset, dispatch });
  };

export const filterProfileIncompletePatients =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));
    debouncedProfileIncompletePatients({ preset, dispatch });
  };

export const getPrescriptionsNeedsReviewByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listPrescriptionsForReviewApi({ page, sortBy, filter: updatedFilter })
      .then(({ count, prescriptions }) => {
        dispatch(
          workQueueActions.loadWorkQueueToPage({ preset, page, items: prescriptions, count })
        );
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedListPrescriptionsForReview = debounce(({ dispatch }) => {
  return dispatch(getPrescriptionsNeedsReviewByPage({ page: 1 }));
}, 1000);

export const sortPrescriptionsNeedsReview =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedListPrescriptionsForReview({ preset, dispatch });
  };

export const filterPrescriptionsNeedsReview =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));
    debouncedListPrescriptionsForReview({ preset, dispatch });
  };

export const syncSig =
  ({ prescriptionId, sig, sigId }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { filter } = getState().workQueue[preset];

    return syncSigApi({ prescriptionId, sig, sigId })
      .then((isSynced) => {
        dispatch(filterPrescriptionsNeedsReview({ filter }));

        return isSynced;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const resolveDuplicatePrescription =
  ({ prescriptionId, orderId, isDuplicate }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { filter } = getState().workQueue[preset];

    return resolveDuplicatePrescriptionApi({ prescriptionId, orderId, isDuplicate })
      .then((resolveDuplicatePrescriptionReview) => {
        dispatch(filterPrescriptionsNeedsReview({ filter }));

        return resolveDuplicatePrescriptionReview;
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const resolveDrugProfile = (input) => (dispatch, getState) => {
  const { preset } = getState().workQueue;
  const { filter } = getState().workQueue[preset];

  return resolveDrugProfileApi(input)
    .then((response) => {
      dispatch(filterPrescriptionsNeedsReview({ filter }));
      return response;
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const overridePrescription = (input) => (dispatch, getState) => {
  const { preset } = getState().workQueue;
  const { filter } = getState().workQueue[preset];

  return overridePrescriptionAPI(input)
    .then((response) => {
      dispatch(filterPrescriptionsNeedsReview({ filter }));
      return response;
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const listEVisitRequestsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;
    const { sortBy, filter } = getState().workQueue[preset];
    const updatedFilter = applyFulfillingPharmacyFilter(getState, filter);

    dispatch(workQueueActions.updateCurrentPage({ preset, page }));

    return listEVisitRequestsApi({ page, sortBy, filter: updatedFilter })
      .then(({ count, results }) =>
        dispatch(workQueueActions.loadWorkQueueToPage({ preset, page, items: results, count }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedEVisitRequests = debounce(({ dispatch }) => {
  return dispatch(listEVisitRequestsByPage({ page: 1 }));
}, 1000);

export const sortInEVisitRequestsByPage =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateSortBy({ preset, sortBy }));

    debouncedEVisitRequests({ preset, dispatch });
  };

export const filterEVisitRequests =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().workQueue;

    dispatch(workQueueActions.updateFilter({ preset, filter }));

    debouncedEVisitRequests({ preset, dispatch });
  };
