import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import { upsertDrugProfile } from 'store/thunks/drugThunks';

import ManageDrugProfileModal from './ManageDrugProfileModal';

const AddDrugButton = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const createDrugProfile = (formData) => {
    setFormLoading(true);
    const drugProfile = { ...formData, isActive: !!formData?.isActive };
    dispatch(upsertDrugProfile(drugProfile))
      .then((response) => {
        if (response) {
          enqueueSnackbar('Drug profile created successfully', { variant: 'success' });
          setShowModal(false);
          setFormLoading(false);
        }
      })
      .finally(() => setFormLoading(false));
  };

  return (
    <>
      <Button
        sx={{ height: 33, mr: 2 }}
        variant='contained'
        color='primary'
        onClick={handleOpenModal}
      >
        Add New
      </Button>

      {showModal && (
        <ManageDrugProfileModal
          showModal={showModal}
          loading={formLoading}
          handleCloseModal={handleCloseModal}
          manageDrugProfile={createDrugProfile}
        />
      )}
    </>
  );
};

export default AddDrugButton;
