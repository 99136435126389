/* eslint-disable import/prefer-default-export */
import { eventActions } from 'store/slices/eventSlice';
import { getEventsApi, listAuditEventsApi } from 'api/events';
import { debounce } from 'lodash';
import { handleError } from './errorHandlerThunks';

export const getEvents =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().criticalEvents.events;

    return getEventsApi({ sortBy, filter, page })
      .then(({ events: { count, results }, eventTypes }) => {
        dispatch(eventActions.loadEvents({ count, events: results, page }));
        dispatch(eventActions.loadEventTypes(eventTypes));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const listEventsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { filter, sortBy } = getState().criticalEvents.events;

    dispatch(eventActions.updateEventsCurrentPage({ page }));

    return listAuditEventsApi({ filter, page, sortBy })
      .then(({ count, results }) => {
        dispatch(eventActions.loadEvents({ count, events: results, page }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedGetEvents = debounce(({ dispatch }) => {
  return dispatch(listEventsByPage({ page: 1 }));
}, 1000);

export const sortEvents =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(eventActions.updateSortBy({ sortBy }));

    debouncedGetEvents({ dispatch });
  };

export const filterEvents =
  ({ filter }) =>
  (dispatch) => {
    dispatch(eventActions.updateFilter({ filter }));

    debouncedGetEvents({ dispatch });
  };
