import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

import useTabContext from 'common/hooks/useTabContext';
import { listPatientInvoices } from 'store/thunks/patientThunks';

import InvoicesTable from './InvoicesTable';

const PatientInvoicesTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const isActiveTab = useTabContext().tab === 'PatientInvoicesTab';
  const isReady = useSelector(({ patient }) => !!patient[mpi]?.firstName);

  useEffect(() => {
    if (isActiveTab && isReady) {
      dispatch(listPatientInvoices({ mpi, page: 1 }));
    }
  }, [dispatch, mpi, isActiveTab, isReady]);

  if (!isActiveTab) {
    return null;
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Invoices
        </Typography>
      </Grid>

      <Grid item>
        <InvoicesTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientInvoicesTab;
