import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import { resolveDuplicatePrescription } from 'store/thunks/workQueueThunks';

const ResolveDuplicationOptions = {
  NEW_PRESCRIPTION: 'NEW_PRESCRIPTION',
  DUPLICATE_PRESCRIPTION: 'DUPLICATE_PRESCRIPTION',
};

const ResolveDuplicatePrescriptionModal = ({ open, onClose, prescriptionId, orderId }) => {
  const dispatch = useDispatch();
  const [reviewOption, setReviewOption] = useState(
    ResolveDuplicationOptions.DUPLICATE_PRESCRIPTION
  );
  const [loading, setLoading] = useState(false);

  const handleChangeRadio = (event) => setReviewOption(event.target.value);

  const resolvePrescription = () => {
    setLoading(true);
    const isDuplicate = reviewOption === ResolveDuplicationOptions.DUPLICATE_PRESCRIPTION;
    dispatch(resolveDuplicatePrescription({ prescriptionId, orderId, isDuplicate })).then(() => {
      onClose();
      setLoading(false);
      enqueueSnackbar('Prescription Duplication resolved!', { variant: 'success' });
    });
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Resolve Duplicate Prescription</DialogTitle>
      <Form
        onSubmit={resolvePrescription}
        render={({ handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: '400px' }}>
              <Grid container direction='row' sx={{ p: 1, pl: 0 }}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby='Review-Prescription-option-radioSelect-group'
                    value={reviewOption}
                    onChange={handleChangeRadio}
                  >
                    <FormControlLabel
                      value={ResolveDuplicationOptions.DUPLICATE_PRESCRIPTION}
                      control={<Radio />}
                      label='Mark as Duplicate Prescription'
                    />
                    <FormControlLabel
                      value={ResolveDuplicationOptions.NEW_PRESCRIPTION}
                      control={<Radio />}
                      label='Mark as New Prescription'
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton loading={loading} variant='contained' type='submit'>
                Resolve
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default ResolveDuplicatePrescriptionModal;

ResolveDuplicatePrescriptionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  prescriptionId: PropTypes.string.isRequired,
};
