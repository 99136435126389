export default {
  RX_ENTRY: 'RX_ENTRY',
  TRANSFER_REQUEST: 'TRANSFER_REQUEST',
};

export const PrescriptionReviewStatus = {
  PRESCRIPTION_DIRECTION_REVIEW: 'PRESCRIPTION_DIRECTION_REVIEW',
  PRESCRIPTION_DUPLICATE_REVIEW: 'PRESCRIPTION_DUPLICATE_REVIEW',
  DRUG_PROFILE_REVIEW: 'DRUG_PROFILE_REVIEW',
  PRESCRIPTION_REVIEW: 'PRESCRIPTION_REVIEW',
};
