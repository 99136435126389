import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { isEmpty, isNil, map, merge, omit } from 'lodash';

import CheckboxField from 'common/forms/CheckboxField';
import SelectField from 'common/forms/SelectField';
import { getGuarantors, updatePatientAuthorizations } from 'store/thunks/patientThunks';

const UpdateAuthorizationButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const authorization = useSelector(({ patient }) => patient[mpi]?.authorization);
  const guarantors = useSelector(({ patient }) => patient[mpi]?.guarantors);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleUpdateAuthorizations = (formData) => {
    const newAuthorization = omit(
      merge(formData, {
        auth: { by: 'GUARANTOR' },
        authTimestamp: new Date().toISOString(),
      }),
      ['auth.firstName', 'auth.lastName', 'auth.middleName']
    );

    return dispatch(updatePatientAuthorizations({ mpi, newAuthorization })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  useEffect(() => {
    if (isNil(guarantors)) {
      dispatch(getGuarantors({ mpi }));
    }
  }, [dispatch, mpi, guarantors]);

  return (
    <>
      <Button variant='contained' onClick={handleOpenModal} disabled={isEmpty(guarantors)}>
        Update
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-UpdateAuthorizationButton-header'
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle id='PatientView-UpdateAuthorizationButton-header'>
          Update Authorization
        </DialogTitle>

        <Form
          initialValues={{
            permissionToTransfer: false,
            mandatoryDisclosure: false,
            childSafetyWaiver: false,
            careboxAuthorization: false,
            textingAuthorization: false,
            ...authorization,
          }}
          onSubmit={handleUpdateAuthorizations}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <CheckboxField
                  id='PatientView-UpdateAuthorizationButton-permissionToTransfer-checkbox'
                  name='permissionToTransfer'
                  label='Permission To Transfer'
                  disableHelperText
                />

                <CheckboxField
                  id='PatientView-UpdateAuthorizationButton-mandatoryDisclosure-checkbox'
                  name='mandatoryDisclosure'
                  label='Mandatory Disclosure'
                  disableHelperText
                />

                <CheckboxField
                  id='PatientView-UpdateAuthorizationButton-childSafetyWaiver-checkbox'
                  name='childSafetyWaiver'
                  label='Child Safety Waiver'
                  disableHelperText
                />

                <CheckboxField
                  id='PatientView-UpdateAuthorizationButton-careboxAuthorization-checkbox'
                  name='careboxAuthorization'
                  label='Carebox Authorization'
                  disableHelperText
                />

                <CheckboxField
                  id='PatientView-UpdateAuthorizationButton-textingAuthorization-checkbox'
                  name='textingAuthorization'
                  label='Texting Authorization'
                />

                <SelectField
                  id='PatientView-UpdateAuthorizationButton-guarantor-checkbox'
                  label='Authorized By'
                  name='auth.id'
                  required
                >
                  {map(guarantors, ({ guarantorId, firstName, lastName, middleName }) => (
                    <MenuItem key={guarantorId} value={guarantorId}>
                      {firstName} {middleName} {lastName}
                    </MenuItem>
                  ))}
                </SelectField>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

UpdateAuthorizationButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default UpdateAuthorizationButton;
