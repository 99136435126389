import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useState } from 'react';

import useInfoDialog from 'common/hooks/useInfoDialog';
import { captureTransaction } from 'store/thunks/transactionsThunk';

import { LoadingButton } from '@mui/lab';
import TransactionAmountSelector from './TransactionAmountSelector';

const CaptureTransactionButton = ({
  amount,
  patientId,
  externalTransactionId,
  userId,
  transactionId,
}) => {
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const initialValues = { amountType: 'fullAmount' };

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleCaptureTransaction = (form) => {
    const isOtherAmount = form.amountType === 'otherAmount';
    const { otherAmount } = form;
    const isLessOrEqualTotalAmount = Number(otherAmount) <= Number(amount);

    const amountToCapture =
      isOtherAmount && isLessOrEqualTotalAmount // Don't capture more than the total amount
        ? Number(otherAmount)
        : Number(amount);

    showInfoDialog({
      title: 'Confirm Capture',
      message: 'Are you sure you want to capture this transaction?',
      callback: () => {
        setSubmitting(true);

        dispatch(
          captureTransaction({
            amount: amountToCapture,
            patientId,
            externalTransactionId,
            userId,
            transactionId,
          })
        ).then(() => {
          setSubmitting(false);
        });
      },
      cancelable: true,
      isWarning: false,
      confirmButtonText: 'Capture',
    });
  };

  const handleOpenModal = () => setShowModal(true);

  return (
    <>
      <InfoDialog />

      <Button variant='contained' sx={{ width: 75 }} onClick={handleOpenModal}>
        Capture
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='Transactions-select-amount-header'
        fullWidth
      >
        <DialogTitle id='Transactions-select-amount-header'>Confirm Amount</DialogTitle>

        <Form
          initialValues={initialValues}
          onSubmit={handleCaptureTransaction}
          render={({ handleSubmit, invalid }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TransactionAmountSelector
                  amount={amount}
                  label='How much do you want to capture?'
                />
              </DialogContent>

              <DialogActions>
                <Button
                  variant='outlined'
                  color='secondary'
                  onClick={handleCloseModal}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <LoadingButton
                  variant='contained'
                  loading={submitting}
                  disabled={invalid}
                  type='submit'
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

CaptureTransactionButton.propTypes = {
  amount: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
  externalTransactionId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
};

export default CaptureTransactionButton;
