/* eslint-disable import/prefer-default-export */
import { first } from 'lodash';
import * as Sentry from '@sentry/react';

import { errorHandlerActions } from 'store/slices/errorHandlerSlice';

export const handleError =
  ({ error, heading }) =>
  (dispatch) => {
    Sentry.withScope((scope) => {
      const requestId = first(error.response?.errors)?.extensions?.requestId;
      /* istanbul ignore if */
      if (requestId) {
        scope.setTag('requestId', requestId);
      }
      Sentry.captureException(error);
    });

    dispatch(
      errorHandlerActions.setError({
        error: {
          errorHeader: heading || 'Error',
          message:
            /* istanbul ignore next */ error.response?.message ||
            first(error.response?.errors)?.message ||
            error.message,
          stack: error.stack,
        },
      })
    );
  };
