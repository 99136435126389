/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listDrugProfilesGql = gql`
  query listDrugProfiles(
    $limit: Int = 25
    $offset: Int = 0
    $filter: DrugProfileFilter
    $sortBy: DrugProfileSortBy
  ) {
    listDrugProfiles(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        drugName
        strength
        unit
        ndc
        manufacturer
        createdAt
        updatedAt
        isActive
        packageSize
        imprint
        drugProfileId
        genericDescription
        shipmentInternalNote
        form
        drugNameHash
        associatedPrescriptionDescriptions
      }
    }
  }
`;

export const upsertDrugProfileGql = gql`
  mutation upsertDrugProfile($input: UpsertDrugProfileInput) {
    upsertDrugProfile(input: $input) {
      drugName
      strength
      unit
      ndc
      manufacturer
      isActive
      packageSize
      imprint
      drugProfileId
      associatedPrescriptionDescriptions
      genericDescription
      shipmentInternalNote
      form
    }
  }
`;

export const listDrugProfilesByDrugNameGql = gql`
  query listDrugProfilesByDrugName($drugName: String!) {
    listDrugProfilesByDrugName(drugName: $drugName) {
      drugName
      drugProfileId
      associatedPrescriptionDescriptions
      strength
      form
      unit
    }
  }
`;
