import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import stc from 'string-to-color';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import UserIcon from 'common/components/UserIcon';
import { listEventsByPage, sortEvents } from 'store/thunks/eventThunks';

import EventDataViewer from './EventDataViewer';

const headCells = [
  {
    id: 'pomUserName',
    label: 'Event Actor',
  },
  {
    id: 'auditEventType',
    label: 'Event Type',
  },
  {
    id: 'auditEventCode',
    label: 'Event Code',
  },
  {
    id: 'createdTimestamp',
    label: 'Created Timestamp',
  },
];

const EventsTable = () => {
  const dispatch = useDispatch();
  const { pages, sortBy, currentPage, count } = useSelector(
    ({ criticalEvents }) => criticalEvents.events
  );
  const events = pages[currentPage];

  const totalPages = Math.ceil(count / 25);

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didn't change, don't do anything.
      return;
    }
    dispatch(listEventsByPage({ page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortEvents({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  return (
    <Grid component={Paper} sx={{ py: 1, px: 2 }} container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={sortBy.sortKey === headCell.id}
                      direction={sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'}
                      onClick={handleSort}
                      data-head-property={headCell.id}
                    >
                      {headCell.label}
                      {sortBy.sortKey === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {toLower(sortBy.sortOrder) === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(events, (event) => {
                const bgcolor = stc(event.userName);

                return (
                  <TableRow key={event.eventId} hover>
                    <TableCell component='th' scope='row' align='left' sx={{ py: '0px' }}>
                      <Grid sx={{ justifyContent: 'start', alignItems: 'center', display: 'flex' }}>
                        <UserIcon
                          bgcolor={bgcolor}
                          firstName={event.firstName}
                          lastName={event.lastName}
                        />
                        <Grid p={2}>
                          <Typography variant='body1' fontWeight={600}>
                            {event.userName}
                          </Typography>
                          <Typography variant='subtitle2' fontWeight='500'>
                            {event.userEmail}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{event.eventType.replace(/_/g, ' ')}</TableCell>
                    <TableCell>{event.eventCode}</TableCell>
                    <TableCell>
                      {format(new Date(event.createdTimestamp), 'MM/dd/yyyy hh:mm a')}
                    </TableCell>
                    <TableCell>
                      <EventDataViewer event={event} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {!isNil(events) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(events) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(events) && isEmpty(events) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default EventsTable;
