import useInfoDialog from 'common/hooks/useInfoDialog';

const useSmsResetWarningDialog = () => {
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const showWarning = ({ warn, warnCallback, callback }) => {
    if (!warn) {
      callback();
      return;
    }

    showInfoDialog({
      title: 'Are you sure?',
      message:
        "The new default address's state does not allow SMS text, updating the address will remove the SMS text functionality.",
      callback: () => {
        warnCallback();
      },
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  return { showWarning, InfoDialog };
};

export default useSmsResetWarningDialog;
