/* eslint-disable import/prefer-default-export */
import graphqlClient from 'api/graphql';

import { listKnownMedicalConditionsGql } from './gql';

export const listKnownMedicalConditionsApi = ({ input }) => {
  return graphqlClient
    .request(listKnownMedicalConditionsGql, input)
    .then(({ listKnownMedicalConditions }) => listKnownMedicalConditions);
};
