export default {
  inactive: 'INACTIVE',
  active: 'ACTIVE',
};

export const prescriptionReviewStatus = {
  PRESCRIPTION_DIRECTION_REVIEW: 'Direction Review',
  PRESCRIPTION_DUPLICATE_REVIEW: 'Duplicate Rx Review',
  DRUG_PROFILE_REVIEW: 'Drug Profile Review',
  PRESCRIPTION_REVIEW: 'Prescription Review',
};
