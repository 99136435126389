/* eslint-disable max-lines */
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useInfoDialog from 'common/hooks/useInfoDialog';
import { voidTransaction } from 'store/thunks/transactionsThunk';

const VoidTransactionButton = ({ patientId, externalTransactionId, userId, transactionId }) => {
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const handleVoidTransaction = () => {
    showInfoDialog({
      title: 'Confirm Void',
      message: 'Are you sure you want to void this transaction?',
      callback: () =>
        dispatch(voidTransaction({ patientId, externalTransactionId, userId, transactionId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Void',
    });
  };

  return (
    <>
      <InfoDialog />

      <Button
        sx={{ mr: 2, width: 75 }}
        variant='outlined'
        color='secondary'
        size='medium'
        onClick={handleVoidTransaction}
      >
        Void
      </Button>
    </>
  );
};

VoidTransactionButton.propTypes = {
  patientId: PropTypes.string.isRequired,
  externalTransactionId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
};

export default VoidTransactionButton;
