import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { updatePatientPharmacy } from 'store/thunks/patientThunks';
import { formatZipCode } from 'common/utils';

const EditPharmacyDialog = ({ mpi, patientPharmacyId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) =>
    find(patient[mpi].pharmacies, { patientPharmacyId })
  );

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleUpdatePatientPharmacy = (formData) => {
    return dispatch(
      updatePatientPharmacy({
        mpi,
        pharmacy: {
          ...formData,
          name: formData.name || '',
          phoneNumber: formData.phoneNumber || '',
          fax: formData.fax || '',
          npi: formData.npi || '',
          addressLine1: formData.addressLine1 || '',
          addressLine2: formData.addressLine2 || '',
          city: formData.city || '',
          state: formData.state || '',
          zip: formData.zip || '',
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditPharmacyDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditPharmacyDialog-header'>Edit Pharmacy</DialogTitle>

      <Form
        initialValues={initialValues}
        onSubmit={handleUpdatePatientPharmacy}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='PatientView-EditPharmacyDialog-name-input'
                label='Pharmacy Name'
                name='name'
                required
                autoFocus
              />

              <TextField
                id='PatientView-EditPharmacyDialog-phoneNumber-input'
                label='Phone Number'
                name='phoneNumber'
                required
                validations={[validatePhoneNumber()]}
              />

              <TextField
                id='PatientView-EditPharmacyDialog-fax-input'
                label='Fax'
                name='fax'
                validations={[validatePhoneNumber('Invalid Fax Number')]}
              />

              <TextField
                id='PatientView-EditPharmacyDialog-npi-input'
                label='NPI'
                name='npi'
                validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
              />

              <TextField
                id='PatientView-EditPharmacyDialog-addressLine1-input'
                label='Address Line 1'
                name='addressLine1'
                required
              />

              <TextField
                id='PatientView-EditPharmacyDialog-addressLine2-input'
                label='Address Line 2'
                name='addressLine2'
              />

              <TextField
                id='PatientView-EditPharmacyDialog-city-input'
                label='City'
                name='city'
                required
              />

              <SelectField
                id='PatientView-EditPharmacyDialog-state-dropdown'
                label='State'
                name='state'
                required
              >
                {map(usStateAbbreviation, ({ name, abbreviation }) => (
                  <MenuItem value={abbreviation} key={abbreviation}>
                    {name}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField
                id='PatientView-EditPharmacyDialog-zip-input'
                label='Zip'
                name='zip'
                required
                maxLength={10}
                format={formatZipCode}
                validations={[validateZipCode()]}
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditPharmacyDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientPharmacyId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPharmacyDialog;
