import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { deletePatientComment } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';
import useRoles from 'common/hooks/useRoles';

const PatientCommentOptions = ({ commentId, authorId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const hasAccess = useRoles();
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const { mpi } = useParams();
  const userId = useSelector(({ auth }) => auth.userId);
  const canDelete = userId === authorId || hasAccess.deleteComments;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const deleteComment = () => {
    showInfoDialog({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete the comment?',
      callback: () => dispatch(deletePatientComment({ mpi, patientCommentId: commentId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  return (
    <>
      <InfoDialog />
      <IconButton
        id={`PatientView-PatientComment-${commentId}options-button`}
        aria-label={`Comment options ${commentId}`}
        aria-controls={menuOpen ? `PatientView-PatientComment-${commentId}options-menu` : undefined}
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`PatientView-PatientComment-${commentId}options-menu`}
        aria-labelledby={`PatientView-PatientComment-${commentId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={deleteComment} disabled={!canDelete}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

PatientCommentOptions.propTypes = {
  commentId: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
};

export default PatientCommentOptions;
