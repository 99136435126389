import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalize, find, map, omit, pick } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { utcToZonedTime } from 'date-fns-tz';

import gender from 'common/constants/gender';
import DateField from 'common/forms/DateField';
import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import languages from 'common/constants/languages';
import {
  validateEmail,
  validatePhoneAllowedInState,
  validatePhoneNumber,
  validateValueWithRegex,
} from 'common/forms/formValidations';
import { editPatient } from 'store/thunks/patientThunks';
import CheckboxField from 'common/forms/CheckboxField';

const EditPatientButton = ({ mpi }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const patientInfo = useSelector(({ patient }) => {
    return {
      ...pick(patient[mpi], [
        'firstName',
        'lastName',
        'middleName',
        'gender',
        'email',
        'homePhone',
        'workPhone',
        'mobilePhone',
        'last4ssn',
        'notificationPreferences',
      ]),
      dob: patient[mpi]?.dob && utcToZonedTime(new Date(patient[mpi]?.dob), 'utc'),
      primaryLanguage: find(patient[mpi]?.languages, 'isPrimary')?.language,
      address: find(patient[mpi]?.addresses, 'isDefault')?.address,
      qs1Id: patient[mpi]?.systemIds?.qs1PatientId,
    };
  }, shallowEqual);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleEditPatient = (formData) => {
    const patientData = {
      ...omit(formData, [
        'primaryLanguage',
        'notificationPreferences',
        'emailPreference',
        'phonePreference',
        'address',
      ]),
      languages: [{ language: formData.primaryLanguage, isPrimary: true }],
      dob: format(new Date(formData.dob), 'yyyy-MM-dd'),
      last4ssn: formData.last4ssn || '',
      middleName: formData.middleName || '',
      workPhone: formData.workPhone || '',
      homePhone: formData.homePhone || '',
      mpi,
      preferencesState: {
        email: formData.emailPreference,
        phone: formData.phonePreference,
      },
    };

    return dispatch(editPatient({ patientData })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  const initialFormValues = {
    ...patientInfo,
    emailPreference: patientInfo?.notificationPreferences?.email,
    phonePreference: patientInfo?.notificationPreferences?.phone,
  };

  return (
    <>
      <Button
        color='secondary'
        variant='outlined'
        onClick={handleOpenModal}
        size='small'
        sx={{ height: theme.spacing(4) }}
        disabled={!patientInfo.firstName}
      >
        Edit Patient
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-EditPatientButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-EditPatientButton-header'>Edit Patient</DialogTitle>

        <Form
          initialValues={initialFormValues}
          onSubmit={handleEditPatient}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-EditPatientButton-firstName-input'
                  label='First Name'
                  name='firstName'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientView-EditPatientButton-lastName-input'
                  label='Last Name'
                  name='lastName'
                  required
                />

                <TextField
                  id='PatientView-EditPatientButton-middleName-input'
                  label='Middle Name'
                  name='middleName'
                />

                <DateField
                  name='dob'
                  id='PatientView-EditPatientButton-dob-input'
                  label='Date of Birth'
                  maxDate={new Date()}
                  required
                />

                <TextField
                  id='PatientView-EditPatientButton-ssn-input'
                  label='SSN (Last 4)'
                  name='last4ssn'
                  validations={[validateValueWithRegex('Must be 4 numbers', /^\d{4}$/)]}
                />

                <SelectField
                  name='gender'
                  id='PatientView-EditPatientButton-gender-dropdown'
                  label='Gender'
                  required
                >
                  {map(gender, (name, value) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <SelectField
                  name='primaryLanguage'
                  id='PatientView-EditPatientButton-primaryLanguage-dropdown'
                  label='Primary Language Spoken'
                  required
                >
                  {map(languages, (language) => (
                    <MenuItem key={language} value={language}>
                      {capitalize(language)}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-EditPatientButton-email-input'
                  label='Email'
                  name='email'
                  required
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientView-EditPatientButton-mobilePhoneNumber-input'
                  label='Mobile Phone Number'
                  name='mobilePhone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-EditPatientButton-workPhoneNumber-input'
                  label='Work Phone Number'
                  name='workPhone'
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-EditPatientButton-homePhoneNumber-input'
                  label='Home Phone Number'
                  name='homePhone'
                  validations={[validatePhoneNumber()]}
                />

                {patientInfo.qs1Id && (
                  <TextField
                    id='PatientView-EditPatientButton-qs1Id-input'
                    label='QS1 Id'
                    name='qs1Id'
                  />
                )}

                <CheckboxField
                  label='Email Notification Preference'
                  name='emailPreference'
                  id='PatientView-EditPatientButton-emailPreference-checkbox'
                />

                <CheckboxField
                  label='Phone Notification Preference'
                  name='phonePreference'
                  id='PatientView-EditPatientButton-phonePreference-checkbox'
                  validations={[validatePhoneAllowedInState(undefined, 'address.state')]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

EditPatientButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default EditPatientButton;
