import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { enqueueSnackbar } from 'notistack';

import useInfoDialog from 'common/hooks/useInfoDialog';
import { useDispatch } from 'react-redux';
import { markOrderForExternalProcessing } from 'store/thunks/patientThunks';

const ReviewOrderVerificationButton = ({ orderId, mpi, disabled }) => {
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const dispatch = useDispatch();

  const handleMarkAsReview = () => {
    showInfoDialog({
      title: 'Confirm Processing External',
      message: `Are you sure you want to mark this order to be processed external?`,
      isWarning: true,
      callback: () =>
        dispatch(markOrderForExternalProcessing({ mpi, orderId })).then((isMarkedReview) => {
          if (isMarkedReview) {
            enqueueSnackbar(`Order ${orderId} marked for processing external`, {
              variant: 'error',
            });
          }
        }),
      cancelable: true,
      confirmButtonText: 'Yes',
    });
  };

  return (
    <>
      <InfoDialog />

      <LoadingButton
        size='small'
        disabled={disabled}
        color='error'
        onClick={handleMarkAsReview}
        variant='outlined'
      >
        Process External
      </LoadingButton>
    </>
  );
};

ReviewOrderVerificationButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

ReviewOrderVerificationButton.defaultProps = {
  disabled: false,
};

export default ReviewOrderVerificationButton;
