import { Button, Grid, Popover, Typography } from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterProfileIncompletePatients } from 'store/thunks/workQueueThunks';

const IncompleteProfileFilterButton = () => {
  const filter = useSelector(({ workQueue: { profileIncomplete } }) => ({
    ...profileIncomplete.filter,
    createdAfter:
      profileIncomplete.filter.createdAfter && new Date(profileIncomplete.filter.createdAfter),
    dob: profileIncomplete.filter.dob && new Date(profileIncomplete.filter.dob),
  }));

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-IncompleteProfileFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterProfileIncompletePatients({
        filter: {
          ...formData,
          createdAfter: formData.createdAfter && formData.createdAfter.toISOString(),
          dob: formData.dob && formData.dob.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name='name'
                    id='WorkQueue-IncompleteProfileFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Date of Birth'
                    name='dob'
                    id='WorkQueue-IncompleteProfileFilterButton-dob-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Created After'
                    name='createdAfter'
                    id='WorkQueue-IncompleteProfileFilterButton-needsByDate-dateField'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default IncompleteProfileFilterButton;
