import { useEffect } from 'react';
import { Avatar, Badge, Box, Grid, Paper, Skeleton, Typography } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { capitalize, first, pick, trim } from 'lodash';
import stc from 'string-to-color';

import { getFullPatientDetails, getPatient } from 'store/thunks/patientThunks';
import useRoles from 'common/hooks/useRoles';

import { PatientStatus } from 'enums/patient.enum';
import PatientDetailsTabs from './components/PatientDetailsTabs';
import PatientInfo from './components/PatientInfo';
import OnboardingButton from './components/OnboardingButton';
import EditPatientButton from './components/EditPatientButton';
import ManagePatientUsersButton from './components/ManagePatientUsersButton';

const PatientView = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const { mpi } = useParams();
  const patientInfo = useSelector(
    ({ patient }) => pick(patient[mpi], 'firstName', 'lastName', 'status', 'email'),
    shallowEqual
  );
  const fullName =
    patientInfo?.firstName &&
    trim(`${patientInfo?.lastName}${patientInfo?.lastName ? ',' : ''} ${patientInfo?.firstName}`);

  const initials =
    `${capitalize(first(patientInfo?.firstName))}${capitalize(first(patientInfo?.lastName))}` ||
    undefined;
  const avatarBgColor =
    patientInfo?.firstName && stc(`${fullName}${patientInfo.dob}${patientInfo.email}`);

  const statusBgColor = lighten(
    {
      OPEN: theme.palette.info.main,
      ONBOARDED: theme.palette.warning.main,
      QUALIFIED: theme.palette.success.main,
      UNQUALIFIED: theme.palette.error.main,
      DEACTIVATED: theme.palette.error.main,
      PROFILE_INCOMPLETE: theme.palette.error.main,
    }[patientInfo?.status] || '#FFF',
    0.7
  );

  useEffect(() => {
    document.title = patientInfo?.firstName ? `${fullName} - SmartOS` : 'SmartOS';
  }, [fullName, patientInfo?.firstName]);

  useEffect(() => {
    dispatch(getPatient({ mpi }));
    dispatch(getFullPatientDetails({ mpi }));
  }, [dispatch, mpi]);

  return (
    <Grid container direction='column' gap={4} sx={{ px: 2, pb: 2 }}>
      <Grid item sx={{ minHeight: 40 }} container>
        <Grid item sx={{ display: 'inline-flex', flexGrow: 1, alignItems: 'center' }}>
          {fullName && (
            <>
              <Typography component='h1' variant='h4' sx={{ fontWeight: '500', mr: 2 }}>
                {fullName}
              </Typography>
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  bgcolor: statusBgColor,
                  borderRadius: 1,
                }}
              >
                <Typography
                  sx={{ color: theme.palette.getContrastText(statusBgColor || '#000') }}
                  align='center'
                >
                  {capitalize(patientInfo?.status?.replace(/_/g, ' '))}
                </Typography>
              </Box>

              <Badge
                color='error'
                variant='dot'
                invisible={patientInfo?.status !== PatientStatus.PROFILE_INCOMPLETE}
              >
                <ManagePatientUsersButton mpi={mpi} patientStatus={patientInfo?.status} />
              </Badge>
            </>
          )}
          {!fullName && <Skeleton variant='rounded' width={250} height={40} animation='wave' />}
        </Grid>
        {hasAccess.onboardPatient && (
          <Grid item sx={{ pr: 2 }}>
            <OnboardingButton mpi={mpi} />
          </Grid>
        )}
      </Grid>

      <Grid item container>
        <Grid item sx={{ minWidth: 200, pb: 2 }} xs={12} md={1}>
          <Avatar
            sx={{
              bgcolor: avatarBgColor,
              color: theme.palette.getContrastText(avatarBgColor || '#000'),
              width: theme.spacing(20),
              height: theme.spacing(20),
              boxShadow: '1px 2px 7px grey',
              fontSize: '5.5em',
              letterSpacing: '0.3rem',
              m: 'auto',
            }}
            alt={patientInfo?.firstName}
          >
            {initials}
          </Avatar>

          {hasAccess.editPatientProfile && (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
              <EditPatientButton mpi={mpi} />
            </Box>
          )}
        </Grid>

        <Grid item component={Paper} sx={{ p: 2, flexGrow: 1 }}>
          <PatientInfo mpi={mpi} />
        </Grid>
      </Grid>

      <Grid item>
        <PatientDetailsTabs />
      </Grid>
    </Grid>
  );
};

export default PatientView;
