import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const headCells = [
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'itemName',
    label: 'Item Name',
  },
  {
    id: 'requestedDate',
    label: 'Requested Date',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'rxNumber',
    label: 'Rx Number',
  },
  {
    id: 'strength',
    label: 'Strength',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'quantity',
    label: 'Quantity',
  },
  {
    id: 'price',
    label: 'Price',
  },
];

const ShipmentOrderLineItemTable = ({ lineItemRow, lineItems }) => {
  return (
    <TableContainer sx={{ mb: 2, p: 2 }} key={`lineItemRow-${lineItemRow}`}>
      <Table size='small'>
        <TableHead>
          <TableRow hover>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} align={headCell.align} sx={{ fontWeight: '600' }}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {map(lineItems, (item) => (
            <TableRow key={item.lineItemId} hover>
              <TableCell>{format(new Date(item.needsByDate), 'MM/dd/yyyy hh:mm a')}</TableCell>
              <TableCell>{item.itemName}</TableCell>
              <TableCell>{format(new Date(item.requestedDate), 'MM/dd/yyyy hh:mm a')}</TableCell>
              <TableCell>{item.itemType}</TableCell>
              <TableCell>{item.rxNumber}</TableCell>
              <TableCell>{item.strength}</TableCell>
              <TableCell>{item.form}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>{item.cost}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ShipmentOrderLineItemTable.propTypes = {
  lineItemRow: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(
    PropTypes.shape({
      lineItemId: PropTypes.string,
      needsByDate: PropTypes.string,
      itemName: PropTypes.string,
      requestedDate: PropTypes.string,
      itemType: PropTypes.string,
      rxNumber: PropTypes.string,
      strength: PropTypes.string,
      form: PropTypes.string,
      quantity: PropTypes.string,
      cost: PropTypes.number,
    })
  ).isRequired,
};

export default ShipmentOrderLineItemTable;
