/* eslint-disable import/prefer-default-export */
import { transformPatientAddresses } from 'api/patient/transform';
import { map, omit } from 'lodash';

export const transformListPaymentTransactions = ({
  listPaymentTransactions: { count, results },
}) => {
  const transactions = map(results, (result) => {
    const { user, paymentMethod } = result;
    const { last4digit } = paymentMethod;

    return {
      ...omit(result, ['user']),
      patientName: `${result.patientLastName}, ${result.patientFirstName}`,
      mpi: result.mpi,
      userFullName: `${user.firstName} ${user.lastName}`,
      userId: user.userId,
      cardNumber: last4digit.replace(/\*/g, ''),
      patientDob: result.patientDob,
      patientGender: result.patientGender,
      cardExpiry: paymentMethod.expiration,
      orderId: result.pomOrderId,
    };
  });

  return { count, results: transactions };
};

export const transformFailedPatientOrders = ({ getFailedOrderDetails }) => {
  const { patient, mpi, ...failedOrder } = getFailedOrderDetails;
  const { firstName, lastName, ...demographics } = patient.patientDemographics;
  const { addresses } = patient.patientContact;
  const patientName = `${lastName}, ${firstName}`;

  return {
    patient: {
      mpi,
      patientName,
      ...demographics,
    },
    failedOrder,
    addresses: transformPatientAddresses({ addresses }),
  };
};
