import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const TabContext = createContext('');

const TabProvider = ({ initialValue, children }) => {
  const [tab, setTab] = useState(initialValue);

  const memoizedValue = useMemo(() => ({ tab, setTab: (_, newTab) => setTab(newTab) }), [tab]);

  return <TabContext.Provider value={memoizedValue}>{children}</TabContext.Provider>;
};

TabProvider.propTypes = {
  initialValue: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const useTabContext = () => useContext(TabContext);

export default useTabContext;
export { TabProvider };
