import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form } from 'react-final-form';

import TextField from 'common/forms/TextField';
import { validateValueWithRegex } from 'common/forms/formValidations';

const ReviewProceedSubstanceDialog = ({ confirmProceedDialog, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby='ReviewActionItems-ReviewSubstance-confirm-dialog'
      fullWidth
    >
      <DialogTitle id='ReviewActionItems-ReviewSubstance-confirm-Dialog'>
        Confirm and Proceed
      </DialogTitle>
      <Form
        onSubmit={confirmProceedDialog}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='ReviewActionItems-ReviewSubstance-internalmessage-input'
                label='QS1 Rx Number'
                name='qs1RxNumber'
                validations={[validateValueWithRegex('Must be 8 numbers', /^\d{8}$/)]}
                required
              />
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Confirm
              </LoadingButton>
              <Button
                onClick={onClose}
                color='secondary'
                size='medium'
                variant='outlined'
                disabled={submitting}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

ReviewProceedSubstanceDialog.propTypes = {
  confirmProceedDialog: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ReviewProceedSubstanceDialog;
