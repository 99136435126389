import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Avatar } from '@mui/material';
import { first } from 'lodash';

const UserIcon = ({ bgcolor, firstName, lastName }) => {
  const theme = useTheme();
  return (
    <Avatar
      sx={{
        bgcolor,
        color: theme.palette.getContrastText(bgcolor),
        boxShadow: '1px 2px 7px grey',
        p: 2.5,
      }}
      alt={first(firstName)}
    >
      {first(firstName)}
      {first(lastName)}
    </Avatar>
  );
};

UserIcon.propTypes = {
  bgcolor: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
};

export default UserIcon;
