import { FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { format, isValid } from 'date-fns';

import {
  composeValidations,
  validateDate,
  validateMaxDate,
  validateMinDate,
  validateRequired,
} from './formValidations';

const DateField = ({
  name,
  id,
  label,
  required,
  helperText,
  maxDate,
  minDate,
  validations,
  disablePast,
}) => {
  const validate = composeValidations([
    validateDate('Invalid date'),
    ...validations,
    ...(required ? [validateRequired('Required')] : []),
    validateMinDate(`Date must be after ${format(minDate, 'MM/dd/yyyy')}`, minDate),
    ...(maxDate
      ? [validateMaxDate(`Date must be before ${format(maxDate, 'MM/dd/yyyy')}`, maxDate)]
      : []),
  ]);

  return (
    <Field
      name={name}
      id={id}
      validate={validate}
      render={({ input, meta }) => {
        // showError needs to be a boolean, material type check this value
        const showError = !!meta.error && (!!meta.dirty || !!meta.touched);
        const displayedHelperText = ((!!meta.dirty || !!meta.touched) && meta.error) || helperText;

        return (
          <DesktopDatePicker
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...input} // intentional spreading, injecting final-form
            value={isValid(input.value) ? input.value : ''} // the value of date picker must be a valid date
            onChange={input.onChange}
            onAccept={input.onChange}
            maxDate={maxDate}
            minDate={minDate}
            disablePast={disablePast}
            getOpenDialogAriaText={
              // only announce selected date if there is a date selected
              (value, utils) =>
                value &&
                `Choose date, selected date is ${utils.format(utils.date(value), 'fullDate')}.`
            }
            renderInput={(params) => {
              return (
                <FormControl error={showError} variant='standard' fullWidth>
                  <InputLabel shrink htmlFor={id} required={required} sx={{ fontSize: '1.2em' }}>
                    {label}
                  </InputLabel>

                  <InputBase
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...input} // intentional spreading, injecting final-form
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params.inputProps}
                    // padding right to prevent Date icon to extend out of the border
                    sx={{
                      py: 0.5,
                      pl: 1,
                      pr: 2.5,
                      '&:focus, &.Mui-focused': { pr: 2.3 },
                      'label + &': {
                        marginTop: '1.3em',
                      },
                      marginTop: '1.3em',
                      boxSizing: 'border-box',
                      borderRadius: 1,
                      marginBottom: 0.25,
                      '&:focus, &:focus&:invalid, &.Mui-focused': {
                        borderWidth: 2,
                        py: '3px',
                        pl: 0.9,
                        pr: 2.3,
                      },
                    }}
                    ref={params.inputRef}
                    id={id}
                    label={label}
                    required={required}
                    inputProps={{
                      'aria-required': required,
                    }}
                    endAdornment={params.InputProps.endAdornment}
                  />

                  <FormHelperText>{displayedHelperText}</FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      }}
    />
  );
};

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  helperText: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  disablePast: PropTypes.bool,
};

DateField.defaultProps = {
  validations: [],
  required: false,
  helperText: '',
  minDate: new Date(1900, 0),
  maxDate: undefined,
  disablePast: false,
};

export default DateField;
