/* eslint-disable import/prefer-default-export */

import { map, omit } from 'lodash';

export const transformListLineItems = ({ listLineItems }) => {
  return {
    count: listLineItems.count,
    lineItems: map(listLineItems.results, ({ patient, partner, lineItem }) => {
      const { firstName, lastName } = patient.patientDemographics;

      return {
        ...omit(lineItem, 'item'),
        mpi: patient.mpi,
        patientName: `${lastName}, ${firstName}`,
        partnerName: partner.partnerName,
        itemName: lineItem.item.patientMedication?.name || '',
      };
    }),
  };
};

export const transformBulkLabels = ({ listBulkLabels }) => ({
  count: listBulkLabels.count,
  bulkLabels: map(listBulkLabels.results, ({ patient, ...bulkLabels }) => {
    const { firstName, lastName } = patient.patientDemographics;
    return {
      ...bulkLabels,
      mpi: patient.mpi,
      patientName: `${lastName}, ${firstName}`,
    };
  }),
});

export const transformListOrdersForProductFulfillment = ({ listOrdersForProductFulfillment }) => {
  return {
    count: listOrdersForProductFulfillment.count,
    orders: map(listOrdersForProductFulfillment.orders, ({ patient, ...order }) => {
      const { firstName, lastName } = patient.patientDemographics;
      const { qs1PatientId } = patient.patientIdentity;

      return {
        ...order,
        mpi: patient.mpi,
        patientName: `${lastName}, ${firstName}`,
        qs1Id: `${qs1PatientId}`,
      };
    }),
  };
};
