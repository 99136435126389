export default {
  OPEN: 'Open',
  ORDER_VERIFICATION: 'Verification',
  ORDER_VERIFIED: 'Verified',
  ADDED_TO_SHIPMENT: 'Shipment Created',
  HOLD: 'Hold',
  CANCELED: 'Canceled',
  READY_FOR_SHIPMENT: 'Ready for shipment',
  SHIPPED: 'Shipped',
  ORDER_RE_VERIFICATION: 'Reverification',
};
