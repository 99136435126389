export default [
  { name: 'Arizona', abbreviation: 'AZ', isSMSAllowed: true },
  { name: 'Alabama', abbreviation: 'AL', isSMSAllowed: true },
  { name: 'Alaska', abbreviation: 'AK', isSMSAllowed: true },
  { name: 'Arkansas', abbreviation: 'AR', isSMSAllowed: true },
  { name: 'California', abbreviation: 'CA', isSMSAllowed: true },
  { name: 'Colorado', abbreviation: 'CO', isSMSAllowed: true },
  { name: 'Connecticut', abbreviation: 'CT', isSMSAllowed: true },
  { name: 'Delaware', abbreviation: 'DE', isSMSAllowed: true },
  { name: 'Florida', abbreviation: 'FL', isSMSAllowed: true },
  { name: 'Georgia', abbreviation: 'GA', isSMSAllowed: true },
  { name: 'Hawaii', abbreviation: 'HI', isSMSAllowed: true },
  { name: 'Idaho', abbreviation: 'ID', isSMSAllowed: true },
  { name: 'Illinois', abbreviation: 'IL', isSMSAllowed: true },
  { name: 'Indiana', abbreviation: 'IN', isSMSAllowed: true },
  { name: 'Iowa', abbreviation: 'IA', isSMSAllowed: true },
  { name: 'Kansas', abbreviation: 'KS', isSMSAllowed: true },
  { name: 'Kentucky', abbreviation: 'KY', isSMSAllowed: true },
  { name: 'Louisiana', abbreviation: 'LA', isSMSAllowed: true },
  { name: 'Maine', abbreviation: 'ME', isSMSAllowed: true },
  { name: 'Maryland', abbreviation: 'MD', isSMSAllowed: true },
  { name: 'Massachusetts', abbreviation: 'MA', isSMSAllowed: true },
  { name: 'Michigan', abbreviation: 'MI', isSMSAllowed: true },
  { name: 'Minnesota', abbreviation: 'MN', isSMSAllowed: true },
  { name: 'Mississippi', abbreviation: 'MS', isSMSAllowed: true },
  { name: 'Missouri', abbreviation: 'MO', isSMSAllowed: true },
  { name: 'Montana', abbreviation: 'MT', isSMSAllowed: true },
  { name: 'Nebraska', abbreviation: 'NE', isSMSAllowed: true },
  { name: 'Nevada', abbreviation: 'NV', isSMSAllowed: true },
  { name: 'New Hampshire', abbreviation: 'NH', isSMSAllowed: true },
  { name: 'New Jersey', abbreviation: 'NJ', isSMSAllowed: true },
  { name: 'New Mexico', abbreviation: 'NM', isSMSAllowed: true },
  { name: 'New York', abbreviation: 'NY', isSMSAllowed: true },
  { name: 'North Carolina', abbreviation: 'NC', isSMSAllowed: true },
  { name: 'North Dakota', abbreviation: 'ND', isSMSAllowed: true },
  { name: 'Ohio', abbreviation: 'OH', isSMSAllowed: true },
  { name: 'Oklahoma', abbreviation: 'OK', isSMSAllowed: true },
  { name: 'Oregon', abbreviation: 'OR', isSMSAllowed: true },
  { name: 'Pennsylvania', abbreviation: 'PA', isSMSAllowed: true },
  { name: 'Rhode Island', abbreviation: 'RI', isSMSAllowed: true },
  { name: 'South Carolina', abbreviation: 'SC', isSMSAllowed: true },
  { name: 'South Dakota', abbreviation: 'SD', isSMSAllowed: true },
  { name: 'Tennessee', abbreviation: 'TN', isSMSAllowed: true },
  { name: 'Texas', abbreviation: 'TX', isSMSAllowed: false },
  { name: 'Utah', abbreviation: 'UT', isSMSAllowed: true },
  { name: 'Vermont', abbreviation: 'VT', isSMSAllowed: true },
  { name: 'Virginia', abbreviation: 'VA', isSMSAllowed: true },
  { name: 'Washington', abbreviation: 'WA', isSMSAllowed: true },
  { name: 'West Virginia', abbreviation: 'WV', isSMSAllowed: true },
  { name: 'Wisconsin', abbreviation: 'WI', isSMSAllowed: true },
  { name: 'Wyoming', abbreviation: 'WY', isSMSAllowed: true },
];
