import { Grid, Typography } from '@mui/material';
import RichTextViewer from 'common/components/RichTextViewer';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import PatientCommentOptions from './PatientCommentOptions';

const PatientComment = ({ comment }) => {
  return (
    <Grid container sx={{ px: 1 }} wrap='nowrap'>
      <Grid item sx={{ minWidth: 200 }}>
        <Grid container direction='column' sx={{ p: 1.5 }}>
          <Grid item>
            <Typography sx={{ fontWeight: 'bold' }}>
              {comment.author.firstName} {comment.author.lastName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant='caption'>
              {format(new Date(comment.createdAt), 'hh:mmaaa MM/dd/yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ flexGrow: 1 }}>
        <RichTextViewer
          value={comment.commentContent.message}
          id={`PatientView-PatientComment-${comment.commentId}-rtv`}
        />
      </Grid>

      <Grid item sx={{ px: 1 }}>
        <PatientCommentOptions commentId={comment.commentId} authorId={comment.author.pomUserId} />
      </Grid>
    </Grid>
  );
};

PatientComment.propTypes = {
  comment: PropTypes.shape({
    commentId: PropTypes.string,
    author: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      pomUserId: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    commentContent: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
};

export default PatientComment;
