import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { throttle } from 'lodash';
import { useSnackbar } from 'notistack';
import { IconButton } from '@mui/material';
import jwtDecode from 'jwt-decode';
import CloseIcon from '@mui/icons-material/Close';

import { checkAndRefreshSession, signOut } from 'store/thunks/authThunks';
import { getCookie } from 'api/utils';

const TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT || 15 * 60 * 1000; // 15 minutes
const snackbarKeyRef = createRef(null);

/**
 * useIdleSignOut
 *
 * If user idles for over specific time, automatically signout.
 * It uses localStorage to sync with tabs.
 *
 * Flow #1: User idle on active tab, user will be signed out after TIMEOUT,
 * Flow #2: User on multiple tab, active tab will set localStorage, which all tabs subscribed to.
 * Flow #3: User's idle tab was suspended for a long period of time. Upon tab active, revalidate session, if user last active was passed TIMEOUT, sign them out immediately
 */
const useIdleSignOut = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [lastActive, setLastActive] = useState(new Date().valueOf());
  const timerRef = useRef(null);

  const showExpiredSnackbar = useCallback(() => {
    snackbarKeyRef.current = enqueueSnackbar('Session Expired', {
      preventDuplicate: true,
      variant: 'info',
      persist: true,
      action: (key) => {
        const dismissSnackbar = () => {
          closeSnackbar(key);
          snackbarKeyRef.current = null;
        };

        return (
          <IconButton color='inherit' onClick={dismissSnackbar} aria-label='Dismiss'>
            <CloseIcon />
          </IconButton>
        );
      },
    });
  }, [enqueueSnackbar, closeSnackbar]);

  const throttledValidateSession = useMemo(() => {
    const validateSession = () => {
      const storageLastActiveValue = localStorage.getItem('POM_lastActive');
      const timeDifference = new Date().valueOf() - new Date(storageLastActiveValue).valueOf(); // current date value - last active value
      const jwt = getCookie('pomAccessToken');

      // check if there's a value in storage, it may not have one on first load.
      if ((storageLastActiveValue && timeDifference > TIMEOUT) || !jwt) {
        showExpiredSnackbar();
        dispatch(signOut());
        return; // don't bother continue, the session was timed out
      }

      const newLastActiveValue = new Date().valueOf();
      setLastActive(newLastActiveValue);
      localStorage.setItem('POM_lastActive', newLastActiveValue);
    };

    return throttle(validateSession, 30000, { trailing: false, leading: true });
  }, [showExpiredSnackbar, dispatch]);

  // Synchronize lastActive value between tabs
  useEffect(() => {
    /* istanbul ignore next */
    const syncLastActive = () => {
      const storageLastActiveValue = localStorage.getItem('POM_lastActive');
      const newLastActiveValue = new Date(storageLastActiveValue).valueOf() || new Date().valueOf();
      setLastActive(newLastActiveValue);
    };

    window.addEventListener('storage', syncLastActive, false);

    return () => {
      window.removeEventListener('storage', syncLastActive, false);
      localStorage.removeItem('POM_lastActive');
    };
  }, []);

  // close session timeout snackbar if still open after logging in
  useEffect(() => {
    if (snackbarKeyRef.current) {
      closeSnackbar(snackbarKeyRef.current);
      snackbarKeyRef.current = null;
    }
  }, [closeSnackbar]);

  // do not depend on setTimeout
  // browser will suspend javascript if device is low on memory or to save power
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      showExpiredSnackbar();
      dispatch(signOut());
    }, TIMEOUT);
    return () => clearTimeout(timerRef.current);
  }, [lastActive, dispatch, showExpiredSnackbar]);

  // On active, or tab switching, go ahead and re-validate session
  useEffect(() => {
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
    // visibility change is only partial supported in safari, but just enough for our purposes.
    window.addEventListener('visibilitychange', throttledValidateSession, false);
    document.addEventListener('visibilitychange', throttledValidateSession, false);
    document.addEventListener('mousemove', throttledValidateSession, false);
    document.addEventListener('mousedown', throttledValidateSession, false);
    document.addEventListener('keypress', throttledValidateSession, false);
    document.addEventListener('touchmove', throttledValidateSession, false);

    return () => {
      window.removeEventListener('visibilitychange', throttledValidateSession, false);
      document.removeEventListener('visibilitychange', throttledValidateSession, false);
      document.removeEventListener('mousemove', throttledValidateSession, false);
      document.removeEventListener('mousedown', throttledValidateSession, false);
      document.removeEventListener('keypress', throttledValidateSession, false);
      document.removeEventListener('touchmove', throttledValidateSession, false);
    };
  }, [throttledValidateSession]);

  useEffect(() => {
    const interval = setInterval(() => {
      const jwt = getCookie('pomAccessToken');

      if (!jwt) {
        return;
      }

      const { exp } = jwtDecode(jwt);

      /* istanbul ignore next */
      if (exp * 1000 - Date.now() < 5 * 60 * 1000) {
        dispatch(checkAndRefreshSession()).catch(() => {
          showExpiredSnackbar();
          dispatch(signOut());
        });
      }
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, [dispatch, showExpiredSnackbar]);

  return null;
};

export default useIdleSignOut;
