import { format } from 'date-fns';
import { isNull, reduce } from 'lodash';
import graphqlClient from '../graphql';
import {
  getPrescriptionOverrideAuditsGql,
  listPrescriptionForReviewGql,
  listSigsGql,
  overridePrescriptionGql,
  resolveDrugProfileGql,
  resolveDuplicatePrescriptionGql,
  syncSigGql,
} from './gql';
import { transformListOverrideDetails, transformListPrescriptionsForReview } from './transform';

export const listSigsApi = ({ searchText }) =>
  graphqlClient.request(listSigsGql, { searchText }).then(({ listSigs }) => listSigs);

export const syncSigApi = ({ prescriptionId, sig, sigId }) =>
  graphqlClient.request(syncSigGql, { prescriptionId, sig, sigId }).then(({ syncSig }) => syncSig);

export const listPrescriptionsForReviewApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    requestedDate: filter.requestedDate && format(new Date(filter.requestedDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listPrescriptionForReviewGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListPrescriptionsForReview);
};

export const resolveDuplicatePrescriptionApi = ({ prescriptionId, orderId, isDuplicate }) => {
  return graphqlClient
    .request(resolveDuplicatePrescriptionGql, { prescriptionId, orderId, isDuplicate })
    .then(({ resolveDuplicatePrescriptionReview }) => resolveDuplicatePrescriptionReview);
};

export const resolveDrugProfileApi = (input) => {
  return graphqlClient
    .request(resolveDrugProfileGql, { ...input })
    .then(({ resolveDrugProfile }) => resolveDrugProfile);
};

export const overridePrescriptionAPI = (input) => {
  return graphqlClient
    .request(overridePrescriptionGql, { ...input })
    .then(({ overridePrescription }) => overridePrescription);
};

export const getPrescriptionOverrideAuditsApi = (prescriptionUUID) => {
  return graphqlClient
    .request(getPrescriptionOverrideAuditsGql, { prescriptionUUID })
    .then(transformListOverrideDetails);
};
