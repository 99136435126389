import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { capitalize, map } from 'lodash';
import transactionTypes from 'common/constants/transactionTypes';
import { filterTransactions } from 'store/thunks/transactionsThunk';

const TransactionsSelectMenu = () => {
  const transactionTypeSelected = useSelector(
    ({ transactions }) => transactions?.filter?.transactionType || ''
  );
  const dispatch = useDispatch();

  const handleApplyFilter = (event) =>
    dispatch(filterTransactions({ filter: { transactionType: event.target.value } }));

  return (
    <Box sx={{ minWidth: 150 }}>
      <FormControl size='small' sx={{ minWidth: 150, bgcolor: 'white' }}>
        <Select
          id='Transactions-transactionType-dropdown'
          value={transactionTypeSelected}
          onChange={handleApplyFilter}
        >
          <MenuItem value={undefined}>All</MenuItem>
          {map(transactionTypes, (eventType, key) => (
            <MenuItem value={key} key={eventType}>
              {capitalize(eventType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default TransactionsSelectMenu;
