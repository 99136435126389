import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { find, isEmpty, isNil, map } from 'lodash';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';
import CheckIcon from '@mui/icons-material/Check';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import PatientPartnerOptions from './PatientPartnerOptions';

const PartnerRow = ({ partner, mpi }) => {
  const partners = useSelector(
    ({ auth, partner: partnerStore }) => partnerStore[auth.currentTenantId]
  );
  const partnerName = find(partners, { partnerUuid: partner.partnerId })?.partnerName;
  const originalPartnerId = useSelector(({ patient }) => patient[mpi]?.originalPartner?.partnerId);

  return (
    <TableRow hover>
      <TableCell component='th' scope='row' align='left'>
        {partnerName}
      </TableCell>
      <TableCell align='center'>
        {originalPartnerId === partner.partnerId && (
          <>
            <CheckIcon />
            <Typography sx={visuallyHidden}>Yes</Typography>
          </>
        )}
      </TableCell>
      <TableCell align='center'>{partner.identityValue}</TableCell>

      <TableCell align='center'>
        <PatientPartnerOptions mpi={mpi} partnerId={partner.partnerId} />
      </TableCell>
    </TableRow>
  );
};

PartnerRow.propTypes = {
  mpi: PropTypes.string.isRequired,
  partner: PropTypes.shape({
    partnerId: PropTypes.string,
    identityValue: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
};

const PatientPartnersTable = ({ mpi }) => {
  const partners = useSelector(({ patient }) => patient[mpi]?.partnerIds);

  return (
    <>
      <TableContainer>
        <Table aria-label='partner List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Partner Name</TableCell>
              <TableCell align='center'>Original Partner</TableCell>
              <TableCell align='center'>Id Value</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(partners, (partner) => (
              <PartnerRow key={partner.partnerId} partner={partner} mpi={mpi} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(partners) && <LoadingModule height='25vh' />}

      {!isNil(partners) && isEmpty(partners) && <NothingFound height='25vh' />}
    </>
  );
};

PatientPartnersTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientPartnersTable;
