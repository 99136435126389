import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, map } from 'lodash';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';

import TrackingNumberDetails from './TrackingNumberDetails';
import ShipmentOrderLineItemTable from './ShipmentOrderLineItemTable';
import ShippingOptions from './ShippingOptions';

const ShipmentOrderRow = ({ shipment, mpi }) => {
  const [showDetails, setShowDetails] = useState();

  const toggleDetails = () => setShowDetails((show) => !show);

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset', bgcolor: showDetails ? '#0000000a' : 'inherit' },
        }}
        hover
      >
        <TableCell sx={{ py: 1 }}>
          <IconButton
            aria-label={`Toggle Details ${shipment.orderShipmentId}`}
            size='small'
            onClick={toggleDetails}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align='center' sx={{ py: 1 }}>
          {shipment.shipmentCreatedDate
            ? format(new Date(shipment.shipmentCreatedDate), 'MM/dd/yyyy hh:mm a')
            : 'N/A'}
        </TableCell>
        <TableCell align='center' sx={{ py: 1 }}>
          <Typography>{shipment.orderShipmentId}</Typography>
        </TableCell>
        <TableCell align='center' sx={{ py: 1 }}>
          {format(new Date(shipment.shipmentUpdatedDate), 'MM/dd/yyyy hh:mm a')}
        </TableCell>
        <TableCell align='center' sx={{ py: 1 }}>
          {shipment.shipmentData.trackingNumber ? (
            <TrackingNumberDetails trackingNumber={shipment.shipmentData.trackingNumber} />
          ) : (
            'N/A'
          )}
        </TableCell>
        <TableCell align='center'>
          <ShippingOptions orderShipmentId={shipment.orderShipmentId} mpi={mpi} />
        </TableCell>
      </TableRow>

      <TableRow sx={{ '& > *': { borderTop: 'unset' } }}>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showDetails} orientation='vertical' timeout='auto'>
            {shipment?.lineItems && (
              <ShipmentOrderLineItemTable
                lineItemRow={shipment.orderShipmentId}
                lineItems={shipment.lineItems}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ShipmentOrderRow.propTypes = {
  mpi: PropTypes.string.isRequired,
  shipment: PropTypes.shape({
    orderShipmentId: PropTypes.string,
    shipmentCreatedDate: PropTypes.string,
    shipmentUpdatedDate: PropTypes.string,
    shipmentData: PropTypes.shape({
      modifyDate: PropTypes.string,
      shipDate: PropTypes.string,
      trackingNumber: PropTypes.string,
    }),
    lineItems: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

const ShipmentTable = ({ mpi }) => {
  const shipments = useSelector(({ patient }) => patient[mpi]?.shipmentHistory?.shipments);

  return (
    <>
      <TableContainer>
        <Table aria-label='ShipmentList' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='center'>
                <Typography sx={{ fontWeight: '600' }}>Date Created</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={{ fontWeight: '600' }}>Shipment Id</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={{ fontWeight: '600' }}>Last Updated</Typography>
              </TableCell>
              <TableCell align='center'>
                <Typography sx={{ fontWeight: '600' }}>Tracking Number</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(shipments, (shipment) => (
              <ShipmentOrderRow key={shipment.orderShipmentId} shipment={shipment} mpi={mpi} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(shipments) && <LoadingModule height='25vh' />}

      {!isNil(shipments) && isEmpty(shipments) && <NothingFound height='25vh' />}
    </>
  );
};

ShipmentTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default ShipmentTable;
