import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import ReviewAdjudicationFilterButton from './ReviewAdjudicationFilterButton';
import ReviewAdjudicationFilterStatusBar from './ReviewAdjudicationFilterStatusBar';
import ReviewAdjudicationTable from './ReviewAdjudicationTable';

const ReviewAdjudication = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.REVIEW_ADJUDICATION) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <ReviewAdjudicationFilterStatusBar />
      </Grid>
      <Grid item>
        <ReviewAdjudicationFilterButton />
      </Grid>
      <ReviewAdjudicationTable />
    </Grid>
  );
};

export default ReviewAdjudication;
