import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';

import TextField from 'common/forms/TextField';
import { assertQs1RxNumber, controlledSubstancesCheck } from 'store/thunks/patientThunks';
import { validateValueWithRegex } from 'common/forms/formValidations';
import lineItemEnum from 'enums/lineItem.enum';
import EligibilityFailureDialog from './EligibilityFailureDialog';

export const EligibilityConfirmDialog = ({
  mpi,
  requestId,
  requestLineItemId,
  showConfirmDialog,
  closeConfirmDialog,
  status,
}) => {
  const dispatch = useDispatch();

  const confirmDialog = ({ qs1RxNumber }) => {
    const checkInput = {
      mpi,
      requestLineItemId,
      qs1RxNumber,
      isControlledSubstance: false,
    };

    if (status === lineItemEnum.RX_ENTRY) {
      return dispatch(
        assertQs1RxNumber({ lineItemId: requestLineItemId, mpi, requestId, rxNumber: qs1RxNumber })
      ).then(() => closeConfirmDialog());
    }

    return dispatch(controlledSubstancesCheck({ mpi, requestId, checkInput })).then(() => {
      closeConfirmDialog();
    });
  };

  return (
    <Dialog
      open={showConfirmDialog}
      onClose={closeConfirmDialog}
      aria-describedby='EligibilityActionItems-EligibilityCheckSubstance-confirm-dialog'
      fullWidth
    >
      <DialogTitle id='EligibilityActionItems-EligibilityCheckSubstance-confirm-dialog'>
        Confirm
      </DialogTitle>
      <Form
        onSubmit={confirmDialog}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='EligibilityActionItems-EligibilityCheckSubstance-internalmessage-input'
                label='QS1 Rx Number'
                name='qs1RxNumber'
                validations={[validateValueWithRegex('Must be 8 numbers', /^\d{8}$/)]}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={closeConfirmDialog}
                color='secondary'
                size='medium'
                variant='outlined'
                disabled={submitting}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EligibilityConfirmDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  requestLineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  closeConfirmDialog: PropTypes.func.isRequired,
  showConfirmDialog: PropTypes.bool.isRequired,
  status: PropTypes.string,
};

EligibilityConfirmDialog.defaultProps = {
  status: '',
};

const EligibilityCheckSubstance = ({ mpi, requestId, requestLineItemId, onClose }) => {
  const [checkValue, setCheckValue] = useState('');
  const [showFailDialog, setShowFailDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const dispatch = useDispatch();

  const handleCheckSubstance = () => {
    if (checkValue === 'yes') {
      setShowFailDialog(true);
    }
    if (checkValue === 'no') {
      setShowConfirmDialog(true);
    }
  };

  const handleChangeRadio = (event) => {
    setCheckValue(event.target.value);
  };

  const closeFailDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowFailDialog(false);
    }
  };

  const closeConfirmDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowConfirmDialog(false);
    }
  };

  const confirmFailureDialog = (formData) => {
    const checkInput = {
      isControlledSubstance: true,
      mpi,
      requestLineItemId,
      failureReason: formData,
    };
    return dispatch(controlledSubstancesCheck({ mpi, requestId, checkInput })).then(() => {
      closeConfirmDialog();
    });
  };

  return (
    <>
      <EligibilityFailureDialog
        confirmFailure={confirmFailureDialog}
        open={showFailDialog}
        onClose={closeFailDialog}
      />

      <EligibilityConfirmDialog
        mpi={mpi}
        requestLineItemId={requestLineItemId}
        requestId={requestId}
        closeConfirmDialog={closeConfirmDialog}
        showConfirmDialog={showConfirmDialog}
      />

      <Grid container>
        <FormControl>
          <Typography
            variant='h6'
            component={FormLabel}
            id='EligibilityActionItems-EligibilityCheckSubstance-radioSelect-group'
          >
            Is this a schedule II controlled substance?
          </Typography>
          <Box sx={{ ml: 2, my: 2 }}>
            <RadioGroup
              aria-labelledby='EligibilityActionItems-EligibilityCheckSubstance-radioSelect-group'
              value={checkValue}
              onChange={handleChangeRadio}
            >
              <FormControlLabel value='yes' control={<Radio />} label='Yes' />
              <FormControlLabel value='no' control={<Radio />} label='No' />
            </RadioGroup>
          </Box>
        </FormControl>
        <Grid container direction='row-reverse'>
          <Button
            variant='contained'
            sx={{ width: 100 }}
            onClick={handleCheckSubstance}
            disabled={!checkValue}
          >
            Save
          </Button>
          <Button
            sx={{ mr: 2, width: 100 }}
            variant='outlined'
            color='secondary'
            size='medium'
            onClick={onClose}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

EligibilityCheckSubstance.propTypes = {
  onClose: PropTypes.func.isRequired,
  mpi: PropTypes.string.isRequired,
  requestLineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default EligibilityCheckSubstance;
