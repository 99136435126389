import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import TextField from 'common/forms/TextField';

const EligibilityFailureDialog = ({ confirmFailure, open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-describedby='EligibilityActionItems-EligibilityFailureDialog-fail-dialog'
      fullWidth
    >
      <DialogTitle id='EligibilityActionItems-EligibilityFailureDialog-fail-dialog'>
        Needs Review
      </DialogTitle>
      <Form
        onSubmit={confirmFailure}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='EligibilityActionItems-EligibilityFailureDialog-internalmessage-input'
                label='Reason of failure'
                name='title'
                required
              />
            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Confirm
              </LoadingButton>
              <Button
                onClick={onClose}
                color='secondary'
                size='medium'
                variant='outlined'
                disabled={submitting}
              >
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EligibilityFailureDialog.propTypes = {
  confirmFailure: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EligibilityFailureDialog;
