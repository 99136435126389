import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { compact, isEmpty, isNil, map, reduce } from 'lodash';

import useRoles from 'common/hooks/useRoles';
import lineItemStatusEnum from 'enums/lineItem.enum';
import LoadingModule from 'common/components/LoadingModule';
import { getPatientActionItems, listPatientPrescriptions } from 'store/thunks/patientThunks';
import { PrintLabelButton, PrintLabelProvider } from 'common/hooks/usePrintLabelContext';
import TransferRequestAccordion from './TransferRequestAccordion';
import PatientOrdersAccordion from './PatientOrdersAccordion';

const ActionItemEligibility = () => {
  const dispatch = useDispatch();
  const { mpi } = useParams();
  const requests = useSelector(({ patient }) => patient[mpi]?.requests);
  const orders = useSelector(({ patient }) => patient[mpi]?.orders);
  const hasAccess = useRoles();

  useEffect(() => {
    dispatch(getPatientActionItems({ mpi }));
    dispatch(listPatientPrescriptions({ mpi }));
  }, [dispatch, mpi]);

  if (isNil(requests)) {
    return <LoadingModule />;
  }

  return (
    <>
      {!isEmpty(requests) && (
        <Typography variant='h6' component='h2' sx={{ p: 1 }}>
          Requests
        </Typography>
      )}

      <List>
        {map(requests, (request) => (
          <ListItem key={request.requestId}>
            <TransferRequestAccordion
              mpi={mpi}
              requestCreatedAt={request.createdAt}
              requestId={request.requestId}
              transferRequest={request.transferRequest}
            />
          </ListItem>
        ))}

        <PrintLabelProvider
          initialValue={reduce(
            orders,
            (result, order) => [
              ...result,
              ...(!order.isProcessedExternal
                ? [
                    ...compact(
                      map(order.lineItems, (lineItem) =>
                        !lineItem?.isPrinted &&
                        lineItem.lineItemStatus !== lineItemStatusEnum.RE_PROCESS
                          ? lineItem?.lineItemId
                          : null
                      )
                    ),
                  ]
                : []),
            ],
            []
          )}
        >
          {!isEmpty(orders) && (
            <Grid container direction='row' justifyContent='space-between' alignItems='center'>
              <Typography variant='h6' component='h2' sx={{ p: 1 }}>
                Orders
              </Typography>
              {hasAccess.generateBulkLabels && <PrintLabelButton />}
            </Grid>
          )}
          <List>
            {map(orders, (order) => (
              <ListItem key={order.orderId}>
                <PatientOrdersAccordion order={order} mpi={mpi} />
              </ListItem>
            ))}
          </List>
        </PrintLabelProvider>
      </List>
    </>
  );
};

export default ActionItemEligibility;
