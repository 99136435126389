import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validateEmail,
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import { addPatientProvider } from 'store/thunks/patientThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';

const NewProviderButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddProvider = (formData) => {
    return dispatch(addPatientProvider({ provider: { mpi, ...formData } })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName} onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewProviderButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewProviderButton-header'>Add Provider</DialogTitle>

        <Form
          onSubmit={handleAddProvider}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-NewProviderButton-firstName-input'
                  label='First Name'
                  name='firstName'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientView-NewProviderButton-lastName-input'
                  label='Last Name'
                  name='lastName'
                  required
                />

                <TextField
                  id='PatientView-NewProviderButton-phoneNumber-input'
                  label='Phone Number'
                  name='phone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewProviderButton-email-input'
                  label='Email'
                  name='email'
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientView-NewProviderButton-npi-input'
                  label='NPI'
                  name='npi'
                  validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
                />

                <TextField
                  id='PatientView-NewProviderButton-fax-input'
                  label='Fax'
                  name='fax'
                  validations={[validatePhoneNumber('Invalid Fax Number')]}
                />

                <TextField
                  id='PatientView-NewProviderButton-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                />

                <TextField
                  id='PatientView-NewProviderButton-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField id='PatientView-NewProviderButton-city-input' label='City' name='city' />

                <SelectField
                  id='PatientView-NewProviderButton-state-dropdown'
                  label='State'
                  name='state'
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewProviderButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewProviderButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewProviderButton;
