import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { map, some, trim } from 'lodash';

import allergyTypes from 'common/constants/allergyTypes';
import TextField from 'common/forms/TextField';
import { addPatientMedicalHistory } from 'store/thunks/patientThunks';
import SelectField from 'common/forms/SelectField';
import useInfoDialog from 'common/hooks/useInfoDialog';

const AddAllergyButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const allergies = useSelector(({ patient }) => patient[mpi]?.medicalHistory?.allergies);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddAllergy = (formData) => {
    const identicalAllergyExists = some(
      allergies,
      (allergy) =>
        new RegExp(`^${trim(formData.allergyType)}$`, 'i').test(allergy.allergyType) &&
        new RegExp(`^${trim(formData.description)}$`, 'i').test(allergy.description)
    );

    if (identicalAllergyExists) {
      return showInfoDialog({
        title: 'Identical Allergy Found',
        message: 'An allergy with same description already exist',
      });
    }

    return dispatch(
      addPatientMedicalHistory({ newMedicalHistory: { mpi, allergy: [formData] } })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <InfoDialog />
      <Button
        variant='contained'
        disabled={!patientName}
        onClick={handleOpenModal}
        aria-label='add allergy'
      >
        Add
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-MedicalHistoriesTab-AddAllergyButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-MedicalHistoriesTab-AddAllergyButton-header'>
          Add Allergy
        </DialogTitle>

        <Form
          onSubmit={handleAddAllergy}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  id='PatientView-MedicalHistoriesTab-AddAllergyButton-allergyType-dropdown'
                  label='Allergy Type'
                  name='allergyType'
                  required
                >
                  {map(allergyTypes, (allergy, key) => (
                    <MenuItem key={key} value={key}>
                      {allergy}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-MedicalHistoriesTab-AddAllergyButton-description-input'
                  label='Description'
                  name='description'
                  required
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

AddAllergyButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default AddAllergyButton;
