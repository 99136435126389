import { useSelector } from 'react-redux';
import { Grid, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { map, orderBy } from 'lodash';
import PropTypes from 'prop-types';

const MedicalConditionTable = ({ mpi }) => {
  const theme = useTheme();
  const medicalConditions = useSelector(
    ({ patient }) => patient[mpi]?.medicalHistory?.medicalConditions
  );

  if (!medicalConditions) {
    return (
      <Typography align='center' sx={{ fontWeight: '500' }}>
        Loading...
      </Typography>
    );
  }

  if (!medicalConditions.length) {
    return (
      <Typography align='center' sx={{ fontWeight: '500' }}>
        No Medical Conditions
      </Typography>
    );
  }

  return (
    <Grid container>
      <Grid
        container
        sx={{ borderBottom: `1px solid ${theme.palette.grey[400]}`, px: 2, pb: 1 }}
        gap={1}
      >
        <Grid item xs={12} sm={2}>
          <Typography sx={{ fontWeight: '500' }}>ICD Code</Typography>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Typography sx={{ fontWeight: '500' }}>Medical Condition</Typography>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Typography sx={{ fontWeight: '500' }}>Description</Typography>
        </Grid>
      </Grid>
      <List sx={{ width: '100%', py: 0 }}>
        {map(orderBy(medicalConditions, ['ICDCode'], ['asc']), (condition) => (
          <Grid
            container
            component={ListItem}
            sx={{
              borderBottom: `1px solid ${theme.palette.grey[200]}`,
              px: 2,
              py: 1,
              '&:hover': { bgcolor: theme.palette.grey[100] },
            }}
            key={condition.patientMedicalConditionId}
            gap={1}
          >
            <Grid item xs={12} sm={2}>
              <Typography>{condition.ICDCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography sx={{ wordBreak: 'break-word' }}>
                {condition.medicalConditionName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography>{condition.description}</Typography>
            </Grid>
          </Grid>
        ))}
      </List>
    </Grid>
  );
};

MedicalConditionTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default MedicalConditionTable;
