/* eslint-disable react/jsx-props-no-spreading */
import { difference, find, isEmpty, map, omit } from 'lodash';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { fetchDrugProfiles } from 'store/thunks/drugThunks';

import DrugProfileForm from './DrugProfileForm';

const ManageDrugProfileModal = ({
  loading,
  showModal,
  handleCloseModal,
  manageDrugProfile,
  drugProfileId,
}) => {
  const dispatch = useDispatch();
  const [drugProfile, setDrugProfile] = useState({});
  const { pages, currentPage } = useSelector(({ drugs: { drugProfiles } }) => drugProfiles);
  const [loadingAssociations, setLoadingAssociations] = useState(false);
  const [removedAssociations, setRemovedAssociations] = useState([]);
  const [preservedFormData, setPreservedFormData] = useState({});
  const [showConfirmModal, setConfirmModal] = useState(false);
  const selectedDrugProfile = find(pages[currentPage], { drugProfileId });

  const fetchAssociatedDrugProfiles = useCallback(
    (drugNameHash) => {
      setLoadingAssociations(true);
      dispatch(
        fetchDrugProfiles({
          filter: {
            drugNameHash,
          },
        })
      ).then((drugProfiles) => {
        setDrugProfile({
          ...selectedDrugProfile,
          form: !isEmpty(selectedDrugProfile?.form) ? selectedDrugProfile.form : 'N/A',
          associatedPrescriptionDescriptions: [
            ...map(
              drugProfiles,
              ({ associatedPrescriptionDescriptions }) => associatedPrescriptionDescriptions
            ),
          ],
        });
        setLoadingAssociations(false);
      });
    },
    [dispatch, selectedDrugProfile]
  );

  useEffect(() => {
    if (drugProfileId) {
      setDrugProfile({
        ...omit(selectedDrugProfile, ['associatedPrescriptionDescriptions']),
        form: !isEmpty(selectedDrugProfile?.form) ? selectedDrugProfile.form : 'N/A',
      });
      fetchAssociatedDrugProfiles(selectedDrugProfile.drugNameHash);
    }
  }, [drugProfileId, selectedDrugProfile, fetchAssociatedDrugProfiles]);

  const handleDrugProfile = (formData) => {
    const removedExistingAssociations = difference(
      drugProfile.associatedPrescriptionDescriptions,
      formData.associatedPrescriptionDescriptions
    );

    if (isEmpty(removedExistingAssociations)) {
      manageDrugProfile(formData);
    } else {
      setRemovedAssociations(removedExistingAssociations);
      setPreservedFormData(formData);
      setConfirmModal(true);
    }
  };

  const closeConfirmationDialog = () => setConfirmModal(false);

  return (
    <Dialog
      open={showModal}
      onClose={handleCloseModal}
      aria-describedby='Create-Drug-Profile'
      fullWidth
    >
      {showConfirmModal ? (
        <>
          <DialogTitle>Confirm Drug profile Update</DialogTitle>
          <DialogContent sx={{ minWidth: 600 }}>
            <Typography variant='subtitle1' sx={{ mb: 2 }}>
              Are you sure want to remove the following prescription descriptions from the drug
              profile?
            </Typography>
            {map(removedAssociations, (association) => (
              <Typography variant='body2' sx={{ m: 1 }}>
                {association}
              </Typography>
            ))}
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' color='secondary' onClick={closeConfirmationDialog}>
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              variant='contained'
              onClick={() => manageDrugProfile(preservedFormData)}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle id='Create-Drug-Profile-header'>
            {!drugProfileId ? 'Create Drug Profile' : 'Edit Drug Profile'}
          </DialogTitle>

          <Form
            initialValues={drugProfile}
            onSubmit={handleDrugProfile}
            render={({ handleSubmit, invalid }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <DrugProfileForm
                    drugProfileId={drugProfileId}
                    loading={loadingAssociations}
                    prescriptionDescription={
                      drugProfile?.associatedPrescriptionDescriptions
                        ? drugProfile?.associatedPrescriptionDescriptions
                        : []
                    }
                  />
                </DialogContent>

                <DialogActions>
                  <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant='contained'
                    disabled={invalid || loadingAssociations}
                    type='submit'
                  >
                    Submit
                  </LoadingButton>
                </DialogActions>
              </form>
            )}
          />
        </>
      )}
    </Dialog>
  );
};

export default ManageDrugProfileModal;

ManageDrugProfileModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  manageDrugProfile: PropTypes.func.isRequired,
  drugProfileId: PropTypes.string,
};

ManageDrugProfileModal.defaultProps = {
  drugProfileId: null,
};
