import { Autocomplete, CircularProgress, ListItem, ListItemText } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { debounce, noop } from 'lodash';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';

import { listSigsApi } from 'api/prescription';

import TextField from './TextField';
import { validateMaxLength, validateMinLength } from './formValidations';

const SigsAutoSuggest = ({ name, id, label, autoFocus, maxLength, handleSelect }) => {
  const form = useForm();
  const [sigs, setSigs] = useState([]);
  const [inputValue, setInputValue] = useState(form.getState().values[name] || '');
  const [loading, setLoading] = useState(false);

  const onInputChange = (_, value) => {
    setInputValue(value);
    handleSelect({});
  };

  const onChange = (_, sig) =>
    form.batch(() => {
      handleSelect(sig);
      form.change(name, sig);
    });

  const debouncedSearch = useMemo(() => {
    const searchFunc = (sig) => {
      setLoading(true);
      listSigsApi({ searchText: sig })
        .then((fetchedSigs) => setSigs(fetchedSigs))
        .catch(noop)
        .finally(() => setLoading(false));
    };

    return debounce(searchFunc, 500, { trailing: true, leading: false });
  }, []);

  useEffect(() => {
    setSigs([]);
    if (inputValue.length >= 3) {
      debouncedSearch(inputValue);
    }
  }, [inputValue, debouncedSearch]);

  useEffect(() => {
    if (sigs.length && inputValue.length < 3) {
      setSigs([]);
    }
  }, [inputValue, sigs]);

  return (
    <Autocomplete
      freeSolo
      id={`${id}_-autosuggest`}
      options={sigs}
      getOptionLabel={(sig) => sig.sig || inputValue}
      renderOption={(props, { sig, sigId }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListItem {...props} key={sigId}>
          <ListItemText primary={sig} />
        </ListItem>
      )}
      filterOptions={(options) => options}
      disableClearable
      value={inputValue || ''}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            name={name}
            id={id}
            label={label}
            required
            params={params}
            endAdornment={loading ? <CircularProgress size={20} /> : undefined}
            autoFocus={autoFocus}
            maxLength={maxLength}
            validations={[
              validateMaxLength('Maximum of 180 characters', 180),
              validateMinLength('Minimum of 3 characters', 3),
            ]}
          />
        );
      }}
    />
  );
};

SigsAutoSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  maxLength: PropTypes.number,
  handleSelect: PropTypes.func,
};

SigsAutoSuggest.defaultProps = { autoFocus: false, maxLength: 100, handleSelect: () => {} };

export default SigsAutoSuggest;
