import { LoadingButton } from '@mui/lab';
import useInfoDialog from 'common/hooks/useInfoDialog';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { markPrescriptionReview } from 'store/thunks/patientThunks';
import { enqueueSnackbar } from 'notistack';
import useRoles from 'common/hooks/useRoles';

const ReviewPrescriptionButton = ({
  disabled,
  prescriptionUUID,
  patientId,
  lineItemId,
  orderId,
}) => {
  const hasAccess = useRoles();
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const handlePrescriptionReview = () => {
    showInfoDialog({
      title: 'Confirm send prescription to review',
      message: 'Are you sure you want to mark the prescription to review?',
      callback: () =>
        dispatch(markPrescriptionReview({ patientId, prescriptionUUID, lineItemId, orderId })).then(
          (result) => {
            if (result) {
              enqueueSnackbar(`Prescription of the order tagged for review`, {
                variant: 'warning',
              });
            }
          }
        ),
      cancelable: true,
      confirmButtonText: 'Yes',
    });
  };

  return (
    <>
      <InfoDialog />

      <LoadingButton
        size='small'
        disabled={disabled || !hasAccess.needsReview}
        onClick={handlePrescriptionReview}
        variant='outlined'
        sx={{ mr: 2 }}
      >
        Needs Review
      </LoadingButton>
    </>
  );
};

ReviewPrescriptionButton.propTypes = {
  disabled: PropTypes.bool,
  prescriptionUUID: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};

ReviewPrescriptionButton.defaultProps = {
  disabled: false,
};

export default ReviewPrescriptionButton;
