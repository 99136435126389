import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { BookmarkAdd } from '@mui/icons-material';

import RichTextViewer from 'common/components/RichTextViewer';
import { updateFailedAdjudicationNoteStatus } from 'store/thunks/workQueueThunks';
import { NoteState } from 'common/constants/notes';

const NoteItem = ({ note, contextId }) => {
  const { noteId, noteAuthor, noteContent, noteState, noteCreatedAt } = note;

  const dispatch = useDispatch();
  const showPinIcon = noteState === NoteState.UNPIN;

  const pinNote = () => {
    dispatch(
      updateFailedAdjudicationNoteStatus({
        contextId,
        action: NoteState.PIN,
        noteId,
      })
    );
  };

  return (
    <Grid container wrap='nowrap' sx={{ p: 1 }}>
      <Grid item sx={{ minWidth: 200 }}>
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid item>
            <Typography>
              {noteAuthor.firstName} {noteAuthor.lastName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant='caption'>
              {format(new Date(noteCreatedAt), 'hh:mmaaa MM/dd/yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ flexGrow: 1 }}>
        <RichTextViewer
          value={noteContent}
          id={`WorkQueue-ReviewFailedAdjudication-${noteId}-rtv`}
        />
      </Grid>

      {showPinIcon && (
        <Grid item>
          <Tooltip title='Pin' arrow placement='top'>
            <IconButton
              id={`WorkQueue-ReviewFailedAdjudication-${noteId}-button`}
              aria-label={`Pin note ${noteId}`}
              onClick={pinNote}
            >
              <BookmarkAdd />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

NoteItem.propTypes = {
  note: PropTypes.shape({
    noteId: PropTypes.string.isRequired,
    noteState: PropTypes.string.isRequired,
    noteCreatedAt: PropTypes.string.isRequired,
    noteContent: PropTypes.string.isRequired,
    noteAuthor: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      pomUserId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  contextId: PropTypes.string.isRequired,
};

export default NoteItem;
