/* eslint max-lines: ["error", {"max": 450, "skipComments": true, "skipBlankLines": true}] */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, filter, find, isEmpty, isNil, map, omit, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { format } from 'date-fns';

import useRoles from 'common/hooks/useRoles';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import useStrictDroppable from 'common/hooks/useStrictDroppable';
import { patientActions } from 'store/slices/patientSlice';
import { updatePatientInsurance } from 'store/thunks/patientThunks';
import insurancePayorTypes from 'common/constants/insurancePayorTypes';

import PatientInsuranceOptions from './PatientInsuranceOptions';

const priorityMapping = {
  primary: 1,
  secondary: 2,
  tertiary: 3,
};

const InsuranceLabel = ({ name, value }) => {
  return (
    <Grid container>
      <Grid item xs={3.5}>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.9em' }}
        >
          {name}
        </Typography>
      </Grid>
      <Grid item xs={8.5}>
        <Typography sx={{ fontSize: '0.9em', overflowWrap: 'anywhere' }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

InsuranceLabel.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InsuranceLabel.defaultProps = {
  value: '',
};

const PriorityInsuranceDroppable = ({ priority, enabled, mpi }) => {
  const hasAccess = useRoles();
  const insurance = useSelector(({ patient }) =>
    find(patient[mpi]?.insurances, { priority: priorityMapping[priority] })
  );

  return (
    <Grid item sx={{ minHeight: 350, p: 1 }} xs={12} md={4}>
      {enabled && (
        <Droppable droppableId={priority}>
          {(provided, snapshot) => (
            <>
              <Paper
                sx={{
                  height: '100%',
                  width: '100%',
                  p: 1,
                  border: (theme) => `1px solid ${lighten(theme.palette.text.primary, 0.9)}`,
                }}
                elevation={insurance ? 4 : 0}
              >
                <>
                  <Typography
                    sx={{
                      fontWeight: '500',
                      color: (theme) => lighten(theme.palette.text.primary, 0.5),
                    }}
                    align='right'
                  >
                    {capitalize(priority)}
                  </Typography>
                  <Box
                    ref={provided.innerRef}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...provided.droppableProps}
                    sx={{ height: '100%' }}
                  >
                    {(snapshot.isDraggingOver || !insurance) && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '80%',
                        }}
                      >
                        {snapshot.isDraggingOver && (
                          <Typography>Drop it to update {priority} insurance</Typography>
                        )}
                        {!snapshot.isDraggingOver && !insurance && (
                          <Typography
                            sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.6) }}
                          >
                            Drag and drop an insurance here
                          </Typography>
                        )}
                      </Box>
                    )}
                    {insurance && (
                      <Draggable
                        draggableId={insurance.patientInsuranceId}
                        index={0}
                        isDragDisabled={!hasAccess.updateInsurance}
                      >
                        {(draggableProvided, draggableSnapshot) => (
                          <Grid
                            container
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...draggableProvided.draggableProps}
                            ref={draggableProvided.innerRef}
                            direction='column'
                            sx={
                              !draggableSnapshot.isDragging && snapshot.isDraggingOver
                                ? visuallyHidden
                                : {
                                    p: 1,
                                  }
                            }
                            component={Paper}
                            elevation={draggableSnapshot.isDragging ? 3 : 0}
                          >
                            <Grid container justifyContent='space-between'>
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: '1.1em',
                                  mb: 2,
                                  overflowWrap: 'anywhere',
                                  maxWidth: '70%',
                                }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...draggableProvided.dragHandleProps}
                              >
                                {insurance.planName}
                              </Typography>

                              <PatientInsuranceOptions
                                mpi={mpi}
                                patientInsuranceId={insurance.patientInsuranceId}
                                isActive={insurance.isActive}
                              />
                            </Grid>
                            <InsuranceLabel
                              name='Plan Type'
                              value={insurancePayorTypes[insurance.planType]}
                            />
                            <InsuranceLabel name='Plan Id' value={insurance.insurancePlanId} />
                            <InsuranceLabel name='Group #' value={insurance.groupNumber} />
                            <InsuranceLabel name='BIN' value={insurance.bin} />
                            <InsuranceLabel name='PCN' value={insurance.pcn} />
                            <InsuranceLabel name='SSN' value={insurance.ssn} />
                            <InsuranceLabel name='Medicare #' value={insurance.medicareNumber} />

                            {insurance.verified && (
                              <Grid container sx={{ mt: 2 }}>
                                <InsuranceLabel
                                  name='Verified By'
                                  value={insurance.verification.verifierName}
                                />
                                <InsuranceLabel
                                  name='Verified Time'
                                  value={format(
                                    new Date(insurance.verification.verificationModifiedAt),
                                    'hh:mmaaa MM/dd/yyyy'
                                  )}
                                />
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </Draggable>
                    )}
                  </Box>
                </>
              </Paper>
              {provided.placeholder}
            </>
          )}
        </Droppable>
      )}
    </Grid>
  );
};

PriorityInsuranceDroppable.propTypes = {
  priority: PropTypes.oneOf(['primary', 'secondary', 'tertiary']).isRequired,
  enabled: PropTypes.bool.isRequired,
  mpi: PropTypes.string.isRequired,
};

const PatientInsurancesDnD = ({ mpi }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const insurances = useSelector(({ patient }) => patient[mpi]?.insurances);
  const [enabled] = useStrictDroppable(!insurances?.length);
  const noPriorityInsurances = filter(insurances, (insurance) => !insurance.priority);
  const sortedInsurance = orderBy(
    noPriorityInsurances,
    ['isActive', 'verified', 'planName'],
    ['desc', 'desc', 'asc']
  );

  const onDragEnd = (dragEvent) => {
    const { draggableId, source, destination } = dragEvent;

    if (!destination || source.droppableId === destination.droppableId) {
      return undefined; // do nothing because priority didn't change;
    }

    // Optimistically update
    dispatch(
      patientActions.loadInsurances({
        mpi,
        insurances: map(insurances, (existingInsurance) => {
          if (existingInsurance.patientInsuranceId === draggableId) {
            return {
              ...existingInsurance,
              priority: priorityMapping[destination.droppableId] || 0,
            };
          }

          return {
            ...existingInsurance,
            priority:
              existingInsurance.priority === priorityMapping[destination.droppableId]
                ? null
                : existingInsurance.priority,
          };
        }),
      })
    );

    const insuranceToBeUpdated = omit(
      {
        ...find(insurances, { patientInsuranceId: draggableId }),
        priority: priorityMapping[destination.droppableId] || 0,
        mpi,
      },
      ['verification', 'modifiedTimestamp']
    );

    return dispatch(updatePatientInsurance({ insurance: insuranceToBeUpdated }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid container sx={{ maxWidth: 1800 }}>
        {!isNil(insurances) && !isEmpty(insurances) && (
          <Grid item xs={12} md={9}>
            <Grid container>
              <PriorityInsuranceDroppable priority='primary' enabled={enabled} mpi={mpi} />
              <PriorityInsuranceDroppable priority='secondary' enabled={enabled} mpi={mpi} />
              <PriorityInsuranceDroppable priority='tertiary' enabled={enabled} mpi={mpi} />
            </Grid>
          </Grid>
        )}

        {enabled && (
          <Droppable droppableId='noPriority'>
            {(provided) => (
              <Grid
                item
                xs={12}
                md={3}
                ref={provided.innerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
                sx={{
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    border: `1px solid ${lighten(theme.palette.text.primary, 0.9)}`,
                    borderRadius: 1,
                    height: '100%',
                  }}
                >
                  {map(sortedInsurance, (insurance, index) => (
                    <Draggable
                      draggableId={insurance.patientInsuranceId}
                      key={insurance.patientInsuranceId}
                      index={index}
                      isDragDisabled={!insurance.isActive || !hasAccess.updateInsurance}
                    >
                      {(draggableProvided) => (
                        <Accordion
                          disableGutters
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          sx={{
                            bgcolor: !insurance.isActive
                              ? lighten(theme.palette.text.primary, 0.98)
                              : undefined,
                          }}
                        >
                          <Grid container>
                            <Grid item>
                              <AccordionSummary>
                                <Typography
                                  noWrap
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...draggableProvided.dragHandleProps}
                                  sx={{
                                    fontWeight: '500',
                                    color: lighten(
                                      theme.palette.text.primary,
                                      insurance.isActive ? 0 : 0.5
                                    ),
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    [theme.breakpoints.up('md')]: {
                                      width: 150,
                                    },
                                    [theme.breakpoints.up('lg')]: {
                                      width: 220,
                                    },
                                    [theme.breakpoints.up('xl')]: {
                                      width: 270,
                                    },
                                  }}
                                >
                                  {insurance.planName}
                                </Typography>
                              </AccordionSummary>
                            </Grid>
                            <Grid item sx={{ position: 'absolute', right: 2, top: 5 }}>
                              <PatientInsuranceOptions
                                mpi={mpi}
                                patientInsuranceId={insurance.patientInsuranceId}
                                isActive={insurance.isActive}
                              />
                            </Grid>
                          </Grid>
                          <AccordionDetails>
                            <InsuranceLabel
                              name='Status'
                              value={insurance.isActive ? 'Active' : 'Deactivated'}
                            />
                            <InsuranceLabel
                              name='Plan Type'
                              value={insurancePayorTypes[insurance.planType]}
                            />
                            <InsuranceLabel name='Plan Id' value={insurance.insurancePlanId} />
                            <InsuranceLabel name='Group #' value={insurance.groupNumber} />
                            <InsuranceLabel name='BIN' value={insurance.bin} />
                            <InsuranceLabel name='PCN' value={insurance.pcn} />
                            <InsuranceLabel name='SSN' value={insurance.ssn} />
                            <InsuranceLabel name='Medicare #' value={insurance.medicareNumber} />

                            {insurance.verified && (
                              <Box sx={{ mt: 2 }}>
                                <InsuranceLabel
                                  name='Verified By'
                                  value={insurance.verification.verifierName}
                                />
                                <InsuranceLabel
                                  name='Verified Time'
                                  value={format(
                                    new Date(insurance.verification.verificationModifiedAt),
                                    'hh:mmaaa MM/dd/yyyy'
                                  )}
                                />
                              </Box>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </Draggable>
                  ))}
                  {isEmpty(sortedInsurance) && (
                    <Grid
                      container
                      alignItems='center'
                      justifyContent='center'
                      sx={{ height: '100%' }}
                    >
                      <Typography sx={{ color: lighten(theme.palette.text.primary, 0.6) }}>
                        No Other Insurance Found
                      </Typography>
                    </Grid>
                  )}
                </Box>
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        )}
      </Grid>

      {isNil(insurances) && <LoadingModule height='25vh' />}

      {!isNil(insurances) && isEmpty(insurances) && <NothingFound height='25vh' />}
    </DragDropContext>
  );
};

PatientInsurancesDnD.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientInsurancesDnD;
