/* istanbul ignore file */
import * as Sentry from '@sentry/react';
import { forEach, noop } from 'lodash';

const mockSpan = { finish: noop, setStatus: noop };

const mockTransaction = {
  finish: noop,
  setStatus: noop,
  setTag: noop,
  startChild: () => mockSpan,
};

let transaction = mockTransaction;
let span = mockSpan;

export const startTransaction = ({ name }) => {
  transaction.finish();

  transaction = Sentry.startTransaction({ name }) || mockTransaction;

  return transaction;
};

export const getTransaction = () => transaction;

export const startSpan = ({ op, description, tags }) => {
  span.finish();
  forEach(tags, (value, key) => transaction.setTag(key, value));

  span = transaction.startChild({ op, description }) || mockSpan;
  return span;
};

export const getSpan = () => span;
