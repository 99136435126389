/* eslint-disable max-lines */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { capitalize, find, map, omit } from 'lodash';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

import gender from 'common/constants/gender';
import DateField from 'common/forms/DateField';
import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import languages from 'common/constants/languages';
import {
  validateEmail,
  validatePhoneAllowedInState,
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import { addPatient } from 'store/thunks/patientThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import AutoCompleteField from 'common/forms/AutoCompleteField';
import leadSource from 'common/constants/leadSource';
import { formatZipCode } from 'common/utils';

import CheckboxField from 'common/forms/CheckboxField';

const NewPatientButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const checkSmsAllowedInState = (state) =>
    find(usStateAbbreviation, { abbreviation: state })?.isSMSAllowed;

  const handleAddPatient = (formData) => {
    const patientData = {
      ...omit(formData, [
        'primaryLanguage',
        'originalPartner',
        'partnerExternalId',
        'emailPreference',
        'phonePreference',
      ]),
      languages: [{ language: formData.primaryLanguage, isPrimary: true }],
      partners: {
        partnerUUID: formData.originalPartner,
        isOriginalPartner: true,
        partnerExternalId: formData.partnerExternalId,
      },
      dob: format(new Date(formData.dob), 'yyyy-MM-dd'),
      preferencesState: {
        email: formData.emailPreference,
        phone: formData.phonePreference && checkSmsAllowedInState(formData.state),
      },
    };

    return dispatch(addPatient({ patientData })).then((mpi) => mpi && navigate(`/patients/${mpi}`));
  };

  return (
    <>
      <Button variant='contained' onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientsList-NewPatientButton-header'
        fullWidth
      >
        <DialogTitle id='PatientsList-NewPatientButton-header'>Add Patient</DialogTitle>

        <Form
          onSubmit={handleAddPatient}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientsList-NewPatientButton-firstName-input'
                  label='First Name'
                  name='firstName'
                  required
                  autoFocus
                />

                <TextField
                  id='PatientsList-NewPatientButton-lastName-input'
                  label='Last Name'
                  name='lastName'
                  required
                />

                <TextField
                  id='PatientsList-NewPatientButton-middleName-input'
                  label='Middle Name'
                  name='middleName'
                />

                <DateField
                  name='dob'
                  id='PatientsList-NewPatientButton-dob-input'
                  label='Date of Birth'
                  maxDate={new Date()}
                  required
                />

                <TextField
                  id='PatientView-EditPatientButton-ssn-input'
                  label='SSN (Last 4)'
                  name='last4ssn'
                  validations={[validateValueWithRegex('Must be 4 numbers', /^\d{4}$/)]}
                />

                <SelectField
                  name='gender'
                  id='PatientsList-NewPatientButton-gender-dropdown'
                  label='Gender'
                  required
                >
                  {map(gender, (name, value) => (
                    <MenuItem value={value} key={value}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <SelectField
                  name='primaryLanguage'
                  id='PatientsList-NewPatientButton-primaryLanguage-dropdown'
                  label='Primary Language Spoken'
                  required
                >
                  {map(languages, (language) => (
                    <MenuItem key={language} value={language}>
                      {capitalize(language)}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-address1-input'
                  label='Address Line 1'
                  name='addressLine1'
                  required
                />

                <TextField
                  id='PatientsList-NewPatientButton-address2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientsList-NewPatientButton-city-input'
                  label='City'
                  name='city'
                  required
                />

                <SelectField
                  id='PatientsList-NewPatientButton-state-dropdown'
                  label='State'
                  name='state'
                  required
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  required
                  validations={[validateZipCode()]}
                  format={formatZipCode}
                  maxLength={10}
                />

                <TextField
                  id='PatientsList-NewPatientButton-email-input'
                  label='Email'
                  name='email'
                  required
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientsList-NewPatientButton-mobilePhoneNumber-input'
                  label='Mobile Phone Number'
                  name='mobilePhone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <AutoCompleteField
                  id='PatientsList-NewPatientButton-leadSource-autocomplete'
                  label='Lead Source'
                  name='leadSource'
                  options={leadSource}
                >
                  {map(leadSource, (source, key) => (
                    <MenuItem value={source} key={key}>
                      {source}
                    </MenuItem>
                  ))}
                </AutoCompleteField>

                <SelectField
                  label='Original Partner'
                  name='originalPartner'
                  id='PatientsList-NewPatientButton-originalPartner-dropdown'
                  required
                >
                  {map(partners, ({ partnerName, partnerUuid }) => (
                    <MenuItem value={partnerUuid} key={partnerUuid}>
                      {partnerName}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientsList-NewPatientButton-partnerExternalId-input'
                  label='Partner External Id'
                  name='partnerExternalId'
                />

                <CheckboxField
                  label='Email Notification Preference'
                  name='emailPreference'
                  id='PatientsList-NewPatientButton-emailPreference-checkbox'
                />

                <CheckboxField
                  label='Phone Notification Preference'
                  name='phonePreference'
                  id='PatientsList-NewPatientButton-phonePreference-checkbox'
                  validations={[validatePhoneAllowedInState(undefined, 'state')]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

export default NewPatientButton;
