import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { filter, includes, isEmpty, isNil, map } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { visuallyHidden } from '@mui/utils';

import guarantorRelationship from 'common/constants/guarantorRelationship';
import { normalizePhoneNumber } from 'common/utils';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import PatientGuarantorOptions from './PatientGuarantorOptions';

const GuarantorLabelValue = ({ value, label, md, lg }) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container direction='column' item xs={12} sm={6} md={md} lg={lg}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

GuarantorLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
};
GuarantorLabelValue.defaultProps = {
  value: '',
  md: 2,
  lg: 3,
};

const GuarantorRow = ({ guarantor, mpi }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((show) => !show);

  const filterPhoneNumbers = ({ tag }) =>
    map(
      filter(guarantor.phoneNumbers, ({ tags }) => includes(tags, tag)),
      'phoneNumber'
    );
  const phoneNumbers = {
    mobile: filterPhoneNumbers({ tag: 'MOBILE' }),
    work: filterPhoneNumbers({ tag: 'WORK' }),
    home: filterPhoneNumbers({ tag: 'HOME' }),
  };

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset', bgcolor: showDetails ? '#0000000a' : 'inherit' } }}
        hover
      >
        <TableCell>
          <IconButton
            aria-label={`Toggle Details ${guarantor.firstName}`}
            size='small'
            onClick={toggleDetails}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {guarantor.firstName} {guarantor.lastName}
        </TableCell>
        <TableCell align='center'>
          {guarantorRelationship[guarantor.patientRelationship.relationship]}
        </TableCell>
        <TableCell align='center'>{normalizePhoneNumber(phoneNumbers.mobile[0])}</TableCell>
        <TableCell align='center'>
          {guarantor.textsEnabled && (
            <>
              <CheckIcon />
              <Typography sx={visuallyHidden}>Yes</Typography>
            </>
          )}
        </TableCell>
        <TableCell align='center'>
          {guarantor.isEmergencyContact && (
            <>
              <CheckIcon />
              <Typography sx={visuallyHidden}>Yes</Typography>
            </>
          )}
        </TableCell>
        <TableCell align='center'>
          <PatientGuarantorOptions guarantorId={guarantor.guarantorId} mpi={mpi} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderTop: 'unset' } }}>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showDetails} timeout='auto' unmountOnExit>
            <Grid container sx={{ px: 10, pt: 2, pb: 6 }} gap={2}>
              <Grid container spacing={2}>
                <GuarantorLabelValue label='First Name' value={guarantor.firstName} lg={2} />
                <GuarantorLabelValue label='Middle Name' value={guarantor.middleName} lg={2} />
                <GuarantorLabelValue label='Last Name' value={guarantor.lastName} lg={2} />
              </Grid>

              <Grid container spacing={2}>
                <GuarantorLabelValue label='Email' value={guarantor.email} md={4} lg={3} />
                <GuarantorLabelValue
                  label='Mobile Phone'
                  value={normalizePhoneNumber(phoneNumbers.mobile[0])}
                />
                <GuarantorLabelValue
                  label='Home Phone'
                  value={normalizePhoneNumber(phoneNumbers.home[0])}
                />
                <GuarantorLabelValue
                  label='Work Phone'
                  value={normalizePhoneNumber(phoneNumbers.work[0])}
                />
              </Grid>

              <Grid container spacing={2}>
                <GuarantorLabelValue
                  label='Address Line 1'
                  value={guarantor.addressLine1}
                  md={4}
                  lg={3}
                />
                <GuarantorLabelValue label='Address Line 2' value={guarantor.addressLine2} />
                <GuarantorLabelValue label='City' value={guarantor.city} />
                <GuarantorLabelValue label='State' value={guarantor.state} />
                <GuarantorLabelValue label='Zip' value={guarantor.zip} />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

GuarantorRow.propTypes = {
  mpi: PropTypes.string.isRequired,
  guarantor: PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    guarantorId: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    patientRelationship: PropTypes.shape({ relationship: PropTypes.string }),
    phoneNumbers: PropTypes.arrayOf(
      PropTypes.shape({ phoneNumber: PropTypes.string, tags: PropTypes.arrayOf(PropTypes.string) })
    ),
    textsEnabled: PropTypes.bool,
    isEmergencyContact: PropTypes.bool,
  }).isRequired,
};

const PatientGuarantorsTable = ({ mpi }) => {
  const guarantors = useSelector(({ patient }) => patient[mpi]?.guarantors);

  return (
    <>
      <TableContainer>
        <Table aria-label='Guarantors List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell align='center'>Relationship</TableCell>
              <TableCell align='center'>Mobile Phone</TableCell>
              <TableCell align='center'>Text Preference</TableCell>
              <TableCell align='center'>Emergency Contact</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(guarantors, (guarantor) => (
              <GuarantorRow mpi={mpi} key={guarantor.guarantorId} guarantor={guarantor} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(guarantors) && <LoadingModule height='25vh' />}

      {!isNil(guarantors) && isEmpty(guarantors) && <NothingFound height='25vh' />}
    </>
  );
};

PatientGuarantorsTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientGuarantorsTable;
