import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import OrderLineItemsTable from './OrderLineItemsTable';
import OrderLineItemsFilterStatusBar from './OrderLineItemsFilterStatusBar';
import OrderLineItemsFilterButton from './OrderLineItemsFilterButton';

const OrderLineItems = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.ORDER_LINE_ITEMS) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <OrderLineItemsFilterStatusBar />
      </Grid>
      <Grid item>
        <OrderLineItemsFilterButton />
      </Grid>
      <OrderLineItemsTable />
    </Grid>
  );
};

export default OrderLineItems;
