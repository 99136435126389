import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from 'react-final-form';
import { trim } from 'lodash';
import PropTypes from 'prop-types';

import MarkdownField from 'common/forms/MarkdownField';
import { validateMaxLength } from 'common/forms/formValidations';
import useRoles from 'common/hooks/useRoles';

const AddNoteDialog = ({ commentContexType, commentContextId, open, onClose, onAddNote }) => {
  const hasAccess = useRoles();

  const closeDialog = () => {
    onClose();
  };

  const addNote = (data) => {
    const content = {
      contentType: 'TEXT',
      message: data.message,
    };
    const commentContext = {
      id: commentContextId,
      type: commentContexType,
    };
    return onAddNote(commentContext, content, (success) => {
      if (success) {
        closeDialog();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-describedby={`${commentContexType}-AddNoteDialog-Header`}
      fullWidth
    >
      <DialogTitle id={`${commentContexType}-AddNoteDialog-Header`}>Add Note</DialogTitle>

      <Form
        onSubmit={addNote}
        render={({ values, submitting, invalid, handleSubmit }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <MarkdownField
                  name='message'
                  id={`${commentContexType}-AddNoteDialog-Header`}
                  validations={[
                    validateMaxLength('The note is too long. Please make it shorter', 65435),
                  ]}
                  disabled={!hasAccess.addPatientNote}
                  label='Note:'
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={closeDialog} color='secondary' size='medium' variant='outlined'>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  type='submit'
                  variant='contained'
                  disabled={trim(values.message).length < 3 || invalid || !hasAccess.addPatientNote}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  commentContexType: PropTypes.string.isRequired,
  commentContextId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
};

export default AddNoteDialog;
