import { debounce } from 'lodash';

import { listDrugApi, listDrugProfilesByDrugNameApi, upsertDrugProfileApi } from 'api/drug';
import { drugActions } from 'store/slices/drugSlice';

import { handleError } from './errorHandlerThunks';

export const loadDrugProfiles =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().drugs.drugProfiles;
    dispatch(drugActions.updateCurrentPage({ page }));

    return listDrugApi({ page, sortBy, filter })
      .then(({ count, result }) => {
        dispatch(drugActions.loadDrugProfiles({ page, items: result, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedLoadDrugProfiles = debounce(({ dispatch }) => {
  return dispatch(loadDrugProfiles({ page: 1 }));
}, 1000);

export const updateDrugProfilesFilter =
  ({ filter }) =>
  (dispatch) => {
    dispatch(drugActions.updateFilter({ filter }));
    debouncedLoadDrugProfiles({ dispatch });
  };

export const updateDrugProfileSortBy =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(drugActions.updateSortBy({ sortBy }));
    debouncedLoadDrugProfiles({ dispatch });
  };

export const upsertDrugProfile = (input) => (dispatch, getState) => {
  const { filter } = getState().drugs.drugProfiles;
  return upsertDrugProfileApi(input)
    .then((response) => {
      dispatch(updateDrugProfilesFilter({ filter }));
      return response;
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const fetchDrugProfiles =
  ({ filter }) =>
  (dispatch) =>
    listDrugApi({ page: 1, filter })
      .then(({ result }) => result)
      .catch((error) => dispatch(handleError({ error })));

export const listDrugProfilesByDrugName = (drugName) => (dispatch) =>
  listDrugProfilesByDrugNameApi(drugName)
    .then((drugProfiles) => drugProfiles)
    .catch((error) => dispatch(handleError({ error })));
