import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import NewPartnerButton from './NewPartnerButton';
import PatientPartnersTable from './PatientPartnerTable';

const PatientPartnersTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container direction='column' spacing={2} sx={{ mt: 2 }}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Partners
        </Typography>
        {hasAccess.addPartner && <NewPartnerButton mpi={mpi} />}
      </Grid>

      <Grid item>
        <PatientPartnersTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientPartnersTab;
