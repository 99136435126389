import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useRoles from 'common/hooks/useRoles';
import { deleteGuarantor } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

import EditGuarantorDialog from './EditGuarantorDialog';

const PatientGuarantorOptions = ({ guarantorId, mpi }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };

  const handleDeleteGuarantor = () => {
    handleMenuClose();
    showInfoDialog({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this guarantor',
      callback: () => dispatch(deleteGuarantor({ mpi, guarantorId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const handleCloseEditDialog = () => setShowEditDialog(false);

  return (
    <>
      <InfoDialog />
      <EditGuarantorDialog
        mpi={mpi}
        guarantorId={guarantorId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
      />

      <IconButton
        id={`PatientView-PatientGuarantorOptions-${guarantorId}options-button`}
        aria-label={`Guarantor options ${guarantorId}`}
        aria-controls={
          menuOpen ? `PatientView-PatientGuarantorOptions-${guarantorId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientGuarantorOptions-${guarantorId}options-menu`}
        aria-labelledby={`PatientView-PatientGuarantorOptions-${guarantorId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog} disabled={!hasAccess.updateGuarantor}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeleteGuarantor} disabled={!hasAccess.deleteGuarantor}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

PatientGuarantorOptions.propTypes = {
  mpi: PropTypes.string.isRequired,
  guarantorId: PropTypes.string.isRequired,
};
export default PatientGuarantorOptions;
