import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useState } from 'react';
import { isEmpty, without } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { saveTestClaim } from 'store/thunks/patientThunks';

const ActionItemFailClaimDialog = ({ open, onClose, testClaimResult }) => {
  const dispatch = useDispatch();
  const [failureReasons, setFailureReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const confirmFailDialog = () => {
    setSubmitting(true);
    dispatch(
      saveTestClaim({
        saveTestClaimResultInput: { ...testClaimResult, failureReasons, comment },
      })
    ).then(() => {
      onClose();
      setSubmitting(false);
    });
  };

  const handleCheck = (event) => {
    if (event.target.checked) {
      setFailureReasons([...failureReasons, event.target.value]);
    } else {
      setFailureReasons(without(failureReasons, event.target.value));
    }
  };

  const handleText = (event) => setComment(event.target.value);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='PatientView-ActionItemFailClaimDialog-failresult-dialog'
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle id='PatientView-ActionItemFailClaimDialog-failresult-dialog'>
        Test Claim Failed Confirmation
      </DialogTitle>
      <DialogContent>
        <Typography
          variant='body1'
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}
        >
          Reason of failure*
        </Typography>
        <FormGroup>
          <FormControlLabel
            onChange={handleCheck}
            control={<Checkbox />}
            label='Not Contracted With Insurance'
            value='Not Contracted With Insurance'
          />
          <FormControlLabel
            onChange={handleCheck}
            control={<Checkbox />}
            label='Non-Preferred With Insurance'
            value='Non-Preferred With Insurance'
          />
          <FormControlLabel
            onChange={handleCheck}
            control={<Checkbox />}
            label='Pharmacy Not Licensed In State'
            value='Pharmacy Not Licensed In State'
          />
          <FormControlLabel
            onChange={handleCheck}
            control={<Checkbox />}
            label='Other'
            value='Other'
          />
        </FormGroup>
        <Typography
          variant='subtitle2'
          component='label'
          id='PatientView-ActionItemFailClaimDialog-internalmessage-label'
          htmlFor='PatientView-ActionItemFailClaimDialog-internalmessage-input'
          sx={{ pt: 1, color: (theme) => lighten(theme.palette.text.primary, 0.3) }}
        >
          Internal Message
        </Typography>
        <TextField
          value={comment}
          onChange={handleText}
          fullWidth
          id='PatientView-ActionItemFailClaimDialog-internalmessage-input'
          variant='outlined'
          inputProps={{
            'aria-labelledby': 'PatientView-ActionItemFailClaimDialog-internalmessage-label',
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
        }}
      >
        <Button onClick={onClose} color='secondary' size='medium' variant='outlined' sx={{ ml: 1 }}>
          Cancel
        </Button>

        <LoadingButton
          loading={submitting}
          disabled={isEmpty(failureReasons)}
          onClick={confirmFailDialog}
          color='primary'
          size='medium'
          variant='contained'
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

ActionItemFailClaimDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  testClaimResult: PropTypes.shape({
    testProfileId: PropTypes.string,
    testClaimSubmissionId: PropTypes.string,
    payorType: PropTypes.string,
    result: PropTypes.oneOf(['PASS', 'FAIL']),
  }),
};

ActionItemFailClaimDialog.defaultProps = {
  testClaimResult: {},
};

export default ActionItemFailClaimDialog;
