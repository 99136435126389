/* eslint-disable import/prefer-default-export */

import { isNull, reduce } from 'lodash';
import { format } from 'date-fns';

import { listOrderLineItemsGql } from './gql';
import graphqlClient from '../graphql';
import { transformListOrderLineItems } from './transform';

export const listOrderLineItemsApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
    requestedDate: filter.requestedDate && format(new Date(filter.requestedDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrderLineItemsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrderLineItems);
};
