export default {
  CONTROLLED_SUBSTANCE_CHECK: 'Controlled Substance Check',
  CLAIM_PAY_CHECK: 'Claim Pay Check',
  CLAIM_PROFITABLE_CHECK: 'Claim Profitable Check',
  WILL_NOT_FILL: 'Will Not Fill',
  NOT_PROFITABLE: 'Not Profitable',
  PRESCRIPTION_VERIFICATION: 'Prescription Verification',
  PRESCRIPTION_CLARIFICATION_CHECK: 'Prescriber Clarification Check',
  PRESCRIPTION_VERIFIED: 'Verified',
  PRODUCT_FULFILLMENT: 'Needs Filled',
  PRODUCT_FULFILLED: 'Filled',
  RX_ENTRY: 'Rx Entry',
  RX_REQUEST: 'Rx Request',
  PROVIDER_CONTACTED: 'Provider Contacted',
  RX_RECEIVED: 'Rx Received',
};
