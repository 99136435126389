import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, map } from 'lodash';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { PrescriptionAutoFillSwitch, PrescriptionStatusSwitch } from './PrescriptionStatusSwitch';
import PatientPrescriptionOptions from './PatientPrescriptionOptions';

const PrescriptionsLabelValue = ({ value, label, md, lg }) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container direction='column' item xs={12} sm={6} md={md} lg={lg}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

PrescriptionsLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
};
PrescriptionsLabelValue.defaultProps = {
  value: '',
  md: 2,
  lg: 2,
};

const PrescriptionRow = ({ prescription, mpi }) => {
  const prescriptionOverride = prescription?.prescriptionOverride;

  return (
    <TableRow>
      <TableCell component='th' scope='row' align='center'>
        {prescription.rxNumber}
      </TableCell>
      <TableCell align='center'>
        {prescriptionOverride?.drugProfile?.drugName || prescription.drug}
      </TableCell>
      <TableCell align='center'>
        {prescriptionOverride?.prescriptionDetails?.prescriberName || prescription.prescriber}
      </TableCell>
      <TableCell align='center'>
        {prescriptionOverride?.prescriptionDetails?.quantity || prescription.dispensedQuantity}
      </TableCell>
      <TableCell align='center'>{prescription.primaryPlan}</TableCell>
      <TableCell align='center'>
        {format(new Date(prescription?.writtenDate), 'MM/dd/yyyy')}
      </TableCell>
      <TableCell align='center'>
        {format(new Date(prescription?.dateOrdered), 'MM/dd/yyyy hh:mm a')}
      </TableCell>
      <TableCell
        align='center'
        sx={{
          padding: '5px 0px 5px 0px',
        }}
      >
        <PrescriptionStatusSwitch
          key={prescription.prescriptionUUID}
          checked={prescription.isActive}
          prescriptionId={prescription.prescriptionUUID}
          mpi={mpi}
        />
      </TableCell>
      <TableCell
        align='center'
        sx={{
          padding: '5px 0px 5px 0px',
        }}
      >
        <PrescriptionAutoFillSwitch
          key={prescription.prescriptionUUID}
          checked={prescription.autofill}
          prescriptionId={prescription.prescriptionUUID}
          mpi={mpi}
          type={prescription?.prescriptionType}
        />
      </TableCell>
      <TableCell align='center'>
        <PatientPrescriptionOptions prescriptionId={prescription.prescriptionUUID} mpi={mpi} />
      </TableCell>
    </TableRow>
  );
};

PrescriptionRow.propTypes = {
  prescription: PropTypes.shape({
    primaryPlan: PropTypes.string,
    lastFillDate: PropTypes.string,
    dispensedQuantity: PropTypes.string,
    sig: PropTypes.string,
    rxNumber: PropTypes.string,
    drug: PropTypes.string,
    prescriber: PropTypes.string,
    refillsRemaining: PropTypes.string,
    hoa: PropTypes.string,
    cycleFill: PropTypes.string,
    expirationDate: PropTypes.string,
    dateOrdered: PropTypes.string,
    writtenDate: PropTypes.string,
    prescriberPhone: PropTypes.string,
    prescriptionUUID: PropTypes.string,
    isActive: PropTypes.bool,
    autofill: PropTypes.bool,
    prescriptionType: PropTypes.string,
    prescriptionOverride: PropTypes.shape({
      drugProfile: PropTypes.shape({
        drugName: PropTypes.string,
      }),
      prescriptionDetails: PropTypes.shape({
        quantity: PropTypes.string,
        prescriberName: PropTypes.string,
      }),
    }),
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

const PatientPrescriptionsTable = ({ mpi }) => {
  const prescriptions = useSelector(({ patient }) => patient[mpi]?.prescriptions);

  return (
    <>
      <TableContainer>
        <Table aria-label='Prescription List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Rx Number</TableCell>
              <TableCell align='center'>Drug Name</TableCell>
              <TableCell align='center'>Prescriber</TableCell>
              <TableCell align='center'>Quantity</TableCell>
              <TableCell align='center'>Plan</TableCell>
              <TableCell align='center'>Written Date</TableCell>
              <TableCell align='center'>Order Sent</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Autofill</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(prescriptions, (prescription) => (
              <PrescriptionRow key={prescription.rxNumber} mpi={mpi} prescription={prescription} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(prescriptions) && <LoadingModule height='25vh' />}

      {!isNil(prescriptions) && isEmpty(prescriptions) && <NothingFound height='25vh' />}
    </>
  );
};

PatientPrescriptionsTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientPrescriptionsTable;
