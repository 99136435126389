/* istanbul ignore file */

// React Beautiful DnD doesn't support strict mode
// This hook is to disable strict mode for that specific use-case
// https://github.com/atlassian/react-beautiful-dnd/issues/2396
import { useEffect, useState } from 'react';

const useStrictDroppable = (loading) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

export default useStrictDroppable;
