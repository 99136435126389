import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextField from 'common/forms/TextField';
import { useState } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';
import { editOrderShipment } from 'store/thunks/patientThunks';
import { useSnackbar } from 'notistack';

const EditShipmentDialog = ({ open, onClose, orderShipmentId, mpi }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const patientShipment = find(patientInfo?.shipmentHistory.shipments, { orderShipmentId });
  const shipment = {
    spOrderId: patientShipment?.spOrderId,
    trackingNumber: patientShipment?.shipmentData?.trackingNumber,
    carrierCode: patientShipment?.shipmentData?.carrierCode,
  };

  const [loading, setLoading] = useState(false);

  const handleCloseModal = (_, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleEditShipment = (formData) => {
    setLoading(true);

    dispatch(editOrderShipment({ input: { ...formData, orderShipmentId }, mpi }))
      .then((updatedShipment) => {
        if (updatedShipment) {
          enqueueSnackbar('Order shipment updated', { variant: 'success' });
        }

        handleCloseModal();
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='Shipments-EditShipment-header'
      fullWidth
    >
      <DialogTitle id='Shipments-EditShipment-header'>
        Edit Shipment - {orderShipmentId}
      </DialogTitle>

      <Form
        initialValues={{ ...shipment }}
        onSubmit={handleEditShipment}
        render={({ handleSubmit, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='Shipments-EditShipment-nameOnAddress-input'
                label='Shipstation Order Id'
                name='spOrderId'
                required
                autoFocus
              />
              <TextField
                id='Shipments-EditShipment-addressLine1-input'
                label='Tracking Number'
                name='trackingNumber'
                required
              />
              <TextField
                id='Shipments-EditShipment-addressLine2-input'
                label='Carrier Code'
                name='carrierCode'
                required
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton loading={loading} variant='contained' disabled={invalid} type='submit'>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditShipmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderShipmentId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};

export default EditShipmentDialog;
