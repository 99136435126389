import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { capitalize, map } from 'lodash';
import PropTypes from 'prop-types';
import { lighten } from '@mui/material/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import { getTransactionHistoryApi } from 'api/transactions';
import gender from 'common/constants/gender';
import transactionTypes from 'common/constants/transactionTypes';
import { handleError } from 'store/thunks/errorHandlerThunks';

const TransactionDetailsLabelValue = ({ value, label }) => {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item container justifyContent='flex-start' alignItems='center'>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }} component='span'>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

TransactionDetailsLabelValue.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const TransactionDetailsViewer = ({ transaction }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState(null);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  useEffect(() => {
    if (!transactionHistory && open) {
      getTransactionHistoryApi({ externalTransactionId: transaction.externalTransactionId })
        .then(setTransactionHistory)
        .catch((error) => dispatch(handleError({ error })));
    }
  }, [transactionHistory, open, dispatch, transaction.externalTransactionId]);

  return (
    <>
      <IconButton
        aria-label={`View transaction ${transaction.externalTransactionId}`}
        onClick={openDialog}
      >
        <OpenInNewIcon />
      </IconButton>

      <Dialog open={open} maxWidth='lg' aria-labelledby='Transactions-TransactionViewer-header'>
        <DialogTitle id='Transactions-TransactionViewer-header' fontWeight={600}>
          Transaction - {transaction.externalTransactionId}
        </DialogTitle>
        {!transactionHistory && <LoadingModule height='25vh' />}
        <DialogContent sx={{ maxWidth: '100vh' }}>
          {transactionHistory && (
            <Grid>
              <Grid container>
                <Grid container direction='column' item xs={6} gap={1}>
                  <TransactionDetailsLabelValue
                    label='User Name'
                    value={transaction.userFullName}
                  />
                  <TransactionDetailsLabelValue
                    label='Patient Gender'
                    value={gender[transaction.patientGender]}
                  />
                  <TransactionDetailsLabelValue
                    label='Credit Card'
                    value={`•••• •••• •••• ${transaction.cardNumber}`}
                  />
                </Grid>
                <Grid container direction='column' item xs={4} gap={1}>
                  <TransactionDetailsLabelValue
                    label='Patient Name'
                    value={transaction.patientName}
                  />
                  <TransactionDetailsLabelValue
                    label='Patient DOB'
                    value={format(new Date(transaction.patientDob), 'MM/dd/yyyy')}
                  />
                  <TransactionDetailsLabelValue
                    label='Card expiry'
                    value={transaction.cardExpiry}
                  />
                </Grid>
              </Grid>
              <Grid sx={{ mt: 2 }}>
                <Box sx={{ border: 0.5 }}>
                  <TableContainer>
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: (theme) => lighten(theme.palette.primary.light, 0.5),
                        }}
                      >
                        <TableRow>
                          <TableCell>Transaction Timestamp</TableCell>
                          <TableCell>Transaction Type</TableCell>
                          <TableCell>Amount</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {map(
                          transactionHistory,
                          ({ amount, transactionType, transactionTimestamp, transactionId }) => (
                            <TableRow key={transactionId}>
                              <TableCell align='left'>
                                {format(new Date(transactionTimestamp), 'MM/dd/yyyy hh:mm a')}
                              </TableCell>
                              <TableCell align='center'>
                                {capitalize(transactionTypes[transactionType])}
                              </TableCell>
                              <TableCell align='center'>{`$${amount}`}</TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} variant='contained' sx={{ maxWidth: 150 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TransactionDetailsViewer.propTypes = {
  transaction: PropTypes.shape({
    externalTransactionId: PropTypes.string.isRequired,
    patientName: PropTypes.string.isRequired,
    transactionType: PropTypes.string.isRequired,
    transactionTimestamp: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    userFullName: PropTypes.string.isRequired,
    patientGender: PropTypes.string.isRequired,
    patientDob: PropTypes.string.isRequired,
    cardExpiry: PropTypes.string.isRequired,
  }).isRequired,
};

export default TransactionDetailsViewer;
