import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Card, CardContent, CardHeader, IconButton } from '@mui/material';

const AdminCenterOptionTileView = ({ pathName, title, description }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleCardElevation = () => {
    setIsHovered(!isHovered);
  };

  return (
    <Box>
      <Card
        sx={{
          minWidth: 300,
          maxWidth: 350,
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease-in-out',
          boxShadow: isHovered ? theme.shadows[3] : theme.shadows[1],
        }}
        onMouseEnter={handleCardElevation}
        onMouseLeave={handleCardElevation}
        onClick={() => navigate(pathName)}
      >
        <CardHeader
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          title={title}
          action={
            <IconButton aria-label={description}>
              <OpenInNewIcon />
            </IconButton>
          }
        />
        <CardContent>{description}</CardContent>
      </Card>
    </Box>
  );
};

export default AdminCenterOptionTileView;

AdminCenterOptionTileView.propTypes = {
  pathName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
