/* eslint-disable max-lines */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { lighten } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { useEffect, useState } from 'react';
import { differenceInYears, format } from 'date-fns';
import { capitalize, find, map, replace } from 'lodash';

import useInfoDialog from 'common/hooks/useInfoDialog';
import patientGenders from 'common/constants/gender';

import { LoadingButton } from '@mui/lab';
import { getFailedOrderDetails, saveAndAuthorizePayment } from 'store/thunks/transactionsThunk';
import CreditCardForm from 'common/payments/CreditCardForm';
import { visuallyHidden } from '@mui/utils';
import LoadingModule from 'common/components/LoadingModule';
import { utcToZonedTime } from 'date-fns-tz';
import { encryptCardData } from 'api/utils';
import { enqueueSnackbar } from 'notistack';

const UpdateAndRetryPayment = ({ orderId, mpi, amount, userId, transactionId }) => {
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const failedOrder = find(patientInfo?.failedOrders, { orderId });
  const patientAddress = find(patientInfo?.addresses, { isDefault: true })?.address;
  const publicKey = useSelector(({ app }) => app.publicKey);

  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!failedOrder && showModal) {
      dispatch(getFailedOrderDetails({ mpi, orderId }));
    }
  }, [dispatch, mpi, orderId, showModal, failedOrder]);

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const triggerSaveAndUpdateCard = (cardData) => {
    setIsLoading(true);
    dispatch(
      saveAndAuthorizePayment({
        amount,
        userId,
        patientId: mpi,
        orderId,
        cardData: encryptCardData({
          cardData,
          publicKey,
        }),
        paymentMethodName: cardData.cardholderName,
        failedTransactionId: transactionId,
        orderReferenceId: failedOrder.orderReferenceId,
      })
    )
      .then((response) => {
        if (response) {
          enqueueSnackbar(`Successfully Updated and Authorized`, { variant: 'success' });
          setShowModal(false);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const handleUpdateAndRetryPayment = (formData) => {
    const cardData = {
      ...formData,
      cardNumber: replace(formData.cardNumber, /[^0-9]/g, ''),
    };

    showInfoDialog({
      title: 'Confirm Update',
      message: `Are you sure you want to update the card and authorize $${amount}?`,
      callback: () => triggerSaveAndUpdateCard(cardData),
      cancelable: true,
      isWarning: false,
      confirmButtonText: 'Update and Authorize',
    });
  };

  const handleOpenModal = () => {
    setShowModal(true);
    setIsLoading(false);
  };

  const showLoading = !patientInfo || !failedOrder;

  return (
    <>
      <InfoDialog />

      <Button variant='contained' sx={{ width: 75 }} onClick={handleOpenModal}>
        Update
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='Transactions-update-card-header'
        fullWidth
      >
        <DialogTitle id='Transactions-update-card-header'>Update and Authorize Payment</DialogTitle>
        {showLoading && <LoadingModule height='25vh' />}

        {failedOrder && patientInfo && (
          <Form
            onSubmit={handleUpdateAndRetryPayment}
            render={({ handleSubmit, submitting, invalid }) => (
              <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid>
                    <PatientDetails patientInfo={patientInfo} />

                    <OrderDetails failedOrder={failedOrder} />

                    <ShippingAddressDetails
                      address={patientAddress}
                      patientName={patientInfo.patientName}
                    />

                    <CreditCardDetails />
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleCloseModal}
                    disabled={submitting}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    variant='contained'
                    loading={isLoading}
                    disabled={invalid}
                    type='submit'
                  >
                    Update Card
                  </LoadingButton>
                </DialogActions>
              </form>
            )}
          />
        )}
      </Dialog>
    </>
  );
};

UpdateAndRetryPayment.propTypes = {
  amount: PropTypes.number.isRequired,
  mpi: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  transactionId: PropTypes.string.isRequired,
};

const TransactionDetailsLabelValue = ({ value, label }) => {
  return (
    <Grid container direction='row' sx={{ mt: 2 }}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item justifyContent='flex-start' alignItems='center' sx={{ ml: 1 }}>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }} component='span'>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

TransactionDetailsLabelValue.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const PatientDetails = ({ patientInfo }) => (
  <Grid>
    <Typography sx={{ fontWeight: 600 }}>Patient Details</Typography>

    <TransactionDetailsLabelValue label='Name: ' value={patientInfo.patientName} />
    <TransactionDetailsLabelValue
      label='Date of Birth: '
      value={`${format(
        utcToZonedTime(new Date(patientInfo?.dob), 'utc'),
        'MM/dd/yyyy'
      )} (Age ${differenceInYears(new Date(), new Date(patientInfo?.dob))})`}
    />
    <TransactionDetailsLabelValue label='Gender: ' value={patientGenders[patientInfo.gender]} />
  </Grid>
);

PatientDetails.propTypes = {
  patientInfo: PropTypes.shape({
    patientName: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    dob: PropTypes.string.isRequired,
  }).isRequired,
};

const OrderDetails = ({ failedOrder }) => (
  <Grid sx={{ mt: 2 }}>
    <Typography sx={{ fontWeight: 600 }}>Order - {failedOrder.orderId}</Typography>

    <Box sx={{ mt: 2 }} component={Paper}>
      <TableContainer>
        <Table>
          <TableHead
            sx={{
              backgroundColor: (theme) => lighten(theme.palette.primary.light, 0.5),
            }}
          >
            <TableRow>
              <TableCell sx={{ p: 1.5 }}>Item name</TableCell>
              <TableCell sx={{ p: 1.5 }}>Rx Number</TableCell>
              <TableCell sx={{ p: 1.5 }}>Type</TableCell>
              <TableCell sx={{ p: 1.5 }}>Form</TableCell>
              <TableCell sx={{ p: 1.5 }} align='center'>
                Autofill
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {map(failedOrder.lineItems, ({ itemName, rxNumber, form, itemType, autoFill }) => (
              <TableRow key={itemName}>
                <TableCell align='left'>{itemName}</TableCell>
                <TableCell align='left'>{rxNumber}</TableCell>
                <TableCell align='left'>{capitalize(itemType)}</TableCell>
                <TableCell align='left'>{form}</TableCell>
                <TableCell align='center'>
                  {autoFill && (
                    <>
                      <CheckIcon />
                      <Typography sx={visuallyHidden}>Yes</Typography>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Grid>
);

OrderDetails.propTypes = {
  failedOrder: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        itemName: PropTypes.string.isRequired,
        itemType: PropTypes.string.isRequired,
        rxNumber: PropTypes.string.isRequired,
        form: PropTypes.string.isRequired,
        autoFill: PropTypes.bool.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

const ShippingAddressDetails = ({ address, patientName }) => (
  <Grid sx={{ mt: 4 }}>
    <Typography sx={{ fontWeight: 600 }}>Shipping Address</Typography>
    <Grid
      container
      component={Paper}
      sx={{ p: 2, maxWidth: 300, width: '100%', mt: 2 }}
      direction='column'
    >
      <Grid item container alignItems='center' justifyContent='space-between'>
        <Grid item sx={{ flexGrow: 5, maxWidth: '65%' }}>
          <Typography
            component='h3'
            variant='subtitle1'
            sx={{ fontWeight: 550, fontSize: '1.2em', overflowWrap: 'anywhere' }}
          >
            {patientName}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography>{address.addressLine1}</Typography>
      </Grid>
      {address.addressLine2 && (
        <Grid item>
          <Typography>{address.addressLine2}</Typography>
        </Grid>
      )}
      <Grid item>
        <Typography>{address.city}</Typography>
      </Grid>
      <Grid item>
        <Typography>{address.state}</Typography>
      </Grid>
      <Grid item>
        <Typography>{address.zip}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

ShippingAddressDetails.propTypes = {
  patientName: PropTypes.string.isRequired,
  address: PropTypes.shape({
    addressLine1: PropTypes.string.isRequired,
    addressLine2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }).isRequired,
};

const CreditCardDetails = () => (
  <Grid sx={{ mt: 4 }}>
    <Typography sx={{ fontWeight: 600 }}>Credit Card Details</Typography>
    <CreditCardForm />
  </Grid>
);

export default UpdateAndRetryPayment;
