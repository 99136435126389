/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getUserAuthGql = gql`
  query getUserAuth($authRef: UUID!) {
    getUserAuth(authReference: $authRef) {
      accessToken
      expiry
    }
  }
`;

export const refreshTokenGql = gql`
  mutation refreshToken {
    refreshToken {
      accessToken
      expiry
    }
  }
`;

export const logoutGql = gql`
  mutation logout {
    logout
  }
`;
