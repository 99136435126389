import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';
import PrescriptionReviewFilterStatusBar from './PrescriptionReviewFilterStatusBar';
import PrescriptionReviewFilterButton from './PrescriptionReviewFilterButton';
import PrescriptionReviewTable from './PrescriptionReviewTable';

const PrescriptionReview = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.PRESCRIPTION_REVIEW) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <PrescriptionReviewFilterStatusBar />
      </Grid>
      <Grid item>
        <PrescriptionReviewFilterButton />
      </Grid>
      <PrescriptionReviewTable />
    </Grid>
  );
};

export default PrescriptionReview;
