/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';
import graphqlClient from 'api/graphql';

import { listSigAssociationsGql, upsertSigAssociationsGql } from './gql';

export const listSigAssociationsApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const defaultPageOffset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);

  return graphqlClient
    .request(listSigAssociationsGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage || defaultPageOffset,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(({ listSigAssociations }) => listSigAssociations);
};

export const upsertSigAssociationsApi = ({ input }) => {
  return graphqlClient
    .request(upsertSigAssociationsGql, { input })
    .then(({ upsertSigAssociations }) => upsertSigAssociations);
};
