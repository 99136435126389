export default {
  0: '0 - No Product Selection Indicated',
  1: '1 - Substitution Not Allowed by Prescriber',
  2: '2 - Substitution Allowed - Patient Requested Product Dispensed',
  3: '3 - Substitution Allowed - Pharmacist Selected Product Dispensed',
  4: '4 - Substitution Allowed - Generic Drug Not In Stock',
  5: '5 - Substitution Allowed - Brand Drug Dispensed as a Generic',
  6: '6 - Override',
  7: '7 - Substitution Not Allowed - Brand Drug Mandated by Law',
  8: '8 - Substitution Allowed - Generic Not Available in Marketplace',
  9: '9 - Substitution Allowed by Prescriber but Plan Requests Brand',
};
