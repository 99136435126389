import moment from 'moment';
import { debounce } from 'lodash';

import { getRequestByReferenceIdApi, listApiOrdersStatusApi } from 'api/orderStatus';
import { orderStatusActions } from 'store/slices/orderStatusSlice';
import { applyFulfillingPharmacyFilter } from 'api/utils';

import { handleError } from './errorHandlerThunks';

export const loadPrescriptionOrders =
  ({ page }) =>
  (dispatch, getState) => {
    const { preset } = getState().orderStatus;
    const { sortBy, filter } = getState().orderStatus.eScriptOrders;
    const updatedFilter = {
      ...applyFulfillingPharmacyFilter(getState, filter),
      ...(!filter?.createdAt && {
        createdAt: [
          moment().subtract(7, 'days').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD'),
        ],
      }),
    };

    dispatch(orderStatusActions.updateCurrentPage({ preset, page }));

    return listApiOrdersStatusApi({ page, sortBy, filter: updatedFilter })
      .then(({ result, count }) =>
        dispatch(orderStatusActions.loadPrescriptionOrders({ preset, page, items: result, count }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedLoadPrescriptionOrders = debounce(({ dispatch }) => {
  return dispatch(loadPrescriptionOrders({ page: 1 }));
}, 1000);

export const updatePrescriptionOrdersFilter =
  ({ filter }) =>
  (dispatch, getState) => {
    const { preset } = getState().orderStatus;
    dispatch(orderStatusActions.updatePrescriptionOrdersFilter({ preset, filter }));
    debouncedLoadPrescriptionOrders({ dispatch });
  };

export const updatePrescriptionOrdersSortBy =
  ({ sortBy }) =>
  (dispatch, getState) => {
    const { preset } = getState().orderStatus;
    dispatch(orderStatusActions.updatePrescriptionOrdersSortBy({ preset, sortBy }));
    debouncedLoadPrescriptionOrders({ dispatch });
  };

export const loadRequestDetails =
  ({ referenceId }) =>
  (dispatch, getState) => {
    const { preset } = getState().orderStatus;
    const { currentPage } = getState().orderStatus[preset];

    return getRequestByReferenceIdApi({ referenceId }).then((requestDetails) =>
      dispatch(
        orderStatusActions.updateRequestDetails({
          preset,
          currentPage,
          referenceId,
          requestDetails,
        })
      )
    );
  };
