/* eslint-disable import/prefer-default-export */
import { map, omit } from 'lodash';

export const transformListOrders = ({ listOrders }) => ({
  count: listOrders.count,
  orders: map(
    listOrders.orders,
    ({
      patient,
      partner,
      lineItems,
      orderId,
      orderStatus,
      requestNeedsByDate,
      createdAt,
      lastUpdated,
      totalInOrder,
      waitingOnFill,
    }) => {
      const { patientDemographics, mpi } = patient;

      return {
        orderId,
        mpi,
        patientName: `${patientDemographics.lastName}, ${patientDemographics.firstName}`,
        partnerName: partner.partnerName,
        orderStatus,
        lineItems,
        requestNeedsByDate,
        createdAt,
        lastUpdated,
        waitingOnFill,
        totalInOrder,
      };
    }
  ),
});

export const transformOrderShipment = ({ createOrderShipment: orderShipment }) => {
  const { orderIds, orderShipmentId, shipmentData, spOrderId, shipmentStatus } = orderShipment;

  return {
    shipmentId: orderShipmentId,
    createdDate: shipmentData.createDate,
    orderIds,
    shipstationId: spOrderId,
    status: shipmentStatus,
    trackingNumber: shipmentData.trackingNumber,
    carrierCode: shipmentData.carrierCode,
  };
};

export const transformListOrdersForShipment = ({ listOrdersForShipment }) => ({
  count: listOrdersForShipment.count,
  orders: map(
    listOrdersForShipment.orders,
    ({ patient, orderId, orderStatus, requestNeedsByDate, lastUpdated }) => {
      const { patientDemographics, mpi } = patient;
      const patientName = `${patientDemographics.lastName}, ${patientDemographics.firstName}`;

      return {
        orderId,
        mpi,
        patientName,
        orderStatus,
        requestNeedsByDate,
        lastUpdated,
      };
    }
  ),
});

export const transformListPomOrders = ({ listPomOrders }) => ({
  count: listPomOrders.count,
  orders: map(listPomOrders.result, (order) => ({
    ...omit(order, 'patient'),
    patientName: `${order.patient.lastName}, ${order.patient.firstName}`,
    mpi: order.patient.mpi,
  })),
});
