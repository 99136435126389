export const DrugProfileFieldKeys = {
  NDC: 'ndc',
  UNIT: 'unit',
  STATUS: 'isActive',
  STRENGTH: 'strength',
  DRUG_NAME: 'drugName',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  MANUFACTURER: 'manufacturer',
  DESCRIPTION: 'description',
};

export const DrugProfileStatus = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
