import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { isNil, map } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingModule from 'common/components/LoadingModule';
import { associatePatientWithExternalProfile, searchQS1Profile } from 'store/thunks/patientThunks';

const CompleteProfileDialog = ({ open, onClose, mpi }) => {
  const dispatch = useDispatch();
  const patientData = useSelector(({ patient }) => patient[mpi]);
  const firstName = patientData?.firstName;
  const lastName = patientData?.lastName;
  const dob = patientData?.dob;

  const [selectedValue, setSelectedValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (open) {
      dispatch(
        searchQS1Profile({
          dob,
          firstName,
          lastName,
          mpi,
        })
      );
    }
  }, [dob, lastName, firstName, open, dispatch, mpi]);

  const qs1Profiles = useSelector(({ patient }) => patient[mpi]?.qs1Profiles);

  const handleSubmit = () => {
    setLoading(true);
    return dispatch(
      associatePatientWithExternalProfile({ externalProfile: { qs1Id: selectedValue }, mpi })
    ).finally(() => {
      setLoading(false);
      onClose();
    });
  };

  return (
    <Dialog open={open} sx={{ width: '100vw' }}>
      <DialogTitle>Complete profile</DialogTitle>

      <DialogContent>
        <TableContainer sx={{ borderTop: '1px solid #d3d3d3 ', borderBottom: '1px solid #d3d3d3' }}>
          <Table aria-label='qs1-profile-table'>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>First name</TableCell>
                <TableCell>Last name</TableCell>
                <TableCell>Date of birth</TableCell>
                <TableCell>Qs1 Id</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {map(qs1Profiles, (profile) => (
                <TableRow
                  key={profile.qs1Id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>
                    <Radio
                      onChange={handleChange}
                      value={profile.qs1Id}
                      name='radio-buttons'
                      checked={selectedValue === profile.qs1Id}
                      title={`patient_qs1_id_${profile.qs1Id}`}
                    />
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {profile.firstName}
                  </TableCell>
                  <TableCell>{profile.lastName}</TableCell>
                  <TableCell>{profile.dob}</TableCell>
                  <TableCell>{profile.qs1Id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {isNil(qs1Profiles) && <LoadingModule height='25vh' />}
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant='contained'
          type='button'
          onClick={handleSubmit}
          loading={isLoading}
          disabled={!selectedValue}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

CompleteProfileDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mpi: PropTypes.string.isRequired,
};

export default CompleteProfileDialog;
