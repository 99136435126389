/* eslint-disable import/prefer-default-export */
import { find, includes, isEmpty, isNull, last, map, mapValues, merge, omit } from 'lodash';

const constructDataForDuplicationReview = (prescriptions) => {
  const data = map(prescriptions, (prescription) => {
    const { instructions, prescriber } = prescription.request.medication;
    const { firstName, lastName, middleName, title, npi } = prescriber;
    return {
      ...omit(prescription, ['isActive', 'prescriptionUUID', 'originalRx', 'refillsRemaining']),
      npi,
      instructions,
      prescriber: `${title || ''} ${firstName} ${middleName || ''} ${lastName}`,
      refillsRemaining: !isNull(prescription.refillsRemaining)
        ? `${prescription.refillsRemaining}`
        : prescription.refillsRemaining,
    };
  });

  return data;
};

export const transformListPrescriptionsForReview = ({ listPrescriptionForReview }) => {
  return {
    count: listPrescriptionForReview.count,
    prescriptions: map(
      listPrescriptionForReview.result,
      ({ orderId, patient, interactionData, prescription, lineItem }) => {
        const { rxNumber, drug, prescriptionUUID, strength, request, form } = prescription;
        const { status, existingPrescriptions } = interactionData;
        const { patientDemographics, patientContact, patientIdentity } = patient;
        const { firstName, middleName, lastName, dob, mpi, gender } = patientDemographics;
        const { addresses, emails, phoneNumbers } = patientContact;
        const { address } = find(addresses, ({ tags }) => includes(tags, 'DEFAULT'));
        const { email } = find(emails, ({ tags }) => includes(tags, 'DEFAULT'));
        const { phoneNumber: mobilePhone } = find(phoneNumbers, ({ tags }) =>
          includes(tags, 'DEFAULT')
        );

        return {
          orderId,
          status,
          rxNumber,
          drugName: drug,
          requestedDate: lineItem?.requestedDate,
          pomPrescriptionId: prescriptionUUID,
          strength,
          instructions: request?.medication?.instructions,
          needsByDate: lineItem?.needsByDate,
          form,
          patient: {
            dob,
            patientName: `${firstName} ${middleName || ''} ${lastName}`,
            phoneNumber: find(patientContact.phoneNumbers, (phoneNumber) =>
              includes(phoneNumber.tags, 'DEFAULT')
            ).phoneNumber,
            patientId: mpi,
            qs1Id: patientIdentity?.qs1PatientId,
            middleName,
            firstName,
            lastName,
            address: { ...address, addressLine1: address.line1, addressLine2: address?.line2 },
            gender,
            mpi,
            email,
            mobilePhone,
          },
          prescriptionData: constructDataForDuplicationReview([prescription])[0],
          existingPrescriptions: constructDataForDuplicationReview(existingPrescriptions),
        };
      }
    ),
  };
};

export const transformListOverrideDetails = ({
  listPrescriptionOverrideAudits: overrideDetails,
}) => {
  const formatObject = (obj) => ({
    editedTimestamp: obj?.editedTimestamp,
    fieldName: obj?.editedDetails?.fieldName,
    user: !isEmpty(obj) && `${obj?.user?.firstName} ${obj?.user?.lastName}`,
  });

  const lastOverrides = mapValues(overrideDetails, (value, key) => {
    if (key === 'prescriptionDetails') {
      return mapValues(value, (val) => (!isEmpty(val) ? formatObject(last(val)) : {}));
    }

    return !isEmpty(value) ? formatObject(last(value)) : {};
  });

  return omit(merge({}, lastOverrides, lastOverrides.prescriptionDetails), 'prescriptionDetails');
};
