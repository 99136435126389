import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import TextField from 'common/forms/TextField';
import AutoCompleteField from 'common/forms/AutoCompleteField';
import DrugNameAutoSuggest from 'common/forms/DrugNameAutoSuggest';
import { validatePhoneNumber } from 'common/forms/formValidations';
import { addPatientMedication } from 'store/thunks/patientThunks';
import SelectField from 'common/forms/SelectField';
import dawCodes from 'common/constants/dawCodes';

const NewMedicationButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(
    ({ patient }) => ({
      firstName: patient[mpi]?.firstName,
      middleName: patient[mpi]?.middleName,
      lastName: patient[mpi]?.lastName,
    }),
    shallowEqual
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddMedication = (formData) =>
    dispatch(
      addPatientMedication({
        medicationData: { ...formData, mpi, dawCode: parseInt(formData.dawCode, 10) },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });

  return (
    <>
      <Button variant='contained' disabled={!patientName.firstName} onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewMedicationButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewMedicationButton-header'>Add Medication</DialogTitle>

        <Form
          initialValues={{ dawCode: 0 }}
          onSubmit={handleAddMedication}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <DrugNameAutoSuggest
                  id='PatientView-NewMedicationButton-medicationName-input'
                  name='medicationName'
                  label='Medication Name'
                  required
                  autoFocus
                  prefillNdcField='medicationNdc'
                />

                <TextField
                  id='PatientView-NewMedicationButton-ndc-input'
                  label='NDC'
                  name='medicationNdc'
                  required
                />

                <SelectField
                  label='DAW'
                  name='dawCode'
                  id='PatientView-NewMedicationButton-daw-dropdown'
                  required
                >
                  {map(dawCodes, (description, key) => (
                    <MenuItem key={key} value={key}>
                      {description}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewMedicationButton-strength-input'
                  label='Strength'
                  name='strength'
                  required
                />

                <AutoCompleteField
                  id='PatientView-NewMedicationButton-form-input'
                  name='form'
                  label='Form'
                  options={['Tablet', 'Capsule']}
                  freeSolo
                  required
                />

                <TextField
                  id='PatientView-NewMedicationButton-quantity-input'
                  label='Quantity'
                  name='quantity'
                  required
                />

                <TextField
                  id='PatientView-NewMedicationButton-instructions-input'
                  label='Instructions'
                  name='instructions'
                  maxLength={256}
                />

                <TextField
                  id='PatientView-NewMedicationButton-prescribedBy-input'
                  label='Prescribed By'
                  name='providerName'
                />

                <TextField
                  id='PatientView-NewMedicationButton-pharmacyName-input'
                  label='Pharmacy Name'
                  name='pharmacyName'
                  required
                />

                <TextField
                  id='PatientView-NewMedicationButton-pharmacyPhone-input'
                  label='Pharmacy Phone'
                  name='pharmacyPhone'
                  required
                  validations={[validatePhoneNumber()]}
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewMedicationButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewMedicationButton;
