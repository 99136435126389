import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import ReviewInClaimEligibilityTable from './ReviewInClaimEligibilityTable';
import ReviewInClaimEligibilityFilterButton from './ReviewInClaimEligibilityFilterButton';
import ReviewInClaimEligibilityFilterStatusBar from './ReviewInClaimEligibilityFilterStatusBar';

const ReviewInClaimEligibility = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.REVIEW_IN_CLAIM_ELIGIBILITY) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <ReviewInClaimEligibilityFilterStatusBar />
      </Grid>
      <Grid item>
        <ReviewInClaimEligibilityFilterButton />
      </Grid>
      <ReviewInClaimEligibilityTable />
    </Grid>
  );
};

export default ReviewInClaimEligibility;
