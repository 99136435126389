import { Chip, Grid, Paper, Typography } from '@mui/material';
import { isEmpty, isNil, map } from 'lodash';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import PatientAddressOption from './PatientAddressOptions';

const PatientAddressesCard = ({ mpi }) => {
  const addresses = useSelector(({ patient }) => patient[mpi]?.addresses);

  return (
    <>
      <Grid container gap={2} sx={{ mb: 3 }}>
        {map(addresses, ({ address, isDefault, patientAddressId, nameOnAddress }) => (
          <Grid component={Paper} sx={{ p: 2, minWidth: 400 }} key={patientAddressId}>
            <Grid item container alignItems='center' justifyContent='space-between'>
              <Grid item sx={{ flexGrow: 5, maxWidth: '65%' }}>
                <Typography
                  component='h3'
                  variant='subtitle1'
                  sx={{ fontWeight: 550, fontSize: '1.2em', overflowWrap: 'anywhere' }}
                >
                  {nameOnAddress}
                </Typography>
              </Grid>
              <Grid item sx={{ maxWidth: '65%' }}>
                {isDefault && <Chip label='Default' size='small' sx={{ borderRadius: 1 }} />}
                <PatientAddressOption
                  mpi={mpi}
                  patientAddressId={patientAddressId}
                  isDefault={isDefault}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Typography>{address.addressLine1}</Typography>
            </Grid>
            <Grid item>
              <Typography>{address.addressLine2}</Typography>
            </Grid>
            <Grid item>
              <Typography>{address.city}</Typography>
            </Grid>
            <Grid item>
              <Typography>{address.state}</Typography>
            </Grid>
            <Grid item>
              <Typography>{address.zip}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>

      {isNil(addresses) && <LoadingModule height='25vh' />}

      {!isNil(addresses) && isEmpty(addresses) && <NothingFound height='25vh' />}
    </>
  );
};

PatientAddressesCard.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientAddressesCard;
