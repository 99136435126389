import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';

import {
  addLineItemNote,
  listLineItemNotes,
  updateNoteStatus,
  updateRequestLineItemState,
} from 'store/thunks/patientThunks';
import { ContextType } from 'common/constants/notes';
import AddNoteDialog from 'common/modules/notes/AddNoteDialog';
import NotesDialog from 'common/modules/notes/NotesDialog';
import lineItemEnum from 'enums/lineItem.enum';
import useInfoDialog from 'common/hooks/useInfoDialog';
import lineItemStatus from 'common/constants/lineItemStatus';
import roles from 'common/constants/roles';

const TransferRequestNoteOptions = ({
  itemId,
  mpi,
  requestId,
  activeNoteItem,
  setActiveNoteItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const dispatch = useDispatch();
  const patientRequests = useSelector(({ patient }) => patient[mpi]?.requests || []);
  const requestLineItems = patientRequests[requestId]?.transferRequest || [];
  const userRoles = useSelector(({ auth }) => auth?.currentTenantRoles);
  const lineItem = requestLineItems[itemId];
  const lineItemNotes = lineItem?.notes;
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const isSystemAdmin = includes(userRoles, roles.admin);
  const showRollBackOption =
    includes(
      [lineItemEnum.PROVIDER_CONTACTED, lineItemEnum.RX_RECEIVED, lineItemEnum.RX_ENTRY],
      lineItem?.status
    ) && isSystemAdmin;

  const showSkipOption =
    includes(
      [lineItemEnum.RX_REQUEST, lineItemEnum.PROVIDER_CONTACTED, lineItemEnum.RX_RECEIVED],
      lineItem?.status
    ) && isSystemAdmin;

  const menuOpen = !!anchorEl;
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleShowNoteDialog = () => {
    setAnchorEl(null);
    setShowAddNoteDialog(true);
  };

  const handleViewNoteDialog = useCallback(() => {
    setShowNotesDialog(true);
    setAnchorEl(null);

    dispatch(
      listLineItemNotes({
        mpi,
        lineItemId: itemId,
        vaultId: requestId,
        vaultType: ContextType.REQUEST_LINE_ITEM,
      })
    );
  }, [dispatch, mpi, itemId, requestId]);

  useEffect(() => {
    if (activeNoteItem === itemId) {
      handleViewNoteDialog();
      setActiveNoteItem(null);
    }
  }, [activeNoteItem, itemId, handleViewNoteDialog, setActiveNoteItem]);

  const closeViewNoteDialog = () => setShowNotesDialog(false);

  const handleCloseNoteDialog = () => setShowAddNoteDialog(false);

  const onAddNote = (commentContext, content, onComplete) => {
    return dispatch(
      addLineItemNote({
        mpi,
        commentContext,
        content,
        vaultId: requestId,
        vaultType: ContextType.REQUEST_LINE_ITEM,
      })
    ).then(() => {
      onComplete(true);
    });
  };

  const onUpdateNoteStatus = (noteId, action) => {
    return dispatch(
      updateNoteStatus({
        lineItemId: itemId,
        mpi,
        noteId,
        vaultId: requestId,
        vaultType: ContextType.REQUEST_LINE_ITEM,
        action,
      })
    );
  };

  const handleUpdateLineItemStatus = ({ skip = false, rollback = false }) => {
    handleMenuClose();

    showInfoDialog({
      title: skip ? 'Skip State' : 'Rollback State',
      message: `Are you sure you want to ${skip ? 'skip' : 'rollback'} ${
        lineItemStatus[lineItem.status]
      } state?`,
      callback: () =>
        dispatch(
          updateRequestLineItemState({
            currentState: lineItem.status,
            lineItemId: itemId,
            mpi,
            requestId,
            rollback,
            skip,
          })
        ),
      cancelable: true,
      isWarning: rollback,
      confirmButtonText: 'Confirm',
    });
  };

  const handleSkipState = () => handleUpdateLineItemStatus({ skip: true });

  const handleRollBackState = () => handleUpdateLineItemStatus({ rollback: true });

  return (
    <>
      <InfoDialog />

      <AddNoteDialog
        commentContexType={ContextType.LINE_ITEM}
        commentContextId={itemId}
        open={showAddNoteDialog}
        onClose={handleCloseNoteDialog}
        onAddNote={onAddNote}
      />

      <NotesDialog
        open={showNotesDialog}
        onClose={closeViewNoteDialog}
        itemNotes={lineItemNotes}
        onUpdateNoteStatus={onUpdateNoteStatus}
      />

      <IconButton
        id={`PatientView-TransferRequestNote-${itemId}options-button`}
        aria-label={`Note options ${itemId}`}
        aria-controls={
          menuOpen ? `PatientView-TransferRequestNote-${itemId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`PatientView-TransferRequestNote-${itemId}options-menu`}
        aria-labelledby={`PatientView-TransferRequestNote-${itemId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowNoteDialog}>Add Note</MenuItem>
        <MenuItem onClick={handleViewNoteDialog}>View Notes</MenuItem>
        {showSkipOption && <MenuItem onClick={handleSkipState}>Skip State</MenuItem>}
        {showRollBackOption && <MenuItem onClick={handleRollBackState}>Rollback State</MenuItem>}
      </Menu>
    </>
  );
};

TransferRequestNoteOptions.propTypes = {
  itemId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  activeNoteItem: PropTypes.string,
  setActiveNoteItem: PropTypes.func,
};

TransferRequestNoteOptions.defaultProps = {
  activeNoteItem: null,
  setActiveNoteItem: undefined,
};

export default TransferRequestNoteOptions;
