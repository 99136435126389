import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useRoles from 'common/hooks/useRoles';
import { deletePatientPharmacy } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

import EditPharmacyDialog from './EditPharmacyDialog';

const PatientPharmacyOptions = ({ patientPharmacyId, mpi }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };

  const handleDeletePharmacy = () => {
    handleMenuClose();
    showInfoDialog({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this pharmacy?',
      callback: () => dispatch(deletePatientPharmacy({ mpi, patientPharmacyId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const handleCloseEditDialog = () => setShowEditDialog(false);

  return (
    <>
      <InfoDialog />
      <EditPharmacyDialog
        mpi={mpi}
        patientPharmacyId={patientPharmacyId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
      />

      <IconButton
        id={`PatientView-PatientPharmacyOptions-${patientPharmacyId}options-button`}
        aria-label={`Pharmacy options ${patientPharmacyId}`}
        aria-controls={
          menuOpen
            ? `PatientView-PatientPharmacyOptions-${patientPharmacyId}options-menu`
            : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientPharmacyOptions-${patientPharmacyId}options-menu`}
        aria-labelledby={`PatientView-PatientPharmacyOptions-${patientPharmacyId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog} disabled={!hasAccess.updatePharmacy}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleDeletePharmacy} disabled={!hasAccess.deletePharmacy}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

PatientPharmacyOptions.propTypes = {
  patientPharmacyId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};
export default PatientPharmacyOptions;
