import { useState } from 'react';
import { first } from 'lodash';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterOrders } from 'store/thunks/workQueueThunks';
import orderEnum from 'enums/order.enum';
import FacilityFilterField from './FacilityFilterField';

const defaultOrderStatus = [orderEnum.ORDER_VERIFICATION, orderEnum.ORDER_RE_VERIFICATION];

const OrderVerificationFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-OrderVerificationFilterButton-Popover' : undefined;

  const filter = useSelector(({ workQueue: { orderVerification } }) => ({
    ...orderVerification?.filter,
    needsByDate:
      orderVerification.filter?.needsByDate && new Date(orderVerification.filter.needsByDate),
    orderStatus:
      orderVerification?.filter?.orderStatus?.length > 1
        ? 'ALL'
        : first(orderVerification?.filter?.orderStatus),
  }));

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterOrders({
        filter: {
          ...formData,
          needsByDate: formData.needsByDate && formData.needsByDate.toISOString(),
          orderStatus: formData.orderStatus === 'ALL' ? defaultOrderStatus : [formData.orderStatus],
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>
          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />
                  <TextField
                    label='Order Id'
                    name='orderId'
                    id='WorkQueue-OrderVerificationFilterButton-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />
                  <DateField
                    label='Needs By Date'
                    name='needsByDate'
                    id='WorkQueue-OrderVerificationFilterButton-needsByDate-dateField'
                  />
                  <TextField
                    label='Patient Name'
                    name='patientName'
                    id='WorkQueue-OrderVerificationFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />
                  <SelectField
                    label='Status'
                    name='orderStatus'
                    id='WorkQueue-OrderVerificationFilterButton-status-dropdown'
                  >
                    <MenuItem value='ORDER_VERIFICATION'>Verification</MenuItem>
                    <MenuItem value='ORDER_RE_VERIFICATION'>Re-verification</MenuItem>
                    <MenuItem value='ALL'>All</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default OrderVerificationFilterButton;
