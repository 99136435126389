/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listSigAssociationsGql = gql`
  query listSigAssociations(
    $limit: Int = 25
    $offset: Int = 0
    $filter: SigAssociationFilter
    $sortBy: SigAssociationSortBy
  ) {
    listSigAssociations(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      result {
        sigId
        sig
        direction
        directionId
        isActive
        createdAt
        updatedAt
        sigStatus
      }
    }
  }
`;

export const upsertSigAssociationsGql = gql`
  mutation upsertSigAssociations($input: upsertSigAssociationsInput!) {
    upsertSigAssociations(input: $input)
  }
`;
