import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid, Paper, Tab, Tabs, Typography } from '@mui/material';
import { lighten, styled } from '@mui/material/styles';
import { map } from 'lodash';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';

import useTabContext, { TabProvider } from 'common/hooks/useTabContext';
import { OrderStatusTabs } from 'enums/requestStatus.enum';
import { orderStatusActions } from 'store/slices/orderStatusSlice';

import EScriptOrders from './components/EScriptOrders/EScriptOrders';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .MuiToggleButton-root`]: {
    backgroundColor: lighten(theme.palette.secondary.light, 0.9),
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));

const TabPanel = ({ children, value }) => {
  const { tab } = useTabContext();
  const hidden = value !== tab;
  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`request-status-tabpanel-${value}`}
      aria-labelledby={`request-order-tab-${value}`}
    >
      <Box sx={{ px: 1 }}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const a11yProps = (index) => ({
  id: `request-status-tab-${index}`,
  'aria-controls': `request-status-tabpanel-${index}`,
});

const tabsDetails = [
  {
    tabName: OrderStatusTabs.E_SCRIPT,
    Component: EScriptOrders,
    tabValue: 'eScriptOrders',
  },
];

const TabList = () => {
  const { tab, setTab } = useTabContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(orderStatusActions.loadSelectedOrderStatusQueue(tab));
  }, [tab, dispatch]);

  return (
    <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={setTab}
        aria-label='Request Status Tabs'
        scrollButtons='auto'
        sx={{ maxWidth: '85vw' }}
        variant='scrollable'
      >
        {map(tabsDetails, ({ tabName, tabValue }, key) => (
          <Tab label={tabName} {...a11yProps(key)} key={key} value={tabValue} />
        ))}
      </Tabs>
    </Box>
  );
};

const OrderRequestsTab = ({ initialTab }) => {
  return (
    <TabProvider initialValue={initialTab}>
      <Grid container component={Paper} sx={{ px: 2, py: 1 }} direction='column'>
        <TabList />
        {map(tabsDetails, ({ Component, tabValue }) => (
          <TabPanel value={tabValue} key={tabValue}>
            <Component />
          </TabPanel>
        ))}
      </Grid>
    </TabProvider>
  );
};

OrderRequestsTab.propTypes = {
  initialTab: PropTypes.string.isRequired,
};

const RequestStatus = () => {
  const [toggleValue, setToggleValue] = useState('orderRequest');

  const handleAlignment = (_, value) => {
    return value !== null && setToggleValue(value);
  };

  return (
    <Grid container sx={{ px: 3, pb: 2, my: 4 }} direction='column'>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: 'wrap',
        }}
      >
        <StyledToggleButtonGroup
          size='small'
          color='primary'
          value={toggleValue}
          exclusive
          onChange={handleAlignment}
        >
          <ToggleButton value='orderRequest' sx={{ px: 8 }}>
            <Typography>Prescription Requests</Typography>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
      <OrderRequestsTab initialTab='eScriptOrders' />
    </Grid>
  );
};

export default RequestStatus;
