import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

const FormNavigationBlocker = () => {
  const isDirty = useForm().getState().dirty;
  const blocker = useBlocker(isDirty);

  const continueNavigation = () => blocker.proceed?.();
  const blockNavigation = () => blocker.reset?.();

  useEffect(() => {
    if (isDirty) {
      // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      // This is intentionally blank function
      window.onbeforeunload = () => '';
    }

    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [blocker, isDirty]);

  return (
    <Dialog
      open={blocker.state === 'blocked'}
      onClose={blockNavigation}
      maxWidth='xs'
      fullWidth
      aria-labelledby='FormNavigationBlocker-title'
    >
      <DialogTitle id='FormNavigationBlocker-title'>Are you sure?</DialogTitle>

      <DialogContent>
        <DialogContentText>Any unsaved changes will be lost.</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={continueNavigation} size='small' variant='contained' color='error'>
          Confirm
        </Button>

        <Button onClick={blockNavigation} size='small' variant='outlined' color='secondary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormNavigationBlocker;
