import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import IncompleteProfilesFilterStatusBar from './IncompleteProfilesFilterStatusBar';
import IncompleteProfilesFilterButton from './IncompleteProfilesFilterButton';
import IncompleteProfilesTable from './IncompleteProfilesTable';

const IncompleteProfiles = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.INCOMPLETE_PROFILES) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <IncompleteProfilesFilterStatusBar />
      </Grid>
      <Grid item>
        <IncompleteProfilesFilterButton />
      </Grid>
      <IncompleteProfilesTable />
    </Grid>
  );
};

export default IncompleteProfiles;
