import { Grid } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import WorkQueues from 'enums/workQueue.enum';
import usePrintLabelContext, {
  MarkAsFilledButton,
  PrintLabelButton,
  PrintLabelProvider,
} from 'common/hooks/usePrintLabelContext';
import { listBulkLabelsByPage } from 'store/thunks/workQueueThunks';

import BulkLabelsTable from './BulkLabelsTable';
import BulkLabelsFilterButton from './BulkLabelsFilterButton';
import BulkLabelsFilterStatusBar from './BulkLabelsFilterStatusBar';

const BulkLabels = () => {
  const dispatch = useDispatch();
  const { clearLabelItems } = usePrintLabelContext();
  const { sortBy, filter, currentPage } = useSelector(
    ({ workQueue }) => workQueue.bulkLabels,
    shallowEqual
  );

  const reloadPage = () => {
    dispatch(listBulkLabelsByPage({ page: currentPage }));
  };

  useEffect(() => {
    clearLabelItems();
  }, [clearLabelItems, currentPage, filter, sortBy]);

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <BulkLabelsFilterStatusBar />
      </Grid>
      <Grid item>
        <Grid container gap={2}>
          <PrintLabelButton onSuccess={reloadPage} onWorkQueue />
          <MarkAsFilledButton onSuccess={reloadPage} />
          <BulkLabelsFilterButton />
        </Grid>
      </Grid>
      <BulkLabelsTable />
    </Grid>
  );
};

const BulkLabelsWithProvider = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.BULK_LABELS) return null;

  return (
    <PrintLabelProvider initialValue={[]}>
      <BulkLabels />
    </PrintLabelProvider>
  );
};

export default BulkLabelsWithProvider;
