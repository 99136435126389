import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { deletePatientPartner } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

import EditPartnerDialog from './EditPartnerDialog';

const PatientPartnerOptions = ({ partnerId, mpi }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const isOriginalPartner = useSelector(
    ({ patient }) => patient[mpi].originalPartner.partnerId === partnerId
  );

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };

  const handleDeletePartner = () => {
    handleMenuClose();
    showInfoDialog({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this partner?',
      callback: () => dispatch(deletePatientPartner({ mpi, partnerUUID: partnerId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const handleCloseEditDialog = () => setShowEditDialog(false);

  return (
    <>
      <InfoDialog />
      <EditPartnerDialog
        mpi={mpi}
        partnerId={partnerId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
      />

      <IconButton
        id={`PatientView-PatientPartnerOptions-${partnerId}options-button`}
        aria-label={`Partner options ${partnerId}`}
        aria-controls={
          menuOpen ? `PatientView-PatientPartnerOptions-${partnerId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientPartnerOptions-${partnerId}options-menu`}
        aria-labelledby={`PatientView-PatientPartnerOptions-${partnerId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog}>Edit</MenuItem>
        <MenuItem onClick={handleDeletePartner} disabled={isOriginalPartner}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

PatientPartnerOptions.propTypes = {
  partnerId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};
export default PatientPartnerOptions;
