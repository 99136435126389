import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { map } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

import insurancePayorTypes from 'common/constants/insurancePayorTypes';
import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import { validateValueWithRegex } from 'common/forms/formValidations';
import { addPatientInsurance } from 'store/thunks/patientThunks';
import CheckboxField from 'common/forms/CheckboxField';

const NewInsuranceButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddInsurance = (formData) => {
    return dispatch(addPatientInsurance({ insurance: { mpi, ...formData } })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName} onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewInsuranceButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewInsuranceButton-header'>Add Insurance</DialogTitle>

        <Form
          initialValues={{ verified: false, isActive: true }}
          onSubmit={handleAddInsurance}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-NewInsuranceButton-planName-input'
                  label='Plan Name'
                  name='planName'
                  required
                  autoFocus
                />

                <SelectField
                  label='Plan Type'
                  name='planType'
                  id='PatientView-NewInsuranceButton-payorType-dropdown'
                  required
                >
                  {map(insurancePayorTypes, (value, key) => (
                    <MenuItem value={key} key={key}>
                      {value}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField id='PatientView-NewInsuranceButton-bin-input' label='BIN' name='bin' />

                <TextField id='PatientView-NewInsuranceButton-pcn-input' label='PCN' name='pcn' />

                <TextField
                  id='PatientView-NewInsuranceButton-groupNumber-input'
                  label='Group Number'
                  name='groupNumber'
                />

                <TextField
                  id='PatientView-NewInsuranceButton-insurancePlanId-input'
                  label='Plan Id'
                  name='insurancePlanId'
                />

                <TextField
                  id='PatientView-NewInsuranceButton-ssn-input'
                  label='SSN (Last 4)'
                  name='ssn'
                  validations={[validateValueWithRegex('Must be 4 numbers', /^\d{4}$/)]}
                />

                <TextField
                  id='PatientView-NewInsuranceButton-medicareNumber-input'
                  label='Medicare Number'
                  name='medicareNumber'
                />

                <CheckboxField
                  id='PatientView-NewInsuranceButton-verified-checkbox'
                  label='Verified'
                  name='verified'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewInsuranceButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewInsuranceButton;
