import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { upsertSigAssociations } from 'store/thunks/sigThunks';

import ManageSigModal from './ManageSigModal';

const SigOptions = ({ sigData }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const handleMenuClose = () => setAnchorEl(false);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleOpenModal = () => {
    setShowModal(true);
    handleMenuClose();
  };

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleEditSigAssociations = (data) => {
    const sigPayload = {
      sigId: sigData.sigId,
      sig: data.sig,
      sigStatus: data.sigStatus,
      associatedDirections: data.associations,
    };

    setFormLoading(true);
    dispatch(upsertSigAssociations({ input: sigPayload }))
      .then((response) => {
        if (response) {
          enqueueSnackbar('Sig Updated Successfully', { variant: 'success' });
          setShowModal(false);
          setFormLoading(false);
        }
      })
      .finally(() => {
        setFormLoading(false);
        setShowModal(false);
      });
  };

  return (
    <>
      <IconButton
        id={`Sig-${sigData.sigId}options-button`}
        aria-label={`Sig Options ${sigData.sigId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`Sig-${sigData.sigId}options-menu`}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
      </Menu>

      {showModal && (
        <ManageSigModal
          sigData={sigData}
          showModal={showModal}
          loading={formLoading}
          handleCloseModal={handleCloseModal}
          handleSigAssociations={handleEditSigAssociations}
        />
      )}
    </>
  );
};

export default SigOptions;

SigOptions.propTypes = {
  sigData: PropTypes.shape({
    sig: PropTypes.string.isRequired,
    sigId: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }).isRequired,
};
