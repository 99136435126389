export default {
  MEDICARE: 'Medicare',
  MEDICAID: 'Medicaid',
  DUAL_ELIGIBLE: 'Dual Eligible',
  COMMERCIAL: 'Commercial',
  CASH_PAY: 'Cash Pay',
  COPAY_CARD: 'Copay Card',
  COUPON: 'Coupon',
  DISCOUNT_CARD: 'Discount Card',
};
