import { Dialog, DialogContent, Grid, IconButton, Slide, Typography } from '@mui/material';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useContext, useEffect, useState } from 'react';

import { addPrescriptionNotes, listPrescriptionNotes } from 'store/thunks/patientThunks';
import { getPrescriptionOverrideAuditsApi } from 'api/prescription';
import { ContextType } from 'common/constants/notes';
import SwitchField from 'common/forms/SwitchField';
import LoadingModule from 'common/components/LoadingModule';

import { PrescriptionReviewPayloadContext } from 'common/hooks/usePrescriptionReviewPayloadContext';
import PatientPrescriptionView from './PatientPrescriptionView';
import PatientPrescriptionNotes from './PatientPrescriptionNotes';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const PatientPrescriptionDialog = ({
  open,
  mpi,
  contextId,
  closeDialog,
  isPrescriptionReview,
  reviewType,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const prescriptionList = useSelector(({ patient }) => patient[mpi]?.prescriptions);
  const prescriptionFromWorkQueue = useSelector(({ workQueue }) => workQueue?.prescriptionReview);
  const currentPage = prescriptionFromWorkQueue?.currentPage;
  const [viewPharmacyTranslation, setViewPharmacyTranslation] = useState(true);
  const [prescriptionLoading, setPrescriptionLoading] = useState(true);
  const [overrideDetails, setOverrideDetails] = useState({});
  const prescriptionObject = find(prescriptionFromWorkQueue?.pages[currentPage], {
    pomPrescriptionId: contextId,
  });
  const patientData = prescriptionObject?.patient;
  const prescriptionData = prescriptionObject?.prescriptionData;
  const prescription = !isEmpty(prescriptionList)
    ? find(prescriptionList, { prescriptionUUID: contextId })
    : prescriptionObject;
  const { payload } = useContext(PrescriptionReviewPayloadContext);

  const prescriptionNotes = prescription?.notes;

  useEffect(() => {
    dispatch(
      listPrescriptionNotes({
        mpi,
        contextId,
        contextType: ContextType.PRESCRIPTION,
        isPrescriptionReview,
      })
    );
  }, [mpi, dispatch, contextId, isPrescriptionReview]);

  const onAddNote = (message, onComplete) => {
    const content = {
      message,
      contentType: 'TEXT',
    };
    const commentContext = {
      id: contextId,
      type: ContextType.PRESCRIPTION,
    };

    return dispatch(
      addPrescriptionNotes({
        mpi,
        commentContext,
        content,
        contextId,
        isPrescriptionReview,
      })
    ).then(() => {
      onComplete(true);
    });
  };

  const handlePrint = () => window.print();
  const handlePharmacyTranslationView = () => setViewPharmacyTranslation((prev) => !prev);

  const getOverrideDetails = () =>
    getPrescriptionOverrideAuditsApi(contextId)
      .then((overrideData) => setOverrideDetails(overrideData))
      .finally(() => setPrescriptionLoading(false));

  useEffect(() => {
    getOverrideDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextId]);

  return (
    <>
      <style>
        {`
        @media print {
          body {
            visibility: hidden;
          }

          .PrescriptionDialog {
            visibility: visible !important;
          }

          .PrescriptionBtnOptions {
           display: none;
          }

          .FooterMessage {
            display: block !important;
            position: fixed;
            bottom: 0;
            width: 100%;
            text-align: center;
            padding: 0.5cm;
            background-color: #f0f0f0;
          }
        }
        `}
      </style>

      <Dialog
        fullScreen
        open={open}
        TransitionComponent={Transition}
        className='PrescriptionDialog'
      >
        <Grid
          container
          sx={{ pt: 4, px: 4 }}
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          className='PrescriptionBtnOptions'
        >
          <Typography variant='h6' sx={{ color: theme.palette.info.main }}>
            View Pharmacy Translation
          </Typography>

          <SwitchField
            id='TranslatedPrescriptionSwitch'
            size='small'
            checked={viewPharmacyTranslation}
            onClick={handlePharmacyTranslationView}
            label='Translated Prescription Switch'
          />

          <IconButton
            aria-label='Print Prescription Button'
            id={`Print Prescription Notes ${contextId}`}
            onClick={handlePrint}
          >
            <PrintIcon fontSize='large' sx={{ color: theme.palette.common.black }} />
          </IconButton>
          <IconButton
            onClick={() => closeDialog(payload)}
            id={`Close Prescription Notes ${contextId}`}
            aria-label='Close Prescription Notes Button'
          >
            <CloseIcon fontSize='large' />
          </IconButton>
        </Grid>
        {prescriptionLoading ? (
          <LoadingModule />
        ) : (
          <DialogContent>
            <Grid container sx={{ px: 8 }} spacing={3}>
              <Grid item xs={12} md={8}>
                <PatientPrescriptionView
                  mpi={mpi}
                  reviewType={reviewType}
                  patientData={patientData}
                  prescriptionId={contextId}
                  overrideDetails={overrideDetails}
                  showTranslation={viewPharmacyTranslation}
                  prescriptionData={prescriptionData}
                  isPrescriptionReview={isPrescriptionReview}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <PatientPrescriptionNotes onAddNote={onAddNote} notes={prescriptionNotes} />
              </Grid>
            </Grid>

            <Grid className='FooterMessage' sx={{ display: 'none' }}>
              <Typography>This is printed copy and not the original</Typography>
            </Grid>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default PatientPrescriptionDialog;

PatientPrescriptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  mpi: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  contextId: PropTypes.string.isRequired,
  isPrescriptionReview: PropTypes.bool,
  reviewType: PropTypes.string,
};

PatientPrescriptionDialog.defaultProps = {
  isPrescriptionReview: false,
  reviewType: '',
};
