import { Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';
import { differenceInYears, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { capitalize, find } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

import insurancePayorTypes from 'common/constants/insurancePayorTypes';
import gender from 'common/constants/gender';
import { normalizePhoneNumber } from 'common/utils';

const PatientLabelValue = ({
  value,
  label,
  isLoading,
  showVerifiedIcon,
  verificationStatus,
  phonePreference,
  emailPreference,
}) => {
  if (!isLoading) {
    return <Skeleton width='80%' height={24} animation='wave' />;
  }

  if (!value) {
    return null;
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item container justifyContent='flex-start' alignItems='center'>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }} component='span'>
          {value}
        </Typography>
        {showVerifiedIcon && verificationStatus && (
          <Tooltip title='Verified' placement='right' sx={{ ml: 0.5 }}>
            <CheckCircleIcon color='success' />
          </Tooltip>
        )}
        {showVerifiedIcon && !verificationStatus && (
          <Tooltip title='Not Verified' placement='right'>
            <ErrorOutlineIcon color='error' />
          </Tooltip>
        )}

        <Grid component='span' sx={{ mr: 0.5 }}>
          {emailPreference && <EmailIcon sx={{ mr: 0.5 }} />}
          {phonePreference && (
            <Tooltip
              title={!verificationStatus && 'Mobile phone not verified, use email instead'}
              placement='right'
            >
              <PhoneIphoneIcon color={!verificationStatus ? 'error' : 'inherit'} />
            </Tooltip>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

PatientLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showVerifiedIcon: PropTypes.bool,
  verificationStatus: PropTypes.bool,
  phonePreference: PropTypes.bool,
  emailPreference: PropTypes.bool,
};
PatientLabelValue.defaultProps = {
  value: '',
  showVerifiedIcon: false,
  verificationStatus: false,
  phonePreference: false,
  emailPreference: false,
};

const PatientInformation = ({ mpi }) => {
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const isLoading = !!patientInfo?.firstName; // patient first name must exist, if it doesn't, that means data is still loading
  const lastUsedInsurance = useSelector(({ patient }) => {
    return (
      find(patient[patientInfo?.mpi]?.insurances, {
        patientInsuranceId: patientInfo?.lastUsedInsurance?.patientInsuranceId,
      }) || patientInfo?.lastUsedInsurance
    );
  });

  const isMobilePhoneVerified = !!patientInfo?.isMobilePhoneVerified;
  const isPhonePreferred = !!patientInfo?.notificationPreferences?.phone;
  const isEmailPreferred = !!patientInfo?.notificationPreferences?.email;

  const address = find(patientInfo?.addresses, { isDefault: true })?.address;

  return (
    <Grid container>
      <Grid container direction='column' item xs={4} gap={1}>
        <PatientLabelValue
          label='First Name'
          value={patientInfo?.firstName}
          isLoading={isLoading}
        />
        <PatientLabelValue label='Last Name' value={patientInfo?.lastName} isLoading={isLoading} />
        <PatientLabelValue
          label='Middle Name'
          value={patientInfo?.middleName}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Date of birth'
          value={
            patientInfo?.dob &&
            `${format(
              utcToZonedTime(new Date(patientInfo?.dob), 'utc'),
              'MM/dd/yyyy'
            )} (Age ${differenceInYears(new Date(), new Date(patientInfo?.dob))})`
          }
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='SSN (Last 4)'
          value={patientInfo?.last4ssn}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Gender'
          value={gender[patientInfo?.gender]}
          isLoading={isLoading}
        />
        <PatientLabelValue label='Email' value={patientInfo?.email} isLoading={isLoading} />
        <PatientLabelValue
          label='Mobile Phone'
          value={normalizePhoneNumber(patientInfo?.mobilePhone)}
          isLoading={isLoading}
          showVerifiedIcon
          verificationStatus={isMobilePhoneVerified}
        />
        <PatientLabelValue
          label='Home Phone'
          value={normalizePhoneNumber(patientInfo?.homePhone)}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Work Phone'
          value={normalizePhoneNumber(patientInfo?.workPhone)}
          isLoading={isLoading}
        />
      </Grid>

      <Grid container direction='column' item xs={5} gap={1}>
        <PatientLabelValue
          label='Primary Spoken Language'
          value={capitalize(find(patientInfo?.languages, 'isPrimary')?.language)}
          isLoading={isLoading}
        />

        <PatientLabelValue
          label='Address Line 1'
          value={address?.addressLine1}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Address Line 2'
          value={address?.addressLine2}
          isLoading={isLoading}
        />
        <PatientLabelValue label='City' value={address?.city} isLoading={isLoading} />
        <PatientLabelValue label='State' value={address?.state} isLoading={isLoading} />
        <PatientLabelValue label='Zip' value={address?.zip} isLoading={isLoading} />
      </Grid>

      <Grid container direction='column' item xs={3} gap={1}>
        <PatientLabelValue
          label='QS1 Id'
          value={patientInfo?.systemIds?.qs1PatientId}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Zendesk Id'
          value={patientInfo?.systemIds?.crmId}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Zoho Id'
          value={patientInfo?.systemIds?.invoiceServiceCustomerId}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Pay Simple Id'
          value={patientInfo?.systemIds?.paymentServiceCustomerId}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Last Used Insurance'
          value={lastUsedInsurance?.planName || 'N/A'}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Insurance Payor Type'
          value={insurancePayorTypes[lastUsedInsurance?.planType] || 'N/A'}
          isLoading={isLoading}
        />
        <PatientLabelValue
          label='Outstanding Balance'
          value={patientInfo?.outstandingBalance}
          isLoading={isLoading}
        />
        {(isEmailPreferred || isPhonePreferred) && (
          <PatientLabelValue
            label='Notification Preferences'
            value={' '}
            isLoading={isLoading}
            emailPreference={isEmailPreferred}
            phonePreference={isPhonePreferred}
            verificationStatus={isMobilePhoneVerified}
          />
        )}
      </Grid>
    </Grid>
  );
};

PatientInformation.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientInformation;
