export default {
  BROTHER: 'Brother',
  CARE_GIVER: 'Care Giver',
  CHILD: 'Child',
  LIFE_PARTNER: 'Life Partner',
  EXTENDED_FAMILY: 'Extended Family',
  FOSTER_CHILD: 'Foster Child',
  FATHER: 'Father',
  GRAND_CHILD: 'Grandchild',
  GUARDIAN: 'Guardian',
  GRAND_PARENT: 'Grandparent',
  MOTHER: 'Mother',
  NATURAL_CHILD: 'Natural Child',
  OTHER: 'Other',
  PARENT: 'Parent',
  STEP_CHILD: 'Step Child',
  SELF: 'Self',
  SIBLING: 'Sibling',
  SISTER: 'Sister',
  SPOUSE: 'Spouse',
  UNKNOWN: 'Unknown',
  WARD_OF_COURT: 'Ward of Court',
};
