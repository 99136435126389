import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Link as MuiLink,
  Typography,
} from '@mui/material';
import { fetchPackageActivities } from 'api/shipping';
import { isEmpty, map, replace } from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { handleError } from 'store/thunks/errorHandlerThunks';

const TrackingNumberDetails = ({ trackingNumber }) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const [trackingDetails, setTrackingDetails] = useState(undefined);

  const toggleModal = () => setShowDetails((prev) => !prev);

  useEffect(() => {
    if (showDetails) {
      fetchPackageActivities({ trackingNumber })
        .then((shipmentData) => setTrackingDetails(shipmentData))
        .catch((error) => dispatch(handleError({ error })));
    }
  }, [showDetails, dispatch, trackingNumber]);

  return (
    <>
      <MuiLink component={Button} sx={{ p: 0 }} onClick={toggleModal}>
        {trackingNumber}
      </MuiLink>

      <Dialog
        open={showDetails}
        onClose={toggleModal}
        aria-describedby='PatientView-TrackingNumberDetails-header'
        fullWidth
      >
        <DialogTitle id='PatientView-TrackingNumberDetails-header'>{trackingNumber}</DialogTitle>

        <DialogContent>
          {!trackingDetails && 'Loading...'}
          {trackingDetails &&
            isEmpty(trackingDetails) &&
            'No Tracking Information Found. History may not be available if delivery date exceed 90 days'}

          {trackingDetails && !isEmpty(trackingDetails) && (
            <>
              <Typography variant='h6' component='p'>
                Service: {trackingDetails.service}
              </Typography>

              <List dense disablePadding sx={{ mt: 2 }}>
                {map(trackingDetails.activities, ({ details, timestamp, location }) => (
                  <ListItem key={timestamp} dense disablePadding>
                    <ListItemText
                      primary={details}
                      secondary={`${format(
                        new Date(replace(timestamp, /z/i, '')),
                        'MM/dd/yyyy p'
                      )} ${location}`}
                      primaryTypographyProps={{ variant: 'body1', component: 'span' }}
                      secondaryTypographyProps={{
                        component: 'span',
                        sx: { fontWeight: 400 },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={toggleModal}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TrackingNumberDetails.propTypes = {
  trackingNumber: PropTypes.string.isRequired,
};

export default TrackingNumberDetails;
