import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  eventTypes: [],
  events: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      createdAfter: undefined,
      eventType: undefined,
      pomUserName: undefined,
    },
    sortBy: {
      sortKey: 'createdTimestamp',
      sortOrder: 'ASC',
    },
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const eventSlice = createSlice({
  name: 'criticalEvents',
  initialState,
  reducers: {
    loadEventTypes(state, action) {
      state.eventTypes = action.payload;
    },
    loadEvents(state, action) {
      const { page, events, count } = action.payload;

      state.events.pages[page] = events;
      state.events.count = count;
    },
    updateEventsCurrentPage(state, action) {
      const { page } = action.payload;
      state.events.currentPage = page;
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.events.pages = {}; // Cached data no longer valid, this is to reset it.
      state.events.count = 0;
      state.events.sortBy = sortBy;
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      state.events.pages = {}; // Cached data no longer valid, this is to reset it.
      state.events.count = 0;
      state.events.filter = filter;
    },
  },
});

export const eventActions = eventSlice.actions;
export default eventSlice.reducer;
