import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateLineItemStatus } from 'store/thunks/patientThunks';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import useRoles from 'common/hooks/useRoles';
import { enqueueSnackbar } from 'notistack';
import { Tooltip } from '@mui/material';

import lineItemStatus from 'enums/lineItem.enum';

const PatientFillProductButton = ({ item, orderId, mpi, isProcessedExternal }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [toolTipMsg, setToolTipMsg] = useState('');
  const hasAccess = useRoles();

  const disabled =
    item.lineItemStatus !== lineItemStatus.PRODUCT_FULFILLMENT ||
    item.lineItemStatus === lineItemStatus.RE_PROCESS ||
    !hasAccess.manageProductFulfillmentStatus ||
    item.taggedForReview;

  useEffect(() => {
    if (isProcessedExternal) {
      setToolTipMsg('Item processing is external');
    } else if (item.lineItemStatus === lineItemStatus.RE_PROCESS) {
      setToolTipMsg('Item is in the reprocessing state');
    } else if (item.taggedForReview) {
      setToolTipMsg('Item tagged for Review');
    }
  }, [item, isProcessedExternal]);

  const handleClick = () => {
    setLoading(true);
    dispatch(
      updateLineItemStatus({
        lineItemId: item.lineItemId,
        mpi,
        orderId,
        state: 'PRODUCT_FULFILLED',
      })
    ).then(() => {
      enqueueSnackbar('Product successfully Fulfilled', { variant: 'success' });
      setLoading(false);
    });
  };

  return (
    <Tooltip title={toolTipMsg} placement='top' arrow>
      <span>
        <LoadingButton
          variant='contained'
          onClick={handleClick}
          sx={{ minWidth: '100px' }}
          disabled={disabled || isProcessedExternal}
          loading={isLoading}
        >
          {item.lineItemStatus === 'PRODUCT_FULFILLED' ? 'Fulfilled' : 'Fill Product'}
        </LoadingButton>
      </span>
    </Tooltip>
  );
};

PatientFillProductButton.propTypes = {
  item: PropTypes.shape({
    itemName: PropTypes.string,
    rxNumber: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    quantity: PropTypes.string,
    lineItemStatus: PropTypes.string,
    lastUpdated: PropTypes.string,
    lineItemId: PropTypes.string,
    taggedForReview: PropTypes.bool,
  }).isRequired,
  orderId: PropTypes.string.isRequired,
  isProcessedExternal: PropTypes.bool.isRequired,
  mpi: PropTypes.string.isRequired,
};

export default PatientFillProductButton;
