import { map, reduce, sortBy } from 'lodash';

/* eslint-disable import/prefer-default-export */
export const transformListAuditEvents = ({ listAuditEvents }) => {
  return {
    count: listAuditEvents.count,
    results: map(
      listAuditEvents.results,
      ({
        pomUserEmail,
        pomUserFirstName,
        pomUserLastName,
        auditEventCode,
        auditEventType,
        auditEventId,
        auditEventData,
        ...event
      }) => ({
        ...event,
        userEmail: pomUserEmail,
        userName: `${pomUserFirstName} ${pomUserLastName}`,
        firstName: pomUserFirstName,
        lastName: pomUserLastName,
        eventCode: auditEventCode,
        eventType: auditEventType,
        eventId: auditEventId,
        eventData: auditEventData,
      })
    ),
  };
};

export const transformEvents = (response) => {
  const { listAllEventTypes, listAuditEvents } = reduce(
    map(response, 'data'),
    (result, value) => {
      return { ...result, ...value };
    },
    {}
  );

  return {
    eventTypes: sortBy(map(listAllEventTypes, 'auditEventType')),
    events: transformListAuditEvents({ listAuditEvents }),
  };
};
