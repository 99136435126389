import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { addPatientPartners } from 'store/thunks/patientThunks';

const NewPartnerButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddPartner = (formData) => {
    return dispatch(addPatientPartners({ mpi, partners: [formData] })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName} onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewPartnerButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewPartnerButton-header'>Add Partner</DialogTitle>

        <Form
          initialValues={{ isOriginalPartner: false }}
          onSubmit={handleAddPartner}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  label='Partner'
                  name='partnerUUID'
                  id='PatientView-NewPartnerButton-Partner-dropdown'
                  required
                >
                  {map(partners, ({ partnerName, partnerUuid }) => (
                    <MenuItem value={partnerUuid} key={partnerUuid}>
                      {partnerName}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewPartnerButton-partnerIdentityValue-input'
                  label='Partner Identity Value'
                  name='partnerExternalId'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewPartnerButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};
export default NewPartnerButton;
