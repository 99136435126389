import { BookmarkRemove } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { useRef } from 'react';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

import RichTextViewer from 'common/components/RichTextViewer';
import useRoles from 'common/hooks/useRoles';
import { NoteState } from 'common/constants/notes';

const DraggableNote = ({ noteId, noteContent, onUpdateNoteStatus }) => {
  const nodeRef = useRef(null);
  const hasAccess = useRoles();

  const handleUnPinNote = () => onUpdateNoteStatus(noteId, NoteState.UNPIN);

  return (
    <Draggable key={noteId} nodeRef={nodeRef}>
      <Grid item ref={nodeRef} sx={{ zIndex: 1 }}>
        <Box
          sx={{
            width: 200,
            height: 200,
            bgcolor: '#fffd75',
            boxShadow: 5,
            borderRadius: 1,
            overflow: 'auto',
            ':hover': {
              cursor: 'move',
            },
          }}
        >
          <Grid container>
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <Tooltip title='Un Pin' arrow placement='top'>
                  <IconButton
                    onClick={handleUnPinNote}
                    disabled={!hasAccess.noteAction}
                    aria-label={`Unpin note ${noteId}`}
                  >
                    <BookmarkRemove />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid sx={{ px: 2 }} item>
              <RichTextViewer value={noteContent} id={`Draggable-LineItemNote-${noteId}`} />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Draggable>
  );
};

DraggableNote.propTypes = {
  noteId: PropTypes.string.isRequired,
  noteContent: PropTypes.string.isRequired,
  onUpdateNoteStatus: PropTypes.func.isRequired,
};

export default DraggableNote;
