import { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { map } from 'lodash';

import SelectField from 'common/forms/SelectField';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { filterOnboardedPatients } from 'store/thunks/workQueueThunks';
import gender from 'common/constants/gender';
import { validateMinLength } from 'common/forms/formValidations';

const PatientQualificationFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ workQueue: { patientQualification } }) => ({
    ...patientQualification.filter,
    createdAfter:
      patientQualification.filter?.createdAfter &&
      new Date(patientQualification.filter.createdAfter),
    onboardedAfter:
      patientQualification.filter?.onboardedAfter &&
      new Date(patientQualification.filter.onboardedAfter),
    dob: patientQualification.filter?.dob && new Date(patientQualification.filter.dob),
  }));

  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-PatientQualificationFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterOnboardedPatients({
        filter: {
          ...formData,
          createdAfter: formData.createdAfter && formData.createdAfter.toISOString(),
          onboardedAfter: formData.onboardedAfter && formData.onboardedAfter.toISOString(),
          dob: formData.dob && formData.dob.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name='name'
                    id='WorkQueue-PatientQualificationFilterButton-name-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Gender'
                    name='gender'
                    id='WorkQueue-PatientQualificationFilterButton-gender-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(gender, (name, value) => (
                      <MenuItem value={value} key={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <DateField
                    label='Date of Birth'
                    name='dob'
                    id='WorkQueue-PatientQualificationFilterButton-dateOfBirth-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Original Partner'
                    name='originalPartner'
                    id='WorkQueue-PatientQualificationFilterButton-originalPartner-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(partners, ({ partnerName, partnerUuid }) => (
                      <MenuItem value={partnerUuid} key={partnerUuid}>
                        {partnerName}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <DateField
                    label='Onboarded After'
                    name='onboardedAfter'
                    id='WorkQueue-PatientQualificationFilterButton-onboardedAfter-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Created After'
                    name='createdAfter'
                    id='WorkQueue-PatientQualificationFilterButton-createdAfter-dateField'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default PatientQualificationFilterButton;
