import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { errorHandlerActions } from 'store/slices/errorHandlerSlice';

const ErrorDialog = () => {
  const dispatch = useDispatch();
  const error = useSelector(({ errorHandler }) => errorHandler.error);

  const closeDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      dispatch(errorHandlerActions.resetErrorHandlerState());
    }
  };

  return (
    <Dialog
      open={!!error}
      onClose={closeDialog}
      aria-labelledby='ErrorDialog-title'
      maxWidth='xs'
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle id='ErrorDialog-title'>{error?.errorHeader}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {error?.message || 'Something went wrong. Please try again.'}
        </DialogContentText>

        <details style={{ whiteSpace: 'pre-wrap', display: 'none' }}>
          {error?.message}
          <br />
          {error?.stack}
        </details>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} size='small' variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
