export const PatientStatus = {
  ONBOARDED: 'ONBOARDED',
  QUALIFIED: 'QUALIFIED',
  OPEN: 'OPEN',
  PROFILE_INCOMPLETE: 'PROFILE_INCOMPLETE',
};

export const PatientTabEvents = {
  PATIENT_ACTION_ITEMS_TAB: 'PatientActionItemsTab',
  PATIENT_MEDICAL_HISTORY_TAB: 'PatientMedicalHistoryTab',
  PATIENT_PROVIDERS_TAB: 'PatientProvidersTab',
  PATIENT_INSURANCE_TAB: 'PatientInsuranceTab',
  PATIENT_PRESCRIPTIONS_TAB: 'PatientPrescriptionsTab',
  PATIENT_SHIPPING_TAB: 'PatientShippingTab',
  PATIENT_INVOICES_TAB: 'PatientInvoicesTab',
  PATIENT_COMMENTS_TAB: 'PatientCommentsTab',
};

export const PatientDemographics = {
  ONBOARDED_DATE: 'onBoardedDate',
  FIRSTNAME: 'firstName',
  GENDER: 'gender',
  DOB: 'dob',
  ORIGINAL_PARTNER: 'originalPartner',
  CREATED_TIMESTAMP: 'createdTimestamp',
};
