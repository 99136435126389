/* eslint-disable max-lines */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import { lighten, useTheme } from '@mui/material/styles';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { every, filter, find, first, includes, map, some } from 'lodash';
import PropTypes from 'prop-types';

import orderStatus from 'common/constants/orderStatus';
import usePrintLabelContext, {
  PrintLabelCheckBox,
  SelectAllCheckBox,
} from 'common/hooks/usePrintLabelContext';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOrder } from 'store/thunks/patientThunks';
import { useSnackbar } from 'notistack';
import useRoles from 'common/hooks/useRoles';

import orderEnum from 'enums/order.enum';
import lineItemStatusEnum from 'enums/lineItem.enum';
import PatientNoteOptions from './PatientNoteOptions';
import PatientFillProductButton from './PatientFillProductButton';
import SendToShippingButton from './SendToShippingButton';
import CancelOrderButton from './CancelOrderButton';
import PatientPrescriptionDialog from '../PrescriptionsTab/PatientPrescriptionDialog';
import ReviewOrderVerificationButton from './ReviewOrderVerificationButton';
import EditOrderAddressButton from './EditOrderAddressButton';
import ReviewPrescriptionButton from './ReviewPrescriptionButton';
import ReverifyOrderButton from './ReVerifyOrderButton';

const headCells = [
  {
    id: 'needsByDate',
    label: 'Needs By Date',
  },
  {
    id: 'itemName',
    label: 'Item Name',
  },
  {
    id: 'requestedDate',
    label: 'Requested Date',
  },
  {
    id: 'type',
    label: 'Type',
  },
  {
    id: 'rxNumber',
    label: 'RX Number',
  },
  {
    id: 'strength',
    label: 'Strength',
    align: 'center',
  },
  {
    id: 'form',
    label: 'Form',
  },
  {
    id: 'quantity',
    label: 'Quantity',
    align: 'center',
  },
  {
    id: 'price',
    label: 'Price',
    align: 'center',
  },
];

const PatientOrdersAccordion = ({ order, mpi }) => {
  const theme = useTheme();
  const orderLineItemIds = map(
    filter(
      order.lineItems,
      ({ lineItemStatus }) => lineItemStatus !== lineItemStatusEnum.RE_PROCESS
    ),
    'lineItemId'
  );
  const isLineItemsInReprocessingState = some(
    order.lineItems,
    ({ lineItemStatus }) => lineItemStatus === lineItemStatusEnum.RE_PROCESS
  );
  const [activeNoteItem, setActiveNoteItem] = useState(null);
  const [toolTipMsg, setToolTipMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const displaySendToShipmentButton = includes(
    [orderEnum.ORDER_VERIFIED, orderEnum.HOLD],
    order.status
  );
  const displayOrderCost = includes(
    [
      orderEnum.ORDER_VERIFIED,
      orderEnum.ADDED_TO_SHIPMENT,
      orderEnum.ORDER_VERIFICATION,
      orderEnum.ORDER_RE_VERIFICATION,
    ],
    order.status
  );
  const patientPrescriptions = useSelector(({ patient }) => patient[mpi]?.prescriptions ?? []);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState(null);
  const [openPrescriptionForm, setOpenPrescriptionForm] = useState(false);
  const [showVerifyDialog, setShowVerifyDialog] = useState(false);
  const isRxEntryOrder = order?.orderType === 'RX_ENTRY';

  const disableVerifyOrderButton =
    some(map(order.lineItems, 'taggedForReview')) || order.isProcessedExternal;

  const showNeedsReviewButtonOnProductFulfillment =
    isRxEntryOrder &&
    hasAccess.markOrderForExternalProcessing &&
    !order.isProcessedExternal &&
    !every(order.lineItems, 'taggedForReview') &&
    order.status === orderEnum.OPEN;

  const showNeedsReviewButton = includes(
    ['OPEN', 'ORDER_VERIFICATION', 'ORDER_RE_VERIFICATION'],
    order.status
  );

  const showVerifyOrderBtn =
    includes([orderEnum.ORDER_VERIFICATION, orderEnum.ORDER_RE_VERIFICATION], order.status) &&
    hasAccess.verifyOrder;

  useEffect(() => {
    if (order.isProcessedExternal) {
      setToolTipMsg('Order processing is external');
    } else if (disableVerifyOrderButton) {
      setToolTipMsg('Line Items needs to be reviewed');
    }
  }, [order.isProcessedExternal, disableVerifyOrderButton]);

  const onClose = () => setOpenPrescriptionForm(false);

  const handlePrescriptionForm = (prescriptionId) => {
    setSelectedPrescriptionId(prescriptionId);
    setOpenPrescriptionForm(!openPrescriptionForm);
  };

  const openVerifyDialog = () => setShowVerifyDialog(true);
  const closeVerifyDialog = () => setShowVerifyDialog(false);

  const handleVerifyOrder = () => {
    setIsLoading(true);
    dispatch(verifyOrder({ mpi, orderId: order.orderId })).then((isVerified) => {
      setIsLoading(false);
      if (isVerified) {
        closeVerifyDialog();
        enqueueSnackbar(`Order ${order.orderId} Verified`, { variant: 'success' });
      }
    });
  };

  const { setLabelItemIds } = usePrintLabelContext();
  const enablePrintLabelButton = () => {
    setLabelItemIds([...orderLineItemIds]);
  };

  const displayEditOrderAddressButton =
    isRxEntryOrder &&
    hasAccess.updateOrderAddress &&
    order.status !== orderEnum.SHIPPED &&
    !order.isProcessedExternal;

  const displayReverifyOrderBtn =
    isRxEntryOrder && !order.isProcessedExternal && order.status === orderEnum.ADDED_TO_SHIPMENT;

  return (
    <>
      {openPrescriptionForm && (
        <PatientPrescriptionDialog
          contextId={selectedPrescriptionId}
          closeDialog={onClose}
          mpi={mpi}
          open={openPrescriptionForm}
        />
      )}

      <Dialog open={showVerifyDialog}>
        <DialogTitle>Verify Order</DialogTitle>
        <DialogContent sx={{ minWidth: 500 }}>
          <>
            <p>I acknowledge that order {order.orderId} has been verified.</p>
            {map(order?.lineItems, (lineItem) => (
              <Grid
                container
                key={lineItem.lineItemId}
                alignItems='center'
                justifyContent='space-between'
                spacing={1}
                sx={{ mb: 1 }}
              >
                <Grid item xs={7}>
                  <Typography fontWeight='600'>
                    {
                      find(patientPrescriptions, { prescriptionUUID: lineItem?.prescriptionId })
                        ?.drug
                    }
                  </Typography>
                </Grid>

                <Grid item container xs={5} justifyContent='flex-end' gap={1}>
                  <Button
                    size='small'
                    sx={{ '&:hover': { backgroundColor: 'transparent' } }}
                    onClick={() => handlePrescriptionForm(lineItem?.prescriptionId)}
                    variant='outlined'
                  >
                    View
                  </Button>
                  {isRxEntryOrder && hasAccess.markOrderForExternalProcessing && (
                    <ReviewOrderVerificationButton mpi={mpi} orderId={order.orderId} />
                  )}
                </Grid>
              </Grid>
            ))}
            {isRxEntryOrder && <p>* Order placed from RX ENTRY</p>}
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeVerifyDialog} color='secondary' size='medium' variant='outlined'>
            Cancel
          </Button>
          <LoadingButton variant='contained' onClick={handleVerifyOrder} loading={isLoading}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Grid container direction='column' sx={{ mb: 1 }}>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary
            sx={{ bgcolor: lighten(theme.palette.text.primary, 0.9) }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${order.orderId}-panel-content`}
            id={`${order.orderId}-panel-header`}
          >
            <Grid container justifyContent='space-between' alignItems='center'>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography
                  variant='subtitle2'
                  fontWeight='bold'
                >{`Order ${order.orderId} - ${order?.partner?.partnerName}`}</Typography>
                <Chip
                  sx={{
                    background: theme.palette.secondary.light,
                    color: theme.palette.primary.contrastText,
                    maxHeight: '80%',
                  }}
                  label={
                    <Typography variant='body2' fontWeight='bold'>
                      {orderStatus[order.status]}
                    </Typography>
                  }
                />
              </Stack>

              {displayOrderCost && order.orderCost && (
                <Typography variant='subtitle2' sx={{ mr: 2, fontWeight: 600 }}>
                  Order Total: ${order.orderCost}
                </Typography>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid sx={{ my: 1 }} container direction='column'>
              <Grid item>
                <TableContainer>
                  <Grid container justifyContent='flex-end' sx={{ pb: 2 }}>
                    {order.status !== orderEnum.SHIPPED && (
                      <CancelOrderButton
                        disabled={isLineItemsInReprocessingState}
                        orderId={order.orderId}
                        mpi={mpi}
                      />
                    )}

                    {displayReverifyOrderBtn && (
                      <ReverifyOrderButton mpi={mpi} orderId={order.orderId} />
                    )}

                    {displayEditOrderAddressButton && (
                      <EditOrderAddressButton
                        mpi={mpi}
                        orderId={order.orderId}
                        orderAddress={order.shippingAddress}
                        afterAddressUpdate={enablePrintLabelButton}
                        disabled={isLineItemsInReprocessingState}
                      />
                    )}

                    {!first(order.lineItems).taggedForReview &&
                      !order.isProcessedExternal &&
                      showNeedsReviewButton && (
                        <ReviewPrescriptionButton
                          disabled={isLineItemsInReprocessingState}
                          patientId={mpi}
                          prescriptionUUID={first(order.lineItems).prescriptionId}
                          lineItemId={first(order.lineItems).lineItemId}
                          orderId={order.orderId}
                        />
                      )}

                    {showNeedsReviewButtonOnProductFulfillment && (
                      <ReviewOrderVerificationButton
                        mpi={mpi}
                        orderId={order.orderId}
                        disabled={isLineItemsInReprocessingState}
                      />
                    )}

                    {showVerifyOrderBtn && (
                      <Tooltip title={toolTipMsg} placement='top' arrow>
                        <span>
                          <LoadingButton
                            variant='contained'
                            onClick={openVerifyDialog}
                            sx={{ mr: 2 }}
                            disabled={disableVerifyOrderButton}
                          >
                            Verify Order
                          </LoadingButton>
                        </span>
                      </Tooltip>
                    )}

                    {displaySendToShipmentButton && hasAccess.createOrderShipment && (
                      <SendToShippingButton
                        mpi={mpi}
                        orderId={order.orderId}
                        orderStatus={order.status}
                      />
                    )}
                  </Grid>
                  <Table size='small'>
                    <TableHead>
                      <TableRow hover>
                        {headCells.map((headCell) => (
                          <TableCell key={headCell.id} align={headCell.align}>
                            {headCell.label}
                          </TableCell>
                        ))}

                        <TableCell align='center' sx={{ py: 0 }}>
                          {hasAccess.generateBulkLabels &&
                            !order.isProcessedExternal &&
                            !isLineItemsInReprocessingState && (
                              <SelectAllCheckBox allLineItemIds={orderLineItemIds} />
                            )}
                        </TableCell>

                        <TableCell />
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map(order.lineItems, (item) => (
                        <TableRow key={item.lineItemId} hover>
                          <TableCell sx={{ paddingY: '12px' }}>
                            {format(
                              utcToZonedTime(new Date(item.needsByDate), 'utc'),
                              'MM/dd/yyyy'
                            )}
                          </TableCell>
                          <TableCell sx={{ paddingY: '12px' }}>{item.itemName}</TableCell>
                          <TableCell>
                            {format(
                              utcToZonedTime(new Date(item.requestedDate), 'utc'),
                              'MM/dd/yyyy'
                            )}
                          </TableCell>
                          <TableCell sx={{ paddingY: '12px' }}>{item.itemType}</TableCell>
                          <TableCell>{item.rxNumber}</TableCell>
                          <TableCell align='center'>{item.strength}</TableCell>
                          <TableCell>{item.form}</TableCell>
                          <TableCell align='center'>{item.quantity}</TableCell>
                          <TableCell align='center'>{item.cost ? `$${item.cost}` : 'NA'}</TableCell>
                          <TableCell align='center'>
                            {hasAccess.generateBulkLabels &&
                              !order.isProcessedExternal &&
                              !isLineItemsInReprocessingState && (
                                <PrintLabelCheckBox lineItemId={item.lineItemId} />
                              )}
                          </TableCell>
                          <TableCell align='right'>
                            {item.hasPinnedNote && (
                              <IconButton
                                onClick={() => setActiveNoteItem(item.lineItemId)}
                                aria-label={`View pinned notes ${item.lineItemId}`}
                              >
                                <StickyNote2Icon color='primary' />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align='right'>
                            <PatientFillProductButton
                              item={item}
                              key={item.lineItemId}
                              mpi={mpi}
                              orderId={order.orderId}
                              isProcessedExternal={order.isProcessedExternal}
                            />
                            <PatientNoteOptions
                              itemId={item.lineItemId}
                              mpi={mpi}
                              itemName={item.itemName}
                              orderId={order.orderId}
                              itemStatus={item.lineItemStatus}
                              activeNoteItem={activeNoteItem}
                              setActiveNoteItem={setActiveNoteItem}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

PatientOrdersAccordion.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string.isRequired,
    status: PropTypes.string,
    orderType: PropTypes.string,
    orderCost: PropTypes.string,
    isProcessedExternal: PropTypes.bool.isRequired,
    partner: PropTypes.shape({ partnerName: PropTypes.string.isRequired }),
    shippingAddress: PropTypes.shape({
      line1: PropTypes.string.isRequired,
      line2: PropTypes.string,
      city: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      zip: PropTypes.string.isRequired,
    }).isRequired,
    lineItems: PropTypes.arrayOf(
      PropTypes.shape({
        itemName: PropTypes.string,
        needsByDate: PropTypes.string,
        itemType: PropTypes.string,
        lineItemStatus: PropTypes.string.isRequired,
        cost: PropTypes.number,
        lineItemId: PropTypes.string.isRequired,
        prescriptionId: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

export default PatientOrdersAccordion;
