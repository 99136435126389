/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const getPartnersGql = gql`
  query getPartners {
    getPartners {
      partnerName
      partnerUuid
    }
  }
`;

export const getPartnerPracticesGql = gql`
  query getPartnerPractices($partnerId: UUID!) {
    getPartnerPractices(partnerId: $partnerId) {
      practiceId
      practiceName
      line1
      line2
      city
      state
      zip
    }
  }
`;
