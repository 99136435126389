/* eslint-disable import/prefer-default-export */

import { map } from 'lodash';

export const transformListOrderLineItems = ({ listOrderLineItems }) => ({
  count: listOrderLineItems.count,
  orderLineItems: map(
    listOrderLineItems.results,
    ({ patient, partner, lineItem, orderId, orderStatus }) => {
      const { patientDemographics, mpi } = patient;
      const patientName = `${patientDemographics.lastName}, ${patientDemographics.firstName}`;
      const { itemName, needsByDate, requestedDate, lineItemId } = lineItem;

      return {
        orderId,
        mpi,
        patientName,
        partnerName: partner.partnerName,
        requestedDate,
        itemName,
        lineItemId,
        orderStatus,
        needsByDate,
      };
    }
  ),
});
