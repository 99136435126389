/* eslint-disable import/prefer-default-export */
import graphqlClient from '../graphql';
import { getPartnerPracticesGql, getPartnersGql } from './gql';

export const getPartnersApi = () => {
  return graphqlClient.request(getPartnersGql).then(({ getPartners }) => getPartners);
};

export const getPartnerPracticesApi = (partnerId) => {
  return graphqlClient
    .request(getPartnerPracticesGql, { partnerId })
    .then(({ getPartnerPractices }) => getPartnerPractices);
};
