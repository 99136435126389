/* eslint-disable import/prefer-default-export */
import graphqlClient from 'api/graphql';
import { setCookie } from 'api/utils';

import { getUserAuthGql, logoutGql, refreshTokenGql } from './gql';

export const getUserAuthApi = ({ authRef }) => {
  return graphqlClient.request(getUserAuthGql, { authRef }).then(({ getUserAuth }) => {
    setCookie({ name: 'pomAccessToken', value: getUserAuth.accessToken });
  });
};

export const refreshTokenApi = () => {
  return graphqlClient.request(refreshTokenGql).then(({ refreshToken }) => {
    setCookie({ name: 'pomAccessToken', value: refreshToken.accessToken });
  });
};

export const logoutApi = () => {
  // intentionally not doing anything if failed, because all access are cleared locally on the browser
  return graphqlClient.request(logoutGql).catch(() => {});
};
