/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listDrugsGql = gql`
  query listDrugs($medicationName: String!) {
    listDrugs(drugName: $medicationName) {
      medicationName: name
      ndc
    }
  }
`;
