export const SortOrder = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

export const SortState = {
  SORTED_ASCENDING: 'sorted ascending',
  SORTED_DESCENDING: 'sorted descending',
};

export const SortKey = {
  NEEDS_BY_DATE: 'needsByDate',
  PATIENT_NAME: 'patientName',
  PARTNER_NAME: 'partnerName',
  REQUESTED_DATE: 'requestedDate',
  STATUS: 'status',
  ON_BOARDED_DATE: 'onBoardedDate',
  ORDER_ID: 'orderId',
  ITEM_NAME: 'itemName',
  ORDER_STATUS: 'orderStatus',
  PARTNER_UUID: 'partnerUuid',
  MEDICATION: 'itemName',
  DOB: 'dob',
  DRUG_NAME: 'drugName',
  RX_NUMBER: 'rxNumber',
  REVIEW_STATUS: 'reviewStatus',
};
