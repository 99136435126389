import { LoadingButton } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import CheckboxField from 'common/forms/CheckboxField';
import { Form } from 'react-final-form';
import { reduce, some } from 'lodash';
import PropTypes from 'prop-types';

const PrescriptionNeedsReviewForm = ({ onSubmit, submitting }) => {
  const handleNeedsReview = (formData) => {
    return onSubmit({
      verified: false,
      failureReasons: reduce(
        formData,
        (result, value, key) => (value ? [...result, key] : result),
        []
      ),
    });
  };

  return (
    <Form
      onSubmit={handleNeedsReview}
      render={({ handleSubmit, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Typography variant='h6' component='h4' sx={{ mt: 2 }}>
            Needs Review
          </Typography>

          <Grid container>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction='column'>
                <Typography variant='body1' component='h5' sx={{ fontWeight: 500, mt: 2, mb: 1 }}>
                  Entry Error
                </Typography>

                <CheckboxField
                  name='ENTRY_ERROR_CYCLE_FILL'
                  label='Cycle Fill'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-cycleFill-checkbox'
                  disableHelperText
                />
                <CheckboxField
                  name='ENTRY_ERROR_DATE_WRITTEN'
                  label='Date Written'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-dateWritten-checkbox'
                  disableHelperText
                />
                <CheckboxField
                  name='ENTRY_ERROR_DAW'
                  label='DAW'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-daw-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_DIRECTIONS'
                  label='Directions'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-directions-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_DRUG'
                  label='Drug'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-Drug-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_HOA'
                  label='HOA'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-hoa-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_ORIGIN_CODE'
                  label='Origin Code'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-originCode-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_PATIENT'
                  label='Patient'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-patient-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_PRESCRIBER'
                  label='Prescriber'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-prescriber-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_QUANTITY'
                  label='Quantity'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-Quantity-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_REFILLS'
                  label='Refills'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-refills-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_SIG_RECORD'
                  label='Sig Record'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-sigRecord-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_STRENGTH'
                  label='Strength'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-strength-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='ENTRY_ERROR_OTHER'
                  label='Other'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-entryOther-checkbox'
                  disableHelperText
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid container direction='column'>
                <Typography variant='body1' component='h5' sx={{ fontWeight: 500, mt: 2, mb: 1 }}>
                  Process Error
                </Typography>

                <CheckboxField
                  name='PROCESS_ERROR_COPAY_LESS_THAN_ACTUAL'
                  label='Copay less than actual'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-copayLessThanActual-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_COPAY_GREATER_THAN_ACTUAL'
                  label='Copay greater than actual'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-copayGreaterTHanActual-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_MISSED_ENTRY'
                  label='Missed Entry'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-missedEntry-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_OLDER_OR_SAME_DATE_RX'
                  label='Older / Same Date Rx'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-olderSameDateRx-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_REFILL_REQUESTS'
                  label='Refill Request'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-refillRequest-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_REVERSING_CLAIM'
                  label='Reversing Claim'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-reversingClaim-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_TAGGING'
                  label='Tagging'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-tagging-checkbox'
                  disableHelperText
                />

                <CheckboxField
                  name='PROCESS_ERROR_OTHER'
                  label='Other'
                  id='PatientView-PrescriptionVerification-PrescriptionNeedsReview-processOther-checkbox'
                  disableHelperText
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container direction='row-reverse'>
            <LoadingButton
              loading={submitting}
              disabled={!some(values)}
              variant='outlined'
              color='secondary'
              type='submit'
              sx={{ minWidth: 130 }}
            >
              Needs Review
            </LoadingButton>
          </Grid>
        </form>
      )}
    />
  );
};

PrescriptionNeedsReviewForm.propTypes = {
  submitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

PrescriptionNeedsReviewForm.defaultProps = {
  submitting: false,
};

export default PrescriptionNeedsReviewForm;
