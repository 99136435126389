import { useState } from 'react';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import { listFailedAdjdicatioNotes } from 'store/thunks/workQueueThunks';

import NotesDialog from './ReviewAdjudicationNoteDialog';

const PinnedNotes = ({ contextId, contextType, mpi, hasPinnedNotes }) => {
  const dispatch = useDispatch();
  const [showNotesDialog, setShowNotesDialog] = useState(false);

  const handleShowNotesDialog = () => {
    setShowNotesDialog(true);
    dispatch(listFailedAdjdicatioNotes({ contextId, contextType, mpi }));
  };

  const handleClosePinnedNotes = () => setShowNotesDialog(false);

  return (
    <>
      <NotesDialog
        open={showNotesDialog}
        onClose={handleClosePinnedNotes}
        contextId={contextId}
        mpi={mpi}
      />
      {hasPinnedNotes && (
        <IconButton onClick={handleShowNotesDialog} aria-label={`View pinned notes ${contextId}`}>
          <StickyNote2Icon color='primary' />
        </IconButton>
      )}
    </>
  );
};

PinnedNotes.propTypes = {
  contextId: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  hasPinnedNotes: PropTypes.bool.isRequired,
};

export default PinnedNotes;
