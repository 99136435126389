/* eslint-disable max-lines */
/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';
import { isNull, reduce } from 'lodash';
import { utcToZonedTime } from 'date-fns-tz';

import graphqlClient from '../graphql';
import {
  addGuarantorGql,
  addPatientAddressGql,
  addPatientGql,
  addPatientInsuranceGql,
  addPatientMedicalHistoryGql,
  addPatientMedicationGql,
  addPatientPartnersGql,
  addPatientPharmacyGql,
  addPatientProviderGql,
  associatePatientWithExternalProfileGql,
  createLineItemNoteGql,
  createPatientCommentGql,
  deactivateInsuranceGql,
  deactivatePatientProviderGql,
  deleteGuarantorGql,
  deletePatientAddressGql,
  deletePatientCommentGql,
  deletePatientMedicationGql,
  deletePatientPartnerGql,
  deletePatientPharmacyGql,
  editOrderShipmentGql,
  editPatientGql,
  editPatientPrescriptionGql,
  getAuthorizationsGql,
  getCurrentTestClaimIdentityGql,
  getDownloadUrlForInvoiceKeyGql,
  getGuarantorsGql,
  getPatientAddressesGql,
  getPatientBalanceGql,
  getPatientGql,
  getPatientInsurancesGql,
  getPatientMedicalHistoryGql,
  getPatientPharmaciesGql,
  getPatientProvidersGql,
  getPatientUsersGql,
  getPatientsGql,
  listFailedAdjudicationsGql,
  listLineItemNotesGql,
  listPatientCommentsGql,
  listPatientInvoicesGql,
  listPatientMedicationsGql,
  listPatientOrderShipmentsGql,
  listPatientOrdersGql,
  listPatientPrescriptionsGql,
  listPatientRequestsGql,
  noteActionGql,
  onboardPatientGql,
  runTestClaimGql,
  saveTestClaimResultGql,
  searchQS1ProfileGql,
  setPartnerExternalIdGql,
  triggerForgotPasswordGql,
  updateGuarantorGql,
  updatePatientAddressGql,
  updatePatientAuthorizationsGql,
  updatePatientInsuranceGql,
  updatePatientMedicationGql,
  updatePatientPharmacyGql,
  updatePatientProviderGql,
  updateRefillAdjudicationStateGql,
} from './gql';
import {
  transformFullPatientDetails,
  transformGetPatientActionItems,
  transformGetPatientUsers,
  transformGetPatients,
  transformGuarantorData,
  transformLineItemNotes,
  transformListFailedAdjudications,
  transformListPatientComments,
  transformNote,
  transformPatientAddress,
  transformPatientAddresses,
  transformPatientData,
} from './transform';

export const getPatientsApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    dob: filter.dob && format(new Date(filter.dob), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(getPatientsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        // omit keys if values are null
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformGetPatients);
};

export const addPatientApi = ({ patientData }) => {
  return graphqlClient.request(addPatientGql, { patientData }).then(({ addPatient }) => addPatient);
};

export const editPatientApi = ({ patientData }) => {
  return graphqlClient
    .request(editPatientGql, { patientData })
    .then(({ editPatient }) => editPatient);
};

export const listPatientCommentsApi = ({ mpi, offset, searchText }) => {
  return graphqlClient
    .request(listPatientCommentsGql, {
      mpi,
      offset,
      // BE has length validation, if search text is a blank string, therefore we need to set it to undefined
      filter: { searchText: searchText || undefined, type: 'INFO' },
    })
    .then(transformListPatientComments);
};

export const createPatientCommentApi = ({ mpi, message, contextId }) => {
  return graphqlClient
    .request(createPatientCommentGql, { mpi, message, contextId })
    .then(({ createPatientComment }) => createPatientComment);
};

export const deletePatientCommentApi = ({ patientCommentId }) => {
  return graphqlClient
    .request(deletePatientCommentGql, { patientCommentId })
    .then(({ deletePatientComment }) => deletePatientComment);
};

export const getPatientApi = ({ mpi }) => {
  return graphqlClient
    .batchRequests([
      { document: getPatientGql, variables: { mpi } },
      { document: getPatientAddressesGql, variables: { mpi } },
      { document: getPatientBalanceGql, variables: { mpi } },
    ])
    .then(transformPatientData);
};

export const addGuarantorApi = ({ guarantor }) => {
  return graphqlClient
    .request(addGuarantorGql, { guarantor })
    .then(({ addGuarantor }) => addGuarantor);
};

export const getGuarantorsApi = ({ mpi }) => {
  return graphqlClient.request(getGuarantorsGql, { mpi }).then(transformGuarantorData);
};

export const updateGuarantorApi = ({ guarantor }) => {
  return graphqlClient
    .request(updateGuarantorGql, { guarantor })
    .then(({ updateGuarantor }) => updateGuarantor);
};

export const deleteGuarantorApi = ({ mpi, guarantorId }) => {
  return graphqlClient.request(deleteGuarantorGql, { mpi, guarantorId });
};

export const addPatientMedicationApi = ({ medicationData }) => {
  return graphqlClient
    .request(addPatientMedicationGql, { medicationData })
    .then(({ addPatientMedication }) => addPatientMedication);
};

export const listPatientMedicationsApi = ({ mpi }) => {
  return graphqlClient
    .request(listPatientMedicationsGql, { mpi })
    .then(({ listPatientMedications }) => listPatientMedications.results);
};

export const updatePatientMedicationApi = ({ medication }) => {
  return graphqlClient
    .request(updatePatientMedicationGql, { medication })
    .then(({ updatePatientMedication }) => updatePatientMedication);
};

export const deletePatientMedicationApi = ({ mpi, patientMedicationId }) => {
  return graphqlClient.request(deletePatientMedicationGql, { mpi, patientMedicationId });
};

export const getPatientMedicalHistoryApi = ({ mpi }) => {
  return graphqlClient
    .request(getPatientMedicalHistoryGql, { mpi })
    .then(({ getPatientMedicalHistory }) => getPatientMedicalHistory);
};

export const addPatientMedicalHistoryApi = ({ newMedicalHistory }) => {
  return graphqlClient
    .request(addPatientMedicalHistoryGql, { input: newMedicalHistory })
    .then(({ addPatientMedicalHistory }) => addPatientMedicalHistory);
};

export const getAuthorizationsApi = ({ mpi }) => {
  return graphqlClient
    .request(getAuthorizationsGql, { mpi })
    .then(({ getAuthorizations }) => getAuthorizations);
};

export const updatePatientAuthorizationsApi = ({ mpi, authorization }) => {
  return graphqlClient
    .request(updatePatientAuthorizationsGql, { mpi, authorization })
    .then(({ updatePatientAuthorizations }) => updatePatientAuthorizations);
};

export const addPatientInsuranceApi = ({ insurance }) => {
  return graphqlClient
    .request(addPatientInsuranceGql, { insurance })
    .then(({ addPatientInsurance }) => addPatientInsurance);
};

export const getPatientInsurancesApi = ({ mpi }) => {
  return graphqlClient
    .request(getPatientInsurancesGql, { mpi })
    .then(({ getPatientInsurances }) => getPatientInsurances);
};

export const updatePatientInsuranceApi = ({ insurance }) => {
  return graphqlClient
    .request(updatePatientInsuranceGql, { insurance })
    .then(({ updatePatientInsurance }) => updatePatientInsurance);
};

export const deactivateInsuranceApi = ({ patientInsuranceId }) => {
  return graphqlClient.request(deactivateInsuranceGql, { patientInsuranceId });
};

export const onboardPatientApi = ({ onboardPatientInput }) => {
  return graphqlClient
    .request(onboardPatientGql, { onboardPatientInput })
    .then(({ onboardPatient }) => ({
      patient: onboardPatient.patient.patientDemographics,
      authorization: onboardPatient.authorization.authorization,
    }));
};

export const getFullPatientDetailsApi = ({ mpi }) => {
  return graphqlClient
    .batchRequests([
      { document: getGuarantorsGql, variables: { mpi } },
      { document: getPatientInsurancesGql, variables: { mpi } },
      { document: listPatientMedicationsGql, variables: { mpi } },
      { document: getAuthorizationsGql, variables: { mpi } },
    ])
    .then(transformFullPatientDetails);
};

export const addPatientProviderApi = ({ provider }) => {
  return graphqlClient
    .request(addPatientProviderGql, { provider })
    .then(({ addPatientProvider }) => addPatientProvider);
};

export const updatePatientProviderApi = ({ provider }) => {
  return graphqlClient
    .request(updatePatientProviderGql, { provider })
    .then(({ updatePatientProvider }) => updatePatientProvider);
};

export const deactivatePatientProviderApi = ({ patientProviderId }) => {
  return graphqlClient.request(deactivatePatientProviderGql, { patientProviderId });
};

export const getPatientProvidersApi = ({ mpi }) => {
  return graphqlClient
    .request(getPatientProvidersGql, { mpi })
    .then(({ getPatientProviders }) => getPatientProviders);
};

export const runTestClaimApi = ({ mpi, patientInsuranceId }) => {
  return graphqlClient
    .request(runTestClaimGql, { input: { mpi, patientInsuranceId } })
    .then(({ runTestClaim }) => runTestClaim);
};

export const saveTestClaimResultApi = ({ saveTestClaimResultInput }) => {
  return graphqlClient
    .request(saveTestClaimResultGql, { input: saveTestClaimResultInput })
    .then(({ saveTestClaimResult }) => saveTestClaimResult);
};

export const getCurrentTestClaimIdentityApi = ({ mpi }) => {
  return graphqlClient
    .request(getCurrentTestClaimIdentityGql, { mpi })
    .then(({ getCurrentTestClaimIdentity }) => getCurrentTestClaimIdentity);
};

export const addPatientPharmacyApi = ({ pharmacy }) => {
  return graphqlClient
    .request(addPatientPharmacyGql, { pharmacy })
    .then(({ addPatientPharmacy }) => addPatientPharmacy);
};

export const getPatientPharmaciesApi = ({ mpi }) => {
  return graphqlClient
    .request(getPatientPharmaciesGql, { mpi })
    .then(({ getPatientPharmacies }) => getPatientPharmacies);
};

export const updatePatientPharmacyApi = ({ pharmacy }) => {
  return graphqlClient
    .request(updatePatientPharmacyGql, { pharmacy })
    .then(({ updatePatientPharmacy }) => updatePatientPharmacy);
};

export const deletePatientPharmacyApi = ({ patientPharmacyId }) => {
  return graphqlClient.request(deletePatientPharmacyGql, { patientPharmacyId });
};

export const addPatientPartnersApi = ({ mpi, partners }) => {
  return graphqlClient
    .request(addPatientPartnersGql, { mpi, partners })
    .then(({ addPatientPartner }) => addPatientPartner);
};

export const setPartnerExternalIdApi = ({ mpi, partnerId, identityValue }) => {
  return graphqlClient
    .request(setPartnerExternalIdGql, { mpi, partnerId, identityValue })
    .then(({ setPartnerExternalId }) => setPartnerExternalId);
};

export const deletePatientPartnerApi = ({ mpi, partnerUUID }) => {
  return graphqlClient.request(deletePatientPartnerGql, { mpi, partnerUUID });
};

export const getPatientActionItemsApi = ({ mpi }) => {
  return graphqlClient
    .batchRequests([
      { document: listPatientRequestsGql, variables: { mpi } },
      { document: listPatientOrdersGql, variables: { mpi } },
    ])
    .then(transformGetPatientActionItems);
};

export const listFailedAdjudicationsApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const defaultPageOffset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);

  return graphqlClient
    .request(listFailedAdjudicationsGql, {
      limit: defaultItemsPerPage,
      offSet: (page - 1) * defaultItemsPerPage || defaultPageOffset,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListFailedAdjudications);
};

export const updateRefillAdjudicationStateApi = ({ rxRequestId, state }) =>
  graphqlClient.request(updateRefillAdjudicationStateGql, { rxRequestId, state });

export const listPatientPrescriptionsApi = ({ mpi, forceRefresh, offset }) => {
  return graphqlClient
    .request(listPatientPrescriptionsGql, { mpi, forceRefresh, offset })
    .then(({ listPatientPrescriptions }) => listPatientPrescriptions.results);
};

export const getPatientAddressesApi = ({ mpi }) => {
  return graphqlClient
    .request(getPatientAddressesGql, { mpi })
    .then(({ getPatientAddresses }) =>
      transformPatientAddresses({ addresses: getPatientAddresses })
    );
};

export const addPatientAddressApi = ({ mpi, address }) => {
  return graphqlClient
    .request(addPatientAddressGql, { input: { mpi, ...address } })
    .then(({ addPatientAddress }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (addPatientAddress?.__typename === 'Addresses') {
        return addPatientAddress;
      }

      return transformPatientAddress(addPatientAddress);
    });
};

export const deletePatientAddressApi = ({ mpi, patientAddressId }) => {
  return graphqlClient.request(deletePatientAddressGql, { mpi, patientAddressId });
};

export const updatePatientAddressApi = ({ mpi, address }) => {
  return graphqlClient
    .request(updatePatientAddressGql, { input: { mpi, ...address } })
    .then(({ updatePatientAddress }) => transformPatientAddress(updatePatientAddress));
};

export const listPatientInvoicesApi = ({ mpi, page }) => {
  return graphqlClient
    .request(listPatientInvoicesGql, { mpi, offset: (page - 1) * 10 })
    .then(({ listPatientInvoices }) => listPatientInvoices);
};

export const getDownloadUrlForInvoiceKeyApi = ({
  invoiceFileKey,
  invoiceId,
  mpi,
  invoiceServiceCustomerId,
}) => {
  return graphqlClient
    .request(getDownloadUrlForInvoiceKeyGql, {
      invoiceFileKey,
      invoiceId,
      mpi,
      invoiceServiceCustomerId,
    })
    .then(({ getDownloadUrlForInvoiceKey }) => getDownloadUrlForInvoiceKey);
};

export const createLineItemNoteApi = ({ mpi, content, commentContext }) => {
  return graphqlClient
    .request(createLineItemNoteGql, { mpi, content, commentContext })
    .then(({ createPatientNote }) => transformNote(createPatientNote));
};

export const listLineItemNotesApi = ({ mpi, contextType, filter }) => {
  const limit = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const offset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);
  return graphqlClient
    .request(listLineItemNotesGql, { mpi, contextType, filter, offset, limit })
    .then(({ listPatientNotes: listLineItemNotes }) => transformLineItemNotes(listLineItemNotes));
};

export const noteActionApi = ({ noteId, action }) => {
  return graphqlClient
    .request(noteActionGql, { action, noteId })
    .then(({ noteAction }) => noteAction);
};

export const listPatientOrderShipmentsApi = ({ mpi, filter }) => {
  return graphqlClient
    .request(listPatientOrderShipmentsGql, {
      mpi,
      filter: {
        ...filter,
        shipmentDate: filter?.shipmentDate
          ? format(utcToZonedTime(new Date(filter?.shipmentDate), 'utc'), 'yyyy-MM-dd')
          : undefined,
      },
    })
    .then(({ listPatientOrderShipments }) => listPatientOrderShipments);
};

export const getPatientUsersApi = ({ mpi }) => {
  return graphqlClient.request(getPatientUsersGql, { mpi }).then(transformGetPatientUsers);
};

export const resetPasswordApi = ({ userId }) => {
  return graphqlClient
    .request(triggerForgotPasswordGql, { peUserId: userId })
    .then(({ triggerPasswordResetForPEUser }) => triggerPasswordResetForPEUser);
};

export const listPatientOrdersApi = ({ mpi }) => {
  return graphqlClient
    .request(listPatientOrdersGql, { mpi })
    .then(({ listPatientOrders }) => listPatientOrders.results);
};

export const editPatientPrescriptionApi = ({ prescriptionId, action, autoFill }) =>
  graphqlClient
    .request(editPatientPrescriptionGql, { action, prescriptionId, autoFill })
    .then(({ editPatientPrescription }) => editPatientPrescription);

export const searchQs1ProfileApi = ({ firstName, lastName, dob }) => {
  return graphqlClient
    .request(searchQS1ProfileGql, { firstName, lastName, dob })
    .then(({ searchQs1Profile }) => searchQs1Profile);
};

export const associatePatientWithExternalProfileApi = ({ mpi, externalProfile }) => {
  return graphqlClient
    .request(associatePatientWithExternalProfileGql, { mpi, externalProfile })
    .then(({ associatePatientWithExternalProfile }) => associatePatientWithExternalProfile);
};

export const editOrderShipmentApi = (input) => {
  return graphqlClient
    .request(editOrderShipmentGql, { ...input })
    .then(({ editOrderShipment }) => editOrderShipment);
};
