import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import useTabContext from 'common/hooks/useTabContext';
import { getAuthorizations, getGuarantors, getPatientInsurances } from 'store/thunks/patientThunks';
import { getPartners } from 'store/thunks/partnerThunks';
import PatientInsuranceTab from './Insurances/PatientInsuranceTab';
import PatientGuarantorsTab from './Guarantors/PatientGuarantorsTab';
import PatientAuthorizationTab from './Authorizations/PatientAuthorizationTab';
import PatientPartnersTab from './Partners/PatientPartnersTab';

const InsurancesTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const isActiveTab = useTabContext().tab === 'PatientInsuranceTab';

  useEffect(() => {
    dispatch(getPatientInsurances({ mpi }));
    dispatch(getGuarantors({ mpi }));
    dispatch(getAuthorizations({ mpi }));
    dispatch(getPartners({ mpi }));
  }, [dispatch, mpi, isActiveTab]);

  if (!isActiveTab) {
    return null;
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <PatientInsuranceTab />
      </Grid>

      <Grid item>
        <PatientGuarantorsTab />
      </Grid>

      <Grid item>
        <PatientAuthorizationTab />
      </Grid>

      <Grid item>
        <PatientPartnersTab />
      </Grid>
    </Grid>
  );
};

export default InsurancesTab;
