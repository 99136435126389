import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';
import EVisitRequestsTable from './eVisitRequestsTable';
import EVisitRequestsFilterButton from './eVisitRequestsFilterButton';
import EVisitRequestsFilterStatusBar from './eVisitRequestsFilterStatusBar';

const EVisitRequests = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.E_VISIT_REQUESTS) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <EVisitRequestsFilterStatusBar />
      </Grid>
      <Grid item>
        <EVisitRequestsFilterButton />
      </Grid>
      <EVisitRequestsTable />
    </Grid>
  );
};

export default EVisitRequests;
