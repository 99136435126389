import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';

import { listEVisitRequestsByPage, sortInEVisitRequestsByPage } from 'store/thunks/workQueueThunks';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { SortOrder } from 'enums/common.enum';
import { awaitingRequestStatus } from 'enums/request.enum';

const headCells = [
  {
    id: 'patientName',
    label: 'Patient Name',
    sortable: true,
  },
  {
    id: 'bundleLabel',
    label: 'Item',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
  },
  {
    id: 'providerName',
    label: 'Provider',
    sortable: true,
  },
  {
    id: 'requestedDate',
    label: 'Requested Date',
    sortable: true,
  },
];

const EVisitRequestsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage } = useSelector(({ workQueue }) => workQueue.eVisitRequests);

  const totalPages = useSelector(({ workQueue }) => Math.ceil(workQueue.eVisitRequests.count / 25));
  const eVisitRequests = pages[currentPage];

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortInEVisitRequestsByPage({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didnt change, don't do anything.
      return;
    }

    dispatch(listEVisitRequestsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listEVisitRequestsByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === SortOrder.DESCENDING
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(eVisitRequests, (request, index) => (
                <TableRow key={`${request.eVisitRequestId}${index}`} hover>
                  <TableCell component='th' scope='row' align='left'>
                    <Link
                      to={`/patients/${request.patient.mpi}`}
                      style={{ color: theme.palette.text.primary }}
                    >
                      {`${request.patient.lastName}, ${request.patient.firstName}`}
                    </Link>
                  </TableCell>
                  <TableCell>{request.campaignBundle.bundleLabel}</TableCell>
                  <TableCell>
                    {awaitingRequestStatus[request.status] || request.status.replace(/_/g, ' ')}
                  </TableCell>
                  <TableCell>{request.partner.partnerName}</TableCell>
                  <TableCell>
                    {format(utcToZonedTime(new Date(request.requestedDate), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(eVisitRequests) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(eVisitRequests) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(eVisitRequests) && isEmpty(eVisitRequests) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default EVisitRequestsTable;
