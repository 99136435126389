import { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';

import SelectField from 'common/forms/SelectField';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { validateMinLength } from 'common/forms/formValidations';
import { updatePatientOrderShipmentsFilter } from 'store/thunks/patientThunks';
import moment from 'moment';

const ShipmentFilter = ({ mpi }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const shipmentFilter = useSelector(({ patient }) => {
    const filter = patient[mpi]?.shipmentHistory?.shipmentFilter || {};

    return {
      ...filter,
      shipmentDate: filter.shipmentDate && new Date(filter.shipmentDate),
    };
  }, shallowEqual);
  const popoverId = anchorEl ? 'PatientView-ShipmentFilter-Popover' : undefined;

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleApplyFilter = (formData) => {
    dispatch(
      updatePatientOrderShipmentsFilter({
        mpi,
        shipmentFilter: {
          ...formData,
          shipmentDate: formData.shipmentDate && moment(formData.shipmentDate).format('YYYY-MM-DD'),
        },
      })
    );
    handleClose();
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='contained'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={shipmentFilter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <SelectField
                    label='Status'
                    name='status'
                    id='PatientView-ShipmentFilter-status-dropdown'
                  >
                    <MenuItem value={undefined}>All</MenuItem>
                    <MenuItem value='ORDER_CREATED'>Created</MenuItem>
                    <MenuItem value='ORDER_SHIPPED'>Shipped</MenuItem>
                    <MenuItem value='ORDER_REJECTED'>Rejected</MenuItem>
                  </SelectField>

                  <TextField
                    label='Tracking Number'
                    name='trackingNumber'
                    id='PatientView-ShipmentFilter-trackingNumber-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Shipment Order Id'
                    name='shipmentOrderId'
                    id='PatientView-ShipmentFilter-shipmentOrderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Shipment Date'
                    name='shipmentDate'
                    id='PatientView-ShipmentFilter-shipmentDate-dateField'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

ShipmentFilter.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default ShipmentFilter;
