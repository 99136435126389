import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listTransactionsByPage } from 'store/thunks/transactionsThunk';
import TransactionsFilterStatusBar from './components/TransactionsFilterStatusBar';
import TransactionsSelectMenu from './components/TransactionSelectMenu';
import TransactionsFilterButton from './components/TransactionsFilterButton';
import TransactionsTable from './components/TransactionsTable';

const Transactions = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(({ transactions }) => transactions?.currentPage);

  useEffect(() => {
    dispatch(listTransactionsByPage({ page: currentPage }));
  }, [currentPage, dispatch]);
  useEffect(() => {
    document.title = 'Transactions - SmartOS';
  }, []);

  return (
    <Grid container sx={{ px: 3, pb: 2 }} direction='column'>
      <Grid item container alignItems='center' spacing={2}>
        <Grid item>
          <Typography component='h1' variant='h5'>
            Billing Transactions
          </Typography>
        </Grid>
        <Grid item>
          <TransactionsSelectMenu />
        </Grid>
      </Grid>
      <Grid container sx={{ py: 2 }}>
        <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
          <TransactionsFilterStatusBar />
        </Grid>
        <Grid item>
          <TransactionsFilterButton />
        </Grid>
        <TransactionsTable />
      </Grid>
    </Grid>
  );
};

export default Transactions;
