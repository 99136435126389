export const SigFieldKeys = {
  SIG: 'sig',
  CREATED_AT: 'createdAt',
  UPDATED_AT: 'updatedAt',
  STATUS: 'status',
  DIRECTION: 'direction',
};

export const SigStatus = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
};
