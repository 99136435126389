import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, omit, values } from 'lodash';

import gender from 'common/constants/gender';
import { PatientStatus } from 'enums/patient.enum';
import { filterOnboardedPatients } from 'store/thunks/workQueueThunks';

const PatientQualificationFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.patientQualification?.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterOnboardedPatients({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(filterOnboardedPatients({ filter: { status: PatientStatus.ONBOARDED } }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.name && (
        <Chip label={`Name = ${filter.name}`} variant='contained' onDelete={removeFilter('name')} />
      )}

      {filter.gender && (
        <Chip
          label={`Gender = ${gender[filter.gender]}`}
          variant='contained'
          onDelete={removeFilter('gender')}
        />
      )}

      {filter.createdAfter && (
        <Chip
          label={`Created After = ${format(new Date(filter.createdAfter), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('createdAfter')}
        />
      )}

      {filter.onboardedAfter && (
        <Chip
          label={`Onboarded After = ${format(new Date(filter.onboardedAfter), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('onboardedAfter')}
        />
      )}

      {filter.dob && (
        <Chip
          label={`Date of Birth = ${format(new Date(filter.dob), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('dob')}
        />
      )}

      {filter.originalPartner && (
        <Chip
          label={`Original Partner = ${
            find(partners, { partnerUuid: filter.originalPartner }).partnerName
          }`}
          variant='contained'
          onDelete={removeFilter('originalPartner')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, ['status'])))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default PatientQualificationFilterStatusBar;
