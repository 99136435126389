import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import { getPartners } from 'store/thunks/partnerThunks';

import EScriptOrdersTable from './EScriptOrdersTable';
import EScriptOrdersFilterStatusBar from './EScriptOrdersFilterStatusBar';
import EScriptOrdersFilterButton from './EScriptOrdersFilterButton';
import EScriptOrdersOutsideFilters from './EScriptOrdersOuterFilters';

const EScriptOrders = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPartners());
  }, [dispatch]);

  return (
    <Grid container sx={{ py: 3 }} direction='row' alignItems='flex-end'>
      <Grid item sx={{ minHeight: 35, flexGrow: 1, pb: 3 }} alignItems='center'>
        <EScriptOrdersFilterStatusBar />
      </Grid>
      <Grid container alignItems='center' justifyContent='flex-end' gap={2}>
        <Grid item>
          <EScriptOrdersOutsideFilters />
        </Grid>
        <Grid item>
          <EScriptOrdersFilterButton />
        </Grid>
      </Grid>
      <Grid container>
        <EScriptOrdersTable />
      </Grid>
    </Grid>
  );
};

export default EScriptOrders;
