import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import AddAllergyButton from './AddAllergyButton';
import AllergyTable from './AllergyTable';
import AddSurgeryButton from './AddSurgeryButton';
import SurgeryTable from './SurgeryTable';
import AddMedicalConditionButton from './AddMedicalConditionButton';
import MedicalConditionTable from './MedicalConditionTable';

const PatientMedicalHistoryTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container gap={3}>
      <Grid item>
        <Typography variant='h6' component='h2'>
          Medical History
        </Typography>
      </Grid>

      <Grid item container alignContent='center' justifyContent='space-between' sx={{ mt: 1 }}>
        <Typography component='h3' sx={{ fontSize: '1.1em', fontWeight: '500' }}>
          Allergies
        </Typography>
        {hasAccess.addMedicalHistory && <AddAllergyButton mpi={mpi} />}
      </Grid>

      <Grid item xs={12}>
        <AllergyTable mpi={mpi} />
      </Grid>

      <Grid item container alignContent='center' justifyContent='space-between'>
        <Typography component='h3' sx={{ fontSize: '1.1em', fontWeight: '500' }}>
          Surgeries
        </Typography>
        {hasAccess.addMedicalHistory && <AddSurgeryButton mpi={mpi} />}
      </Grid>

      <Grid item xs={12}>
        <SurgeryTable mpi={mpi} />
      </Grid>

      <Grid item container alignContent='center' justifyContent='space-between'>
        <Typography component='h3' sx={{ fontSize: '1.1em', fontWeight: '500' }}>
          Medical Conditions
        </Typography>
        {hasAccess.addMedicalHistory && <AddMedicalConditionButton mpi={mpi} />}
      </Grid>

      <Grid item xs={12}>
        <MedicalConditionTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientMedicalHistoryTab;
