export default {
  PATIENT_REQUEST: 'Patient Request',
  BILLING_REQUEST: 'Billing Request',
  DOCTOR_REQUEST: 'Doctor Request',
  PATIENT_DECEASED: 'Patient Deceased',
  NQ_DUPLICATE_ORDER: 'NQ-Duplicate Order',
  NQ_REMOVED_BY_PARTNER: 'NQ-Removed by Partner',
  NQ_TEST: 'NQ-Test',
  NQ_STATE: 'NQ-State',
};
