import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import WorkQueues from 'enums/workQueue.enum';

import OrderVerificationFilterButton from './OrderVerificationFilterButton';
import OrderVerificationStatusBar from './OrderVerificationFilterStatusBar';
import OrderVerificationTable from './OrderVerificationTable';

const OrderVerification = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.ORDER_VERIFICATION) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <OrderVerificationStatusBar />
      </Grid>
      <Grid item>
        <OrderVerificationFilterButton />
      </Grid>
      <OrderVerificationTable />
    </Grid>
  );
};

export default OrderVerification;
