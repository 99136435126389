import React from 'react';
import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isBoolean, isEmpty, values } from 'lodash';

import { updateSigAssociationsFilter } from 'store/thunks/sigThunks';
import { SigFieldKeys } from 'enums/SigManagement.enum';

const SigsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ sigs: { filter: sigFilters } }) => sigFilters);

  const removeFilter = (filterName) => () => {
    dispatch(
      updateSigAssociationsFilter({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(updateSigAssociationsFilter({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter?.sig && (
        <Chip
          label={`Sig = ${filter.sig}`}
          variant='contained'
          onDelete={removeFilter(SigFieldKeys.SIG)}
        />
      )}

      {filter?.direction && (
        <Chip
          label={`Direction = ${filter.direction}`}
          variant='contained'
          onDelete={removeFilter(SigFieldKeys.DIRECTION)}
        />
      )}

      {filter?.createdAt && (
        <Chip
          label={`Created At = ${format(new Date(filter.createdAt), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SigFieldKeys.CREATED_AT)}
        />
      )}

      {filter?.updatedAt && (
        <Chip
          label={`Updated At = ${format(new Date(filter.updatedAt), 'MM/dd/yyyy')} `}
          variant='contained'
          onDelete={removeFilter(SigFieldKeys.UPDATED_AT)}
        />
      )}

      {filter?.status && (
        <Chip
          label={`Status = ${filter.status}`}
          variant='contained'
          onDelete={removeFilter(SigFieldKeys.STATUS)}
        />
      )}

      {!isEmpty(compact(values(filter))) && !isBoolean(compact(values(filter))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default SigsFilterStatusBar;
