import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getPatientMedicalHistory, listPatientMedications } from 'store/thunks/patientThunks';
import useTabContext from 'common/hooks/useTabContext';

import PatientMedicationsTab from './Medications/PatientMedicationsTab';
import PatientMedicalHistoryTab from './MedicalHistory/PatientMedicalHistoryTab';

const MedicalHistoryTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const isActiveTab = useTabContext().tab === 'PatientMedicalHistoryTab';

  useEffect(() => {
    if (isActiveTab) {
      dispatch(getPatientMedicalHistory({ mpi }));
      dispatch(listPatientMedications({ mpi }));
    }
  }, [mpi, dispatch, isActiveTab]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <PatientMedicationsTab />
      </Grid>

      <Grid item>
        <PatientMedicalHistoryTab />
      </Grid>
    </Grid>
  );
};

export default MedicalHistoryTab;
