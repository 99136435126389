import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, omit, values } from 'lodash';

import { filterReviewAdjudications } from 'store/thunks/workQueueThunks';

const ReviewAdjudicationFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.reviewAdjudication.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterReviewAdjudications({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterReviewAdjudications({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.patientName && (
        <Chip
          label={`Patient Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.adjudicatedTime && (
        <Chip
          label={`Adjudicated Time = ${format(new Date(filter.adjudicatedTime), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('adjudicatedTime')}
        />
      )}

      {filter.rxNumber && (
        <Chip
          label={`Rx Number = ${filter.rxNumber}`}
          variant='contained'
          onDelete={removeFilter('rxNumber')}
        />
      )}

      {filter.lastFillDate && (
        <Chip
          label={`Last Fill Date = ${format(new Date(filter.lastFillDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('lastFillDate')}
        />
      )}

      {filter.adjudicationStatus && (
        <Chip
          label={`Name = ${filter.adjudicationStatus}`}
          variant='contained'
          onDelete={removeFilter('adjudicationStatus')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, ['orderStatus'])))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default ReviewAdjudicationFilterStatusBar;
