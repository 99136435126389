/* eslint-disable import/prefer-default-export */
import jwtDecode from 'jwt-decode';
import { throttle } from 'lodash';
import * as Sentry from '@sentry/react';

import { authActions } from 'store/slices/authSlice';
import { patientActions } from 'store/slices/patientSlice';
import { patientsListActions } from 'store/slices/patientsListSlice';
import { ordersListActions } from 'store/slices/ordersListSlice';
import { partnerActions } from 'store/slices/partnerSlice';
import { getCookie, setCookie } from 'api/utils';
import { logoutApi, refreshTokenApi } from 'api/auth';

export const resetAppState = () => (dispatch) => {
  setCookie({ name: 'pomAccessToken', value: '', expDays: -1 });
  setCookie({ name: 'tokenSfgp', value: '', expDays: -1 });
  dispatch(authActions.resetAuthState());
  dispatch(patientActions.resetPatientState());
  dispatch(patientsListActions.resetPatientsListState());
  dispatch(partnerActions.resetPartnerState());
  dispatch(ordersListActions.resetOrdersListState());
};

export const signOut = () => (dispatch) => {
  logoutApi();
  return dispatch(resetAppState());
};

export const loadUserFromJwt = () => (dispatch) => {
  const jwt = getCookie('pomAccessToken');

  const userData = jwtDecode(jwt);
  dispatch(authActions.loadUserData({ userData }));
  Sentry.setUser({ id: userData.userId, ip_address: '{{auto}}' });
};

export const refreshJwt = () => (dispatch) => {
  return refreshTokenApi()
    .then(() => dispatch(loadUserFromJwt()))
    .catch(() => {
      dispatch(resetAppState());
      throw new Error('Session Expired');
    });
};

const throttledRefresh = throttle(({ dispatch }) => dispatch(refreshJwt()), 5000, {
  trailing: false,
});

export const checkAndRefreshSession = () => async (dispatch) => {
  const jwt = getCookie('pomAccessToken');

  if (!jwt) {
    dispatch(resetAppState());
    throw new Error('No Existing Session');
  }

  return throttledRefresh({ dispatch });
};
