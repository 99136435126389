import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import loadingGear from 'assets/illustrations/loadingGear.json';

const LoadingModule = ({ height }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container justifyContent='center' sx={{ height: '100%', px: 2 }}>
      <Grid container direction='column' sx={{ maxWidth: theme.maxWidth / 2, mt: '5vh' }}>
        <Grid item>
          <Typography
            variant={/* istanbul ignore next */ isMobile ? 'h6' : 'h5'}
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              color: theme.palette.customTheme.headerText,
            }}
          >
            Loading...
          </Typography>
        </Grid>

        <Grid item>
          <Lottie
            animationData={loadingGear}
            loop
            style={{
              height,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

LoadingModule.propTypes = {
  height: PropTypes.string,
};

LoadingModule.defaultProps = {
  height: '50vh',
};

export default LoadingModule;
