import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import MarkdownField from 'common/forms/MarkdownField';
import { validateMaxLength } from 'common/forms/formValidations';
import { addFailedAdjudicationNotes } from 'store/thunks/workQueueThunks';
import { ContentType } from 'common/constants/notes';

const AddNoteDialog = ({ contextId, contextType, mpi, open, onClose }) => {
  const dispatch = useDispatch();

  const closeDialog = () => {
    onClose();
  };

  const addNote = (data) => {
    const content = {
      contentType: ContentType.TEXT,
      message: data.message,
    };
    const commentContext = {
      id: contextId,
      type: contextType,
    };

    return dispatch(addFailedAdjudicationNotes({ mpi, commentContext, content, contextId })).then(
      (success) => {
        if (success) {
          closeDialog();
        }
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-describedby='WorkQueue-ReviewFailedAdjudication-AddNoteDialog-header'
      fullWidth
    >
      <DialogTitle id='WorkQueue-ReviewFailedAdjudication-AddNoteDialog-header'>
        Add Note
      </DialogTitle>

      <Form
        onSubmit={addNote}
        render={({ submitting, handleSubmit }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <MarkdownField
                  name='message'
                  id='WorkQueue-ReviewFailedAdjudication-AddNoteDialog-input'
                  validations={[
                    validateMaxLength('The note is too long. Please make it shorter', 65435),
                  ]}
                  label='Note:'
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={closeDialog} color='secondary' size='medium' variant='outlined'>
                  Cancel
                </Button>
                <LoadingButton loading={submitting} type='submit' variant='contained'>
                  Submit
                </LoadingButton>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};

AddNoteDialog.propTypes = {
  contextId: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddNoteDialog;
