/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';

import { listAllEventTypesGql, listAuditEventsGql } from './gql';
import graphqlClient from '../graphql';
import { transformEvents, transformListAuditEvents } from './transform';

const buildEventFilters = (filter) =>
  reduce(
    filter,
    (result, value, key) => ({
      ...result,
      [key]: isNull(value) ? undefined : value,
    }),
    {}
  );

export const getEventsApi = ({ sortBy, filter, page }) => {
  return graphqlClient
    .batchRequests([
      { document: listAllEventTypesGql },
      {
        document: listAuditEventsGql,
        variables: {
          offset: (page - 1) * 25,
          sortBy,
          filter: buildEventFilters(filter),
        },
      },
    ])
    .then(transformEvents);
};

export const listAuditEventsApi = ({ filter, sortBy, page }) => {
  return graphqlClient
    .request(listAuditEventsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: buildEventFilters(filter),
    })
    .then(transformListAuditEvents);
};
