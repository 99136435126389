import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { capitalize, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import {
  getPublicKey,
  listTransactionsByPage,
  sortTransactions,
} from 'store/thunks/transactionsThunk';
import transactionTypes from 'common/constants/transactionTypes';
import CancelOrderButton from 'modules/PatientView/components/EligibilityActionItems/CancelOrderButton';
import useRoles from 'common/hooks/useRoles';
import VoidTransactionButton from './VoidTransactionButton';
import CaptureTransactionButton from './CaptureTransactionButton';
import RefundTransactionButton from './RefundTransactionButton';
import TransactionDetailsViewer from './TransactionDetailsViewer';
import UpdateAndRetryPayment from './UpdateAndRetryPaymentButton';

const TransactionsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage, filter } = useSelector(({ transactions }) => transactions);
  const hasAccess = useRoles();

  const noTypeFiltersApplied = !filter.transactionType;

  const totalPages = useSelector(({ transactions }) => Math.ceil(transactions.count / 25));
  const transactions = pages[currentPage];

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;
    const sortOrder = sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC';

    dispatch(sortTransactions({ sortBy: { sortKey, sortOrder } }));
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didn't change, don't do anything.
      return;
    }

    dispatch(listTransactionsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listTransactionsByPage({ page: 1 }));
    dispatch(getPublicKey());
  }, [dispatch]);

  return (
    <Grid component={Paper} sx={{ py: 1, px: 2 }} container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Transaction Id</TableCell>
                <TableCell>Patient Name</TableCell>
                <TableCell>Transaction Type</TableCell>
                <TableCell
                  sortDirection={
                    sortBy.sortKey === 'transactionDate' ? toLower(sortBy.sortOrder) : false
                  }
                >
                  <TableSortLabel
                    active={sortBy.sortKey === 'transactionDate'}
                    direction={
                      sortBy.sortKey === 'transactionDate' ? toLower(sortBy.sortOrder) : 'asc'
                    }
                    onClick={handleSort}
                    data-head-property='transactionDate'
                  >
                    Transaction Timestamp
                    {sortBy.sortKey === 'transactionDate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {toLower(sortBy.sortOrder) === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell>Credit card</TableCell>
                <TableCell>Amount</TableCell>
                {!noTypeFiltersApplied && <TableCell align='center'>Action</TableCell>}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(transactions, (transaction) => {
                const showNoAction =
                  transaction.transactionType === 'REFUND' ||
                  transaction.transactionType === 'VOID';
                return (
                  <TableRow key={transaction.transactionId} hover>
                    <TableCell component='th' scope='row' align='left'>
                      {transaction.externalTransactionId}
                    </TableCell>
                    <TableCell component='th' scope='row' align='left'>
                      <Link
                        to={`/patients/${transaction.mpi}`}
                        style={{ color: theme.palette.text.primary }}
                      >
                        {transaction.patientName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {capitalize(transactionTypes[transaction.transactionType])}
                    </TableCell>
                    <TableCell>
                      {format(new Date(transaction.transactionTimestamp), 'MM/dd/yyyy hh:mm a')}
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {map(Array(3), (_, index) => (
                          <span key={index}>&bull;&bull;&bull;&bull;&ensp;</span>
                        ))}
                        {transaction.cardNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>{`$${transaction.amount}`}</TableCell>
                    {!noTypeFiltersApplied && (
                      <TableCell>
                        <Grid container justifyContent='center'>
                          {transaction.transactionType === 'AUTHORIZE' && (
                            <>
                              <VoidTransactionButton
                                patientId={transaction.mpi}
                                externalTransactionId={transaction.externalTransactionId}
                                userId={transaction.userId}
                                transactionId={transaction.transactionId}
                              />
                              <CaptureTransactionButton
                                amount={transaction.amount}
                                patientId={transaction.mpi}
                                externalTransactionId={transaction.externalTransactionId}
                                userId={transaction.userId}
                                transactionId={transaction.transactionId}
                              />
                            </>
                          )}
                          {transaction.transactionType === 'CAPTURE' && (
                            <RefundTransactionButton
                              amount={transaction.amount}
                              patientId={transaction.mpi}
                              externalTransactionId={transaction.externalTransactionId}
                              userId={transaction.userId}
                              transactionId={transaction.transactionId}
                            />
                          )}

                          {transaction.transactionType === 'FAILED' && (
                            <>
                              {hasAccess.cancelOrder && (
                                <CancelOrderButton
                                  mpi={transaction.mpi}
                                  orderId={transaction.orderId}
                                  transactionId={transaction.externalTransactionId}
                                />
                              )}

                              <UpdateAndRetryPayment
                                amount={transaction.amount}
                                mpi={transaction.mpi}
                                orderId={transaction.orderId}
                                userId={transaction.userId}
                                transactionId={transaction.externalTransactionId}
                              />
                            </>
                          )}

                          {showNoAction && <Typography>NA</Typography>}
                        </Grid>
                      </TableCell>
                    )}
                    <TableCell sx={{ py: 1 }} align='right'>
                      <TransactionDetailsViewer transaction={transaction} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            {!isNil(transactions) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(transactions) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(transactions) && isEmpty(transactions) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default TransactionsTable;
