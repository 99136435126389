import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  pages: {},
  count: 0,
  filter: {
    status: undefined,
    gender: undefined,
    createdAfter: undefined,
    dob: undefined,
    name: undefined,
  },
  sortBy: {
    sortKey: 'createdAt',
    sortOrder: 'DESC',
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const ordersListSlice = createSlice({
  name: 'ordersList',
  initialState,
  reducers: {
    resetOrdersListState(state) {
      Object.assign(state, initialState);
    },
    updateCurrentPage(state, action) {
      const { page } = action.payload;

      state.currentPage = page;
    },
    loadOrdersToPage(state, action) {
      const { page, orders, count } = action.payload;

      state.count = count;
      state.pages[page] = orders;
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.sortBy = sortBy;
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.filter = filter;
    },
  },
});

export const ordersListActions = ordersListSlice.actions;
export default ordersListSlice.reducer;
