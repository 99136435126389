import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import ProductFulfillmentStatusBar from './ProductFulfillmentFilterStatusBar';
import ProductFulfillmentFilterButton from './ProductFulfillmentFilterButton';
import ProductFulfillmentTable from './ProductFulfillmentTable';

const ProductFulfillment = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.PRODUCT_FULFILLMENT) return null;
  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <ProductFulfillmentStatusBar />
      </Grid>
      <Grid item>
        <ProductFulfillmentFilterButton />
      </Grid>
      <ProductFulfillmentTable />
    </Grid>
  );
};

export default ProductFulfillment;
