import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import InClaimEligibilityTable from './InClaimEligibilityTable';
import InClaimEligibilityFilterButton from './InClaimEligibilityFilterButton';
import InClaimEligibilityFilterStatusBar from './InClaimEligibilityFilterStatusBar';

const InClaimEligibility = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.IN_CLAIM_ELIGIBILITY) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <InClaimEligibilityFilterStatusBar />
      </Grid>
      <Grid item>
        <InClaimEligibilityFilterButton />
      </Grid>
      <InClaimEligibilityTable />
    </Grid>
  );
};

export default InClaimEligibility;
