import { Box, IconButton, InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';
import { trim } from 'lodash';

import useInfoDialog from 'common/hooks/useInfoDialog';

const PatientCommentSearchInput = ({ onSubmit }) => {
  const theme = useTheme();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const handleCommentsSearch = (event) => {
    event.preventDefault();

    const searchText = event.target.elements.searchText.value;

    // the !searchText.length condition is to allow user to remove search filter when they do blank string
    if (trim(searchText).length >= 3 || !searchText.length) {
      onSubmit({ text: searchText });
    } else {
      showInfoDialog({
        title: 'Error',
        message: 'Minimum 3 characters needed for search',
      });
    }
  };

  return (
    <Box
      component='form'
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 200,
        marginRight: theme.spacing(1),
        border: `1px solid`,
        borderColor: 'divider',
        borderRadius: 1,
      }}
      onSubmit={handleCommentsSearch}
    >
      <InfoDialog />
      <InputBase
        inputProps={{
          autoComplete: 'off',
          'aria-label': 'search by comment text',
        }}
        sx={{ border: 'none', px: theme.spacing(2) }}
        type='text'
        placeholder='Search Comments'
        name='searchText'
      />
      <IconButton type='submit' aria-label='search comment'>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

PatientCommentSearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PatientCommentSearchInput;
