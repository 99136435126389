import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import ReadyToShipFilterStatusBar from './ReadyToShipFilterStatusBar';
import ReadyToShipFilterButton from './ReadyToShipFilterButton';
import ReadyToShipTable from './ReadyToShipTable';

const ReadyToShip = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.READY_TO_SHIP) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <ReadyToShipFilterStatusBar />
      </Grid>
      <Grid item>
        <ReadyToShipFilterButton />
      </Grid>
      <ReadyToShipTable />
    </Grid>
  );
};

export default ReadyToShip;
