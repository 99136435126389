import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';

import useRoles from 'common/hooks/useRoles';
import { reverifyOrder } from 'store/thunks/patientThunks';

const ReverifyOrderButton = ({ mpi, orderId }) => {
  const hasAccess = useRoles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const sendToReVerifiedState = () => {
    setIsLoading(true);
    dispatch(reverifyOrder({ mpi, orderId }))
      .then((isReVerified) => {
        if (isReVerified) {
          handleCloseModal();
          enqueueSnackbar(`Sent order ${orderId} back to verification`, {
            variant: 'success',
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='ReverifyOrderDialog-header'
        fullWidth
      >
        <Grid container sx={{ px: 3, py: 2 }} justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' component='h2' id='ReverifyOrderDialog-header'>
            Reverify Order
          </Typography>
          <IconButton onClick={handleCloseModal} aria-label='close dialog'>
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent>Are you sure you want to move order back to verification ?</DialogContent>

        <DialogActions>
          <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
            Cancel
          </Button>
          <LoadingButton loading={isLoading} variant='contained' onClick={sendToReVerifiedState}>
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <Button
        size='small'
        color='secondary'
        variant='outlined'
        onClick={handleOpenModal}
        disabled={!hasAccess.reverifyOrder}
        sx={{ mr: 2 }}
      >
        Reverify Order
      </Button>
    </>
  );
};

export default ReverifyOrderButton;

ReverifyOrderButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};
