import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useSnackbar } from 'notistack';
import { upsertSigAssociations } from 'store/thunks/sigThunks';

import ManageSigModal from './ManageSigModal';

const AddSigButton = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const popoverId = anchorEl ? 'DrugFilterButton-Popover' : undefined;

  const handleOpenModal = (event) => {
    setShowModal(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleCreateSigAssociations = (data) => {
    const sigPayload = {
      sig: data?.sig,
      sigStatus: true,
      associatedDirections: data.associations,
    };

    setFormLoading(true);
    dispatch(upsertSigAssociations({ input: sigPayload }))
      .then((response) => {
        if (response) {
          enqueueSnackbar('Sig Created Successfully', { variant: 'success' });
          handleCloseModal();
          setFormLoading(false);
        }
      })
      .finally(() => setFormLoading(false));
  };

  return (
    <>
      <Button
        sx={{ height: 33, mr: 2 }}
        variant='contained'
        color='primary'
        onClick={handleOpenModal}
        aria-describedby={popoverId}
      >
        Add New
      </Button>

      {showModal && (
        <ManageSigModal
          showModal={showModal}
          loading={formLoading}
          handleCloseModal={handleCloseModal}
          handleSigAssociations={handleCreateSigAssociations}
        />
      )}
    </>
  );
};

export default AddSigButton;
