import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useTabContext from 'common/hooks/useTabContext';
import { getPatientPharmacies, getPatientProviders } from 'store/thunks/patientThunks';
import PatientProvidersTab from './Providers/PatientProvidersTab';
import PatientPharmaciesTab from './Pharmacies/PatientPharmaciesTab';

const ProvidersTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const isActiveTab = useTabContext().tab === 'PatientProvidersTab';

  useEffect(() => {
    if (isActiveTab) {
      dispatch(getPatientProviders({ mpi }));
      dispatch(getPatientPharmacies({ mpi }));
    }
  }, [dispatch, mpi, isActiveTab]);

  if (!isActiveTab) {
    return null;
  }

  return (
    <Grid container direction='column'>
      <Grid item>
        <PatientProvidersTab />
      </Grid>

      <Grid item>
        <PatientPharmaciesTab />
      </Grid>
    </Grid>
  );
};

export default ProvidersTab;
