import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { LoadingButton } from '@mui/lab';

import { reviewLineItem } from 'store/thunks/patientThunks';

import ReviewFailureDialog from '../EligibilityActionItems/ReviewFailureDialog';

const ReviewPrescription = ({ mpi, lineItemId, requestId, reviewReason }) => {
  const [showFailDialog, setShowFailDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleWillNotFill = () => {
    setShowFailDialog(true);
  };

  const handleProceed = () => {
    setLoading(true);
    dispatch(reviewLineItem({ mpi, lineItemId, reviewResult: true, requestId }));
  };

  const closeFailDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowFailDialog(false);
    }
  };

  const confirmFailureDialog = ({ reasonForRejection }) => {
    return dispatch(
      reviewLineItem({ mpi, lineItemId, reviewResult: false, reasonForRejection, requestId })
    ).then(() => {
      closeFailDialog();
    });
  };

  return (
    <>
      <ReviewFailureDialog
        confirmFailure={confirmFailureDialog}
        open={showFailDialog}
        onClose={closeFailDialog}
      />

      <Grid container gap={2} direction='column'>
        <Typography variant='h6' component='h4'>
          Review Reason
        </Typography>
        {map(reviewReason, (reason) => (
          <Typography key={reason}> {reason}</Typography>
        ))}
      </Grid>
      <Grid container direction='column'>
        <Typography>
          If there are no issues with the prescription, please proceed. Otherwise the prescription
          will not be filled.
        </Typography>
      </Grid>
      <Grid container direction='row-reverse' sx={{ mt: 4 }}>
        <LoadingButton
          loading={loading}
          variant='contained'
          sx={{ width: 100 }}
          onClick={handleProceed}
        >
          Proceed
        </LoadingButton>
        <Button
          sx={{ mr: 2, width: 100 }}
          variant='contained'
          color='error'
          onClick={handleWillNotFill}
        >
          Will Not Fill
        </Button>
      </Grid>
    </>
  );
};

ReviewPrescription.propTypes = {
  mpi: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  reviewReason: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ReviewPrescription;
