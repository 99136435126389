import { createSlice } from '@reduxjs/toolkit';
import { filter as lodashFilter } from 'lodash';

const initialState = {
  currentPage: 1,
  pages: {},
  count: 0,
  filter: {
    patientName: undefined,
    transactionType: 'AUTHORIZE',
    externalTransactionId: undefined,
    transactionDate: undefined,
  },
  sortBy: {
    sortKey: 'transactionDate',
    sortOrder: 'DESC',
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    loadTransactions(state, action) {
      const { page, transactions, count } = action.payload;

      state.pages[page] = transactions;
      state.count = count;
    },
    updateTransactionsCurrentPage(state, action) {
      const { page } = action.payload;
      state.currentPage = page;
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.sortBy = sortBy;
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      state.pages = {}; // Cached data no longer valid, this is to reset it.
      state.count = 0;
      state.filter = filter;
    },
    removeTransaction(state, action) {
      const { transactionId, page } = action.payload;

      state.pages[page] = lodashFilter(
        state.pages[page],
        (transaction) => transaction.transactionId !== transactionId
      );
    },
  },
});

export const transactionActions = transactionSlice.actions;
export default transactionSlice.reducer;
