import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import merge from 'lodash/merge';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { enUS } from '@mui/material/locale';

import commonTheme from './commonTheme';
import smartScriptsTheme from './smartScriptsTheme';

const isTestEnv = process.env.NODE_ENV === 'test';
const envOverride = isTestEnv && { transitions: { create: () => 'none' } };

const getTheme = (theme) => {
  const themeMap = {
    smartScriptsTheme,
  };

  return createTheme(
    merge(
      commonTheme,
      themeMap[theme],
      envOverride // override theme (transitions) during test, for performance
    ),
    enUS
  );
};

const ThemeProvider = ({ children }) => {
  const theme = useSelector(({ app }) => app.theme);

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ThemeProvider;
