import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import { map } from 'lodash';
import { LoadingButton } from '@mui/lab';
import SelectField from 'common/forms/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getPatientUsers, resetPassword } from 'store/thunks/patientThunks';
import { useEffect } from 'react';

const TriggerResetPasswordDialog = ({ open, onClose, mpi }) => {
  const dispatch = useDispatch();
  const patientUsers = useSelector(({ patient }) => patient[mpi]?.users || []);
  const { enqueueSnackbar } = useSnackbar();

  const handleResetPassword = ({ user }) => {
    return dispatch(resetPassword({ user })).then(() => {
      onClose();
      enqueueSnackbar('Password Reset Email Sent!', { variant: 'success' });
    });
  };

  useEffect(() => {
    if (open) {
      dispatch(getPatientUsers({ mpi }));
    }
  }, [mpi, dispatch, open]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Trigger Password Reset</DialogTitle>

      <Form
        onSubmit={handleResetPassword}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: '500px' }}>
              <SelectField id='ResetPassword-user-dropdown' label='User' name='user' required>
                {map(patientUsers, ({ userName, email, userId }) => {
                  return (
                    <MenuItem
                      key={userId}
                      value={userId}
                      aria-label={`User ${userName} user email ${email}`}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <Typography variant='subtitle1'>{userName}</Typography>
                          <Typography variant='body2'>{email}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  );
                })}
              </SelectField>
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                type='submit'
                disabled={invalid}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

TriggerResetPasswordDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TriggerResetPasswordDialog;
