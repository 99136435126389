import React, { useEffect, useState } from 'react';
import MultiDatePicker from 'react-multi-date-picker';
import { FormHelperText, Grid, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { find, includes, isEmpty, isEqual, map } from 'lodash';
import moment from 'moment';

import MultipleSelectField from 'common/forms/MultipleSelectField';
import { updatePrescriptionOrdersFilter } from 'store/thunks/orderStatusThunks';
import { OrderType } from 'enums/requestStatus.enum';

const StyledDatePicker = styled(MultiDatePicker)(({ theme }) => ({
  '& .rmdp-week-day': {
    color: theme.palette.primary.main,
    cursor: 'default',
    fontSize: '13px',
    fontWeight: 500,
  },
  '& .rmdp-input:focus': {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  '& .rmdp-range': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 0 3px #fff',
    color: '#fff',
  },
  '& .rmdp-range-hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .rmdp-day.rmdp-today span': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    borderColor: '#fff',
  },
  '& .rmdp-day:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '& .rmdp-day:not(.rmdp-disabled,.rmdp-day-hidden) span:hover': {
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
  },
  '& .rmdp-arrow-container:hover': {
    backgroundColor: theme.palette.primary.light,
    boxShadow: '0 0 3px #8798ad',
  },
  '& .rmdp-arrow': {
    border: `solid ${theme.palette.primary.light}`,
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    height: '3px',
    marginTop: '5px',
    padding: '3px',
    width: '3px',
  },
}));

const EScriptOrdersOuterFilters = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState([]);
  const [isValidDate, setIsValidDate] = useState(true);
  const [selectedPartner, setSelectedPartner] = useState('ALL');
  const [prevDateRange, setPrevDateRange] = useState(null);
  const displayedHelperText = !isValidDate ? 'Please select a valid date' : '';

  const partners = useSelector(
    ({ auth, partner: partnerStore }) => partnerStore[auth.currentTenantId]
  );
  const partnerNames = map(partners, (partner) => partner.partnerName);
  const filter = useSelector(({ orderStatus: { eScriptOrders } }) => eScriptOrders.filter);

  const onHandleDateChange = (data) => {
    setSelectedDate(data);
    const dates = data?.map((sDate) => sDate.toDate?.().toString());

    if (!includes(dates, 'Invalid Date')) {
      setIsValidDate(true);
      const dateRange = dates?.map((date) => moment(date).format('YYYY-MM-DD'));
      setPrevDateRange(dateRange);

      const startDate = !isEmpty(dateRange) && dateRange[0];
      const endDate = !isEmpty(dateRange) && dateRange[1];

      if (!isEqual(dateRange, prevDateRange) && !isEqual(startDate, endDate)) {
        dispatch(
          updatePrescriptionOrdersFilter({
            filter: {
              ...filter,
              ...(dateRange && { createdAt: dateRange }),
              orderType: OrderType.E_SCRIPT,
            },
          })
        );
      }
    } else {
      setIsValidDate(false);
    }
  };

  const onHandlePartnerFilter = (value) => {
    setSelectedPartner(value);
    dispatch(
      updatePrescriptionOrdersFilter({
        filter: {
          ...filter,
          partnerId:
            value === 'ALL'
              ? undefined
              : find(partners, (partner) => partner?.partnerName === value)?.partnerUuid,
        },
      })
    );
  };

  useEffect(() => {
    if (!filter?.partnerId) {
      setSelectedPartner('ALL');
    }

    if (!filter?.createdAt) {
      setSelectedDate([]);
    }
  }, [filter]);

  return (
    <Grid container gap={2}>
      <Grid item>
        <MultipleSelectField
          id='Partner-Multiple-Select'
          name='partner'
          options={[...partnerNames, 'ALL']}
          label='Partner'
          selectedValue={selectedPartner}
          onChange={onHandlePartnerFilter}
        />
      </Grid>

      <Grid item>
        <InputLabel shrink id='' sx={{ fontSize: '1.2em' }}>
          Date range
        </InputLabel>
        <StyledDatePicker
          name='dateRange'
          placeholder='MM-DD-YYYY ~ MM-DD-YYYY'
          format='MM-DD-YYYY'
          style={{
            width: '100%',
            minWidth: 210,
            height: '35px',
            padding: 10,
            margin: 1,
            borderColor: !isValidDate && 'red',
          }}
          calendarPosition='bottom-center'
          range
          rangeHover
          value={selectedDate}
          onChange={onHandleDateChange}
        />
        <FormHelperText sx={{ color: 'red', pl: 1 }}>{displayedHelperText}</FormHelperText>
      </Grid>
    </Grid>
  );
};

export default EScriptOrdersOuterFilters;
