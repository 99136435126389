import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';

import useRoles from 'common/hooks/useRoles';
import useTabContext from 'common/hooks/useTabContext';
import { getPatientAddresses, listPatientOrderShipments } from 'store/thunks/patientThunks';

import PatientAddressesCard from './PatientAddressesCards';
import NewPatientAddressButton from './NewPatientAddressButton';
import ShipmentFilter from './ShipmentFilter';
import ShipmentTable from './ShipmentTable';

const PatientShippingTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const isActiveTab = useTabContext().tab === 'PatientShippingTab';

  useEffect(() => {
    if (isActiveTab) {
      dispatch(getPatientAddresses({ mpi }));
      dispatch(listPatientOrderShipments({ mpi }));
    }
  }, [dispatch, mpi, isActiveTab]);

  if (!isActiveTab) {
    return null;
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Addresses
        </Typography>
        {hasAccess.addPatientAddress && <NewPatientAddressButton mpi={mpi} />}
      </Grid>

      <Grid item>
        <PatientAddressesCard mpi={mpi} />
      </Grid>

      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Shipments
        </Typography>
        <ShipmentFilter mpi={mpi} />
      </Grid>

      <Grid item>
        <ShipmentTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientShippingTab;
