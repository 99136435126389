import { forwardRef, useMemo } from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import QueueIcon from '@mui/icons-material/Grading';
import PatientsIcon from '@mui/icons-material/People';
import OrdersIcon from '@mui/icons-material/LocalShipping';
import EventsIcon from '@mui/icons-material/Event';
import TransactionsIcon from '@mui/icons-material/ListAlt';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import useRoles from 'common/hooks/useRoles';
import { split } from 'lodash';

const NavigationLink = ({ to, label, Icon }) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const match = split(pathname, '/')[1] === split(to, '/')[1];

  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line react/no-unstable-nested-components
      forwardRef((itemProps, ref) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />
      )),
    [to]
  );

  return (
    <li>
      <Tooltip title={label} placement='right' arrow>
        <ListItemButton
          component={renderLink}
          sx={{
            bgcolor: match && `${lighten(theme.palette.primary.main, 0.85)} !important`,
            px: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ListItemIcon
            sx={{
              color: match ? theme.palette.primary.main : 'white',
              minWidth: 0,
            }}
          >
            <Icon />
          </ListItemIcon>
        </ListItemButton>
      </Tooltip>
    </li>
  );
};

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

const NavigationBar = () => {
  const theme = useTheme();
  const {
    viewEvents: hasEventsAccess,
    viewWorkQueue: hasWorkQueueAccess,
    viewAdminCenter: hasAdminCenterAccess,
    viewPaymentTransactions: hasTransactionsAccess,
  } = useRoles();

  return (
    <Drawer
      variant='permanent'
      PaperProps={{
        sx: {
          width: `calc(${theme.spacing(6)} + 1px)`,
          bgcolor: theme.palette.customTheme.navigationBar,
          overflow: 'hidden',
        },
        elevation: 4,
      }}
    >
      <nav aria-label='navigation list'>
        <List>
          {hasWorkQueueAccess && <NavigationLink to='/' label='Work Queue' Icon={QueueIcon} />}
          <NavigationLink to='/patients' label='Patients' Icon={PatientsIcon} />
          <NavigationLink to='/orders' label='Orders' Icon={OrdersIcon} />
          {hasEventsAccess && <NavigationLink to='/events' label='Events' Icon={EventsIcon} />}
          {hasAdminCenterAccess && (
            <NavigationLink to='/adminCenter' label='Admin Center' Icon={SettingsOutlinedIcon} />
          )}
          {hasTransactionsAccess && (
            <NavigationLink to='/transactions' label='Transactions' Icon={TransactionsIcon} />
          )}
        </List>
      </nav>
    </Drawer>
  );
};

export default NavigationBar;
