import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, map, omit } from 'lodash';

import TextField from 'common/forms/TextField';
import AutoCompleteField from 'common/forms/AutoCompleteField';
import DrugNameAutoSuggest from 'common/forms/DrugNameAutoSuggest';
import { validatePhoneNumber } from 'common/forms/formValidations';
import { updatePatientMedication } from 'store/thunks/patientThunks';
import SelectField from 'common/forms/SelectField';
import dawCodes from 'common/constants/dawCodes';

const EditMedicationDialog = ({ mpi, patientMedicationId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) =>
    find(patient[mpi].medications, { patientMedicationId })
  );

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleUpdatePatientMedication = (formData) => {
    return dispatch(
      updatePatientMedication({
        medication: {
          mpi,
          ...formData,
          instructions: formData.instructions || '',
          providerName: formData.providerName || '',
          dawCode: parseInt(formData.dawCode, 10),
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditMedicationDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditMedicationDialog-header'>Edit Medication</DialogTitle>

      <Form
        initialValues={{
          ...omit(initialValues, ['name', 'prescriber', 'pharmacy']),
          medicationName: initialValues?.name,
          providerName: initialValues?.prescriber?.name,
          pharmacyName: initialValues?.pharmacy.pharmacyName,
          pharmacyPhone: initialValues?.pharmacy.pharmacyPhoneNumber,
        }}
        onSubmit={handleUpdatePatientMedication}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <DrugNameAutoSuggest
                id='PatientView-EditMedicationDialog-medicationName-input'
                name='medicationName'
                label='Medication Name'
                required
                autoFocus
                prefillNdcField='ndc'
              />

              <TextField
                id='PatientView-EditMedicationDialog-ndc-input'
                label='NDC'
                name='ndc'
                required
              />

              <SelectField
                label='DAW'
                name='dawCode'
                id='PatientView-EditMedicationDialog-daw-dropdown'
                required
              >
                {map(dawCodes, (description, key) => (
                  <MenuItem key={key} value={key}>
                    {description}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField
                id='PatientView-EditMedicationDialog-strength-input'
                label='Strength'
                name='strength'
                required
              />

              <AutoCompleteField
                id='PatientView-EditMedicationDialog-form-input'
                name='form'
                label='Form'
                options={['Tablet', 'Capsule']}
                freeSolo
                required
              />

              <TextField
                id='PatientView-EditMedicationDialog-quantity-input'
                label='Quantity'
                name='quantity'
                required
              />

              <TextField
                id='PatientView-EditMedicationDialog-instructions-input'
                label='Instructions'
                name='instructions'
                maxLength={256}
              />

              <TextField
                id='PatientView-EditMedicationDialog-prescribedBy-input'
                label='Prescribed By'
                name='providerName'
              />

              <TextField
                id='PatientView-EditMedicationDialog-pharmacyName-input'
                label='Pharmacy Name'
                name='pharmacyName'
                required
              />

              <TextField
                id='PatientView-EditMedicationDialog-pharmacyPhone-input'
                label='Pharmacy Phone'
                name='pharmacyPhone'
                required
                validations={[validatePhoneNumber()]}
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditMedicationDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientMedicationId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditMedicationDialog;
