export const NoteState = {
  PIN: 'PIN',
  UNPIN: 'UNPIN',
};

export const ContextType = {
  LINE_ITEM: 'LINE_ITEM',
  PRESCRIPTION: 'PRESCRIPTION',
  REQUEST_LINE_ITEM: 'REQUEST_LINE_ITEM',
};

export const ContentType = {
  TEXT: 'TEXT',
};
