import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

import WorkQueues from 'enums/workQueue.enum';

import PatientQualificationTable from './PatientQualificationTable';
import PatientQualificationFilterButton from './PatientQualificationFilterButton';
import PatientQualificationFilterStatusBar from './PatientQualificationFilterStatusBar';

const PatientQualification = () => {
  const preset = useSelector(({ workQueue }) => workQueue.preset);

  if (preset !== WorkQueues.PATIENT_QUALIFICATION) return null;

  return (
    <Grid container sx={{ py: 2 }}>
      <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
        <PatientQualificationFilterStatusBar />
      </Grid>
      <Grid item>
        <PatientQualificationFilterButton />
      </Grid>
      <PatientQualificationTable />
    </Grid>
  );
};

export default PatientQualification;
