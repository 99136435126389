/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';
import graphqlClient from 'api/graphql';

import { listDrugProfilesByDrugNameGql, listDrugProfilesGql, upsertDrugProfileGql } from './gql';

export const listDrugApi = ({ page, sortBy, filter }) => {
  const defaultItemsPerPage = Number(process.env.REACT_APP_DEFAULT_ITEMS_PER_PAGE);
  const defaultPageOffset = Number(process.env.REACT_APP_DEFAULT_PAGE_OFFSET);

  return graphqlClient
    .request(listDrugProfilesGql, {
      limit: defaultItemsPerPage,
      offset: (page - 1) * defaultItemsPerPage || defaultPageOffset,
      sortBy,
      filter: reduce(
        filter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(({ listDrugProfiles }) => listDrugProfiles);
};

export const upsertDrugProfileApi = (input) => {
  return graphqlClient
    .request(upsertDrugProfileGql, { input })
    .then(({ upsertDrugProfile }) => upsertDrugProfile);
};

export const listDrugProfilesByDrugNameApi = (drugName) => {
  return graphqlClient
    .request(listDrugProfilesByDrugNameGql, { drugName })
    .then(({ listDrugProfilesByDrugName }) => listDrugProfilesByDrugName);
};
