import React from 'react';
import { Grid } from '@mui/material';

import AddSigButton from './components/AddSigButton';
import SigsTable from './components/SigsTable';
import SigsFilterButton from './components/SigsFilterButton';
import SigsFilterStatusBar from './components/SigsFilterStatusBar';

const SigManagement = () => {
  return (
    <>
      <Grid
        container
        sx={{ px: 3, py: 2 }}
        direction='row'
        alignItems='center'
        justifyContent='space-between'
      >
        <Grid item sx={{ minHeight: 25, flexGrow: 1 }}>
          <SigsFilterStatusBar />
        </Grid>
        <Grid item>
          <AddSigButton />
          <SigsFilterButton />
        </Grid>
      </Grid>

      <Grid item container sx={{ px: 3 }}>
        <SigsTable />
      </Grid>
    </>
  );
};

export default SigManagement;
