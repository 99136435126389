import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { capitalize, isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import {
  listOrderLineItemsByPage,
  sortOrderLineItemsWorkQueue,
} from 'store/thunks/workQueueThunks';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';

import { SortKey, SortOrder, SortState } from 'enums/common.enum';

const headCells = [
  {
    id: SortKey.PATIENT_NAME,
    label: 'Patient Name',
  },
  {
    id: SortKey.PARTNER_NAME,
    label: 'Partner Name',
  },
  {
    id: SortKey.ORDER_ID,
    label: 'Order Id',
  },
  {
    id: SortKey.ITEM_NAME,
    label: 'Item Name',
  },
  {
    id: SortKey.REQUESTED_DATE,
    label: 'Requested Date',
  },
  {
    id: SortKey.NEEDS_BY_DATE,
    label: 'Needs By Date',
  },
  {
    id: SortKey.ORDER_STATUS,
    label: 'Status',
  },
];

const OrderLineItemsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { sortBy, pages, currentPage } = useSelector(({ workQueue }) => workQueue.orderLineItems);
  const orderLineItems = pages[currentPage];

  useEffect(() => {
    dispatch(listOrderLineItemsByPage({ page: 1 }));
  }, [dispatch]);

  const totalPages = useSelector(({ workQueue }) => Math.ceil(workQueue.orderLineItems.count / 25));

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortOrderLineItemsWorkQueue({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didn't change, don't do anything.
      return;
    }

    dispatch(listOrderLineItemsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={sortBy.sortKey === headCell.id}
                      direction={sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'}
                      onClick={handleSort}
                      data-head-property={headCell.id}
                    >
                      {headCell.label}
                      {sortBy.sortKey === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {toLower(sortBy.sortOrder) === 'desc'
                            ? SortState.SORTED_DESCENDING
                            : SortState.SORTED_ASCENDING}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(orderLineItems, ({ mpi, patientName, partnerName, ...order }) => (
                <TableRow key={order.lineItemId} hover>
                  <TableCell component='th' scope='row' align='left'>
                    <Link to={`/patients/${mpi}`} style={{ color: theme.palette.text.primary }}>
                      {patientName}
                    </Link>
                  </TableCell>
                  <TableCell>{capitalize(partnerName)}</TableCell>
                  <TableCell>{order.orderId}</TableCell>
                  <TableCell>{order.itemName}</TableCell>
                  <TableCell>
                    {format(utcToZonedTime(new Date(order.requestedDate), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>
                    {format(utcToZonedTime(new Date(order.needsByDate), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>{order.orderStatus}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(orderLineItems) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(orderLineItems) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(orderLineItems) && isEmpty(orderLineItems) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderLineItemsTable;
