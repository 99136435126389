import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import EScriptRequestView from './PomRequestView';

const EScriptOrdersOptions = ({ referenceId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = !!anchorEl;
  const [detailedView, setDetailedView] = useState(false);

  const closeRequestDetailedView = () => setDetailedView(false);
  const openRequestDetailedView = () => {
    setDetailedView(true);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  return (
    <>
      <IconButton
        id={`EScriptOrdersOptions-${referenceId}options-button`}
        aria-label={`OrderStatus options ${referenceId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`EScriptOrdersOptions-${referenceId}options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openRequestDetailedView}>View</MenuItem>
      </Menu>

      <EScriptRequestView
        open={detailedView}
        referenceId={referenceId}
        onClose={closeRequestDetailedView}
      />
    </>
  );
};

export default EScriptOrdersOptions;

EScriptOrdersOptions.propTypes = {
  referenceId: PropTypes.string.isRequired,
};
