import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isBoolean, isEmpty, values } from 'lodash';

import { updateDrugProfilesFilter } from 'store/thunks/drugThunks';

import { DrugProfileFieldKeys, DrugProfileStatus } from 'enums/drugManagement.enum';

const DrugsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ drugs: { drugProfiles } }) => drugProfiles.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      updateDrugProfilesFilter({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(updateDrugProfilesFilter({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter?.drugName && (
        <Chip
          label={`Drug Name = ${filter.drugName}`}
          variant='contained'
          onDelete={removeFilter(DrugProfileFieldKeys.DRUG_NAME)}
        />
      )}

      {filter?.createdAt && (
        <Chip
          label={`Created At =  ${format(new Date(filter.createdAt), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(DrugProfileFieldKeys.CREATED_AT)}
        />
      )}

      {filter?.updatedAt && (
        <Chip
          label={`Updated At = ${format(new Date(filter.updatedAt), 'MM/dd/yyyy')} `}
          variant='contained'
          onDelete={removeFilter(DrugProfileFieldKeys.UPDATED_AT)}
        />
      )}

      {filter?.strength && (
        <Chip
          label={`Strength = ${filter.strength}`}
          variant='contained'
          onDelete={removeFilter(DrugProfileFieldKeys.STRENGTH)}
        />
      )}

      {(filter?.isActive || isBoolean(filter?.isActive)) && (
        <Chip
          label={`Status =  ${
            filter.isActive ? DrugProfileStatus.ACTIVE : DrugProfileStatus.INACTIVE
          }`}
          variant='contained'
          onDelete={removeFilter(DrugProfileFieldKeys.STATUS)}
        />
      )}

      {!isEmpty(compact(values(filter))) && !isBoolean(compact(values(filter))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default DrugsFilterStatusBar;
