import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import useRoles from 'common/hooks/useRoles';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { defaultPatientAddress, deletePatientAddress } from 'store/thunks/patientThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { patientActions } from 'store/slices/patientSlice';

import EditPatientAddressDialog from './EditPatientAddressDialog';
import useSmsResetWarningDialog from './useSmsResetWarningDialog';

const PatientAddressOptions = ({ mpi, isDefault, patientAddressId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = !!anchorEl;
  const hasAccess = useRoles();
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const dispatch = useDispatch();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const patientInfo = useSelector(({ patient }) => patient[mpi]);
  const patientAddress = find(patientInfo?.addresses, { patientAddressId })?.address;
  const notificationPreference = patientInfo?.notificationPreferences;
  const { InfoDialog: WarnInfoDialog, showWarning } = useSmsResetWarningDialog();

  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleDeleteAddress = () => {
    handleMenuClose();

    showInfoDialog({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this address?',
      callback: () => dispatch(deletePatientAddress({ mpi, patientAddressId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const dispatchDefaultPatientAddress = () =>
    dispatch(
      defaultPatientAddress({
        mpi,
        patientAddressId,
      })
    );

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };

  const handleCloseEditDialog = () => setShowEditDialog(false);

  const handleResetPhonePreference = () => {
    dispatch(
      patientActions.updatePatientNotificationPreference({
        phone: false,
        email: notificationPreference?.email,
        mpi,
      })
    );
  };

  const handleDefaultAddress = () => {
    handleMenuClose();
    const isDisallowedState = !find(usStateAbbreviation, {
      abbreviation: patientAddress.state,
    })?.isSMSAllowed;

    showWarning({
      warn: isDisallowedState && notificationPreference?.phone,
      callback: () => dispatchDefaultPatientAddress(),
      warnCallback: () => {
        handleResetPhonePreference();
        dispatchDefaultPatientAddress();
      },
    });
  };

  return (
    <>
      <InfoDialog />

      <WarnInfoDialog />

      <EditPatientAddressDialog
        mpi={mpi}
        patientPharmacyId={patientAddressId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
        patientAddressId={patientAddressId}
        isDefault={isDefault}
        key={patientAddressId}
      />

      <IconButton
        id={`PatientView-PatientAddressOptions-${patientAddressId}options-button`}
        aria-label={`Address Options ${patientAddressId}`}
        aria-controls={
          menuOpen ? `PatientView-PatientAddressOptions-${patientAddressId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientAddressOptions-${patientAddressId}options-menu`}
        aria-labelledby={`PatientView-PatientAddressOptions-${patientAddressId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog} disabled={!hasAccess.updatePatientAddress}>
          Edit
        </MenuItem>

        {!isDefault && (
          <MenuItem onClick={handleDefaultAddress} disabled={!hasAccess.updatePatientAddress}>
            Set as Default
          </MenuItem>
        )}

        <MenuItem
          onClick={handleDeleteAddress}
          disabled={!hasAccess.deletePatientAddress || isDefault}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

PatientAddressOptions.propTypes = {
  patientAddressId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  isDefault: PropTypes.bool.isRequired,
};

export default PatientAddressOptions;
