/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef, useState } from 'react';
import { diff } from 'deep-object-diff';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { lighten } from '@mui/material/styles';
import {
  find,
  flatMap,
  includes,
  isBoolean,
  isEmpty,
  isNumber,
  keys,
  map,
  pick,
  reduce,
  startCase,
  uniq,
} from 'lodash';
import {
  AppBar,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ItemLabelValue = ({ value, label, color, fontSize, fontWeight, opacity }) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ mt: '3px' }}>
      <Grid item sx={{ width: '30%' }}>
        <Typography
          variant={fontSize || 'body1'}
          sx={{
            ml: 3,
            opacity: color ? 1 : 0.75,
            color: color || lighten(theme.palette.text.primary, opacity),
            fontWeight: fontWeight || 'regular',
          }}
        >
          {`${label} :`}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '40%' }}>
        <Typography
          sx={{
            ml: 3,
            overflow: 'hidden',
            opacity: color ? 1 : 0.75,
            fontWeight: fontWeight || 'regular',
            color: color || lighten(theme.palette.text.primary, opacity),
          }}
          variant={fontSize || 'body1'}
        >
          {!isEmpty(value) || isBoolean(value) || isNumber(value) ? value : 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  opacity: PropTypes.number,
};

ItemLabelValue.defaultProps = {
  label: '',
  value: '',
  color: null,
  fontSize: null,
  fontWeight: null,
  opacity: null,
};

const PrescriptionField = ({ label, fieldKey, fieldValue, diffKeys, prescriptionDiffs }) => {
  const theme = useTheme();
  return (
    <>
      <ItemLabelValue
        label={label}
        value={fieldValue}
        fontWeight='bold'
        color={includes(diffKeys, fieldKey) ? theme.palette.error.light : ''}
      />
      {map(
        prescriptionDiffs,
        (existingPrescription, index) =>
          existingPrescription?.[fieldKey] && (
            <ItemLabelValue
              key={index}
              label={label}
              value={existingPrescription[fieldKey]}
              fontWeight='bold'
              color={theme.palette.info.dark}
            />
          )
      )}
    </>
  );
};

PrescriptionField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldKey: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  diffKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  prescriptionDiffs: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
};

PrescriptionField.defaultProps = {
  fieldValue: null,
};

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const PrescriptionComparisonModel = ({ open, onClose, prescriptionId }) => {
  const theme = useTheme();
  const [viewMoreOption, setViewMoreOption] = useState(false);
  const { currentPage, pages } = useSelector(({ workQueue }) => workQueue.prescriptionReview);
  const {
    patient,
    prescriptionData: prescription,
    existingPrescriptions,
  } = find(pages[currentPage], {
    pomPrescriptionId: prescriptionId,
  });

  const styles = {
    matchedPrescription: { fontWeight: 600, opacity: 0.75 },
    currentPrescription: { fontWeight: 600, color: theme.palette.error.light },
    existingPrescriptions: { fontWeight: 600, color: theme.palette.info.dark },
  };

  const prescriptionDiffs = reduce(
    existingPrescriptions,
    (result, existingPrescription) => {
      const currentDifferences = diff(prescription, existingPrescription);
      result.push(currentDifferences);
      return result;
    },
    []
  );

  const diffKeys = uniq(flatMap(prescriptionDiffs, Object.keys));

  const moreDrugDetails = pick(prescription, [
    'form',
    'drugUnit',
    'generic',
    'lastFillDate',
    'nextRefill',
    'expirationDate',
    'refillsRemaining',
    'dispensedQuantity',
  ]);

  return (
    <Dialog open={open} fullScreen TransitionComponent={Transition}>
      <AppBar position='static'>
        <Toolbar variant='dense'>
          <Grid container sx={{ p: 2 }} justifyContent='space-between' alignItems='center'>
            <Grid item justifyContent='center' alignItems='center'>
              <Typography variant='h4'>Duplication Prescription Review</Typography>
            </Grid>

            <Grid item>
              <IconButton
                onClick={onClose}
                id={`Close Prescription View  ${prescriptionId}`}
                aria-label='Close Prescription View'
                className='CloseIconBtn'
              >
                <CloseIcon fontSize='medium' />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <DialogContent>
        <Grid container justifyContent='center' alignItems='flex-start' direction='row'>
          <Grid item sx={{ width: '60%' }}>
            <PrescriptionField
              label='Rx Number'
              fieldKey='rxNumber'
              diffKeys={diffKeys}
              fieldValue={prescription.rxNumber}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='Date Ordered'
              fieldKey='dateOrdered'
              diffKeys={diffKeys}
              fieldValue={prescription.dateOrdered}
              prescriptionDiffs={prescriptionDiffs}
            />

            <Typography variant='h6' sx={{ ml: 3, my: 1, fontWeight: 600 }}>
              Patient
            </Typography>

            <ItemLabelValue label='Patient' value={patient.patientName} fontWeight='bold' />
            <ItemLabelValue label='Phone Number' value={patient.phoneNumber} fontWeight='bold' />
            <ItemLabelValue label='Date of Birth' value={patient.dob} fontWeight='bold' />

            <Typography variant='h6' sx={{ ml: 3, my: 1, fontWeight: 600 }}>
              Prescriber
            </Typography>

            <PrescriptionField
              label='Prescriber'
              fieldKey='prescriber'
              diffKeys={diffKeys}
              fieldValue={prescription.prescriber}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='NPI'
              fieldKey='npi'
              diffKeys={diffKeys}
              fieldValue={prescription.npi}
              prescriptionDiffs={prescriptionDiffs}
            />

            <Typography variant='h6' sx={{ ml: 3, my: 1, fontWeight: 600 }}>
              Drug
            </Typography>

            <ItemLabelValue label='Drug' value={prescription?.drug} fontWeight='bold' />

            <PrescriptionField
              label='Strength'
              fieldKey='strength'
              diffKeys={diffKeys}
              fieldValue={prescription.strength}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='Quantity'
              fieldKey='quantityPrescribed'
              diffKeys={diffKeys}
              fieldValue={prescription.quantityPrescribed}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='Directions'
              fieldKey='instructions'
              diffKeys={diffKeys}
              fieldValue={prescription.instructions}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='DAW'
              fieldKey='daw'
              diffKeys={diffKeys}
              fieldValue={prescription.daw}
              prescriptionDiffs={prescriptionDiffs}
            />

            <PrescriptionField
              label='Date Written'
              fieldKey='writtenDate'
              diffKeys={diffKeys}
              fieldValue={prescription.writtenDate}
              prescriptionDiffs={prescriptionDiffs}
            />

            <Grid>
              <IconButton
                disableRipple
                onClick={() => setViewMoreOption(!viewMoreOption)}
                id={`Close View More Drug Details ${prescriptionId}`}
                aria-label='Close View More'
                className='CloseIconBtn'
              >
                <Typography
                  variant='body1'
                  sx={{ ml: 2, my: 1, color: theme.palette.common.black, fontWeight: 600 }}
                >
                  {viewMoreOption ? 'View Less' : 'View More'}
                </Typography>
                {viewMoreOption ? (
                  <KeyboardArrowUpIcon fontSize='medium' sx={{ ml: 1 }} />
                ) : (
                  <KeyboardArrowDownIcon fontSize='medium' sx={{ ml: 1 }} />
                )}
              </IconButton>
              {viewMoreOption &&
                map(keys(moreDrugDetails), (drugField, index) => (
                  <PrescriptionField
                    key={index}
                    label={startCase(drugField)}
                    fieldKey={drugField}
                    diffKeys={diffKeys}
                    fieldValue={prescription[drugField]}
                    prescriptionDiffs={prescriptionDiffs}
                  />
                ))}
            </Grid>
          </Grid>

          <Grid item sx={{ width: '40%' }}>
            <Typography variant='h6' sx={{ mt: 2, mb: 1, fontWeight: 600 }}>
              Legend
            </Typography>

            <Typography sx={styles.matchedPrescription}>Matching Fields</Typography>
            <Typography sx={styles.currentPrescription}>Current Prescription</Typography>
            <Typography sx={styles.existingPrescriptions}>Existing Prescriptions</Typography>

            <Typography variant='h6' sx={{ mt: 2, fontWeight: 600 }}>
              Summary
            </Typography>
            <Table sx={{ maxWidth: '300px' }}>
              <TableHead>
                <TableRow>
                  <TableCell align='left' sx={{ fontWeight: 600 }}>
                    Rx Number
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: 600 }}>
                    Date Ordered
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell align='left' sx={styles.currentPrescription}>
                    {prescription.rxNumber}
                  </TableCell>
                  <TableCell align='left' sx={styles.currentPrescription}>
                    {prescription.dateOrdered}
                  </TableCell>
                </TableRow>
                {map(existingPrescriptions, ({ rxNumber, dateOrdered }) => (
                  <TableRow hover key={rxNumber}>
                    <TableCell align='left' sx={styles.existingPrescriptions}>
                      {rxNumber}
                    </TableCell>
                    <TableCell align='left' sx={styles.existingPrescriptions}>
                      {dateOrdered}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default PrescriptionComparisonModel;

PrescriptionComparisonModel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  prescriptionId: PropTypes.string.isRequired,
};
