import { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { getPatientsByPage } from 'store/thunks/patientsListThunks';
import { getPartners } from 'store/thunks/partnerThunks';
import useRoles from 'common/hooks/useRoles';

import PatientFilterButton from './components/PatientFilterButton';
import PatientsTable from './components/PatientsTable';
import FilterStatusBar from './components/FilterStatusBar';
import NewPatientButton from './components/NewPatientButton';

const PatientsList = () => {
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  useEffect(() => {
    document.title = 'Patients - SmartOS';
  }, []);

  useEffect(() => {
    dispatch(getPatientsByPage({ page: 1 }));
    dispatch(getPartners());
  }, [dispatch]);

  return (
    <Grid container sx={{ px: 3, pt: 2 }} direction='column'>
      <Grid item container justifyContent='space-between'>
        <Typography component='h1' variant='h5'>
          Patients
        </Typography>

        <Grid item>
          <Grid container gap={2}>
            {hasAccess.createNewPatient && <NewPatientButton />}
            <PatientFilterButton />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ minHeight: 40 }} container alignItems='center'>
        <FilterStatusBar />
      </Grid>

      <Grid item>
        <PatientsTable />
      </Grid>
    </Grid>
  );
};

export default PatientsList;
