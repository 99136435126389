/* eslint-disable import/prefer-default-export */
import { getPatientsApi } from 'api/patient';
import { debounce } from 'lodash';
import { patientsListActions } from 'store/slices/patientsListSlice';

import { handleError } from './errorHandlerThunks';

export const getPatientsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().patientsList;

    dispatch(patientsListActions.updateCurrentPage({ page }));

    return getPatientsApi({ page, sortBy, filter })
      .then(({ count, patients }) => {
        dispatch(patientsListActions.loadPatientsToPage({ page, patients, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedGetPatients = debounce(({ dispatch }) => {
  return dispatch(getPatientsByPage({ page: 1 }));
}, 1000);

export const sortPatientsList =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(patientsListActions.updateSortBy({ sortBy }));

    debouncedGetPatients({ dispatch });
  };

export const filterPatientsList =
  ({ filter }) =>
  (dispatch) => {
    dispatch(patientsListActions.updateFilter({ filter }));

    // debounced to avoid making multiple api calls when removing individual filter too quickly
    debouncedGetPatients({ dispatch });
  };
