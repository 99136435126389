/* eslint-disable import/prefer-default-export */
import {
  captureTransactionApi,
  getFailedOrderDetailsApi,
  getPublicKeyApi,
  listTransactionsApi,
  refundTransactionApi,
  saveAndAuthorizePaymentApi,
  voidTransactionApi,
} from 'api/transactions';
import { transactionActions } from 'store/slices/transactionSlice';
import { compact, debounce, uniqBy } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { patientActions } from 'store/slices/patientSlice';
import { appActions } from 'store/slices/appSlice';
import { handleError } from './errorHandlerThunks';

export const listTransactionsByPage =
  ({ page }) =>
  (dispatch, getState) => {
    const { filter, sortBy } = getState().transactions;

    dispatch(transactionActions.updateTransactionsCurrentPage({ page }));

    return listTransactionsApi({ filter, page, sortBy })
      .then(({ count, results }) =>
        dispatch(transactionActions.loadTransactions({ count, transactions: results, page }))
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedGetTransactions = debounce(({ dispatch }) => {
  return dispatch(listTransactionsByPage({ page: 1 }));
}, 1000);

export const filterTransactions =
  ({ filter }) =>
  (dispatch) => {
    dispatch(transactionActions.updateFilter({ filter }));

    debouncedGetTransactions({ dispatch });
  };

export const sortTransactions =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(transactionActions.updateSortBy({ sortBy }));

    debouncedGetTransactions({ dispatch });
  };

export const captureTransaction =
  ({ userId, patientId, amount, externalTransactionId, transactionId }) =>
  (dispatch, getState) => {
    const { currentPage } = getState().transactions;

    return captureTransactionApi({
      amount,
      patientId,
      externalTransactionId,
      userId,
    })
      .then((response) => {
        if (response) {
          dispatch(transactionActions.removeTransaction({ transactionId, page: currentPage }));
          enqueueSnackbar(`Transaction ${externalTransactionId} captured successfully`, {
            variant: 'success',
          });
          dispatch(listTransactionsByPage({ page: currentPage }));
        }
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const voidTransaction =
  ({ userId, patientId, externalTransactionId, transactionId }) =>
  (dispatch, getState) => {
    const { currentPage } = getState().transactions;

    return voidTransactionApi({
      patientId,
      externalTransactionId,
      userId,
    })
      .then((response) => {
        if (response) {
          dispatch(transactionActions.removeTransaction({ transactionId, page: currentPage }));
          enqueueSnackbar(`Transaction ${externalTransactionId} voided successfully`, {
            variant: 'success',
          });
          dispatch(listTransactionsByPage({ page: currentPage }));
        }
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const refundTransaction =
  ({ userId, patientId, amount, externalTransactionId, transactionId }) =>
  (dispatch, getState) => {
    const { currentPage } = getState().transactions;

    return refundTransactionApi({
      amount,
      patientId,
      externalTransactionId,
      userId,
    })
      .then((response) => {
        if (response) {
          dispatch(transactionActions.removeTransaction({ transactionId, page: currentPage }));
          enqueueSnackbar(`Transaction ${externalTransactionId} refunded successfully`, {
            variant: 'success',
          });
          dispatch(listTransactionsByPage({ page: currentPage }));
        }
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const getFailedOrderDetails =
  ({ orderId, mpi }) =>
  (dispatch, getState) => {
    return getFailedOrderDetailsApi({ mpi, orderId })
      .then(({ failedOrder, patient, addresses }) => {
        const existingFailedOrders = getState().patient[mpi]?.failedOrders || [];
        const failedOrders = uniqBy(compact([...existingFailedOrders, failedOrder]), { orderId });

        dispatch(patientActions.loadPatientFailedOrder({ mpi, failedOrders }));
        dispatch(patientActions.loadPatientData({ patient }));
        dispatch(patientActions.loadAddresses({ mpi, addresses }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const saveAndAuthorizePayment = (inputPayload) => (dispatch) => {
  return saveAndAuthorizePaymentApi({ inputPayload })
    .then((transactionId) => {
      dispatch(filterTransactions({ filter: { transactionType: 'FAILED' } }));

      return !!transactionId;
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const getPublicKey = () => (dispatch) =>
  getPublicKeyApi()
    .then((publicKey) => dispatch(appActions.loadPublicKey({ publicKey })))
    .catch((error) => dispatch(handleError({ error })));
