import { Component } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ErrorIcon from '@mui/icons-material/ErrorOutline';

const GridItem = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

// https://reactjs.org/docs/error-boundaries.html
class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.initialState = { error: null, errorInfo: null };
    this.state = this.initialState;
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;

    if (error) {
      return (
        <Box sx={{ height: '80vh' }}>
          <Grid container alignItems='center' justifyContent='center' sx={{ height: '100%' }}>
            <Grid item container sx={{ p: 2, maxWidth: 600, margin: 1, gap: 2 }} direction='column'>
              <GridItem item>
                <ErrorIcon sx={{ fontSize: '15em' }} color='error' />
              </GridItem>

              <GridItem item>
                <Typography component='h1' variant='h5'>
                  {error?.cause?.response?.data?.message ||
                    'Something went wrong. Please try again.'}
                </Typography>
              </GridItem>

              <Grid item>
                <details style={{ whiteSpace: 'pre-wrap', display: 'none' }}>
                  {error && error.toString()}
                  <br />
                  {errorInfo.componentStack}
                </details>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
    }

    return children;
  }
}

AppErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AppErrorBoundary;
