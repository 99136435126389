import { compact, debounce, isNil, map, omit } from 'lodash';

import { SigFieldKeys, SigStatus } from 'enums/SigManagement.enum';
import { sigActions } from 'store/slices/sigSlice';
import { listSigAssociationsApi, upsertSigAssociationsApi } from 'api/sig';

import { handleError } from './errorHandlerThunks';

export const loadSigAssociations =
  ({ page }) =>
  (dispatch, getState) => {
    const { sortBy, filter } = getState().sigs;
    dispatch(sigActions.updateSigsCurrentPage({ page }));

    return listSigAssociationsApi({
      page,
      sortBy,
      filter: {
        ...omit(filter, SigFieldKeys.STATUS),
        ...(!isNil(filter?.status) && { isActive: filter.status === SigStatus.ACTIVE }),
      },
    })
      .then(({ count, result }) => {
        dispatch(sigActions.loadSigs({ page, items: result, count }));
      })
      .catch((error) => dispatch(handleError({ error })));
  };

export const debouncedLoadSigAssociations = debounce(({ dispatch }) => {
  return dispatch(loadSigAssociations({ page: 1 }));
}, 1000);

export const updateSigAssociationsFilter =
  ({ filter }) =>
  (dispatch) => {
    dispatch(sigActions.updateSigsFilter({ filter }));
    debouncedLoadSigAssociations({ dispatch });
  };

export const updateSigAssociationsSortBy =
  ({ sortBy }) =>
  (dispatch) => {
    dispatch(sigActions.updateSigsSortBy({ sortBy }));
    debouncedLoadSigAssociations({ dispatch });
  };

export const getSigAssociationsBySigId =
  ({ sigId }) =>
  (dispatch) => {
    return listSigAssociationsApi({ limit: 0, offset: 0, filter: { sigId } })
      .then(({ result }) =>
        compact(
          map(result, ({ isActive, directionId, direction }) => {
            if (directionId) {
              return { direction, directionId, directionStatus: isActive };
            }
            return null;
          })
        )
      )
      .catch((error) => dispatch(handleError({ error })));
  };

export const upsertSigAssociations =
  ({ input }) =>
  (dispatch) => {
    return upsertSigAssociationsApi({ input })
      .then((response) => {
        dispatch(updateSigAssociationsFilter({ dispatch }));
        return response;
      })
      .catch((error) => dispatch(handleError({ error })));
  };
