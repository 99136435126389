import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { reviewLineItem } from 'store/thunks/patientThunks';
import ReviewFailureDialog from './ReviewFailureDialog';
import ReviewProceedSubstanceDialog from './ReviewProceedSubstanceDialog';

const ReviewControlledSubstance = ({ mpi, lineItemId, requestId }) => {
  const [showFailDialog, setShowFailDialog] = useState(false);
  const [showProceedDialog, setShowProceedDialog] = useState(false);
  const dispatch = useDispatch();

  const handleWillNotFill = () => {
    setShowFailDialog(true);
  };

  const handleProceed = () => {
    setShowProceedDialog(true);
  };

  const closeFailDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowFailDialog(false);
    }
  };

  const closeProceedDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowProceedDialog(false);
    }
  };

  const confirmProceedDialog = ({ qs1RxNumber }) => {
    return dispatch(
      reviewLineItem({ mpi, lineItemId, reviewResult: true, qs1RxNumber, requestId })
    ).then(() => {
      closeProceedDialog();
    });
  };

  const confirmFailureDialog = ({ reasonForRejection }) => {
    return dispatch(
      reviewLineItem({ mpi, lineItemId, reviewResult: false, reasonForRejection, requestId })
    ).then(() => {
      closeFailDialog();
    });
  };

  return (
    <>
      <ReviewFailureDialog
        confirmFailure={confirmFailureDialog}
        open={showFailDialog}
        onClose={closeFailDialog}
      />

      <ReviewProceedSubstanceDialog
        confirmProceedDialog={confirmProceedDialog}
        onClose={closeProceedDialog}
        open={showProceedDialog}
      />
      <Grid container gap={2} direction='column'>
        <Typography>
          If this is not scheduled II controlled substance, please proceed to next step. Otherwise,
          this prescription will not be filled.
        </Typography>
      </Grid>
      <Grid container direction='row-reverse' sx={{ mt: 4 }}>
        <Button variant='contained' sx={{ width: 100 }} onClick={handleProceed}>
          Proceed
        </Button>
        <Button
          sx={{ mr: 2, width: 100 }}
          variant='contained'
          color='error'
          onClick={handleWillNotFill}
        >
          Will Not Fill
        </Button>
      </Grid>
    </>
  );
};

ReviewControlledSubstance.propTypes = {
  mpi: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default ReviewControlledSubstance;
