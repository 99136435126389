import { Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEvents } from 'store/thunks/eventThunks';

import EventsFilterStatusBar from './components/EventsFilterStatusBar';
import EventsFilterButton from './components/EventsFilterButton';
import EventsTable from './components/EventsTable';

const Events = () => {
  const dispatch = useDispatch();
  const { currentPage } = useSelector(({ criticalEvents }) => criticalEvents.events);

  useEffect(() => {
    dispatch(getEvents({ page: currentPage }));
  }, [currentPage, dispatch]);
  useEffect(() => {
    document.title = 'Events - SmartOS';
  }, []);

  return (
    <Grid container sx={{ px: 3, pb: 2 }} direction='column'>
      <Grid item container alignItems='center' spacing={2}>
        <Grid item>
          <Typography component='h1' variant='h5'>
            Events
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ py: 2 }}>
        <Grid item sx={{ minHeight: 40, flexGrow: 1 }} alignItems='center'>
          <EventsFilterStatusBar />
        </Grid>
        <Grid item>
          <EventsFilterButton />
        </Grid>
        <EventsTable />
      </Grid>
    </Grid>
  );
};

export default Events;
