import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Document, Page, pdfjs } from 'react-pdf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import LoadingModule from 'common/components/LoadingModule';
import { getDownloadUrlForInvoiceKeyApi } from 'api/patient';
import { handleError } from 'store/thunks/errorHandlerThunks';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const InvoiceViewer = ({ invoiceNumber, invoiceFileKey, invoiceId, mpi }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const invoiceServiceCustomerId = useSelector(
    ({ patient }) => patient[mpi]?.systemIds?.invoiceServiceCustomerId
  );

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => setNumPages(nextNumPages);

  /* istanbul ignore next */
  const mobilePdfWidth = useMediaQuery(theme.breakpoints.down('sm')) && 300;
  /* istanbul ignore next */
  const tabletPdfWidth = useMediaQuery(theme.breakpoints.down('md')) && 600;
  /* istanbul ignore next */
  const desktopPdfWidth = useMediaQuery(theme.breakpoints.up('md')) && 900;

  const pdfWidth = mobilePdfWidth || tabletPdfWidth || desktopPdfWidth;

  useEffect(() => {
    if (!invoiceUrl && open) {
      getDownloadUrlForInvoiceKeyApi({
        invoiceFileKey,
        invoiceId,
        mpi,
        invoiceServiceCustomerId,
      })
        .then(setInvoiceUrl)
        .catch((error) => dispatch(handleError({ error })));
    }
  }, [invoiceFileKey, invoiceId, invoiceServiceCustomerId, invoiceUrl, mpi, open, dispatch]);

  return (
    <>
      <IconButton aria-label={`View Invoice ${invoiceNumber}`} onClick={openDialog}>
        <OpenInNewIcon />
      </IconButton>

      <Dialog
        open={open}
        maxWidth='lg'
        fullScreen={!!mobilePdfWidth}
        aria-labelledby='PatientView-InvoiceViewer-header'
      >
        <DialogTitle id='PatientView-InvoiceViewer-header'>Invoice {invoiceNumber}</DialogTitle>

        <DialogContent sx={{ p: 0, [theme.breakpoints.up('sm')]: { maxHeight: '70vh' } }}>
          {!invoiceUrl && <LoadingModule height='25vh' />}
          {invoiceUrl && (
            <Document
              file={invoiceUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              error={<Box sx={{ p: 3 }}>Failed to load invoice</Box>}
              loading={<LoadingModule height='25vh' />}
            >
              {map(new Array(numPages), (_, index) => (
                <Page pageNumber={index + 1} width={pdfWidth} key={index} />
              ))}
            </Document>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            disabled={!invoiceUrl}
            component={Link}
            href={invoiceUrl}
            target='_blank'
            variant='outlined'
            color='secondary'
            fullWidth
            sx={{ mr: 2, maxWidth: 150 }}
          >
            Download
          </Button>

          <Button onClick={closeDialog} variant='contained' fullWidth sx={{ maxWidth: 150 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

InvoiceViewer.propTypes = {
  invoiceNumber: PropTypes.string.isRequired,
  invoiceFileKey: PropTypes.string.isRequired,
  invoiceId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};

export default InvoiceViewer;
