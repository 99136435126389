import { createRef } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarProvider = ({ transitionDuration, children }) => {
  const theme = useTheme();
  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <NotistackProvider
      transitionDuration={transitionDuration}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      dense
      sx={{
        '& .SnackbarItem-variantInfo': {
          bgcolor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        },
        '& .SnackbarItem-variantSuccess': {
          bgcolor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
        '& .SnackbarItem-variantError': {
          bgColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
        '& .SnackbarItem-variantWarning': {
          bgColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        },
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton color='inherit' onClick={onClickDismiss(key)} aria-label='Dismiss'>
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  transitionDuration: PropTypes.shape({ enter: PropTypes.number, exit: PropTypes.number }),
};

SnackbarProvider.defaultProps = {
  transitionDuration: undefined,
};

export default SnackbarProvider;
