import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';

import { updatePatientPartner } from 'store/thunks/patientThunks';

const EditPartnerDialog = ({ mpi, partnerId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) => find(patient[mpi].partnerIds, { partnerId }));
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleUpdatePatientPartner = ({ identityValue }) => {
    return dispatch(updatePatientPartner({ mpi, partnerId, identityValue })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditProviderDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditProviderDialog-header'>Edit Partner</DialogTitle>

      <Form
        initialValues={initialValues}
        onSubmit={handleUpdatePatientPartner}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <SelectField
                label='Partner'
                name='partnerId'
                id='PatientView-EditPartnerDialog-Partner-dropdown'
                required
                disabled
              >
                {map(partners, ({ partnerName, partnerUuid }) => (
                  <MenuItem value={partnerUuid} key={partnerUuid}>
                    {partnerName}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField
                id='PatientView-EditPartnerDialog-partnerIdentityValue-input'
                label='Partner Identity Value'
                name='identityValue'
                required
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditPartnerDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  partnerId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditPartnerDialog;
