import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, map } from 'lodash';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import {
  validateEmail,
  validatePhoneNumber,
  validateValueWithRegex,
  validateZipCode,
} from 'common/forms/formValidations';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { updatePatientProvider } from 'store/thunks/patientThunks';
import { formatZipCode } from 'common/utils';

const EditProviderDialog = ({ mpi, patientProviderId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(({ patient }) =>
    find(patient[mpi].providers, { patientProviderId })
  );

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleUpdatePatientProvider = (formData) => {
    return dispatch(
      updatePatientProvider({
        mpi,
        provider: {
          ...formData,
          firstName: formData.firstName || '',
          lastName: formData.lastName || '',
          phone: formData.phone || '',
          email: formData.email || '',
          npi: formData.npi || '',
          fax: formData.fax || '',
          addressLine1: formData.addressLine1 || '',
          addressLine2: formData.addressLine2 || '',
          city: formData.city || '',
          state: formData.state || '',
          zip: formData.zip || '',
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditProviderDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditProviderDialog-header'>Add Provider</DialogTitle>

      <Form
        initialValues={initialValues}
        onSubmit={handleUpdatePatientProvider}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='PatientView-EditProviderDialog-firstName-input'
                label='First Name'
                name='firstName'
                required
                autoFocus
              />

              <TextField
                id='PatientView-EditProviderDialog-lastName-input'
                label='Last Name'
                name='lastName'
                required
              />

              <TextField
                id='PatientView-EditProviderDialog-phoneNumber-input'
                label='Phone Number'
                name='phone'
                required
                validations={[validatePhoneNumber()]}
              />

              <TextField
                id='PatientView-EditProviderDialog-email-input'
                label='Email'
                name='email'
                validations={[validateEmail()]}
              />

              <TextField
                id='PatientView-EditProviderDialog-npi-input'
                label='NPI'
                name='npi'
                validations={[validateValueWithRegex('Must be 10 numbers', /^\d{10}$/)]}
              />

              <TextField
                id='PatientView-EditProviderDialog-fax-input'
                label='Fax'
                name='fax'
                validations={[validatePhoneNumber('Invalid Fax Number')]}
              />

              <TextField
                id='PatientView-EditProviderDialog-addressLine1-input'
                label='Address Line 1'
                name='addressLine1'
              />

              <TextField
                id='PatientView-EditProviderDialog-addressLine2-input'
                label='Address Line 2'
                name='addressLine2'
              />

              <TextField id='PatientView-EditProviderDialog-city-input' label='City' name='city' />

              <SelectField
                id='PatientView-EditProviderDialog-state-dropdown'
                label='State'
                name='state'
              >
                {map(usStateAbbreviation, ({ name, abbreviation }) => (
                  <MenuItem value={abbreviation} key={abbreviation}>
                    {name}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField
                id='PatientView-EditProviderDialog-zip-input'
                label='Zip'
                name='zip'
                type='zip'
                maxLength={10}
                format={formatZipCode}
                validations={[validateZipCode()]}
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditProviderDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientProviderId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditProviderDialog;
