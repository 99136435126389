import { Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';

import useTabContext from 'common/hooks/useTabContext';
import { listPatientPrescriptions } from 'store/thunks/patientThunks';
import PatientPrescriptionsTable from './PatientPrescriptionsTable';

const PatientPrescriptionsTab = () => {
  const { mpi } = useParams();
  const dispatch = useDispatch();
  const isActiveTab = useTabContext().tab === 'PatientPrescriptionsTab';
  const [loading, setLoading] = useState(false);

  const reloadPrescriptions = () => {
    setLoading(true);
    return dispatch(listPatientPrescriptions({ mpi, forceRefresh: true })).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isActiveTab) {
      dispatch(listPatientPrescriptions({ mpi }));
    }
  }, [dispatch, mpi, isActiveTab]);

  if (!isActiveTab) {
    return null;
  }

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Prescriptions
        </Typography>

        <IconButton
          aria-label='refresh Prescription'
          onClick={reloadPrescriptions}
          disabled={loading}
        >
          <RefreshIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <PatientPrescriptionsTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientPrescriptionsTab;
