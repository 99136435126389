import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { capitalize, compact, find, isEmpty, values } from 'lodash';

import gender from 'common/constants/gender';
import { filterPatientsList } from 'store/thunks/patientsListThunks';

const FilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ patientsList }) => patientsList.filter);

  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const removeFilter = (filterName) => () =>
    dispatch(filterPatientsList({ filter: { ...filter, [filterName]: undefined } }));

  const removeAllFilter = () => dispatch(filterPatientsList({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.name && (
        <Chip label={`Name = ${filter.name}`} variant='contained' onDelete={removeFilter('name')} />
      )}

      {filter.status && (
        <Chip
          label={`Status = ${capitalize(filter.status)}`}
          variant='contained'
          onDelete={removeFilter('status')}
        />
      )}

      {filter.gender && (
        <Chip
          label={`Gender = ${gender[filter.gender]}`}
          variant='contained'
          onDelete={removeFilter('gender')}
        />
      )}

      {filter.createdAfter && (
        <Chip
          label={`Created After = ${format(new Date(filter.createdAfter), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('createdAfter')}
        />
      )}

      {filter.dob && (
        <Chip
          label={`Date of Birth = ${format(new Date(filter.dob), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('dob')}
        />
      )}

      {filter.originalPartner && (
        <Chip
          label={`Original Partner = ${
            find(partners, { partnerUuid: filter.originalPartner }).partnerName
          }`}
          variant='contained'
          onDelete={removeFilter('originalPartner')}
        />
      )}

      {filter.flatSearch && (
        <Chip
          label={`Search = ${filter.flatSearch}`}
          variant='contained'
          onDelete={removeFilter('flatSearch')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default FilterStatusBar;
