import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import NewMedicationButton from './NewMedicationButton';
import PatientMedicationsTable from './PatientMedicationsTable';

const PatientMedicationsTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Medications
        </Typography>
        {hasAccess.addMedication && <NewMedicationButton mpi={mpi} />}
      </Grid>

      <Grid item>
        <PatientMedicationsTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientMedicationsTab;
