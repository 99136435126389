import { createSlice } from '@reduxjs/toolkit';
import { OrderType } from 'enums/requestStatus.enum';
import { forEach } from 'lodash';

export const initialState = {
  preset: 'eScriptOrders',
  eScriptOrders: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: { orderType: OrderType.E_SCRIPT },
    sortBy: {
      sortKey: 'createdAt',
      sortOrder: 'DESC',
    },
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const orderStatusSlice = createSlice({
  name: 'orderStatus',
  initialState,
  reducers: {
    loadSelectedOrderStatusQueue(state, action) {
      state.preset = action.payload;
    },
    loadPrescriptionOrders(state, action) {
      const { preset, page, items, count } = action.payload;

      state[preset].count = count;
      state[preset].pages[page] = items;
    },
    updateCurrentPage(state, action) {
      const { preset, page } = action.payload;

      state[preset].currentPage = page;
    },
    updatePrescriptionOrdersFilter(state, action) {
      const { preset, filter } = action.payload;

      state[preset].pages = {};
      state[preset].count = 0;
      state[preset].filter = filter;
    },
    updatePrescriptionOrdersSortBy(state, action) {
      const { preset, sortBy } = action.payload;

      state[preset].pages = {};
      state[preset].count = 0;
      state[preset].sortBy = sortBy;
    },
    updateRequestDetails(state, action) {
      const { preset, currentPage, referenceId, requestDetails } = action.payload;
      const { pages } = state[preset];
      const requests = pages[currentPage];

      forEach(requests, (request) => {
        if (request.referenceId === referenceId) {
          request.requestDetails = requestDetails;
        }
      });
    },
  },
});

export const orderStatusActions = orderStatusSlice.actions;
export default orderStatusSlice.reducer;
