import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { find, map, omit } from 'lodash';

import insurancePayorTypes from 'common/constants/insurancePayorTypes';
import SelectField from 'common/forms/SelectField';
import TextField from 'common/forms/TextField';
import { validateValueWithRegex } from 'common/forms/formValidations';
import { updatePatientInsurance } from 'store/thunks/patientThunks';
import CheckboxField from 'common/forms/CheckboxField';

const EditInsuranceDialog = ({ mpi, patientInsuranceId, open, onClose }) => {
  const dispatch = useDispatch();
  const initialValues = useSelector(
    ({ patient }) =>
      omit(find(patient[mpi]?.insurances, { patientInsuranceId }), [
        'verification',
        'modifiedTimestamp',
      ]),
    shallowEqual
  );

  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      onClose(false);
    }
  };

  const handleUpdatePatientInsurance = (formData) => {
    return dispatch(
      updatePatientInsurance({
        insurance: {
          ...formData,
          mpi,
          bin: formData.bin || '',
          pcn: formData.pcn || '',
          groupNumber: formData.groupNumber || '',
          insurancePlanId: formData.insurancePlanId || '',
          ssn: formData.ssn || '',
          medicareNumber: formData.medicareNumber || '',
          verified: formData.verified || false,
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      aria-describedby='PatientView-EditInsuranceDialog-header'
      fullWidth
    >
      <DialogTitle id='PatientView-EditInsuranceDialog-header'>Edit Insurance</DialogTitle>

      <Form
        initialValues={initialValues}
        onSubmit={handleUpdatePatientInsurance}
        render={({ handleSubmit, invalid, submitting }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                id='PatientView-EditInsuranceDialog-planName-input'
                label='Plan Name'
                name='planName'
                required
                autoFocus
              />

              <SelectField
                label='Plan Type'
                name='planType'
                id='PatientView-NewInsuranceButton-payorType-dropdown'
                required
              >
                {map(insurancePayorTypes, (value, key) => (
                  <MenuItem value={key} key={key}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>

              <TextField id='PatientView-EditInsuranceDialog-bin-input' label='BIN' name='bin' />

              <TextField id='PatientView-EditInsuranceDialog-pcn-input' label='PCN' name='pcn' />

              <TextField
                id='PatientView-EditInsuranceDialog-groupNumber-input'
                label='Group Number'
                name='groupNumber'
              />

              <TextField
                id='PatientView-EditInsuranceDialog-insurancePlanId-input'
                label='Plan Id'
                name='insurancePlanId'
              />

              <TextField
                id='PatientView-EditInsuranceDialog-ssn-input'
                label='SSN (Last 4)'
                name='ssn'
                validations={[validateValueWithRegex('Must be 4 numbers', /^\d{4}$/)]}
              />

              <TextField
                id='PatientView-EditInsuranceDialog-medicareNumber-input'
                label='Medicare Number'
                name='medicareNumber'
              />

              <CheckboxField
                id='PatientView-EditInsuranceDialog-verified-checkbox'
                label='Verified'
                name='verified'
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                variant='contained'
                disabled={invalid}
                type='submit'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

EditInsuranceDialog.propTypes = {
  mpi: PropTypes.string.isRequired,
  patientInsuranceId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EditInsuranceDialog;
