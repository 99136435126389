import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, ListItem, ListItemText } from '@mui/material';
import { debounce, noop } from 'lodash';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import TextField from 'common/forms/TextField';
import { listDrugsApi } from 'api/medication';
import { matchSorter } from 'match-sorter';

const DrugNameAutoSuggest = ({
  name,
  id,
  label,
  required,
  validations,
  autoFocus,
  prefillNdcField,
}) => {
  const form = useForm();
  const [drugCollection, setDrugCollection] = useState([]);
  const [inputValue, setInputValue] = useState(form.getState().values[name] || '');
  const [loading, setLoading] = useState(false);

  const onInputChange = (_, value) => setInputValue(value);
  const onChange = (_, medication) => {
    form.batch(() => {
      form.change(name, medication.medicationName);
      if (prefillNdcField) {
        form.change(prefillNdcField, medication.ndc);
      }
    });
  };

  const debouncedSearch = useMemo(() => {
    const searchFunc = (medicationName) => {
      setLoading(true);
      listDrugsApi({ medicationName })
        .then((results) => {
          setDrugCollection(matchSorter(results, medicationName, { keys: ['medicationName'] }));
        })
        .catch(noop) // intentionally not doing anything
        .finally(() => {
          setLoading(false);
        });
    };

    return debounce(searchFunc, 500, { trailing: true, leading: false });
  }, []);

  // start searching after a user entered 3 character
  useEffect(() => {
    setDrugCollection([]);
    if (inputValue.length >= 3) {
      debouncedSearch(inputValue);
    }
  }, [inputValue, debouncedSearch]);

  // reset result if input value is less than 3 characters
  useEffect(() => {
    if (drugCollection.length && inputValue.length < 3) {
      setDrugCollection([]);
    }
  }, [inputValue, drugCollection]);

  return (
    <Autocomplete
      freeSolo
      id={`${id}-autosuggest`}
      options={drugCollection}
      getOptionLabel={(drug) => {
        return drug?.medicationName || inputValue;
      }}
      renderOption={(props, { medicationName, ndc }) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ListItem {...props} key={ndc}>
            <ListItemText primary={medicationName} secondary={ndc} />
          </ListItem>
        );
      }}
      filterOptions={(options) => options}
      disableClearable
      value={form.getState().values[name] || inputValue || ''}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            name={name}
            id={id}
            label={label}
            required={required}
            params={params}
            endAdornment={loading ? <CircularProgress size={20} /> : undefined}
            validations={validations}
            autoFocus={autoFocus}
          />
        );
      }}
    />
  );
};

DrugNameAutoSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  prefillNdcField: PropTypes.string,
};

DrugNameAutoSuggest.defaultProps = {
  validations: [],
  required: false,
  autoFocus: false,
  prefillNdcField: '',
};

export default DrugNameAutoSuggest;
