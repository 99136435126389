/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { lighten, useTheme } from '@mui/material/styles';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { filter, find, isEmpty, map } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import SwitchField from 'common/forms/SwitchField';
import TextField from 'common/forms/TextField';
import { truncateString } from 'common/utils';
import {
  validateDuplicate,
  validateMaxLength,
  validateMinLength,
} from 'common/forms/formValidations';
import { getSigAssociationsBySigId } from 'store/thunks/sigThunks';

const ManageSigModal = ({
  sigData,
  showModal,
  loading,
  handleCloseModal,
  handleSigAssociations,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sigStatus, setSigStatus] = useState(!!sigData?.isActive);
  const [showNewDirectionBox, setShowNewDirectionBox] = useState(false);
  const [associationsLoading, setAssociationsLoading] = useState(false);
  const [newAssociations, setNewAssociations] = useState([]);
  const [existingAssociations, setExistingAssociations] = useState([]);
  const allDirections = map([...newAssociations, ...existingAssociations], 'direction');

  const handleNewAssociations = (direction) => {
    if (isEmpty(find([...newAssociations, ...existingAssociations], { direction }))) {
      setNewAssociations([...newAssociations, { direction, directionStatus: true }]);
      setShowNewDirectionBox(false);
    }
  };

  const handleSigStatus = () => {
    setExistingAssociations((associations) =>
      map(associations, (assoc) => ({ ...assoc, directionStatus: !sigStatus }))
    );
    setNewAssociations((associations) =>
      map(associations, (assoc) => ({ ...assoc, directionStatus: !sigStatus }))
    );
    setSigStatus(!sigStatus);
  };

  const handleAssociationStatus = (key, value) => {
    if (key === 'directionId') {
      setExistingAssociations((associations) =>
        map(associations, (assoc) =>
          assoc.directionId === value
            ? { ...assoc, directionStatus: !assoc.directionStatus }
            : assoc
        )
      );
    } else {
      setNewAssociations((associations) =>
        map(associations, (assoc) =>
          assoc.direction === value ? { ...assoc, directionStatus: !assoc.directionStatus } : assoc
        )
      );
    }
  };

  const handleRemoveAssociation = (key, value) => {
    if (key === 'directionId') {
      setExistingAssociations((associations) =>
        filter(associations, (assoc) => assoc.directionId !== value)
      );
    } else {
      setNewAssociations((associations) =>
        filter(associations, (assoc) => assoc.direction !== value)
      );
    }
  };

  const handleSigModal = (formData) =>
    handleSigAssociations({
      sig: formData.sig,
      sigStatus,
      associations: [...newAssociations, ...existingAssociations],
    });

  const getExistingSigAssociations = () => {
    setAssociationsLoading(true);
    dispatch(getSigAssociationsBySigId({ sigId: sigData.sigId }))
      .then((response) => setExistingAssociations(response))
      .finally(() => setAssociationsLoading(false));
  };

  useEffect(() => {
    if (sigData?.sigId) {
      getExistingSigAssociations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sigData]);

  return (
    <Dialog
      open={showModal}
      onClose={handleCloseModal}
      aria-describedby='Manage-Sigs'
      fullWidth
      sx={{ height: '100%' }}
    >
      <DialogTitle variant='h5'>
        {sigData?.sig ? `Edit Sig - ${truncateString(sigData?.sig, 15)}` : 'Add Sig'}
      </DialogTitle>
      <Form
        initialValues={{ sig: sigData?.sig }}
        onSubmit={handleSigModal}
        render={({ form, values, invalid, handleSubmit }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ maxHeight: '700px', overflow: 'auto', px: 2, py: 0 }}>
              <Grid container>
                <Grid container justifyContent='space-between' alignItems='center'>
                  <Typography sx={{ mt: 2 }}>Sig *</Typography>
                  {sigData?.sigId && (
                    <Grid container justifyContent='flex-end' sx={{ mt: 2, width: '150px' }}>
                      <Typography textAlign='center'>Sig Status</Typography>

                      <SwitchField
                        label='Sig'
                        size='small'
                        checked={sigStatus}
                        onClick={handleSigStatus}
                      />
                    </Grid>
                  )}
                  <Grid item container>
                    <TextField
                      required
                      id='Sig-text-input'
                      label='Sig'
                      name='sig'
                      minRows={3}
                      multiline
                      fullWidth
                      disabledLabel
                      maxLength={250}
                      validations={[
                        validateMaxLength('Maximum of 180 characters', 180),
                        validateMinLength('Minimum of 3 characters', 3),
                      ]}
                    />
                  </Grid>
                </Grid>

                <Grid container justifyContent='space-between' alignItems='center' sx={{ px: 1 }}>
                  <Typography>Associated Prescription Direction</Typography>
                  {!showNewDirectionBox && (
                    <Button
                      size='small'
                      variant='contained'
                      color='primary'
                      onClick={() => setShowNewDirectionBox(!showNewDirectionBox)}
                    >
                      Create New Association
                    </Button>
                  )}
                </Grid>

                {showNewDirectionBox && (
                  <Grid container sx={{ mt: 1 }}>
                    <TextField
                      id='Direction-text-input'
                      name='direction'
                      label='Direction'
                      multiline
                      minRows={3}
                      fullWidth
                      maxLength={250}
                      disabledLabel
                      validations={[
                        validateMinLength('Minimum of 3 characters', 3),
                        validateDuplicate(
                          'An association already exists for this sig and direction combination',
                          allDirections
                        ),
                      ]}
                    />
                    <Grid container gap={1} sx={{ mb: 3 }} justifyContent='flex-end'>
                      <Button
                        size='small'
                        variant='outlined'
                        color='secondary'
                        onClick={() => setShowNewDirectionBox(false)}
                      >
                        Cancel
                      </Button>
                      <Button
                        size='small'
                        variant='contained'
                        color='primary'
                        disabled={isEmpty(values?.direction) || invalid || loading}
                        onClick={() => {
                          handleNewAssociations(values.direction);
                          form.batch(() => form.change('direction', ''));
                        }}
                      >
                        Add Association
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {associationsLoading && (
                  <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    sx={{ mt: 2, minHeight: '200px' }}
                  >
                    <CircularProgress />
                  </Grid>
                )}

                {!associationsLoading && (
                  <Grid container sx={{ mt: 2 }}>
                    <List sx={{ width: '100%', p: 1 }}>
                      {!isEmpty(newAssociations) && (
                        <Typography variant='caption'>Newly Added Associations : </Typography>
                      )}
                      {map(newAssociations, ({ direction, directionStatus }, index) => (
                        <ListItem
                          key={index}
                          sx={{
                            p: 2,
                            my: 1,
                            backgroundColor: lighten(theme.palette.secondary.light, 0.9),
                          }}
                          disableGutters
                          secondaryAction={
                            <Grid>
                              <Checkbox
                                size='small'
                                edge='end'
                                onChange={() => handleAssociationStatus('direction', direction)}
                                checked={directionStatus}
                                sx={{ mr: 1 }}
                              />
                              <IconButton
                                onClick={() => handleRemoveAssociation('direction', direction)}
                                sx={{ mr: 1 }}
                              >
                                <DeleteIcon color='error' size='small' />
                              </IconButton>
                            </Grid>
                          }
                        >
                          <ListItemText sx={{ maxWidth: '85%' }} secondary={direction} />
                        </ListItem>
                      ))}

                      {!isEmpty(existingAssociations) && (
                        <Typography variant='caption'>Existing Associations : </Typography>
                      )}
                      {map(
                        existingAssociations,
                        ({ direction, directionId, directionStatus }, index) => (
                          <ListItem
                            key={index}
                            sx={{
                              p: 2,
                              my: 1,
                              backgroundColor: lighten(theme.palette.secondary.light, 0.9),
                            }}
                            disableGutters
                            secondaryAction={
                              <Grid>
                                <Checkbox
                                  size='small'
                                  edge='end'
                                  onChange={() =>
                                    handleAssociationStatus('directionId', directionId)
                                  }
                                  checked={directionStatus}
                                  sx={{ mr: 1 }}
                                />
                                <IconButton
                                  onClick={() =>
                                    handleRemoveAssociation('directionId', directionId)
                                  }
                                  sx={{ mr: 1 }}
                                >
                                  <DeleteIcon color='error' size='small' />
                                </IconButton>
                              </Grid>
                            }
                          >
                            <ListItemText sx={{ maxWidth: '85%' }} secondary={direction} />
                          </ListItem>
                        )
                      )}
                    </List>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                disabled={invalid || associationsLoading}
                variant='contained'
                type='submit'
              >
                Save Changes
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

export default ManageSigModal;

ManageSigModal.propTypes = {
  sigData: PropTypes.shape({
    sig: PropTypes.string.isRequired,
    sigId: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
  }),
  showModal: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSigAssociations: PropTypes.func.isRequired,
};

ManageSigModal.defaultProps = {
  sigData: null,
};
