import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, omit, values } from 'lodash';

import { filterInClaimElgibilityRequests } from 'store/thunks/workQueueThunks';
import RequestStatus from 'enums/request.enum';
import lineItemStatus from 'common/constants/lineItemStatus';

const InClaimEligibilityFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.inClaimEligibility?.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterInClaimElgibilityRequests({
        filter: {
          ...filter,
          [filterName]: undefined,
          requestStatus: RequestStatus.OPEN,
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(filterInClaimElgibilityRequests({ filter: { requestStatus: RequestStatus.OPEN } }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.state && (
        <Chip
          label={`Status = ${lineItemStatus[filter.state]}`}
          variant='contained'
          onDelete={removeFilter('state')}
        />
      )}

      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.partnerUuid && (
        <Chip
          label={`Partner Name = ${
            find(partners, { partnerUuid: filter.partnerUuid }).partnerName
          }`}
          variant='contained'
          onDelete={removeFilter('partnerUuid')}
        />
      )}

      {filter.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('requestedDate')}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('needsByDate')}
        />
      )}

      {!isEmpty(compact(values(omit(filter, 'requestStatus')))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default InClaimEligibilityFilterStatusBar;
