import moment from 'moment';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateMinLength } from 'common/forms/formValidations';
import { updateDrugProfilesFilter } from 'store/thunks/drugThunks';

import { DrugProfileFieldKeys, DrugProfileStatus } from 'enums/drugManagement.enum';

const DrugsFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ drugs: { drugProfiles } }) => ({
    ...drugProfiles.filter,
    ...(drugProfiles.filter?.createdAt && {
      createdAt: new Date(drugProfiles.filter?.createdAt),
    }),
    ...(drugProfiles.filter?.updatedAt && { updatedAt: new Date(drugProfiles.filter?.updatedAt) }),
    isActive: drugProfiles.filter?.isActive ? drugProfiles.filter.isActive : 'ALL',
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'DrugFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      updateDrugProfilesFilter({
        filter: {
          ...formData,
          createdAt: formData?.createdAt && moment(formData.createdAt).format('YYYY-MM-DD'),
          updatedAt: formData?.updatedAt && moment(formData.updatedAt).format('YYYY-MM-DD'),
          isActive:
            formData?.isActive === DrugProfileStatus.ALL
              ? undefined
              : formData.isActive === DrugProfileStatus.ACTIVE,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Drug Name'
                    name={DrugProfileFieldKeys.DRUG_NAME}
                    id='DrugFilterButton-drugName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Created At'
                    name={DrugProfileFieldKeys.CREATED_AT}
                    id='DrugFilterButton-createdAt-dateField'
                    maxDate={new Date()}
                  />

                  <TextField
                    label='Strength'
                    name={DrugProfileFieldKeys.STRENGTH}
                    id='DrugFilterButton-strength-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Updated At'
                    name={DrugProfileFieldKeys.UPDATED_AT}
                    id='DrugFilterButton-updatedAt-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Status'
                    name={DrugProfileFieldKeys.STATUS}
                    id='DrugFilterButton-status-dropdown'
                  >
                    <MenuItem value={DrugProfileStatus.ALL}>All</MenuItem>
                    <MenuItem value={DrugProfileStatus.ACTIVE}>ACTIVE</MenuItem>
                    <MenuItem value={DrugProfileStatus.INACTIVE}>INACTIVE</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default DrugsFilterButton;
