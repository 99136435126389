import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Dialog, DialogContent, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { find, isEmpty } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PrescriptionReviewStatus } from 'enums/prescription.enum';

import PatientPrescriptionDialog from 'modules/PatientView/components/PrescriptionsTab/PatientPrescriptionDialog';
import PrescriptionReviewPayloadProvider from 'common/hooks/usePrescriptionReviewPayloadContext';
import useInfoDialog from 'common/hooks/useInfoDialog';
import ResolveDuplicatePrescriptionModal from './ResolveDuplicatePrescriptionModal';
import PrescriptionComparisonModel from './PrescriptionComparisonModel';

const PrescriptionReviewOptions = ({ status, prescriptionId, patientId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [resolveModal, setResolveModal] = useState(false);
  const [comparisonView, setComparisonView] = useState(false);
  const menuOpen = !!anchorEl;
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const { pages, currentPage } = useSelector(({ workQueue }) => workQueue.prescriptionReview);
  const prescriptions = pages[currentPage];
  const prescription = find(
    prescriptions,
    ({ pomPrescriptionId }) => pomPrescriptionId === prescriptionId
  );

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const openResolveModal = () => {
    setResolveModal(true);
    handleMenuClose();
  };
  const closeResolveModal = (payload) => {
    if (!isEmpty(payload)) {
      showInfoDialog({
        title: 'Unsaved Changes',
        message: 'You have unsaved changes. Closing will discard them. Continue?',
        confirmButtonText: 'Yes',
        cancelable: true,
        callback: () => setResolveModal(false),
      });
    } else {
      setResolveModal(false);
    }
  };

  const handleComparisonModalView = () => {
    setResolveModal(!resolveModal);
    setComparisonView(!comparisonView);
  };

  if (!prescription) {
    return null;
  }

  return (
    <>
      <InfoDialog />
      <IconButton
        id={`PrescriptionReview-${prescription.rxNumber}-Options`}
        aria-label={`Review Options ${prescription.rxNumber}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`Prescription-${prescriptionId}options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {status === PrescriptionReviewStatus.PRESCRIPTION_DUPLICATE_REVIEW && (
          <MenuItem onClick={handleComparisonModalView}>View</MenuItem>
        )}
        <MenuItem onClick={openResolveModal}>Resolve</MenuItem>
      </Menu>

      {resolveModal && status === PrescriptionReviewStatus.PRESCRIPTION_DUPLICATE_REVIEW && (
        <Grid>
          {comparisonView ? (
            <PrescriptionComparisonModel
              open={comparisonView}
              onClose={handleComparisonModalView}
              prescriptionId={prescriptionId}
            />
          ) : (
            <ResolveDuplicatePrescriptionModal
              open={resolveModal}
              onClose={closeResolveModal}
              orderId={prescription.orderId}
              prescriptionId={prescriptionId}
            />
          )}
        </Grid>
      )}

      {resolveModal && status !== PrescriptionReviewStatus.PRESCRIPTION_DUPLICATE_REVIEW && (
        <Dialog open={resolveModal}>
          <DialogContent>
            <Grid container sx={{ px: 8 }} spacing={3}>
              <Grid item xs={12} md={8}>
                <PrescriptionReviewPayloadProvider>
                  <PatientPrescriptionDialog
                    mpi={patientId}
                    open={resolveModal}
                    reviewType={status}
                    contextId={prescriptionId}
                    closeDialog={closeResolveModal}
                    isPrescriptionReview
                  />
                </PrescriptionReviewPayloadProvider>
              </Grid>
            </Grid>

            <Grid className='FooterMessage' sx={{ display: 'none' }}>
              <Typography>This is printed copy and not the original</Typography>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default PrescriptionReviewOptions;

PrescriptionReviewOptions.propTypes = {
  prescriptionId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  patientId: PropTypes.string,
};

PrescriptionReviewOptions.defaultProps = {
  patientId: '',
};
