import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { BookmarkAdd } from '@mui/icons-material';

import RichTextViewer from 'common/components/RichTextViewer';
import useRoles from 'common/hooks/useRoles';
import { NoteState } from 'common/constants/notes';

const NoteItem = ({ note, onUpdateNoteStatus }) => {
  const { noteId, noteAuthor, noteContent, noteState, noteCreatedAt } = note;

  const showPinIcon = noteState === NoteState.UNPIN;
  const hasAccess = useRoles();

  const pinNote = () => onUpdateNoteStatus(noteId, NoteState.PIN);

  return (
    <Grid container wrap='nowrap' sx={{ p: 1 }}>
      <Grid item sx={{ minWidth: 200 }}>
        <Grid container direction='column' sx={{ p: 1 }}>
          <Grid item>
            <Typography>
              {noteAuthor.firstName} {noteAuthor.lastName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant='caption'>
              {format(new Date(noteCreatedAt), 'hh:mmaaa MM/dd/yyyy')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item sx={{ flexGrow: 1 }}>
        <RichTextViewer value={noteContent} id={`NoteItem-NoteId-${noteId}`} />
      </Grid>

      {showPinIcon && (
        <Grid item>
          <Tooltip title='Pin' arrow placement='top'>
            <span>
              <IconButton
                id={`NoteItem-NoteId-${noteId}`}
                aria-label={`Pin note ${noteId}`}
                onClick={pinNote}
                disabled={!hasAccess.noteAction}
              >
                <BookmarkAdd />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

NoteItem.propTypes = {
  note: PropTypes.shape({
    noteId: PropTypes.string.isRequired,
    noteState: PropTypes.string.isRequired,
    noteCreatedAt: PropTypes.string.isRequired,
    noteContent: PropTypes.string.isRequired,
    noteAuthor: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      pomUserId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onUpdateNoteStatus: PropTypes.func.isRequired,
};

export default NoteItem;
