import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const errorHandlerSlice = createSlice({
  name: 'errorHandler',
  initialState,
  reducers: {
    resetErrorHandlerState(state) {
      Object.assign(state, initialState);
    },
    setError(state, action) {
      const { error } = action.payload;
      state.error = error;
    },
  },
});

export const errorHandlerActions = errorHandlerSlice.actions;
export default errorHandlerSlice.reducer;
