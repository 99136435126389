import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import useRoles from 'common/hooks/useRoles';
import { activateInsurance, deactivateInsurance } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

import EditInsuranceDialog from './EditInsuranceDialog';

const PatientInsuranceOptions = ({ patientInsuranceId, mpi, isActive }) => {
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleShowEditDialog = () => {
    setShowEditDialog(true);
    handleMenuClose();
  };
  const handleCloseEditDialog = () => setShowEditDialog(false);

  const handleDeactivateInsurance = () => {
    handleMenuClose();
    showInfoDialog({
      title: 'Confirm Deactivation',
      message: 'Are you sure you want to deactivate this insurance?',
      callback: () => dispatch(deactivateInsurance({ mpi, patientInsuranceId })),
      cancelable: true,
      isWarning: true,
      confirmButtonText: 'Confirm',
    });
  };

  const handleActivateInsurance = () => {
    handleMenuClose();
    dispatch(activateInsurance({ mpi, patientInsuranceId }));
  };

  return (
    <>
      <InfoDialog />
      <EditInsuranceDialog
        mpi={mpi}
        patientInsuranceId={patientInsuranceId}
        open={showEditDialog}
        onClose={handleCloseEditDialog}
      />

      <IconButton
        id={`PatientView-PatientInsuranceOptions-${patientInsuranceId}options-button`}
        aria-label={`Insurance options ${patientInsuranceId}`}
        aria-controls={
          menuOpen
            ? `PatientView-PatientInsuranceOptions-${patientInsuranceId}options-menu`
            : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientInsuranceOptions-${patientInsuranceId}options-menu`}
        aria-labelledby={`PatientView-PatientInsuranceOptions-${patientInsuranceId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowEditDialog} disabled={!hasAccess.updateInsurance}>
          Edit
        </MenuItem>
        {!isActive && (
          <MenuItem onClick={handleActivateInsurance} disabled={!hasAccess.updateInsurance}>
            Activate
          </MenuItem>
        )}
        {isActive && (
          <MenuItem onClick={handleDeactivateInsurance} disabled={!hasAccess.updateInsurance}>
            Deactivate
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

PatientInsuranceOptions.propTypes = {
  patientInsuranceId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default PatientInsuranceOptions;
