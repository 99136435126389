import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, map, omit, some } from 'lodash';
import { useState } from 'react';
import { normalizePhoneNumber } from 'common/utils';
import PropTypes from 'prop-types';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import PatientPharmacyOptions from './PatientPharmacyOptions';

const PharmaciesLabelValue = ({ value, label, md, lg }) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container direction='column' item xs={12} sm={6} md={md} lg={lg}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

PharmaciesLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
};
PharmaciesLabelValue.defaultProps = {
  value: '',
  md: 2,
  lg: 2,
};

const PharmacyRow = ({ pharmacy, mpi }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((show) => !show);

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset', bgcolor: showDetails ? '#0000000a' : 'inherit' } }}
        hover
      >
        <TableCell>
          <IconButton
            aria-label={`Toggle Details ${pharmacy.name}`}
            size='small'
            onClick={toggleDetails}
            disabled={
              !some(
                omit(pharmacy, [
                  'patientPharmacyId',
                  'name',
                  'phoneNumber',
                  'isActive',
                  'fax',
                  'npi',
                ])
              )
            }
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' align='left'>
          {pharmacy.name}
        </TableCell>
        <TableCell align='center'>{normalizePhoneNumber(pharmacy.phoneNumber)}</TableCell>
        <TableCell align='center'>{normalizePhoneNumber(pharmacy.fax)}</TableCell>
        <TableCell align='center'>{pharmacy.npi}</TableCell>

        <TableCell align='center'>
          <PatientPharmacyOptions mpi={mpi} patientPharmacyId={pharmacy.patientPharmacyId} />
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderTop: 'unset' } }}>
        <TableCell colSpan={8} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showDetails} timeout='auto' unmountOnExit>
            <Grid container sx={{ px: 10, pt: 2, pb: 6 }} gap={2}>
              <Grid container spacing={2}>
                <PharmaciesLabelValue
                  label='Address Line 1'
                  value={pharmacy.addressLine1}
                  md={4}
                  lg={3}
                />
                <PharmaciesLabelValue label='Address Line 2' value={pharmacy.addressLine2} />
                <PharmaciesLabelValue label='City' value={pharmacy.city} />
                <PharmaciesLabelValue label='State' value={pharmacy.state} />
                <PharmaciesLabelValue label='Zip' value={pharmacy.zip} />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

PharmacyRow.propTypes = {
  mpi: PropTypes.string.isRequired,
  pharmacy: PropTypes.shape({
    patientPharmacyId: PropTypes.string,
    name: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    phoneNumber: PropTypes.string,
    fax: PropTypes.string,
    npi: PropTypes.string,
    isActive: PropTypes.bool,
  }).isRequired,
};

const PatientPharmaciesTable = ({ mpi }) => {
  const pharmacies = useSelector(({ patient }) => patient[mpi]?.pharmacies);

  return (
    <>
      <TableContainer>
        <Table aria-label='Pharmacy List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Pharmacy Name</TableCell>
              <TableCell align='center'>Phone</TableCell>
              <TableCell align='center'>Fax</TableCell>
              <TableCell align='center'>NPI</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(pharmacies, (pharmacy) => (
              <PharmacyRow key={pharmacy.patientPharmacyId} pharmacy={pharmacy} mpi={mpi} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(pharmacies) && <LoadingModule height='25vh' />}

      {!isNil(pharmacies) && isEmpty(pharmacies) && <NothingFound height='25vh' />}
    </>
  );
};

PatientPharmaciesTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};
export default PatientPharmaciesTable;
