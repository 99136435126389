import { useEffect, useState } from 'react';
import { Grid, Paper, useMediaQuery } from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import signInWaveImg from 'assets/img/signInWave.svg';
import TeamIllustration from 'assets/illustrations/Team';
import { checkAndRefreshSession, loadUserFromJwt } from 'store/thunks/authThunks';
import { getUserAuthApi } from 'api/auth';
import { decodeB64 } from 'common/utils';

import SignInForm from './components/SignInForm';

const SignIn = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams] = useSearchParams();
  const authRef = searchParams.get('authRef');
  const state = searchParams.get('state');

  const { username, userId } = useSelector(
    ({ auth }) => ({ username: auth.username, userId: auth.userId }),
    shallowEqual
  );

  useEffect(() => {
    document.title = 'Sign In - SmartOS';

    return () => {
      document.title = 'SmartOS - SmartScripts';
    };
  }, []);

  useEffect(() => {
    if (username && userId) {
      navigate(location.state?.from || (state && decodeB64(state)) || '/', { replace: true });
    } else if (authRef && loading) {
      getUserAuthApi({ authRef })
        .then(() => dispatch(loadUserFromJwt()))
        .catch(() => setLoading(false));
    } else if (loading) {
      dispatch(checkAndRefreshSession()).catch(() => {
        setLoading(false);
      });
    }
  }, [authRef, username, userId, dispatch, navigate, location, loading, state]);

  if (loading) {
    return null;
  }

  return (
    <Grid container justifyContent='center' alignItems='center' sx={{ height: '100vh' }}>
      <img
        src={signInWaveImg}
        alt='wave'
        aria-hidden
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: -1,
          opacity: 0.9,
        }}
      />

      <Grid
        item
        component={Paper}
        container
        elevation={4}
        sx={{
          maxWidth: /* istanbul ignore next */ isMobile ? '80vw' : '800px',
          borderRadius: 3,
          overflow: 'hidden',
        }}
        justifyContent='space-evenly'
      >
        <Grid item xs={12} md={6} sx={{ height: '100%', width: '100%', py: 3 }}>
          <SignInForm />
        </Grid>

        {!isMobile && (
          <Grid
            item
            md={6}
            sx={{
              height: '100%',
              width: '100%',
              bgcolor: lighten(theme.palette.primary.main, 0.8),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TeamIllustration />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SignIn;
