import { shallowEqual, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { useEffect } from 'react';

import useIdleSignOut from 'common/hooks/useIdleSignOut';
import roles from 'common/constants/roles';

const ProtectedRoutes = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const userRoles = useSelector(({ auth }) => auth.currentTenantRoles);
  const { username, userId } = useSelector(
    ({ auth }) => ({ username: auth.username, userId: auth.userId }),
    shallowEqual
  );

  useEffect(() => {
    if (includes(userRoles, roles.shippingUser)) {
      navigate('/patients');
    }
  }, [userRoles, navigate]);

  useIdleSignOut();

  if (!username || !userId) {
    return (
      <Navigate to='/signin' state={{ from: `${location.pathname}${location.search}` }} replace />
    );
  }

  return <div key={username}>{children}</div>;
};

ProtectedRoutes.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoutes;
