import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Box, Grid, IconButton, List, ListItem, Tooltip, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isNil, map, trim } from 'lodash';
import LoadingButton from '@mui/lab/LoadingButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import PropTypes from 'prop-types';

import useTabContext from 'common/hooks/useTabContext';
import { patientActions } from 'store/slices/patientSlice';
import { addPatientComments, listPatientComments } from 'store/thunks/patientThunks';
import MarkdownField from 'common/forms/MarkdownField';
import useRoles from 'common/hooks/useRoles';
import { validateMaxLength } from 'common/forms/formValidations';
import FormNavigationBlocker from 'common/forms/FormNavigationBlocker';

import PatientComment from './PatientComment';
import PatientCommentSearchInput from './PatientCommentSearchInput';

const SearchContext = createContext();

const clearPatientComments = (dispatch, mpi) => {
  dispatch(
    patientActions.loadComments({
      mpi,
      comments: undefined,
      hasMoreComments: false,
    })
  );
};

const PatientCommentsTab = ({ mpi }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const comments = useSelector(({ patient }) => patient[mpi]?.comments);
  const hasMoreComments = useSelector(({ patient }) => patient[mpi]?.hasMoreComments);
  const hasAccess = useRoles();
  const isActiveTab = useTabContext().tab === 'PatientCommentsTab';
  const { searchText, searchComment } = useContext(SearchContext);

  const submitComment = ({ message }) => {
    return dispatch(addPatientComments({ mpi, message }));
  };

  const loadMoreComments = () => {
    return dispatch(listPatientComments({ mpi, offset: comments.length, searchText }));
  };

  const reloadComments = () => {
    setLoading(true);
    clearPatientComments(dispatch, mpi);
    return dispatch(listPatientComments({ mpi, offset: 0, searchText })).then(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (isActiveTab) {
      dispatch(listPatientComments({ mpi, offset: 0, searchText }));
    }
  }, [dispatch, mpi, isActiveTab, searchText]);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item xs={12}>
        <Form
          onSubmit={submitComment}
          render={({ handleSubmit, values, form, submitting, invalid }) => {
            const onSubmit = (event) => handleSubmit(event).then(form.reset);

            return (
              <form noValidate onSubmit={onSubmit}>
                <FormNavigationBlocker />
                <MarkdownField
                  name='message'
                  id='PatientView-PatientCommentsTab-comment-input'
                  label='Comment:'
                  validations={[
                    validateMaxLength('The comment is too long. Please make it shorter', 65435),
                  ]}
                  disabled={hasAccess.addComments}
                />

                <LoadingButton
                  sx={{ minWidth: 100, float: 'right' }}
                  loading={submitting}
                  type='submit'
                  variant='contained'
                  disabled={!trim(values.message) || invalid || !hasAccess.addComments}
                >
                  Submit
                </LoadingButton>
              </form>
            );
          }}
        />
      </Grid>

      <Box sx={{ display: 'flex', flexDirection: 'row-reverse', mt: 3 }}>
        <Tooltip title='Refresh Comments' placement='bottom'>
          <IconButton aria-label='refresh comments' onClick={reloadComments} disabled={loading}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        <PatientCommentSearchInput onSubmit={searchComment} />
      </Box>

      <List sx={{ p: 2, maxHeight: 800, overflowY: 'auto', mt: 2 }}>
        {map(comments, (comment) => (
          <ListItem
            disablePadding
            key={comment.commentId}
            sx={{ borderTop: 1, borderColor: theme.palette.divider }}
          >
            <PatientComment comment={comment} />
          </ListItem>
        ))}
      </List>

      <Grid item container justifyContent='center' sx={{ minHeight: 100 }}>
        {hasMoreComments && (
          <Form
            onSubmit={loadMoreComments}
            render={({ submitting, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit}>
                <LoadingButton type='submit' variant='contained' loading={submitting}>
                  Load more comments
                </LoadingButton>
              </form>
            )}
          />
        )}

        {!hasMoreComments && !isNil(comments) && <Typography>No more comments</Typography>}
        {isNil(comments) && <Typography>Loading Comments...</Typography>}
      </Grid>
    </Grid>
  );
};

PatientCommentsTab.propTypes = {
  mpi: PropTypes.string.isRequired,
};

const PatientCommentsTabWithContext = () => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useDispatch();
  const { mpi } = useParams();

  const searchComment = useCallback(
    ({ text }) => {
      if (searchText === text) {
        return; // dont do anything if the search text has already been searched
      }

      clearPatientComments(dispatch, mpi);
      setSearchText(text);
    },
    [mpi, dispatch, searchText]
  );

  const contextValue = useMemo(
    () => ({
      searchText,
      searchComment,
    }),
    [searchText, searchComment]
  );

  return (
    <SearchContext.Provider value={contextValue}>
      <PatientCommentsTab mpi={mpi} />
    </SearchContext.Provider>
  );
};

export default PatientCommentsTabWithContext;
