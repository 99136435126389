/* eslint-disable import/prefer-default-export */

import { isNull, reduce } from 'lodash';
import graphqlClient from '../graphql';
import {
  capturePaymentGql,
  getFailedOrderDetailsGql,
  getPublicKeyGql,
  getTransactionHistoryGql,
  listPaymentTransactionsGql,
  refundPaymentGql,
  saveAndAuthorizePaymentGql,
  voidPaymentGql,
} from './gql';
import { transformFailedPatientOrders, transformListPaymentTransactions } from './transform';

const buildEventFilters = (filter) =>
  reduce(
    filter,
    (result, value, key) => ({
      ...result,
      [key]: isNull(value) ? undefined : value,
    }),
    {}
  );

export const listTransactionsApi = ({ filter, sortBy, page }) =>
  graphqlClient
    .request(listPaymentTransactionsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: buildEventFilters(filter),
    })
    .then(transformListPaymentTransactions);

export const captureTransactionApi = ({ userId, patientId, externalTransactionId, amount }) =>
  graphqlClient
    .request(capturePaymentGql, {
      userId,
      patientId,
      externalTransactionId,
      amount,
    })
    .then(({ capturePayment }) => capturePayment);

export const refundTransactionApi = ({ userId, patientId, externalTransactionId, amount }) =>
  graphqlClient
    .request(refundPaymentGql, {
      userId,
      patientId,
      externalTransactionId,
      amount,
    })
    .then(({ refundPayment }) => refundPayment);

export const voidTransactionApi = ({ userId, patientId, externalTransactionId }) =>
  graphqlClient
    .request(voidPaymentGql, {
      userId,
      patientId,
      externalTransactionId,
    })
    .then(({ voidPayment }) => voidPayment);

export const getTransactionHistoryApi = ({ externalTransactionId }) =>
  graphqlClient
    .request(getTransactionHistoryGql, { externalTransactionId })
    .then(({ getTransactionHistory }) => getTransactionHistory);

export const getFailedOrderDetailsApi = ({ mpi, orderId }) =>
  graphqlClient
    .request(getFailedOrderDetailsGql, { mpi, orderId })
    .then(transformFailedPatientOrders);

export const saveAndAuthorizePaymentApi = ({ inputPayload }) =>
  graphqlClient
    .request(saveAndAuthorizePaymentGql, { ...inputPayload })
    .then(({ saveAndAuthorizePayment }) => saveAndAuthorizePayment);

export const getPublicKeyApi = async () =>
  graphqlClient.request(getPublicKeyGql).then(({ getPublicKey }) => atob(getPublicKey));
