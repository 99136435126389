import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import NewInsuranceButton from './NewInsuranceButton';
import PatientInsurancesDnD from './PatientInsurancesDnD';

const PatientInsuranceTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Insurances
        </Typography>
        {hasAccess.addInsurance && <NewInsuranceButton mpi={mpi} />}
      </Grid>

      <Grid item>
        <PatientInsurancesDnD mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientInsuranceTab;
