export default {
  OPEN: 'OPEN',
  HOLD: 'HOLD',
  ORDER_VERIFIED: 'ORDER_VERIFIED',
  ORDER_VERIFICATION: 'ORDER_VERIFICATION',
  ADDED_TO_SHIPMENT: 'ADDED_TO_SHIPMENT',
  READY_FOR_SHIPMENT: 'READY_FOR_SHIPMENT',
  SHIPPED: 'SHIPPED',
  ORDER_RE_VERIFICATION: 'ORDER_RE_VERIFICATION',
};
