import {
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';
import { capitalize, isEmpty, isNaN, isNil, map } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { listPatientInvoices } from 'store/thunks/patientThunks';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';

import InvoiceViewer from './InvoiceViewer';

const InvoicesTable = ({ mpi }) => {
  const dispatch = useDispatch();
  const { invoices, currentPage, totalPages } = useSelector(
    ({ patient }) => ({
      invoices: patient[mpi]?.invoices?.pages?.[patient[mpi]?.invoices?.currentPage],
      currentPage: patient[mpi]?.invoices?.currentPage,
      // eslint-disable-next-line no-unsafe-optional-chaining
      totalPages: Math.ceil(patient[mpi]?.invoices?.count / 10),
    }),
    shallowEqual
  );

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return; // page didnt change, don't do anything.
    }

    dispatch(listPatientInvoices({ mpi, page }));
  };

  return (
    <Grid container>
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 150 }}>Created Date</TableCell>
              <TableCell sx={{ width: 150 }}>Due Date</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell sx={{ width: 150 }} align='right'>
                Amount Due
              </TableCell>
              <TableCell sx={{ width: 100 }} align='right'>
                Status
              </TableCell>
              <TableCell sx={{ width: 25 }} />
            </TableRow>
          </TableHead>

          <TableBody>
            {!isNil(invoices) &&
              !isEmpty(invoices) &&
              map(invoices, (invoice) => (
                <TableRow key={invoice.invoiceId} hover>
                  <TableCell sx={{ width: 150 }}>
                    {format(new Date(invoice.invoiceDate), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell sx={{ width: 150 }}>
                    {format(new Date(invoice.dueDate), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>{invoice.invoiceNumber}</TableCell>
                  <TableCell sx={{ width: 150 }} align='right'>
                    {!isNaN(parseFloat(invoice.totalAmount, 10))
                      ? `$${(Math.round(invoice.totalAmount * 100) / 100).toFixed(2)}`
                      : 'N/A'}
                  </TableCell>
                  <TableCell sx={{ width: 100 }} align='right'>
                    {capitalize(invoice.invoiceStatus)}
                  </TableCell>
                  <TableCell sx={{ width: 25 }}>
                    <InvoiceViewer
                      invoiceNumber={invoice.invoiceNumber}
                      invoiceFileKey={invoice.invoiceFileKey}
                      invoiceId={invoice.invoiceId}
                      mpi={mpi}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          {!isNil(invoices) && totalPages > 1 && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={6} align='right' sx={{ borderBottom: 'none' }}>
                  <Pagination
                    sx={{
                      justifyContent: 'flex-end',
                      alignItems: 'flex-end',
                      display: 'flex',
                      mt: 1,
                    }}
                    count={totalPages}
                    page={currentPage}
                    size='small'
                    onChange={handlePageChange}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {isNil(invoices) && <LoadingModule height='25vh' />}

      {!isNil(invoices) && isEmpty(invoices) && <NothingFound height='25vh' />}
    </Grid>
  );
};

InvoicesTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default InvoicesTable;
