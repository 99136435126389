import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';

import { filterProfileIncompletePatients } from 'store/thunks/workQueueThunks';

const IncompleteProfileFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.profileIncomplete?.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterProfileIncompletePatients({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterProfileIncompletePatients({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.name && (
        <Chip label={`Name = ${filter.name}`} variant='contained' onDelete={removeFilter('name')} />
      )}

      {filter.dob && (
        <Chip
          label={`Date of Birth = ${format(new Date(filter.dob), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('dob')}
        />
      )}

      {filter.createdAfter && (
        <Chip
          label={`Created After = ${format(new Date(filter.createdAfter), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('createdAfter')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default IncompleteProfileFilterStatusBar;
