import { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const PrescriptionReviewPayloadContext = createContext({});

const PrescriptionReviewPayloadProvider = ({ children }) => {
  const [payload, setPayload] = useState({});

  const value = useMemo(() => ({ payload, setPayload }), [payload, setPayload]);

  return (
    <PrescriptionReviewPayloadContext.Provider value={value}>
      {children}
    </PrescriptionReviewPayloadContext.Provider>
  );
};

PrescriptionReviewPayloadProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default PrescriptionReviewPayloadProvider;
