import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { isPlainObject, map, reduce } from 'lodash';

const EventDataViewer = ({ event }) => {
  const [open, setOpen] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const flattenObject = (obj, prefix = '') => {
    return reduce(
      obj,
      (result, value, key) => {
        const flattenedKey = prefix ? `${prefix}.${key}` : key;

        if (isPlainObject(value)) {
          const flattenedValue = flattenObject(value, flattenedKey);
          return { ...result, ...flattenedValue };
        }
        return { ...result, [flattenedKey]: value };
      },
      {}
    );
  };

  return (
    <>
      <IconButton aria-label={`View Event ${event.eventId}`} onClick={openDialog}>
        <OpenInNewIcon />
      </IconButton>

      <Dialog open={open} maxWidth='lg' aria-labelledby='Events-EventViewer-header'>
        <DialogTitle id='Events-EventViewer-header' fontWeight={600}>
          Event - {event.eventId}
        </DialogTitle>

        <DialogContent sx={{ paddingLeft: 5, maxWidth: '100vh' }}>
          {map(flattenObject(event.eventData), (value, key) => (
            <Typography paddingBottom={1} key={key}>
              {' '}
              {`${key} : ${JSON.stringify(value)}`}{' '}
            </Typography>
          ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDialog} variant='contained' sx={{ maxWidth: 150 }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

EventDataViewer.propTypes = {
  event: PropTypes.shape({
    eventId: PropTypes.string.isRequired,
    eventData: PropTypes.shape({}).isRequired,
  }).isRequired,
};

export default EventDataViewer;
