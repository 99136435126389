/* eslint-disable import/prefer-default-export */

export const fetchPackageActivities = async ({ trackingNumber }) => {
  return fetch(`https://ustrack17.com/api/track/guess/${trackingNumber}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_US_TRACK17_AUTH_TOKEN}`,
    },
  }).then((data) => data.json());
};
