/* eslint-disable import/prefer-default-export */
import { isNull, reduce } from 'lodash';
import { format } from 'date-fns';

import { transformPatientAddress } from 'api/patient/transform';
import graphqlClient from '../graphql';
import {
  transformListOrders,
  transformListOrdersForShipment,
  transformListPomOrders,
  transformOrderShipment,
} from './transform';
import {
  cancelOrderGql,
  createOrderShipmentGql,
  getOrdersGql,
  listOrdersForShipmentGql,
  listOrdersGql,
  manageProductFulfillmentGql,
  orderNeedsReviewGql,
  prescriptionNeedsReviewGql,
  reverifyOrderGql,
  updateOrderAddressGql,
  verifyOrderGql,
} from './gql';

export const listOrdersApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrdersGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrders);
};

export const manageProductFulfillmentApi = ({ lineItemId, mpi, orderId, state, failureReason }) => {
  return graphqlClient
    .request(manageProductFulfillmentGql, { lineItemId, mpi, orderId, state, failureReason })
    .then(({ manageProductFulfillmentStatus }) => manageProductFulfillmentStatus);
};

export const verifyOrderApi = ({ orderId, mpi }) => {
  return graphqlClient
    .request(verifyOrderGql, { orderId, mpi })
    .then(({ verifyOrder }) => verifyOrder);
};

export const createOrderShipmentApi = ({ mpi, orderIds, shipmentType }) => {
  return graphqlClient
    .request(createOrderShipmentGql, { orderIds, mpi, shipmentType })
    .then(transformOrderShipment);
};

export const cancelOrderApi = ({ orderId, cancelReason, cancelNote }) => {
  return graphqlClient
    .request(cancelOrderGql, { orderId, cancelReason, cancelNote })
    .then(({ cancelOrder }) => cancelOrder);
};

export const listOrdersForShipmentApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrdersForShipmentGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrdersForShipment);
};

export const orderNeedsReviewApi = ({ orderId, mpi }) =>
  graphqlClient
    .request(orderNeedsReviewGql, { orderId, mpi })
    .then(({ markOrderForExternalProcessing }) => markOrderForExternalProcessing);

export const updateOrderAddressApi = ({ orderId, mpi, addressInput }) =>
  graphqlClient
    .request(updateOrderAddressGql, { orderId, addressInput: { mpi, ...addressInput } })
    .then(({ updateOrderAddress }) => {
      // eslint-disable-next-line no-underscore-dangle
      if (updateOrderAddress?.__typename === 'Addresses') {
        return updateOrderAddress;
      }

      return transformPatientAddress(updateOrderAddress);
    });

export const prescriptionNeedsReviewAPI = ({ prescriptionUUID, patientId }) =>
  graphqlClient
    .request(prescriptionNeedsReviewGql, { prescriptionUUID, patientId })
    .then(({ prescriptionNeedsReview }) => prescriptionNeedsReview);

export const getOrdersApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    createdAt: filter.createdAt && format(new Date(filter.createdAt), 'yyyy-MM-dd'),
    updatedAt: filter.updatedAt && format(new Date(filter.updatedAt), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(getOrdersGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({ ...result, [key]: isNull(value) ? undefined : value }),
        {}
      ),
    })
    .then(transformListPomOrders);
};

export const reverifyOrderApi = ({ orderId, mpi }) => {
  return graphqlClient
    .request(reverifyOrderGql, { orderId, mpi })
    .then(({ reverifyOrder }) => reverifyOrder);
};
