import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { isEmpty, isNil, map, reduce, toLower } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import { Link } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { listBulkLabelsByPage, sortBulkLabelsWorkQueue } from 'store/thunks/workQueueThunks';
import { PrintLabelCheckBox, SelectAllCheckBox } from 'common/hooks/usePrintLabelContext';
import lineItemStatus from 'common/constants/lineItemStatus';

const headCells = [
  {
    id: 'patientName',
    label: 'Patient Name',
    align: 'left',
    sortable: true,
  },
  {
    id: 'medicationName',
    label: 'Medication Name',
    align: 'right',
    sortable: true,
  },
  {
    id: 'itemStatus',
    label: 'Product Status',
    align: 'right',
    sortable: true,
  },
  {
    id: 'isTaggedForReview',
    label: 'Product in Review',
    align: 'right',
    sortable: false,
  },
  {
    id: 'needsByDate',
    label: 'Needs By Date',
    align: 'right',
    sortable: true,
  },
  {
    id: 'createdAt',
    label: 'Created At',
    align: 'right',
    sortable: true,
  },
  {
    id: 'lastPrintedDate',
    label: 'Last Printed Timestamp',
    align: 'right',
    sortable: true,
  },
];

const BulkLabelsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage } = useSelector(({ workQueue }) => workQueue.bulkLabels);
  const bulkLabels = pages[currentPage];
  const allLabelItemIds = reduce(
    bulkLabels,
    (acc, { lineItemId, isTaggedForReview }) => (!isTaggedForReview ? [...acc, lineItemId] : acc),
    []
  );

  useEffect(() => {
    dispatch(listBulkLabelsByPage({ page: 1 }));
  }, [dispatch]);

  const totalPages = useSelector(({ workQueue }) => Math.ceil(workQueue.bulkLabels.count / 25));

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortBulkLabelsWorkQueue({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didn't change, don't do anything.
      return;
    }

    dispatch(listBulkLabelsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {headCell.sortable ? (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        data-head-property={headCell.id}
                        onClick={handleSort}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
                <TableCell align='right' sx={{ py: 0 }}>
                  <SelectAllCheckBox
                    allLineItemIds={allLabelItemIds}
                    isDisabled={isEmpty(allLabelItemIds)}
                  />
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {map(bulkLabels, ({ mpi, patientName, ...lineItem }) => (
                <TableRow key={lineItem.lineItemId} hover>
                  <TableCell component='th' scope='row' align='left'>
                    <Link to={`/patients/${mpi}`} style={{ color: theme.palette.text.primary }}>
                      {patientName}
                    </Link>
                  </TableCell>
                  <TableCell align='right'>{lineItem.medicationName}</TableCell>
                  <TableCell align='right'>
                    {lineItem.lineItemStatus ? lineItemStatus[lineItem.lineItemStatus] : 'NA'}
                  </TableCell>
                  <TableCell align='right'>{lineItem.isTaggedForReview ? 'Yes' : 'No'}</TableCell>
                  <TableCell align='right'>
                    {format(utcToZonedTime(new Date(lineItem.needsByDate), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell align='right'>
                    {format(utcToZonedTime(new Date(lineItem.createdAt), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell align='right'>
                    {lineItem.lastPrintedDate
                      ? format(new Date(lineItem.lastPrintedDate), 'MM/dd/yyyy hh:mm a')
                      : 'NA'}
                  </TableCell>
                  <TableCell align='right'>
                    <PrintLabelCheckBox
                      lineItemId={lineItem.lineItemId}
                      isDisabled={lineItem.isTaggedForReview}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(bulkLabels) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={7} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(bulkLabels) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(bulkLabels) && isEmpty(bulkLabels) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default BulkLabelsTable;
