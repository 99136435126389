import {
  Box,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { includes, isEmpty, isNil, map, toLower } from 'lodash';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useEffect } from 'react';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import {
  listFailedAdjudicationsByPage,
  sortReviewAdjudications,
} from 'store/thunks/workQueueThunks';
import { ContextType } from 'common/constants/notes';
import { SortOrder } from 'enums/common.enum';

import PinnedNotesButton from './ReviewAdjudicationPinnedNotesButton';
import ReviewAdjudicationOptions from './ReviewAdjudicationOptions';

const headCells = [
  {
    id: 'patientName',
    label: 'Patient Name',
    align: 'left',
  },
  {
    id: 'qs1Id',
    label: 'QS1 Id',
    align: 'left',
  },
  {
    id: 'adjudicatedTime',
    label: 'Adjudicated Time',
    align: 'left',
  },
  {
    id: 'rxNumber',
    label: 'Rx Number',
    align: 'left',
  },
  {
    id: 'lastFillDate',
    label: 'Last Fill Date',
    align: 'left',
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
  },
];

const sortableFields = ['patientName', 'adjudicatedTime'];

const ReviewAdjudicationTable = () => {
  const { sortBy, pages, currentPage } = useSelector(
    ({ workQueue }) => workQueue.reviewAdjudication,
    shallowEqual
  );

  const adjudicationPrescriptions = pages[currentPage];

  const totalPages = useSelector(({ workQueue }) =>
    Math.ceil(workQueue.reviewAdjudication.count / 25)
  );

  const dispatch = useDispatch();

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;
    dispatch(
      sortReviewAdjudications({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }

    dispatch(listFailedAdjudicationsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(listFailedAdjudicationsByPage({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align={headCell.align}>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy.sortKey === headCell.id}
                        direction={
                          sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'
                        }
                        data-head-property={headCell.id}
                        onClick={handleSort}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell colSpan={2} />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(
                adjudicationPrescriptions,
                ({
                  patientName,
                  patientId,
                  adjudicatedTime,
                  adjudicationStatus,
                  patientQS1Id,
                  rxNumber,
                  prescriptionId,
                  prescriptionLastFillDate,
                  hasPinnedNotes,
                  rxRequestId,
                }) => (
                  <TableRow key={prescriptionId} hover>
                    <TableCell align='left'>{patientName}</TableCell>
                    <TableCell align='left'>{patientQS1Id}</TableCell>
                    <TableCell align='left'>
                      {format(
                        utcToZonedTime(new Date(adjudicatedTime), 'utc'),
                        'MM/dd/yyyy hh:mm a'
                      )}
                    </TableCell>
                    <TableCell align='left'>{rxNumber}</TableCell>
                    <TableCell align='left'>
                      {format(
                        utcToZonedTime(new Date(prescriptionLastFillDate), 'utc'),
                        'MM/dd/yyyy'
                      )}
                    </TableCell>
                    <TableCell align='left'>{adjudicationStatus}</TableCell>
                    <TableCell align='left'>
                      <PinnedNotesButton
                        contextId={prescriptionId}
                        contextType={ContextType.PRESCRIPTION}
                        mpi={patientId}
                        hasPinnedNotes={hasPinnedNotes}
                      />
                    </TableCell>
                    <TableCell align='left'>
                      <ReviewAdjudicationOptions
                        mpi={patientId}
                        contextId={prescriptionId}
                        contextType={ContextType.PRESCRIPTION}
                        rxRequestId={rxRequestId}
                      />
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>

            {!isNil(adjudicationPrescriptions) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={8} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(adjudicationPrescriptions) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(adjudicationPrescriptions) && isEmpty(adjudicationPrescriptions) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default ReviewAdjudicationTable;
