import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  currentPage: 1,
  pages: {},
  count: 0,
  filter: {
    referrerName: undefined,
    referrerShortCode: undefined,
  },
  sortBy: {
    sortKey: 'createdTimestamp',
    sortOrder: 'DESC',
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const referrerSlice = createSlice({
  name: 'referrer',
  initialState,
  reducers: {
    loadReferrerProfiles(state, action) {
      const { page, items, count } = action.payload;

      state.count = count;
      state.pages[page] = items;
    },
    updateCurrentPage(state, action) {
      const { page } = action.payload;

      state.currentPage = page;
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.pages = {};
      state.count = 0;
      state.sortBy = sortBy;
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      state.pages = {};
      state.count = 0;
      state.filter = filter;
    },
  },
});

export const referrerActions = referrerSlice.actions;
export default referrerSlice.reducer;
