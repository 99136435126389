import { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { map } from 'lodash';

import SelectField from 'common/forms/SelectField';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { filterReviewInClaimEligibilityLineItems } from 'store/thunks/workQueueThunks';
import { validateMinLength } from 'common/forms/formValidations';

import { SortKey } from 'enums/common.enum';

const ReviewInClaimEligibilityFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const filter = useSelector(({ workQueue: { reviewInClaimEligibility } }) => ({
    ...reviewInClaimEligibility.filter,
    requestedDate:
      reviewInClaimEligibility.filter?.requestedDate &&
      new Date(reviewInClaimEligibility.filter.requestedDate),
    needsByDate:
      reviewInClaimEligibility.filter?.needsByDate &&
      new Date(reviewInClaimEligibility.filter.needsByDate),
  }));

  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-ReviewInClaimEligibilityFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterReviewInClaimEligibilityLineItems({
        filter: {
          ...formData,
          requestedDate: formData.requestedDate && formData.requestedDate.toISOString(),
          needsByDate: formData.needsByDate && formData.needsByDate.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name={SortKey.PATIENT_NAME}
                    id='WorkQueue-ReviewInClaimEligibilityFilterButton-patientName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Partner Name'
                    name={SortKey.PARTNER_UUID}
                    id='WorkQueue-ReviewInClaimEligibilityFilterButton-partnerUuid-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(partners, ({ partnerName, partnerUuid }) => (
                      <MenuItem value={partnerUuid} key={partnerUuid}>
                        {partnerName}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <DateField
                    label='Requested Date'
                    name={SortKey.REQUESTED_DATE}
                    id='WorkQueue-ReviewInClaimEligibilityFilterButton-requestedDate-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Needs By Date'
                    name={SortKey.NEEDS_BY_DATE}
                    id='WorkQueue-ReviewInClaimEligibilityFilterButton-needsByDate-dateField'
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default ReviewInClaimEligibilityFilterButton;
