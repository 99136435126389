import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import useInfoDialog from 'common/hooks/useInfoDialog';
import { reviewLineItem } from 'store/thunks/patientThunks';
import ReviewFailureDialog from './ReviewFailureDialog';

const ReviewPrescriberClarification = ({ mpi, lineItemId, requestId }) => {
  const [showFailDialog, setShowFailDialog] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const dispatch = useDispatch();

  const handleProceed = () => {
    showInfoDialog({
      title: 'Confirm Proceed',
      message: 'Are you sure you want to proceed?',
      callback: () => {
        dispatch(reviewLineItem({ mpi, lineItemId, reviewResult: true, requestId }));
      },
      cancelable: true,
      confirmButtonText: 'Proceed',
    });
  };

  const handleReviewPrescriberWillNotFill = () => {
    setShowFailDialog(true);
  };

  const closeFailDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowFailDialog(false);
    }
  };

  const confirmFailureDialog = ({ reasonForRejection }) => {
    return dispatch(
      reviewLineItem({ mpi, lineItemId, reviewResult: false, reasonForRejection, requestId })
    ).then(() => {
      closeFailDialog();
    });
  };

  return (
    <>
      <ReviewFailureDialog
        confirmFailure={confirmFailureDialog}
        open={showFailDialog}
        onClose={closeFailDialog}
      />
      <InfoDialog />
      <Grid container gap={2} direction='column'>
        <Typography>
          If prescriber clarification is not needed, please proceed, otherwise will not fill.
        </Typography>
      </Grid>
      <Grid container direction='row-reverse' sx={{ mt: 4 }}>
        <Button variant='contained' sx={{ width: 100 }} onClick={handleProceed}>
          Proceed
        </Button>
        <Button
          sx={{ mr: 2, width: 100 }}
          variant='contained'
          color='error'
          onClick={handleReviewPrescriberWillNotFill}
        >
          Will Not Fill
        </Button>
      </Grid>
    </>
  );
};

ReviewPrescriberClarification.propTypes = {
  mpi: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default ReviewPrescriberClarification;
