import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, find, isEmpty, omit, values } from 'lodash';

import { SortKey } from 'enums/common.enum';
import { filterReviewInClaimEligibilityLineItems } from 'store/thunks/workQueueThunks';

const ReviewInClaimEligibilityFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.reviewInClaimEligibility?.filter);
  const partners = useSelector(({ auth, partner }) => partner[auth.currentTenantId]);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterReviewInClaimEligibilityLineItems({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () =>
    dispatch(
      filterReviewInClaimEligibilityLineItems({
        filter: {
          taggedForReview: true,
        },
      })
    );

  return (
    <Stack direction='row' spacing={1}>
      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter(SortKey.PATIENT_NAME)}
        />
      )}

      {filter.requestedDate && (
        <Chip
          label={`Requested Date = ${format(new Date(filter.requestedDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.REQUESTED_DATE)}
        />
      )}

      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter(SortKey.NEEDS_BY_DATE)}
        />
      )}

      {filter.partnerUuid && (
        <Chip
          label={`Partner Name = ${
            find(partners, { partnerUuid: filter.partnerUuid }).partnerName
          }`}
          variant='contained'
          onDelete={removeFilter(SortKey.PARTNER_UUID)}
        />
      )}

      {!isEmpty(compact(values(omit(filter, 'taggedForReview')))) && (
        <Button onClick={removeAllFilter}>Remove All</Button>
      )}
    </Stack>
  );
};

export default ReviewInClaimEligibilityFilterStatusBar;
