import { BookmarkRemove } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { filter, find, isEmpty, isNil, map } from 'lodash';
import { forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import RichTextViewer from 'common/components/RichTextViewer';
import { updateFailedAdjudicationNoteStatus } from 'store/thunks/workQueueThunks';
import { NoteState } from 'common/constants/notes';

import NoteItem from './ReviewAdjudicationNoteItem';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const DraggableNote = ({ noteId, noteContent, contextId }) => {
  const dispatch = useDispatch();
  const nodeRef = useRef(null);

  const handleUnPinNote = () =>
    dispatch(updateFailedAdjudicationNoteStatus({ noteId, action: NoteState.UNPIN, contextId }));

  return (
    <Draggable key={noteId} nodeRef={nodeRef}>
      <Grid item ref={nodeRef} sx={{ zIndex: 1 }}>
        <Box
          sx={{
            width: 200,
            height: 200,
            bgcolor: '#fffd75',
            boxShadow: 5,
            borderRadius: 1,
            overflow: 'auto',
            ':hover': {
              cursor: 'move',
            },
          }}
        >
          <Grid container>
            <Grid item container justifyContent='flex-end'>
              <Grid item>
                <Tooltip title='Un Pin' arrow placement='top'>
                  <IconButton onClick={handleUnPinNote} aria-label={`Unpin note ${noteId}`}>
                    <BookmarkRemove />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid sx={{ px: 2 }} item>
              <RichTextViewer
                value={noteContent}
                id={`WorkQueue-ReviewFailedAdjudication-${noteId}`}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Draggable>
  );
};

DraggableNote.propTypes = {
  contextId: PropTypes.string.isRequired,
  noteId: PropTypes.string.isRequired,
  noteContent: PropTypes.string.isRequired,
};

const NotesDialog = ({ open, onClose, contextId }) => {
  const theme = useTheme();
  const { currentPage, pages } = useSelector(({ workQueue }) => workQueue.reviewAdjudication);
  const itemNotes = find(pages[currentPage], { prescriptionId: contextId })?.notes;

  const pinnedNotes = filter(itemNotes, ({ noteState }) => noteState === NoteState.PIN);
  const unPinnedNotes = filter(itemNotes, ({ noteState }) => noteState === NoteState.UNPIN);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Grid
        container
        sx={{ px: 2, p: 1, bgcolor: 'primary.light' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography component='h2' variant='h6'>
          Notes
        </Typography>

        <IconButton onClick={onClose} aria-label='Close Dialog'>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        {isNil(itemNotes) && <LoadingModule height='25vh' />}
        {!isNil(itemNotes) && isEmpty(itemNotes) && <NothingFound height='25vh' />}
        <Grid container>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              {isEmpty(pinnedNotes) && <NothingFound height='25vh' />}

              {map(pinnedNotes, ({ noteId, noteContent }) => (
                <DraggableNote
                  key={noteId}
                  noteContent={noteContent}
                  noteId={noteId}
                  contextId={contextId}
                />
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            {!isEmpty(pinnedNotes) && <Grid sx={{ pb: 1, fontWeight: 'bold' }}>Pinned Notes</Grid>}

            {!isEmpty(pinnedNotes) && (
              <List sx={{ pb: 2 }}>
                {map(pinnedNotes, (note) => (
                  <ListItem
                    disablePadding
                    key={note.noteId}
                    sx={{ border: 1, borderColor: theme.palette.divider, minWidth: 500 }}
                  >
                    <NoteItem note={note} contextId={contextId} />
                  </ListItem>
                ))}
              </List>
            )}

            {!isEmpty(unPinnedNotes) && (
              <Grid sx={{ pb: 1, fontWeight: 'bold' }}>UnPinned Notes</Grid>
            )}

            {!isEmpty(unPinnedNotes) && (
              <List>
                {map(unPinnedNotes, (note) => (
                  <ListItem
                    disablePadding
                    key={note.noteId}
                    sx={{ border: 1, borderColor: theme.palette.divider, minWidth: 500 }}
                  >
                    <NoteItem note={note} contextId={contextId} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NotesDialog.propTypes = {
  contextId: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotesDialog;
