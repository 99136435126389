import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  drugProfiles: {
    currentPage: 1,
    pages: {},
    count: 0,
    filter: {
      drugName: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      strength: undefined,
      isActive: undefined,
    },
    sortBy: {
      sortKey: 'drugName',
      sortOrder: 'ASC',
    },
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const drugSlice = createSlice({
  name: 'drugs',
  initialState,
  reducers: {
    loadDrugProfiles(state, action) {
      const { page, items, count } = action.payload;

      state.drugProfiles.count = count;
      state.drugProfiles.pages[page] = items;
    },
    updateCurrentPage(state, action) {
      const { page } = action.payload;

      state.drugProfiles.currentPage = page;
    },
    updateFilter(state, action) {
      const { filter } = action.payload;

      state.drugProfiles.pages = {};
      state.drugProfiles.count = 0;
      state.drugProfiles.filter = filter;
    },
    updateSortBy(state, action) {
      const { sortBy } = action.payload;

      state.drugProfiles.pages = {};
      state.drugProfiles.count = 0;
      state.drugProfiles.sortBy = sortBy;
    },
  },
});

export const drugActions = drugSlice.actions;
export default drugSlice.reducer;
