import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { some, trim } from 'lodash';

import TextField from 'common/forms/TextField';
import DateField from 'common/forms/DateField';
import { addPatientMedicalHistory } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';

const AddSurgeryButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const surgeries = useSelector(({ patient }) => patient[mpi]?.medicalHistory?.surgeries);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddSurgery = (formData) => {
    const dateOfSurgery = format(formData.dateOfSurgery, 'yyyy-MM-dd');

    const identicalSurgeryExists = some(
      surgeries,
      (surgery) =>
        new RegExp(`^${trim(formData.surgeryName)}$`, 'i').test(surgery.surgeryName) &&
        dateOfSurgery === surgery.dateOfSurgery
    );

    if (identicalSurgeryExists) {
      return showInfoDialog({
        title: 'Identical Surgery Found',
        message: 'A surgery with the same name and date already exist.',
      });
    }

    return dispatch(
      addPatientMedicalHistory({
        newMedicalHistory: {
          mpi,
          surgery: [
            {
              ...formData,
              dateOfSurgery,
            },
          ],
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <InfoDialog />
      <Button
        variant='contained'
        disabled={!patientName}
        onClick={handleOpenModal}
        aria-label='add surgery'
      >
        Add
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-MedicalHistoriesTab-AddSurgeryButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-MedicalHistoriesTab-AddSurgeryButton-header'>
          Add Surgery
        </DialogTitle>

        <Form
          onSubmit={handleAddSurgery}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <TextField
                  id='PatientView-MedicalHistoriesTab-AddSurgeryButton-surgeryName-input'
                  label='Surgery Name'
                  name='surgeryName'
                  required
                  autoFocus
                />

                <DateField
                  label='Date of Surgery'
                  name='dateOfSurgery'
                  id='PatientView-MedicalHistoriesTab-AddSurgeryButton-dateOfSurgery-input'
                  required
                />

                <TextField
                  id='PatientView-MedicalHistoriesTab-AddSurgeryButton-description-input'
                  label='Description'
                  name='description'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

AddSurgeryButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default AddSurgeryButton;
