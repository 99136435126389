/* istanbul ignore file */

import JSEncrypt from 'jsencrypt';
import { map, omit, split } from 'lodash';

/* eslint-disable import/prefer-default-export */
export const setCookie = ({ name, value, expDays = 1 }) => {
  const date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
};

export const getCookie = (name) => {
  return decodeURIComponent(document.cookie).match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
};

const getCCIssuer = (cardNumber) => {
  if (/^3[47][0-9]{13}$/gi.test(cardNumber)) {
    return 'Amex';
  }

  if (
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/gi.test(
      cardNumber
    )
  ) {
    return 'Master';
  }

  if (
    /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/gi.test(
      cardNumber
    )
  ) {
    return 'Discover';
  }

  if (/^4[0-9]{12}(?:[0-9]{3})?$/gi.test(cardNumber)) {
    return 'Visa';
  }

  return 'unknown';
};

export const encryptCardData = ({ cardData, publicKey }) => {
  const jsEncrypt = new JSEncrypt();

  jsEncrypt.setPublicKey(publicKey);
  const [expirationMonth, expirationYear] = split(cardData.expiration, '/');

  return jsEncrypt.encrypt(
    JSON.stringify({
      ...omit(cardData, ['expiration']),
      expirationMonth,
      expirationYear: `20${expirationYear}`,
      issuer: getCCIssuer(cardData.cardNumber),
    })
  );
};

export const applyFulfillingPharmacyFilter = (store, filter) => {
  if (filter?.fulfillingPharmacyIds) {
    return filter;
  }

  const fulfillingPharmacies = store().auth?.fulfillingPharmacies;
  const fulfillingPharmacyIds = map(
    fulfillingPharmacies,
    ({ fulfillingPharmacyId }) => fulfillingPharmacyId
  );

  return { ...filter, fulfillingPharmacyIds };
};
