export default [
  'Friend/Family Referral',
  'Doctor Referral',
  'Hospital Referral',
  'In Home Care Provider Referral',
  'Online',
  'News',
  'Radio',
  'SmartScripts Employee/Dependent',
];
