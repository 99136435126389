import moment from 'moment';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { SigFieldKeys, SigStatus } from 'enums/SigManagement.enum';
import { updateSigAssociationsFilter } from 'store/thunks/sigThunks';
import { validateMinLength } from 'common/forms/formValidations';

const SigsFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const sigFilter = useSelector(({ sigs: { filter } }) => ({
    ...filter,
    ...(filter?.createdAt && { createdAt: new Date(filter?.createdAt) }),
    ...(filter?.updatedAt && { updatedAt: new Date(filter?.updatedAt) }),
    status: isNil(filter?.status) ? SigStatus.ALL : filter?.status,
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'SigFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      updateSigAssociationsFilter({
        filter: {
          ...formData,
          createdAt: formData?.createdAt && moment(formData.createdAt).format('YYYY-MM-DD'),
          updatedAt: formData?.updatedAt && moment(formData.updatedAt).format('YYYY-MM-DD'),
          status: formData?.status === SigStatus.ALL ? undefined : formData?.status,
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>
      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={sigFilter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Sig'
                    name={SigFieldKeys.SIG}
                    id='SigFilterButton-sig-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Direction'
                    name={SigFieldKeys.DIRECTION}
                    id='SigFilterButton-direction-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Created At'
                    name={SigFieldKeys.CREATED_AT}
                    id='SigFilterButton-createdAt-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Updated At'
                    name={SigFieldKeys.UPDATED_AT}
                    id='SigFilterButton-updatedAt-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Status'
                    name={SigFieldKeys.STATUS}
                    id='SigFilterButton-status-dropdown'
                  >
                    <MenuItem value={SigStatus.ALL}>All</MenuItem>
                    <MenuItem value={SigStatus.ACTIVE}>ACTIVE</MenuItem>
                    <MenuItem value={SigStatus.INACTIVE}>INACTIVE</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default SigsFilterButton;
