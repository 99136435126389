import React, { useEffect } from 'react';
import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { includes, isBoolean, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { visuallyHidden } from '@mui/utils';
import { format } from 'date-fns';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { SigFieldKeys, SigStatus } from 'enums/SigManagement.enum';
import { loadSigAssociations, updateSigAssociationsSortBy } from 'store/thunks/sigThunks';
import { SortOrder } from 'enums/common.enum';

import SigOptions from './SigOptions';

const headCells = [
  {
    id: SigFieldKeys.SIG,
    label: 'Sig',
  },
  {
    id: SigFieldKeys.DIRECTION,
    label: 'Direction',
  },
  {
    id: SigFieldKeys.CREATED_AT,
    label: 'Created At',
  },
  {
    id: SigFieldKeys.UPDATED_AT,
    label: 'Updated At',
  },
  {
    id: SigFieldKeys.STATUS,
    label: 'Status',
  },
];

const sortableFields = [
  SigFieldKeys.SIG,
  SigFieldKeys.DIRECTION,
  SigFieldKeys.CREATED_AT,
  SigFieldKeys.UPDATED_AT,
];

const SigsTable = () => {
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage, count } = useSelector(({ sigs }) => sigs);

  const sigList = pages[currentPage];
  const totalPages = Math.ceil(count / 25);

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      updateSigAssociationsSortBy({
        sortBy: {
          sortKey,
          sortOrder:
            sortBy.sortKey === sortKey && sortBy.sortOrder === SortOrder.ASCENDING
              ? SortOrder.DESCENDING
              : SortOrder.ASCENDING,
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      return;
    }
    dispatch(loadSigAssociations({ page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    dispatch(loadSigAssociations({ page: 1 }));
  }, [dispatch]);

  return (
    <Grid component={Paper} container direction='column' sx={{ py: 1, px: 2 }}>
      <Grid item container>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id} align='left'>
                    {!includes(sortableFields, headCell.id) ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={sortBy?.sortKey === headCell.id}
                        direction={
                          sortBy?.sortKey === headCell.id ? toLower(sortBy?.sortOrder) : 'asc'
                        }
                        onClick={handleSort}
                        data-head-property={headCell.id}
                      >
                        {headCell.label}
                        {sortBy.sortKey === headCell.id ? (
                          <Box component='span' sx={visuallyHidden}>
                            {toLower(sortBy.sortOrder) === 'desc'
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {map(
                sigList,
                ({ sigId, sig, direction, createdAt, updatedAt, isActive, sigStatus }, index) => {
                  let directionStatus;
                  if (isBoolean(isActive)) {
                    directionStatus = isActive ? SigStatus.ACTIVE : SigStatus.INACTIVE;
                  }

                  return (
                    <TableRow key={`${sigId}-${index}`} hover>
                      <TableCell align='left' sx={{ maxWidth: '200px' }}>
                        {sig}
                      </TableCell>
                      <TableCell align='left' sx={{ maxWidth: '200px' }}>
                        {direction || 'N/A'}
                      </TableCell>
                      <TableCell align='left'>
                        {createdAt ? format(new Date(createdAt), 'MM/dd/yyyy') : 'N/A'}
                      </TableCell>
                      <TableCell align='left'>
                        {updatedAt ? format(new Date(updatedAt), 'MM/dd/yyyy') : 'N/A'}
                      </TableCell>
                      <TableCell align='left'>{directionStatus || 'N/A'}</TableCell>
                      <TableCell align='left'>
                        <SigOptions sigData={{ sigId, sig, isActive: sigStatus }} />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>

            {!isNil(sigList) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={9} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(sigList) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(sigList) && isEmpty(sigList) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default SigsTable;
