/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listKnownMedicalConditionsGql = gql`
  query listKnownMedicalConditions($ICDCode: String, $medicalConditionName: String) {
    listKnownMedicalConditions(ICDCode: $ICDCode, medicalConditionName: $medicalConditionName) {
      ICDCode
      medicalConditionName: description
    }
  }
`;
