import { useState } from 'react';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import moment from 'moment';

import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { validateMinLength } from 'common/forms/formValidations';
import { filterReviewAdjudications } from 'store/thunks/workQueueThunks';
import SelectField from 'common/forms/SelectField';

const ReviewAdjudicationFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const filter = useSelector(({ workQueue: { reviewAdjudication } }) => ({
    ...reviewAdjudication.filter,
    ...(reviewAdjudication.filter?.adjudicatedTime && {
      adjudicatedTime: new Date(reviewAdjudication.filter.adjudicatedTime),
    }),
  }));

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'WorkQueue-ReviewAdjudicationFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterReviewAdjudications({
        filter: {
          ...formData,
          adjudicatedTime:
            formData.adjudicatedTime && moment(formData.adjudicatedTime).format('YYYY-MM-DD'),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Patient Name'
                    name='patientName'
                    id='WorkQueue-ReviewAdjudicationFilterButton-name-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Adjudicated Time'
                    name='adjudicatedTime'
                    id='WorkQueue-ReviewAdjudicationFilterButton-adjudicatedTime-dateField'
                    maxDate={new Date()}
                  />

                  <TextField
                    label='Rx Number'
                    name='rxNumber'
                    id='WorkQueue-ReviewAdjudicationFilterButton-RxNumber-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />
                  <SelectField
                    label='Status'
                    name='adjudicationStatus'
                    id='WorkQueue-ReviewAdjudicationFilterButton-adjudicationStatus-dropdown'
                  >
                    <MenuItem value='RX_NOT_FOUND'>RX_NOT_FOUND</MenuItem>
                    <MenuItem value='EXPIRED_RX'>EXPIRED_RX</MenuItem>
                    <MenuItem value='EARLY_REFILL'>EARLY_REFILL</MenuItem>
                    <MenuItem value='NO_REFILLS'>NO_REFILLS</MenuItem>
                    <MenuItem value=''>All</MenuItem>
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default ReviewAdjudicationFilterButton;
