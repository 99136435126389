/* eslint-disable import/prefer-default-export */
import { getPartnerPracticesApi, getPartnersApi } from 'api/partner';
import { partnerActions } from 'store/slices/partnerSlice';

import { handleError } from './errorHandlerThunks';

export const getPartners = () => (dispatch, getState) => {
  const { currentTenantId } = getState().auth;

  return getPartnersApi()
    .then((partners) => {
      dispatch(partnerActions.loadPartners({ partners, currentTenantId }));
    })
    .catch((error) => dispatch(handleError({ error })));
};

export const getPartnerPractices =
  ({ partnerId }) =>
  (dispatch, getState) => {
    const { currentTenantId } = getState().auth;

    return getPartnerPracticesApi(partnerId)
      .then((practices) => {
        dispatch(
          partnerActions.loadPartnerPractices({
            currentTenantId,
            practices,
            partnerId,
          })
        );
      })
      .catch((error) => {
        dispatch(handleError({ error }));
      });
  };
