import {
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  Radio,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import RadioGroupField from 'common/forms/RadioGroupField';
import {
  composeValidations,
  validateMaxNumber,
  validateMinNumber,
  validateRequired,
} from 'common/forms/formValidations';
import { useTheme } from '@emotion/react';
import { Field, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';

const OptionContainer = styled(Grid, { shouldForwardProp: (prop) => prop !== 'selected' })(
  ({ theme, selected }) => ({
    border: `2px solid ${selected ? theme.palette.primary.main : theme.palette.background.default}`,
    borderRadius: '4px',
    padding: theme.spacing(0.5, 2, 0.5, 0),
    marginBottom: theme.spacing(1),
  })
);

const TransactionAmountSelector = ({ amount, label }) => {
  const { values, touched, errors } = useFormState();
  const selectedType = values.amountType;
  const theme = useTheme();

  const formatAmountToPrecision = (value) => {
    const formatted = Number(value).toFixed(2);

    // making sure it is a valid number and not 0
    return Number(formatted) ? formatted : '';
  };

  return (
    <RadioGroupField
      ariaLabel='Transactions-amount-selector'
      name='amountType'
      id='Transactions-amount-selector'
      label={label}
    >
      <OptionContainer
        container
        alignItems='center'
        selected={selectedType === 'fullAmount'}
        sx={{ mt: 2 }}
      >
        <FormControlLabel
          sx={{ mx: 0, flexGrow: 1 }}
          value='fullAmount'
          control={<Radio />}
          label='Full Amount'
        />

        <Grid item>$ {amount}</Grid>
      </OptionContainer>

      <OptionContainer
        container
        alignItems='center'
        selected={selectedType === 'otherAmount'}
        sx={{ mt: 2 }}
      >
        <FormControlLabel
          sx={{ mx: 0, flexGrow: 1 }}
          value='otherAmount'
          control={<Radio />}
          label='Other'
        />

        {selectedType === 'otherAmount' && (
          <Grid item sx={{ maxWidth: 100 }}>
            <Field
              name='otherAmount'
              formatOnBlur
              format={formatAmountToPrecision}
              validate={composeValidations([
                validateRequired(),
                validateMaxNumber('Amount should not be higher than the full amount', amount),
                validateMinNumber('Amount should not be less than 0', 0),
              ])}
              render={({ input, meta }) => (
                <InputBase
                  autoFocus
                  error={!!meta.error && !!meta.touched}
                  type='number'
                  startAdornment={
                    <InputAdornment position='end' sx={{ mr: 1 }}>
                      $
                    </InputAdornment>
                  }
                  sx={{
                    mr: -1,
                    borderColor: theme.palette.background.default,
                    '&:focus, &.Mui-focused': {
                      borderColor: theme.palette.background.default,
                    },
                    '&.Mui-error, &.Mui-error:invalid, &.Mui-error:focus&:invalid': {
                      borderColor: theme.palette.error.main,
                    },
                  }}
                  inputProps={{
                    'aria-label': 'Other',
                    'aria-required': true,
                    onKeyPress: (event) => {
                      /* istanbul ignore next */
                      if (event.key === 'Enter') event.preventDefault();
                    },
                  }}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...input} // intentional spreading, injecting final-form
                  value={input.value || ''} // https://github.com/final-form/react-final-form/issues/529
                />
              )}
            />
          </Grid>
        )}
      </OptionContainer>
      <FormHelperText error sx={{ fontSize: '0.9em', mt: -0.5, textAlign: 'right' }}>
        {touched?.otherAmount && errors?.otherAmount}
      </FormHelperText>

      {selectedType === 'otherAmount' && (
        <Typography sx={{ mx: 'auto' }}>
          * If capturing other amount, please update the Transaction in QS1
        </Typography>
      )}
    </RadioGroupField>
  );
};

TransactionAmountSelector.propTypes = {
  amount: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
};

export default TransactionAmountSelector;
