import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, values } from 'lodash';
import { filterEvents } from 'store/thunks/eventThunks';

const EventsFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ criticalEvents }) => criticalEvents.events?.filter);

  const removeFilter = (filterName) => () => {
    dispatch(
      filterEvents({
        filter: {
          ...filter,
          [filterName]: undefined,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterEvents({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.pomUserName && (
        <Chip
          label={`Event Actor = ${filter.pomUserName}`}
          variant='contained'
          onDelete={removeFilter('pomUserName')}
        />
      )}

      {filter.eventType && (
        <Chip
          label={`Event Type = ${filter.eventType.replace(/_/g, ' ')}`}
          variant='contained'
          onDelete={removeFilter('eventType')}
        />
      )}

      {filter.createdAfter && (
        <Chip
          label={`Created After = ${format(new Date(filter.createdAfter), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('createdAfter')}
        />
      )}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default EventsFilterStatusBar;
