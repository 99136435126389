import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import { map, omit, reduce } from 'lodash';
import PropTypes from 'prop-types';

import TextField from 'common/forms/TextField';
import SelectField from 'common/forms/SelectField';
import { validateEmail, validatePhoneNumber, validateZipCode } from 'common/forms/formValidations';
import { addGuarantor } from 'store/thunks/patientThunks';
import guarantorRelationship from 'common/constants/guarantorRelationship';
import CheckboxField from 'common/forms/CheckboxField';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { formatZipCode } from 'common/utils';

const NewGuarantorButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const patientName = useSelector(
    ({ patient }) => ({
      firstName: patient[mpi]?.firstName,
      middleName: patient[mpi]?.middleName,
      lastName: patient[mpi]?.lastName,
    }),
    shallowEqual
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddGuarantor = (formData) => {
    const phoneNumbers = reduce(
      formData,
      (results, value, key) => {
        switch (key) {
          case 'mobilePhone':
            return [...results, { phoneNumber: value, tags: ['MOBILE'] }];
          case 'workPhone':
            return [...results, { phoneNumber: value, tags: ['WORK'] }];
          case 'homePhone':
            return [...results, { phoneNumber: value, tags: ['HOME'] }];
          default:
            return results;
        }
      },
      []
    );

    const guarantor = {
      textsEnabled: false,
      isEmergencyContact: false,
      ...omit(formData, ['mobilePhone', 'workPhone', 'homePhone']),
      ...(formData.relationship === 'SELF' && patientName),
      mpi,
      phoneNumbers,
    };

    return dispatch(addGuarantor({ guarantor })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <Button variant='contained' disabled={!patientName.firstName} onClick={handleOpenModal}>
        New
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-NewGuarantorButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-NewGuarantorButton-header'>Add Guarantor</DialogTitle>

        <Form
          onSubmit={handleAddGuarantor}
          render={({ handleSubmit, invalid, submitting, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  name='relationship'
                  id='PatientView-NewGuarantorButton-relationship-dropdown'
                  label='Relationship'
                  required
                >
                  {map(guarantorRelationship, (value, key) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </SelectField>

                {values.relationship !== 'SELF' && (
                  <>
                    <TextField
                      id='PatientView-NewGuarantorButton-firstName-input'
                      label='First Name'
                      name='firstName'
                      required
                    />

                    <TextField
                      id='PatientView-NewGuarantorButton-middleName-input'
                      label='Middle Name'
                      name='middleName'
                    />

                    <TextField
                      id='PatientView-NewGuarantorButton-lastName-input'
                      label='Last Name'
                      name='lastName'
                      required
                    />
                  </>
                )}

                <TextField
                  id='PatientView-NewGuarantorButton-email-input'
                  label='Email'
                  name='email'
                  required
                  validations={[validateEmail()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-mobilePhoneNumber-input'
                  label='Mobile Phone Number'
                  name='mobilePhone'
                  required
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-workPhoneNumber-input'
                  label='Work Phone Number'
                  name='workPhone'
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-homePhoneNumber-input'
                  label='Home Phone Number'
                  name='homePhone'
                  validations={[validatePhoneNumber()]}
                />

                <TextField
                  id='PatientView-NewGuarantorButton-addressLine1-input'
                  label='Address Line 1'
                  name='addressLine1'
                />

                <TextField
                  id='PatientView-NewGuarantorButton-addressLine2-input'
                  label='Address Line 2'
                  name='addressLine2'
                />

                <TextField
                  id='PatientView-NewGuarantorButton-city-input'
                  label='City'
                  name='city'
                />

                <SelectField
                  id='PatientView-NewGuarantorButton-state-dropdown'
                  label='State'
                  name='state'
                >
                  {map(usStateAbbreviation, ({ name, abbreviation }) => (
                    <MenuItem value={abbreviation} key={abbreviation}>
                      {name}
                    </MenuItem>
                  ))}
                </SelectField>

                <TextField
                  id='PatientView-NewGuarantorButton-zip-input'
                  label='Zip'
                  name='zip'
                  type='zip'
                  maxLength={10}
                  format={formatZipCode}
                  validations={[validateZipCode()]}
                />

                <CheckboxField
                  label='Text Notification Preference'
                  name='textsEnabled'
                  id='PatientView-NewGuarantorButton-textNotificationPreference-checkbox'
                />

                <CheckboxField
                  label='Emergency Contact'
                  name='isEmergencyContact'
                  id='PatientView-NewGuarantorButton-isEmergencyContact-checkbox'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

NewGuarantorButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default NewGuarantorButton;
