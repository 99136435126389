/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';
import { isNull, reduce } from 'lodash';

import graphqlClient from '../graphql';
import {
  assertQs1RxNumberGql,
  bulkFillGql,
  completeEntryGql,
  controlledSubstancesCheckGql,
  copayResultCheckGql,
  generateBulkLabelsGql,
  getRequestLineItemVerificationDataGql,
  listBulkLabelsGql,
  listEVisitRequestsGql,
  listLineItemsGql,
  listOrdersForProductFulfillmentGql,
  markLineItemsPrintedGql,
  prescriberClarificationCheckGql,
  profitabilityResultCheckGql,
  reviewLineItemGql,
  updateRequestLineItemStateGql,
  verifyRequestLineItemGql,
} from './gql';
import {
  transformBulkLabels,
  transformListLineItems,
  transformListOrdersForProductFulfillment,
} from './transform';

export const listLineItemsApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
    requestedDate: filter.requestedDate && format(new Date(filter.requestedDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listLineItemsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        // omit keys if values are null
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListLineItems);
};

export const controlledSubstancesCheckApi = ({ checkInput }) => {
  return graphqlClient
    .request(controlledSubstancesCheckGql, { checkInput })
    .then(({ controlledSubstancesCheck }) => controlledSubstancesCheck);
};

export const reviewLineItemApi = ({
  mpi,
  lineItemId,
  reviewResult,
  reasonForRejection,
  qs1RxNumber,
}) => {
  return graphqlClient
    .request(reviewLineItemGql, {
      mpi,
      lineItemId,
      reviewResult,
      reasonForRejection,
      qs1RxNumber,
    })
    .then(({ reviewLineItem }) => reviewLineItem);
};

export const prescriberClarificationCheckApi = ({ checkInput }) => {
  return graphqlClient
    .request(prescriberClarificationCheckGql, { checkInput })
    .then(({ prescriberClarificationCheck }) => prescriberClarificationCheck);
};

export const copayResultCheckApi = ({ checkInput }) => {
  return graphqlClient
    .request(copayResultCheckGql, { checkInput })
    .then(({ copayResultCheck }) => copayResultCheck);
};

export const profitabilityResultCheckApi = ({ checkInput }) => {
  return graphqlClient
    .request(profitabilityResultCheckGql, { checkInput })
    .then(({ profitabilityResultCheck }) => profitabilityResultCheck);
};

export const getRequestLineItemVerificationDataApi = ({ mpi, requestLineItemId }) => {
  return graphqlClient
    .request(getRequestLineItemVerificationDataGql, { mpi, requestLineItemId })
    .then(({ getRequestLineItemVerificationData }) => getRequestLineItemVerificationData);
};

export const verifyRequestLineItemApi = ({ input }) => {
  return graphqlClient
    .request(verifyRequestLineItemGql, { input })
    .then(({ verifyRequestLineItem }) => verifyRequestLineItem);
};

export const listBulkLabelsApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listBulkLabelsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformBulkLabels);
};

export const generateBulkLabelsApi = ({ requestLineItemIds }) => {
  return graphqlClient
    .request(generateBulkLabelsGql, { requestLineItemIds })
    .then(({ generateBulkLabels }) => generateBulkLabels);
};

export const markLineItemsPrintedApi = ({ lineItemIds, markItemsFilled }) => {
  return graphqlClient
    .request(markLineItemsPrintedGql, { lineItemIds, markProductsFilled: markItemsFilled })
    .then(({ markLineItemsPrinted }) => markLineItemsPrinted);
};

export const listOrdersForProductFulfillmentApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    needsByDate: filter.needsByDate && format(new Date(filter.needsByDate), 'yyyy-MM-dd'),
    requestNeedsByDate:
      filter.requestNeedsByDate && format(new Date(filter.requestNeedsByDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listOrdersForProductFulfillmentGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        // omit keys if values are null
        transformedFilter,
        (orders, value, key) => ({
          ...orders,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(transformListOrdersForProductFulfillment);
};

export const completeEntryApi = ({ mpi, lineItemId, state }) =>
  graphqlClient
    .request(completeEntryGql, { mpi, lineItemId, state })
    .then(({ completeEntry }) => completeEntry);

export const assertQs1RxNumberApi = ({ mpi, rxNumber, lineItemId }) =>
  graphqlClient
    .request(assertQs1RxNumberGql, { lineItemId, mpi, rxNumber })
    .then(({ assertQs1RxNumber }) => assertQs1RxNumber);

export const updateRequestLineItemStateApi = (input) =>
  graphqlClient
    .request(updateRequestLineItemStateGql, input)
    .then(({ updateRequestLineItemState }) => updateRequestLineItemState);

export const bulkFillApi = ({ lineItemIds }) =>
  graphqlClient.request(bulkFillGql, { lineItemIds }).then(({ bulkFill }) => bulkFill);

export const listEVisitRequestsApi = ({ page, sortBy, filter }) => {
  const transformedFilter = {
    ...filter,
    requestedDate: filter.requestedDate && format(new Date(filter.requestedDate), 'yyyy-MM-dd'),
  };

  return graphqlClient
    .request(listEVisitRequestsGql, {
      offset: (page - 1) * 25,
      sortBy,
      filter: reduce(
        transformedFilter,
        (result, value, key) => ({
          ...result,
          [key]: isNull(value) ? undefined : value,
        }),
        {}
      ),
    })
    .then(({ listAwaitingEVisitRequests }) => listAwaitingEVisitRequests);
};
