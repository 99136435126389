import {
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useSelector } from 'react-redux';
import { isEmpty, isNil, map } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { normalizePhoneNumber } from 'common/utils';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';

import PatientMedicationOptions from './PatientMedicationOptions';

const MedicationLabelValue = ({ value, label, md, lg }) => {
  if (!value) {
    return null;
  }
  return (
    <Grid container direction='column' item xs={12} sm={6} md={md} lg={lg}>
      <Grid item>
        <Typography
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), fontSize: '0.85em' }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{value}</Typography>
      </Grid>
    </Grid>
  );
};

MedicationLabelValue.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  md: PropTypes.number,
  lg: PropTypes.number,
};
MedicationLabelValue.defaultProps = {
  value: '',
  md: 2,
  lg: 3,
};

const MedicationRow = ({ medication, mpi }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails((show) => !show);

  return (
    <>
      <TableRow
        sx={{ '& > *': { borderBottom: 'unset', bgcolor: showDetails ? '#0000000a' : 'inherit' } }}
        hover
      >
        <TableCell>
          <IconButton
            aria-label={`Toggle Details ${medication.name}`}
            size='small'
            onClick={toggleDetails}
          >
            {showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row' align='left'>
          {medication.name}
        </TableCell>
        <TableCell align='center'>{medication.ndc}</TableCell>
        <TableCell align='center'>{medication.dawCode}</TableCell>
        <TableCell align='center'>{medication.strength}</TableCell>
        <TableCell align='center'>{medication.form}</TableCell>
        <TableCell align='center'>{medication.pharmacy?.pharmacyName}</TableCell>
        <TableCell align='center'>
          {normalizePhoneNumber(medication.pharmacy?.pharmacyPhoneNumber)}
        </TableCell>
        <TableCell align='center'>
          <PatientMedicationOptions
            mpi={mpi}
            patientMedicationId={medication.patientMedicationId}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ '& > *': { borderTop: 'unset' } }}>
        <TableCell colSpan={9} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={showDetails} timeout='auto' unmountOnExit>
            <Grid container sx={{ px: 10, pt: 2, pb: 6 }} gap={2}>
              <Grid container spacing={2}>
                <MedicationLabelValue label='Quantity' value={medication.quantity} />
                <MedicationLabelValue label='Prescribed By' value={medication.prescriber?.name} />
              </Grid>

              <Grid container spacing={2}>
                <MedicationLabelValue
                  label='Instructions'
                  value={medication.instructions}
                  md={12}
                  lg={12}
                />
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

MedicationRow.propTypes = {
  medication: PropTypes.shape({
    patientMedicationId: PropTypes.string,
    ndc: PropTypes.string,
    daw: PropTypes.string,
    name: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    quantity: PropTypes.string,
    instructions: PropTypes.string,
    dawCode: PropTypes.number,
    prescriber: PropTypes.shape({
      name: PropTypes.string,
    }),
    pharmacy: PropTypes.shape({
      pharmacyName: PropTypes.string,
      pharmacyPhoneNumber: PropTypes.string,
    }),
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

const PatientMedicationsTable = ({ mpi }) => {
  const medications = useSelector(({ patient }) => patient[mpi]?.medications);

  return (
    <>
      <TableContainer>
        <Table aria-label='Medications List' padding='checkbox'>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align='left'>Medication Name</TableCell>
              <TableCell align='center'>NDC</TableCell>
              <TableCell align='center'>DAW</TableCell>
              <TableCell align='center'>Strength</TableCell>
              <TableCell align='center'>Form</TableCell>
              <TableCell align='center'>Pharmacy Name</TableCell>
              <TableCell align='center'>Pharmacy Phone</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {map(medications, (medication) => (
              <MedicationRow
                key={medication.patientMedicationId}
                mpi={mpi}
                medication={medication}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isNil(medications) && <LoadingModule height='25vh' />}

      {!isNil(medications) && isEmpty(medications) && <NothingFound height='25vh' />}
    </>
  );
};

PatientMedicationsTable.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default PatientMedicationsTable;
