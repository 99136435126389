export default {
  maxWidth: 1400,
  typography: {
    fontFamily: [
      'MuseoSans',
      '"Open Sans"',
      '"Helvetica Neue"',
      'helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // removes native html 'X' on type search
          [`input[type="search"]::-webkit-search-decoration,
          input[type="search"]::-webkit-search-cancel-button,
          input[type="search"]::-webkit-search-results-button,
          input[type="search"]::-webkit-search-results-decoration`]: { display: 'none' },
        },
      },
    },
  },
};
