import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { lighten } from '@mui/material/styles';

import useRoles from 'common/hooks/useRoles';

import EligibilityCheckSubstance from './EligibilityCheckSubstance';
import EligibilityCheckPrescriber from './EligibilityCheckPrescriber';
import EligibilityCheckCopayResult from './EligibilityCheckCopayResult';
import EligibilityCheckProfitability from './EligibilityCheckProfitability';

const ItemLabelValue = ({ value, label }) => {
  const usedValue = value || 'N/A';

  return (
    <Grid container spacing={2} columns={3}>
      <Grid item xs={1}>
        <Typography sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{usedValue}</Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};
ItemLabelValue.defaultProps = {
  value: '',
};

const CheckEligibilityButton = ({ mpi, lineItem }) => {
  const [showModal, setShowModal] = useState(false);
  const hasAccess = useRoles();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={handleOpenModal}
        size='small'
        fullWidth
        disabled={!hasAccess.eligibilityCheck}
      >
        Check
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='EligibilityActionItems-CheckEligibilityButton-header'
        fullWidth
      >
        <DialogTitle id='EligibilityActionItems-CheckEligibilityButton-header'>
          Eligibility Check
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mb: 3 }}>
            <ItemLabelValue label='Medication Name:' value={lineItem.name} />
            <ItemLabelValue label='Strength:' value={lineItem.strength} />
            <ItemLabelValue label='Quantity' value={lineItem.quantity} />
            <ItemLabelValue label='Form:' value={lineItem.form} />
            <ItemLabelValue label='NDC:' value={lineItem.ndc} />
            <ItemLabelValue label='DAW:' value={lineItem.dawCode} />
            <ItemLabelValue label='Instructions:' value={lineItem.instructions} />
          </Box>

          {lineItem.status === 'CONTROLLED_SUBSTANCE_CHECK' && (
            <EligibilityCheckSubstance
              mpi={mpi}
              requestLineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}
          {lineItem.status === 'PRESCRIPTION_CLARIFICATION_CHECK' && (
            <EligibilityCheckPrescriber
              mpi={mpi}
              requestLineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}
          {lineItem.status === 'CLAIM_PAY_CHECK' && (
            <EligibilityCheckCopayResult
              mpi={mpi}
              requestLineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}
          {lineItem.status === 'CLAIM_PROFITABLE_CHECK' && (
            <EligibilityCheckProfitability
              mpi={mpi}
              requestLineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

CheckEligibilityButton.propTypes = {
  lineItem: PropTypes.shape({
    name: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    instructions: PropTypes.string,
    quantity: PropTypes.string,
    ndc: PropTypes.string,
    status: PropTypes.string,
    requestLineItemId: PropTypes.string,
    requestId: PropTypes.string,
    dawCode: PropTypes.number,
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

export default CheckEligibilityButton;
