import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

const PatientComboSearchSelect = ({ searchType, setComboSearchType }) => {
  useEffect(() => {
    if (!searchType) {
      setComboSearchType('Patient');
    }
  }, [searchType, setComboSearchType]);

  const handleApplyFilterContext = (e) => {
    e.preventDefault();
    setComboSearchType(e.target.value);
  };

  return (
    <Box sx={{ mr: 1, mt: '3px' }}>
      <FormControl size='small' sx={{ minWidth: 100, bgcolor: 'white' }}>
        <Select
          name='PatientComboSearchType'
          sx={{ border: 'none' }}
          id='Context-Search-Select-Menu-dropdown'
          value={searchType}
          onChange={handleApplyFilterContext}
        >
          <MenuItem value='Patient'>Patient</MenuItem>
          <MenuItem value='Order'>Order</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default PatientComboSearchSelect;

PatientComboSearchSelect.propTypes = {
  searchType: PropTypes.string,
  setComboSearchType: PropTypes.func.isRequired,
};

PatientComboSearchSelect.defaultProps = {
  searchType: 'Patient',
};
