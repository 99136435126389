/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listAllEventTypesGql = gql`
  query listAllEventTypes {
    listAllEventTypes {
      auditEventType
    }
  }
`;

export const listAuditEventsGql = gql`
  query listAuditEvents(
    $limit: Int = 25
    $offset: Int = 0
    $filter: AuditEventFilter
    $sortBy: AuditEventSortBy
  ) {
    listAuditEvents(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: data {
        auditEventId
        patientId
        auditEventData
        createdTimestamp
        pomUserId
        auditEventType
        auditEventCode
        pomUserFirstName
        pomUserLastName
        pomUserEmail
      }
    }
  }
`;
