import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  pages: {},
  count: 0,
  filter: {},
  sortBy: {
    sortKey: 'createdAt',
    sortOrder: 'DESC',
  },
};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const sigSlice = createSlice({
  name: 'sigs',
  initialState,
  reducers: {
    loadSigs(state, action) {
      const { page, items, count } = action.payload;

      state.count = count;
      state.pages[page] = items;
    },
    updateSigsCurrentPage(state, action) {
      const { page } = action.payload;

      state.currentPage = page;
    },
    updateSigsFilter(state, action) {
      const { filter } = action.payload;

      state.pages = {};
      state.count = 0;
      state.filter = filter;
    },
    updateSigsSortBy(state, action) {
      const { sortBy } = action.payload;

      state.pages = {};
      state.count = 0;
      state.sortBy = sortBy;
    },
  },
});

export const sigActions = sigSlice.actions;
export default sigSlice.reducer;
