// eslint-disable import/prefer-default-export
import { filter, find, includes, isNaN, map, omit, reduce, toLower } from 'lodash';
import { formatName } from 'common/utils';

export const transformGetPatients = ({ getPatients }) => {
  return {
    count: getPatients.count,
    patients: map(getPatients.results, ({ firstName, lastName, ...otherDetails }) => ({
      ...otherDetails,
      firstName: formatName(firstName),
      lastName: formatName(lastName),
      patientName: `${formatName(lastName)}, ${formatName(firstName)}`,
    })),
  };
};

export const transformListPatientComments = ({ listPatientComments }) => {
  return map(listPatientComments, 'comment');
};

export const transformPatientAddress = (address) => {
  return {
    ...omit(address, ['tags']),
    isDefault: includes(address.tags, 'DEFAULT'),
  };
};

export const transformPatientAddresses = ({ addresses }) => {
  return map(addresses, transformPatientAddress);
};

export const transformPatientData = (response) => {
  // Response is an array like this [{data: {getMedication : value}}, {data: {getGuarantors : value}}]
  // responseObj is simply transforming the array into {getMedication: value, getGuarantors: value} for easier consumption
  const { getPatient, getPatientAddresses, patientBalance } = reduce(
    map(response, 'data'),
    (result, value) => {
      return { ...result, ...value };
    },
    {}
  );

  const { patientContact, patientDemographics, patientIdentity, mpi, lastUsedInsurance } =
    getPatient;
  const firstName = formatName(patientDemographics.firstName);
  const lastName = formatName(patientDemographics.lastName);
  const middleName = patientDemographics?.middleName && formatName(patientDemographics.middleName);
  const patientName = `${lastName}, ${firstName}`;

  return {
    ...omit(patientDemographics, 'preferencesState'),
    firstName,
    lastName,
    middleName,
    patientName,
    mpi,
    systemIds: omit(patientIdentity, 'partnerIdentities'),
    partnerIds: patientIdentity.partnerIdentities,
    email: find(patientContact.emails, { tags: ['DEFAULT'] })?.email,
    mobilePhone: find(patientContact.phoneNumbers, { tags: ['MOBILE'] })?.phoneNumber,
    workPhone: find(patientContact.phoneNumbers, { tags: ['WORK'] })?.phoneNumber,
    homePhone: find(patientContact.phoneNumbers, { tags: ['HOME'] })?.phoneNumber,
    addresses: transformPatientAddresses({ addresses: getPatientAddresses }),
    lastUsedInsurance,
    outstandingBalance: !isNaN(parseFloat(patientBalance?.outstandingBalance, 10))
      ? `$${(Math.round(patientBalance.outstandingBalance * 100) / 100).toFixed(2)}`
      : 'N/A',
    notificationPreferences: patientDemographics.preferencesState,
    isMobilePhoneVerified: find(patientContact.phoneNumbers, { tags: ['MOBILE'] })?.isVerified,
  };
};

export const transformFullPatientDetails = (response) => {
  // Response is an array like this [{data: {getMedication : value}}, {data: {getGuarantors : value}}]
  // responseObj is simply transforming the array into {getMedication: value, getGuarantors: value} for easier consumption
  const responseObj = reduce(
    map(response, 'data'),
    (result, value) => {
      return { ...result, ...value };
    },
    {}
  );

  return {
    guarantors: responseObj.getGuarantors,
    insurances: responseObj.getPatientInsurances,
    medications: responseObj.listPatientMedications.results,
    authorization: responseObj.getAuthorizations?.authorization || null,
  };
};

const transformTransferRequestList = (transferRequestList, requestId) => {
  return reduce(
    transferRequestList,
    (
      results,
      {
        taggedForReview,
        status,
        requestLineItemId,
        needsByDate,
        qs1RxNumber,
        item,
        reviewReason,
        hasPinnedNote,
      }
    ) => ({
      ...results,
      [requestLineItemId]: {
        ...item.patientMedication,
        requestLineItemId,
        status,
        needsByDate,
        qs1RxNumber,
        requestId,
        taggedForReview,
        reviewReason,
        hasPinnedNote,
      },
    }),
    {}
  );
};

const transformListPatientRequests = ({ listPatientRequests }) =>
  reduce(
    listPatientRequests,
    (results, { requestLineItems, ...patientRequest }) =>
      patientRequest.status === 'OPEN'
        ? {
            ...results,
            [patientRequest.requestId]: {
              ...patientRequest,
              transferRequest: transformTransferRequestList(
                filter(requestLineItems, { itemType: 'TRANSFER_REQUEST' }),
                patientRequest.requestId
              ),
            },
          }
        : results,
    {}
  );

export const transformListFailedAdjudications = ({ listAdjudicationRejectedPrescriptions }) => ({
  count: listAdjudicationRejectedPrescriptions.count,
  results: listAdjudicationRejectedPrescriptions.results,
});

export const transformGetPatientActionItems = (response) => {
  const { listPatientRequests, listPatientOrders } = reduce(
    map(response, 'data'),
    (result, value) => {
      return { ...result, ...value };
    },
    {}
  );

  return {
    requests: transformListPatientRequests({ listPatientRequests }),
    orders: listPatientOrders.results,
  };
};

export const transformNote = ({ comment }) => ({
  noteId: comment.commentId,
  noteContent: comment.commentContent.message,
  noteLineItemId: comment.commentContext.id,
  noteState: comment.currentState.state,
  noteCreatedAt: comment.createdAt,
  noteAuthor: comment.author,
});

export const transformLineItemNotes = (notes) => map(notes, transformNote);

export const transformGetPatientUsers = ({ getPatientUsers: patientUsers }) => {
  return map(patientUsers, ({ peUser: { userId, firstName, lastName, emailAddress } }) => {
    return { userId, userName: `${lastName}, ${firstName}`, email: emailAddress };
  });
};

export const transformGuarantorData = ({ getGuarantors: guarantors }) => [
  ...map(guarantors, ({ firstName, lastName, ...otherDetails }) => ({
    ...otherDetails,
    firstName: formatName(toLower(firstName)),
    lastName: formatName(toLower(lastName)),
  })),
];
