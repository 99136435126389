/* istanbul ignore file */
import { Suspense, useEffect, useMemo } from 'react';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';

import { getSpan, getTransaction } from 'common/sentry/SentryTransaction';
import ErrorDialog from 'common/errors/ErrorDialog';
import ProtectedRoutes from 'common/routes/ProtectedRoutes';
import RequireAuth from 'common/routes/RequireAuth';
import AppLayout from 'common/layouts/AppLayout';
import { appActions } from 'store/slices/appSlice';
import useRoles from 'common/hooks/useRoles';

import SignIn from 'modules/SignIn';
import PatientsList from 'modules/PatientsList';
import PatientView from 'modules/PatientView';
import WorkQueue from 'modules/WorkQueue';
import Events from 'modules/Events';
import Transactions from 'modules/Transactions';
import AdminCenter from 'modules/AdminCenter';
import DrugManagement from 'modules/DrugManagement';
import OrderRequest from 'modules/RequestStatus';
import SigManagement from 'modules/SigManagement';
import OrdersList from 'modules/ordersList';

import packageJson from '../package.json';

Sentry.init({
  environment: process.env.REACT_APP_ENV,
  enabled: !!process.env.REACT_APP_SENTRY_ENABLED,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  tracesSampleRate: parseInt(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE, 10) || 0,
  normalizeDepth: 10,
  maxValueLength: 1000,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  initialScope: {
    tags: {
      version: packageJson.version,
    },
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const App = () => {
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const router = useMemo(
    () =>
      sentryCreateBrowserRouter(
        createRoutesFromElements(
          <>
            {/* Unprotected public routes */}
            <Route path='/signin' element={<SignIn />} />
            {/* Protected routes */}
            <Route
              path='/'
              element={
                <ProtectedRoutes>
                  <AppLayout />
                </ProtectedRoutes>
              }
            >
              <Route
                index
                element={
                  <RequireAuth allow={hasAccess.viewWorkQueue}>
                    <WorkQueue />
                  </RequireAuth>
                }
              />
              <Route
                path='/patients'
                element={
                  <RequireAuth allow={hasAccess.viewPatientsList}>
                    <PatientsList />
                  </RequireAuth>
                }
              />
              <Route
                path='/patients/:mpi'
                element={
                  <RequireAuth allow={hasAccess.viewPatientDetails}>
                    <PatientView />
                  </RequireAuth>
                }
              />
              <Route
                path='/orders'
                element={
                  <RequireAuth allow={hasAccess.viewOrdersList}>
                    <OrdersList />
                  </RequireAuth>
                }
              />
              <Route
                path='/events'
                element={
                  <RequireAuth allow={hasAccess.viewEvents}>
                    <Events />
                  </RequireAuth>
                }
              />
              <Route
                path='/adminCenter'
                element={
                  <RequireAuth allow={hasAccess.viewAdminCenter}>
                    <AdminCenter />
                  </RequireAuth>
                }
              >
                <Route
                  path='/adminCenter/drugManagement'
                  element={
                    <RequireAuth allow={hasAccess.viewDrugManagement}>
                      <DrugManagement />
                    </RequireAuth>
                  }
                />
                <Route
                  path='/adminCenter/sigManagement'
                  element={
                    <RequireAuth allow={hasAccess.viewSigManagement}>
                      <SigManagement />
                    </RequireAuth>
                  }
                />
                <Route
                  path='/adminCenter/requestStatus'
                  element={
                    <RequireAuth allow={hasAccess.viewRequestStatus}>
                      <OrderRequest />
                    </RequireAuth>
                  }
                />
              </Route>
              <Route
                path='/transactions'
                element={
                  <RequireAuth allow={hasAccess.viewPaymentTransactions}>
                    <Transactions />
                  </RequireAuth>
                }
              />
              <Route path='*' element={<div>Not Found </div>} />
            </Route>
          </>
        )
      ),
    [hasAccess]
  );

  useEffect(() => {
    const flushSentry = () => {
      const transaction = getTransaction();
      const span = getSpan();

      span.setStatus('unknown');
      span.finish();
      transaction.setStatus('canceled');
      transaction.finish();
      Sentry.flush(1000);
    };
    window.addEventListener('beforeunload', flushSentry);

    return () => window.removeEventListener('beforeunload', flushSentry);
  }, []);

  useEffect(() => {
    fetch('https://geolocation-db.com/json/')
      .then((data) => data.json())
      .then(({ IPv4, ...location }) =>
        dispatch(
          appActions.updateGeolocation({
            ipAddress: IPv4,
            geolocation: JSON.stringify({ ...location }),
          })
        )
      )
      .catch(() => ({}));
  }, [dispatch]);

  return (
    <>
      <ErrorDialog />
      {/* This is a global level suspense, thats why it's intentional to be blank div at this time */}
      <Suspense fallback={<div />}>
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};

export default App;
