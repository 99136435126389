/* eslint max-lines: ["error", {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty, keys, map, merge, pickBy, some } from 'lodash';
import { format, sub } from 'date-fns';

import DateField from 'common/forms/DateField';
import CheckboxField from 'common/forms/CheckboxField';
import SelectField from 'common/forms/SelectField';
import { onboardPatient } from 'store/thunks/patientThunks';

const OnboardingButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [medicationsToTransfer, setMedicationsToTransfer] = useState({});
  const medications = useSelector(({ patient }) => patient[mpi]?.medications);
  const guarantors = useSelector(({ patient }) => patient[mpi]?.guarantors);
  const hasInsurances = useSelector(({ patient }) => !!patient[mpi]?.insurances?.length);
  const isOpenStatus = useSelector(({ patient }) => patient[mpi]?.status === 'OPEN');
  const hasAuthorization = useSelector(({ patient }) => !!patient[mpi]?.authorization?.auth);
  const canBeOnboarded =
    !!medications?.length && !!guarantors?.length && isOpenStatus && hasInsurances;

  const handleNextStep = () => setActiveStep((prev) => prev + 1);
  const handleBackStep = () => setActiveStep((prev) => prev - 1);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
      setMedicationsToTransfer({}); // reset the list on close
      setActiveStep(0);
    }
  };

  const getMedicationNameStrengthForm = (id) => {
    const med = find(medications, { patientMedicationId: id });
    return `${med.name} - ${med.strength} ${med.form}`;
  };

  const handleOnMedicationCheck = (event) => {
    const patientMedicationId = event.currentTarget?.dataset.medicationId;
    setMedicationsToTransfer((prev) => ({
      ...prev,
      [patientMedicationId]: !prev[patientMedicationId],
    }));
  };

  const handleOnboarding = (formData) => {
    const onboardPatientInput = {
      mpi,
      ...(!isEmpty(formData.authorization) && {
        authorization: {
          mpi,
          authorization: merge(
            {
              permissionToTransfer: false,
              mandatoryDisclosure: false,
              childSafetyWaiver: false,
              careboxAuthorization: false,
              textingAuthorization: false,
              auth: { by: 'GUARANTOR' },
            },
            formData.authorization
          ),
        },
      }),
      request: {
        mpi,
        needsByDate: format(formData.needsByDate, 'yyyy-MM-dd'),
        lineItems: map(keys(pickBy(medicationsToTransfer)), (medId) => ({
          expectedFulfillmentDate:
            formData[medId]?.expectedFulfillmentDate &&
            format(formData[medId]?.expectedFulfillmentDate, 'yyyy-MM-dd'),
          itemType: 'TRANSFER_REQUEST',
          transferRequest: {
            patientMedicationId: medId,
          },
        })),
      },
    };

    return dispatch(onboardPatient({ onboardPatientInput })).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  if (!canBeOnboarded) {
    return null;
  }

  return (
    <>
      <Button variant='contained' onClick={handleOpenModal}>
        Start Onboarding
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-labelledby='PatientView-OnboardingButton-header'
        fullWidth
      >
        <Grid container justifyContent='space-between' alignItems='center'>
          <DialogTitle id='PatientView-OnboardingButton-header'>Start Onboarding</DialogTitle>
          <IconButton
            sx={{ mr: 2 }}
            aria-label='Close Onboarding Dialog'
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Form
          onSubmit={handleOnboarding}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent sx={{ pt: 0 }}>
                <Stepper activeStep={activeStep} orientation='vertical'>
                  <Step>
                    <StepLabel>Medication to Transfer</StepLabel>
                    <StepContent>
                      <TableContainer sx={{ my: 1, maxHeight: 250 }}>
                        <Table stickyHeader aria-label='Medication Table' padding='checkbox'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ minWidth: 200, py: 1 }}>Medication Name</TableCell>
                              <TableCell align='right'>Strength</TableCell>
                              <TableCell align='right'>Form</TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {map(medications, (medication) => (
                              <TableRow
                                key={medication.patientMedicationId}
                                hover
                                role='checkbox'
                                aria-checked={
                                  !!medicationsToTransfer[medication.patientMedicationId]
                                }
                                data-medication-id={medication.patientMedicationId}
                                onClick={handleOnMedicationCheck}
                              >
                                <TableCell
                                  id={`PatientView-OnboardingButton-${medication.patientMedicationId}-name`}
                                >
                                  {medication.name}
                                </TableCell>
                                <TableCell align='right'>{medication.strength}</TableCell>
                                <TableCell align='right'>{medication.form}</TableCell>
                                <TableCell align='center'>
                                  <Checkbox
                                    color='primary'
                                    checked={
                                      !!medicationsToTransfer[medication.patientMedicationId]
                                    }
                                    inputProps={{
                                      'aria-labelledby': `PatientView-OnboardingButton-${medication.patientMedicationId}-name`,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StepContent>
                  </Step>

                  <Step>
                    <StepLabel>Dates</StepLabel>
                    <StepContent sx={{ pt: 2 }}>
                      <DateField
                        name='needsByDate'
                        id='PatientView-OnboardingButton-needsByDate-input'
                        label='Medications Needed By'
                        disablePast
                        minDate={sub(new Date(), { days: 1 })}
                        required
                      />

                      <Typography sx={{ mb: 2, fontWeight: '500' }}>
                        Expected Fulfillment Dates
                      </Typography>

                      {map(keys(pickBy(medicationsToTransfer)), (medId) => (
                        <DateField
                          key={medId}
                          name={`${medId}.expectedFulfillmentDate`}
                          id={`PatientView-OnboardingButton-${medId}expectedFulfillmentDate-input`}
                          label={getMedicationNameStrengthForm(medId)}
                          disablePast
                          minDate={sub(new Date(), { days: 1 })}
                        />
                      ))}
                    </StepContent>
                  </Step>

                  {!hasAuthorization && (
                    <Step>
                      <StepLabel>Authorization</StepLabel>
                      <StepContent>
                        <CheckboxField
                          id='PatientView-UpdateAuthorizationButton-permissionToTransfer-checkbox'
                          name='authorization.permissionToTransfer'
                          label='Permission To Transfer'
                          disableHelperText
                        />

                        <CheckboxField
                          id='PatientView-UpdateAuthorizationButton-mandatoryDisclosure-checkbox'
                          name='authorization.mandatoryDisclosure'
                          label='Mandatory Disclosure'
                          disableHelperText
                        />

                        <CheckboxField
                          id='PatientView-UpdateAuthorizationButton-childSafetyWaiver-checkbox'
                          name='authorization.childSafetyWaiver'
                          label='Child Safety Waiver'
                          disableHelperText
                        />

                        <CheckboxField
                          id='PatientView-UpdateAuthorizationButton-careboxAuthorization-checkbox'
                          name='authorization.careboxAuthorization'
                          label='Carebox Authorization'
                          disableHelperText
                        />

                        <CheckboxField
                          id='PatientView-UpdateAuthorizationButton-textingAuthorization-checkbox'
                          name='authorization.textingAuthorization'
                          label='Texting Authorization'
                        />

                        <SelectField
                          id='PatientView-UpdateAuthorizationButton-guarantor-checkbox'
                          label='Authorized By'
                          name='authorization.auth.id'
                          required
                        >
                          {map(guarantors, ({ guarantorId, firstName, lastName, middleName }) => (
                            <MenuItem key={guarantorId} value={guarantorId}>
                              {firstName} {middleName} {lastName}
                            </MenuItem>
                          ))}
                        </SelectField>
                      </StepContent>
                    </Step>
                  )}
                </Stepper>
              </DialogContent>
              <DialogActions>
                {activeStep === 0 && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleCloseModal}
                    sx={{ width: 90 }}
                  >
                    Cancel
                  </Button>
                )}
                {activeStep > 0 && (
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleBackStep}
                    sx={{ width: 90 }}
                  >
                    Back
                  </Button>
                )}

                {activeStep < (!hasAuthorization ? 2 : 1) && (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={handleNextStep}
                    sx={{ width: 90 }}
                    disabled={invalid || !some(medicationsToTransfer)}
                  >
                    Next
                  </Button>
                )}

                {activeStep === (hasAuthorization ? 1 : 2) && (
                  <LoadingButton
                    loading={submitting}
                    variant='contained'
                    disabled={invalid}
                    type='submit'
                    sx={{ width: 90 }}
                  >
                    Submit
                  </LoadingButton>
                )}
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

OnboardingButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default OnboardingButton;
