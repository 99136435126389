import { FormControl, FormLabel, RadioGroup } from '@mui/material';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { validateRequired } from './formValidations';

const RadioGroupField = ({ ariaLabel, name, id, label, row, required, children }) => {
  return (
    <Field
      name={name}
      id={id}
      type='text' // <- MUI will handle the conversion from text to radios
      validate={required ? validateRequired('Required') : undefined}
      render={({ input }) => {
        return (
          <FormControl fullWidth component='fieldset' required={required}>
            <FormLabel component='legend' sx={{ color: 'inherit' }}>
              {label}
            </FormLabel>

            <RadioGroup
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input} // intentional spreading, injecting final-form
              value={input.value || ''} // https://github.com/final-form/react-final-form/issues/529
              name={name}
              aria-label={ariaLabel || label}
              row={row}
              id={id}
            >
              {children}
            </RadioGroup>
          </FormControl>
        );
      }}
    />
  );
};

RadioGroupField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  row: PropTypes.bool,
  required: PropTypes.bool,
};

RadioGroupField.defaultProps = {
  row: false,
  ariaLabel: '',
  required: false,
};

export default RadioGroupField;
