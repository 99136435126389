import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import {
  addLineItemNote,
  listLineItemNotes,
  updateLineItemStatus,
  updateNoteStatus,
} from 'store/thunks/patientThunks';
import useRoles from 'common/hooks/useRoles';
import { find, includes } from 'lodash';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { useSnackbar } from 'notistack';

import { ContextType } from 'common/constants/notes';
import lineItemStatus from 'enums/lineItem.enum';
import orderStatus from 'enums/order.enum';
import AddNoteDialog from 'common/modules/notes/AddNoteDialog';
import NotesDialog from 'common/modules/notes/NotesDialog';

import ProductFulfillFailureReasonDialog from './ProductFulfillFailureReasonDialog';

const PatientNoteOptions = ({
  itemId,
  mpi,
  orderId,
  itemStatus,
  activeNoteItem,
  setActiveNoteItem,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showProductFillFailureDialog, setShowProductFillFailureDialog] = useState(false);
  const hasAccess = useRoles();
  const dispatch = useDispatch();
  const patientOrders = useSelector(({ patient }) => patient[mpi]?.orders || []);
  const orderLineItems = find(patientOrders, { orderId })?.lineItems || [];
  const isProcessedExternal = find(patientOrders, { orderId })?.isProcessedExternal;
  const lineItemNotes = find(orderLineItems, { lineItemId: itemId })?.notes;
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const { enqueueSnackbar } = useSnackbar();
  const currentOrderStatus = find(patientOrders, { orderId }).status;
  const orderStatusesAllowsUndo = ['OPEN', 'ORDER_VERIFICATION'];

  const menuOpen = !!anchorEl;
  const handleMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);

  const handleShowNoteDialog = () => {
    setAnchorEl(null);
    setShowAddNoteDialog(true);
  };

  const handleViewNoteDialog = useCallback(() => {
    setShowNotesDialog(true);
    setAnchorEl(null);

    dispatch(
      listLineItemNotes({
        mpi,
        lineItemId: itemId,
        vaultId: orderId,
        vaultType: ContextType.LINE_ITEM,
      })
    );
  }, [dispatch, mpi, itemId, orderId]);

  useEffect(() => {
    if (activeNoteItem === itemId) {
      handleViewNoteDialog();
      setActiveNoteItem(null);
    }
  }, [activeNoteItem, itemId, handleViewNoteDialog, setActiveNoteItem]);

  const closeViewNoteDialog = () => setShowNotesDialog(false);

  const handleCloseNoteDialog = () => setShowAddNoteDialog(false);

  const handleCloseFailureReasonDialog = () => setShowProductFillFailureDialog(false);

  const handleCannotFill = () => {
    setShowProductFillFailureDialog(true);
    setAnchorEl(null);
  };

  const handleUndoFilledProduct = () => {
    setAnchorEl(null);

    showInfoDialog({
      title: 'Undo Product Fulfilled',
      message: 'Are you sure, Do you need to undo Product Fulfilled',
      callback: () =>
        dispatch(
          updateLineItemStatus({
            lineItemId: itemId,
            mpi,
            orderId,
            state: lineItemStatus.PRODUCT_FULFILLMENT,
          })
        ).then((isUndone) => {
          if (isUndone) {
            enqueueSnackbar('Line Item undone to Product Fulfillment', { variant: 'success' });
          } else {
            enqueueSnackbar('Failed to undone to Product Fulfillment', { variant: 'error' });
          }
        }),
      cancelable: true,
      confirmButtonText: 'Confirm',
    });
  };

  const onAddNote = (commentContext, content, onComplete) => {
    return dispatch(
      addLineItemNote({
        mpi,
        commentContext,
        content,
        vaultId: orderId,
        vaultType: ContextType.LINE_ITEM,
      })
    ).then(() => {
      onComplete(true);
    });
  };

  const onUpdateNoteStatus = (noteId, action) => {
    return dispatch(
      updateNoteStatus({
        lineItemId: itemId,
        mpi,
        noteId,
        vaultId: orderId,
        vaultType: ContextType.LINE_ITEM,
        action,
      })
    );
  };

  return (
    <>
      <InfoDialog />
      <AddNoteDialog
        commentContexType={ContextType.LINE_ITEM}
        commentContextId={itemId}
        open={showAddNoteDialog}
        onClose={handleCloseNoteDialog}
        onAddNote={onAddNote}
      />

      <NotesDialog
        open={showNotesDialog}
        onClose={closeViewNoteDialog}
        itemNotes={lineItemNotes}
        onUpdateNoteStatus={onUpdateNoteStatus}
      />

      <ProductFulfillFailureReasonDialog
        open={showProductFillFailureDialog}
        onClose={handleCloseFailureReasonDialog}
        lineItemId={itemId}
        mpi={mpi}
        orderId={orderId}
      />

      <IconButton
        id={`PatientView-PatientNoteOptions-${itemId}options-button`}
        aria-label={`Note options ${itemId}`}
        aria-controls={
          menuOpen ? `PatientView-PatientNoteOptions-${itemId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`PatientView-PatientNoteOptions-${itemId}options-menu`}
        aria-labelledby={`PatientView-PatientNoteOptions-${itemId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleShowNoteDialog} disabled={!hasAccess.addPatientNote}>
          Add Note
        </MenuItem>
        <MenuItem onClick={handleViewNoteDialog} disabled={!hasAccess.listLineItemNotes}>
          View Notes
        </MenuItem>
        <MenuItem
          disabled={
            isProcessedExternal ||
            itemStatus === lineItemStatus.PRODUCT_FULFILLMENT ||
            !hasAccess.manageProductFulfillmentStatus ||
            !includes(orderStatusesAllowsUndo, currentOrderStatus)
          }
          onClick={handleUndoFilledProduct}
        >
          Undo
        </MenuItem>
        {orderLineItems.length !== 1 && (
          <MenuItem
            disabled={
              isProcessedExternal ||
              !hasAccess.manageProductFulfillmentStatus ||
              currentOrderStatus === orderStatus.ORDER_VERIFIED ||
              itemStatus === lineItemStatus.PRODUCT_FULFILLED
            }
            onClick={handleCannotFill}
          >
            Cannot Fill
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

PatientNoteOptions.propTypes = {
  itemId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  itemStatus: PropTypes.string.isRequired,
  activeNoteItem: PropTypes.string,
  setActiveNoteItem: PropTypes.func,
};

PatientNoteOptions.defaultProps = {
  activeNoteItem: null,
  setActiveNoteItem: undefined,
};

export default PatientNoteOptions;
