/* eslint max-lines: ["error", {"max": 350, "skipComments": true, "skipBlankLines": true}] */
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import { lighten } from '@mui/material/styles';
import { filter, find, first, includes, isEmpty, map, noop, sortBy } from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';

import useRoles from 'common/hooks/useRoles';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { getCurrentTestClaimIdentityApi, runTestClaimApi } from 'api/patient';
import { handleError } from 'store/thunks/errorHandlerThunks';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';
import { copyTextToClipBoard } from 'common/utils';
import insurancePayorTypes from 'common/constants/insurancePayorTypes';
import SelectField from 'common/forms/SelectField';
import { saveTestClaim } from 'store/thunks/patientThunks';

import ActionItemFailClaimDialog from './ActionItemFailClaimDialog';

const statesAbbreviations = map(usStateAbbreviation, 'abbreviation');

const InsuranceInfoLabel = ({ label, value }) => {
  return (
    <Grid container sx={{ minHeight: 25 }}>
      <Grid item sx={{ minWidth: 150 }}>
        <Typography
          variant='subtitle2'
          component='span'
          sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}
        >
          {label}
        </Typography>
      </Grid>

      <Typography variant='subtitle2' component='span' gutterBottom>
        {value}
      </Typography>
    </Grid>
  );
};

InsuranceInfoLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
};

InsuranceInfoLabel.defaultProps = {
  value: '',
};

const ActionItemPatientQualification = ({ mpi }) => {
  const dispatch = useDispatch();
  const hasAccess = useRoles();
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const patientAddressState = useSelector(
    ({ patient }) => find(patient[mpi].addresses, { isDefault: true })?.address.state
  );
  const insurances = useSelector(({ patient }) => patient[mpi]?.insurances);
  const [testClaimResult, setTestClaimResult] = useState(null);
  const [saveFailTestClaimDialog, setSaveFailTestClaimDialog] = useState(false);
  const [isSavingTestClaim, setIsSavingTestClaim] = useState(false);

  const sortedPrioritizedInsurance = useMemo(
    () => sortBy(filter(insurances, 'priority'), ['priority'], ['asc']),
    [insurances]
  );

  const handleSaveTestClaim = (formData) => {
    const claim = {
      ...testClaimResult,
      mpi,
      payorType: formData.payorType,
      result: formData.claimResult ? 'PASS' : 'FAIL',
    };
    setTestClaimResult((prev) => ({
      ...prev,
      ...claim,
    }));

    if (formData.claimResult) {
      showInfoDialog({
        title: 'Test Claim Success Confirmation',
        message: 'Please confirm that the test claim succeeded',
        confirmButtonText: 'Confirm',
        cancelable: true,
        callback: () => {
          setIsSavingTestClaim(true);
          dispatch(
            saveTestClaim({
              saveTestClaimResultInput: claim,
            })
          ).then(() => setIsSavingTestClaim(false));
        },
      });
    } else {
      setSaveFailTestClaimDialog(true);
    }
  };

  const closeFailTestClaimDialog = () => setSaveFailTestClaimDialog(null);

  const handleRunTestClaim = ({ patientInsuranceId }) => {
    return runTestClaimApi({ mpi, patientInsuranceId })
      .then(setTestClaimResult)
      .catch((error) => {
        dispatch(handleError({ error }));
        return getCurrentTestClaimIdentityApi({ mpi }).then(setTestClaimResult).catch(noop);
      });
  };

  const copyProfileToClipboard = () => copyTextToClipBoard(testClaimResult?.testProfileId);

  return (
    <>
      <InfoDialog />
      <ActionItemFailClaimDialog
        testClaimResult={testClaimResult}
        open={saveFailTestClaimDialog}
        onClose={closeFailTestClaimDialog}
      />

      <Grid container direction='column'>
        <Accordion defaultExpanded disableGutters>
          <AccordionSummary
            sx={{ bgcolor: (theme) => lighten(theme.palette.text.primary, 0.9) }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls='onboarding-panel-content'
            id='onboarding-panel-header'
          >
            <Typography>Patient Qualification</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Grid container alignItems='center'>
              <Typography
                variant='subtitle2'
                sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3), mr: 1 }}
              >
                State of Residence:
              </Typography>
              <Typography>{patientAddressState}</Typography>
            </Grid>

            {(patientAddressState === 'CA' ||
              !includes(statesAbbreviations, patientAddressState)) && (
              <Typography variant='caption' sx={{ color: (theme) => theme.palette.error.main }}>
                Pharmacy may not be licensed in this state
              </Typography>
            )}

            <Grid container sx={{ width: '30%', minWidth: 400, mt: 3 }}>
              <Form
                initialValues={{
                  patientInsuranceId: first(sortedPrioritizedInsurance)?.patientInsuranceId,
                }}
                onSubmit={handleRunTestClaim}
                render={({ handleSubmit, invalid, values, submitting }) => {
                  const selectedInsurance = find(sortedPrioritizedInsurance, {
                    patientInsuranceId: values.patientInsuranceId,
                  });

                  return (
                    <>
                      <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <SelectField
                          label='Insurance'
                          name='patientInsuranceId'
                          id='PatientView-ActionItemPatientQualification-insurance-dropdown'
                          required
                        >
                          {isEmpty(sortedPrioritizedInsurance) && (
                            <MenuItem disabled>No Prioritized Insurance Found</MenuItem>
                          )}
                          {map(sortedPrioritizedInsurance, ({ planName, patientInsuranceId }) => (
                            <MenuItem value={patientInsuranceId} key={patientInsuranceId}>
                              {planName}
                            </MenuItem>
                          ))}
                        </SelectField>

                        <Grid container justifyContent='space-between' alignItems='center'>
                          <Typography
                            variant='body2'
                            sx={{
                              mt: 1,
                              color: (theme) => lighten(theme.palette.text.primary, 0.3),
                              display: 'flex',
                              alignItems: 'center',
                              minHeight: 30,
                            }}
                          >
                            Test Profile Patient Id:&nbsp;
                            {testClaimResult?.testProfileId}
                            {testClaimResult?.testProfileId && (
                              <IconButton
                                onClick={copyProfileToClipboard}
                                aria-label='Copy Test Profile'
                                size='small'
                                sx={{ mx: 1 }}
                              >
                                <ContentCopyIcon fontSize='small' />
                              </IconButton>
                            )}
                          </Typography>

                          <LoadingButton
                            loading={submitting}
                            disabled={
                              invalid ||
                              !hasAccess.runSubmitTestClaims ||
                              !!testClaimResult?.testProfileId
                            }
                            sx={{ minWidth: 115 }}
                            variant='contained'
                            type='submit'
                          >
                            Run Test Claim
                          </LoadingButton>
                        </Grid>
                      </form>

                      <Grid container direction='column' sx={{ mt: 4 }}>
                        <Typography sx={{ fontWeight: 'bold', mb: 1 }} variant='subtitle2'>
                          Insurance Information
                        </Typography>
                        <InsuranceInfoLabel
                          label='Plan Id'
                          value={selectedInsurance?.insurancePlanId}
                        />
                        <InsuranceInfoLabel label='BIN' value={selectedInsurance?.bin} />
                        <InsuranceInfoLabel label='PCN' value={selectedInsurance?.pcn} />
                        <InsuranceInfoLabel
                          label='Group Number'
                          value={selectedInsurance?.groupNumber}
                        />
                        <InsuranceInfoLabel
                          label='Medicare Number'
                          value={selectedInsurance?.medicareNumber}
                        />
                        <InsuranceInfoLabel
                          label='Plan Type'
                          value={insurancePayorTypes[selectedInsurance?.planType] || 'N/A'}
                        />
                      </Grid>
                    </>
                  );
                }}
              />
            </Grid>

            <Grid container sx={{ width: '30%', minWidth: 400, mt: 3 }}>
              <Form
                onSubmit={handleSaveTestClaim}
                render={({ handleSubmit, invalid }) => (
                  <form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
                    <SelectField
                      label='Test Claim Result'
                      name='claimResult'
                      id='PatientView-ActionItemPatientQualification-passFail-dropdown'
                      required
                    >
                      <MenuItem value>Pass</MenuItem>
                      <MenuItem value={false}>Fail</MenuItem>
                    </SelectField>

                    <SelectField
                      label='Insurance Payor Type'
                      name='payorType'
                      id='PatientView-ActionItemPatientQualification-payorType-dropdown'
                      required
                    >
                      {map(insurancePayorTypes, (value, key) => (
                        <MenuItem value={key} key={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </SelectField>

                    <Grid container direction='row-reverse'>
                      <LoadingButton
                        loading={isSavingTestClaim}
                        disabled={
                          invalid ||
                          !testClaimResult?.testProfileId ||
                          !hasAccess.runSubmitTestClaims
                        }
                        sx={{ minWidth: 115 }}
                        variant='contained'
                        type='submit'
                      >
                        Submit
                      </LoadingButton>
                    </Grid>
                  </form>
                )}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
};

ActionItemPatientQualification.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default ActionItemPatientQualification;
