/* istanbul ignore file */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { configuredStore } from 'store';
import ThemeProvider from 'common/theme/ThemeProvider';
import SnackbarProvider from 'common/theme/SnackbarProvider';
import UnderMaintenance from 'common/components/UnderMaintenance';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const { store, persistor } = configuredStore;
const root = createRoot(document.getElementById('root'));

// if (process.env.NODE_ENV === 'development') {
//   // eslint-disable-next-line global-require
//   const { worker } = require('./mocks/browser');
//   worker.start({ onUnhandledRequest: 'bypass' });
// }

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider>
            <SnackbarProvider>
              {process.env.REACT_APP_MAINTENANCE ? <UnderMaintenance /> : <App />}
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
