/* eslint-disable default-param-last */
/* eslint-disable import/prefer-default-export */
import { find, includes, isDate, reduce, replace, some, split, trim } from 'lodash';
import { isAfter, isBefore, isValid } from 'date-fns';

import {
  creditCardRegex,
  emailRfc5322Regex,
  phoneNumberRegex,
  zipCodeRegex,
} from 'common/constants/regex';
import usStateAbbreviation from 'common/constants/usStateAbbreviation';

export const composeValidations = (validators) => (value, allValues, meta) => {
  if (!validators?.length) {
    return undefined;
  }

  let result;
  some(validators, (validator) => {
    result = validator(value, allValues, meta);
    return result;
  });

  return result;
};

export const validateRequired =
  (errorMessage = 'Required') =>
  (value) =>
    trim(value) ? undefined : errorMessage;

export const validateRequiredCheckbox =
  (errorMessage = 'Required') =>
  (value) =>
    value ? undefined : errorMessage;

export const validateMinLength =
  (errorMessage = 'Too Short', minLength) =>
  (value) =>
    !value || trim(value).length >= minLength ? undefined : errorMessage;

export const validateMaxLength =
  (errorMessage = 'Too Long', maxLength) =>
  (value) =>
    !value || trim(value).length <= maxLength ? undefined : errorMessage;

export const validateValueWithRegex =
  (errorMessage = 'Invalid Value', regex) =>
  (value) =>
    !value || regex.test(value) ? undefined : errorMessage;

export const validateZipCode =
  (errorMessage = 'Zip Code must be 5 or 9 digits') =>
  (value) =>
    !value || zipCodeRegex.test(value) ? undefined : errorMessage;

// This regex should allow 1800 numbers and normal 10digits phone numbers
// anything above 10 digits would require a + infront of it. this includes 1800 numbers
export const validatePhoneNumber =
  (errorMessage = 'Invalid Phone Number') =>
  (value) =>
    !value || phoneNumberRegex.test(value) ? undefined : errorMessage;

export const validateMustMatch =
  (errorMessage = 'Does not match', match) =>
  (value, allValues) =>
    value === allValues[match] ? undefined : errorMessage;

export const validateEmail =
  (errorMessage = 'Invalid Email') =>
  (value) =>
    !value || emailRfc5322Regex.test(value) ? undefined : errorMessage;

export const validateMinNumber =
  (errorMessage = 'Too low', minValue) =>
  (value) =>
    !Number(value) || Number(value) >= minValue ? undefined : errorMessage;

export const validateMaxNumber =
  (errorMessage = 'Too high', maxValue) =>
  (value) =>
    !Number(value) || Number(value) <= maxValue ? undefined : errorMessage;

export const validateMinDate =
  (errorMessage = 'Invalid min date', minDate) =>
  (value) =>
    !value || (isValid(value) && isAfter(value, minDate)) ? undefined : errorMessage;

export const validateMaxDate =
  (errorMessage = 'Invalid max date', maxDate) =>
  (value) =>
    !value || (isValid(value) && isBefore(value, maxDate)) ? undefined : errorMessage;

export const validateDate =
  (errorMessage = 'Invalid date') =>
  (value) =>
    !value || isValid(value) ? undefined : errorMessage;

export const validatePhoneAllowedInState =
  (errorMessage = 'Phone notifications are not allowed in the selected state', key) =>
  (value, allValues) => {
    const props = split(key, '.');
    const state = reduce(props, (result, prop) => result[prop], allValues);

    if (value && state) {
      return find(usStateAbbreviation, { abbreviation: state }).isSMSAllowed
        ? undefined
        : errorMessage;
    }

    return undefined;
  };

export const validateCreditCardNumber =
  (errorMessage = 'Invalid card') =>
  (value) =>
    !value || creditCardRegex.test(replace(value, /[^0-9]/gm, '')) ? undefined : errorMessage;

export const validateExpirationDate =
  (errorMessage = 'Invalid Expiration') =>
  (value) => {
    if (!value) return undefined;

    const [month, year] = split(value, '/');
    const date = `20${year}-${month}-01`;

    return isDate(new Date(date)) && isBefore(new Date(), new Date(date))
      ? undefined
      : errorMessage;
  };

export const validateQuantity =
  (errorMessage = 'Quantity should be greater than 0') =>
  (value) =>
    +value >= 1 ? undefined : errorMessage;

export const validateDuplicate = (errorMessage, existingData) => (value) => {
  return includes(existingData, trim(value)) ? errorMessage : undefined;
};
