import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import NewProviderButton from './NewProviderButton';
import PatientProvidersTable from './PatientProvidersTable';

const PatientProvidersTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Providers
        </Typography>
        {hasAccess.addProvider && <NewProviderButton mpi={mpi} />}
      </Grid>
      <Grid item>
        <PatientProvidersTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};
export default PatientProvidersTab;
