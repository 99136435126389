/* eslint-disable import/prefer-default-export */
import { gql } from 'graphql-request';

export const listOrderLineItemsGql = gql`
  query listOrderLineItems(
    $limit: Int = 25
    $offset: Int = 0
    $filter: ListOrderLineItemsFilter
    $sortBy: OrderLineItemsSortBy = { sortKey: needsByDate, sortOrder: DESC }
  ) {
    listOrderLineItems(limit: $limit, offset: $offset, filter: $filter, sortBy: $sortBy) {
      count
      results: result {
        orderId
        orderStatus
        patient {
          mpi: patientId
          patientDemographics {
            firstName
            lastName
          }
        }
        partner {
          partnerName
        }
        lineItem {
          itemName
          needsByDate
          requestedDate
          itemType
          lineItemId
        }
      }
    }
  }
`;
