import { useSelector } from 'react-redux';
import { every, reduce } from 'lodash';

const useActionItemsCount = ({ mpi }) => {
  const isOnboardedStatus = useSelector(({ patient }) => patient[mpi]?.status === 'ONBOARDED');

  const requestCount = useSelector(({ patient }) =>
    reduce(
      patient[mpi]?.requests,
      (result, request) => {
        const verifiedRequest = every(request.transferRequest, { status: 'PRODUCT_FULFILLMENT' });

        return verifiedRequest ? result : result + 1;
      },
      0
    )
  );

  const ordersCount = useSelector(({ patient }) => patient[mpi]?.orders?.length || 0);

  return isOnboardedStatus ? 1 : requestCount + ordersCount;
};

export default useActionItemsCount;
