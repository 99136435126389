import {
  Box,
  Grid,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { capitalize, isEmpty, isNil, map, toLower } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import visuallyHidden from '@mui/utils/visuallyHidden';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { utcToZonedTime } from 'date-fns-tz';

import { getPatientsByPage, sortPatientsList } from 'store/thunks/patientsListThunks';
import gender from 'common/constants/gender';
import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';

const headCells = [
  {
    id: 'lastName',
    label: 'Patient Name',
  },
  {
    id: 'gender',
    label: 'Gender',
  },
  {
    id: 'dob',
    label: 'Date of Birth',
  },
  {
    id: 'status',
    label: 'Status',
  },
  {
    id: 'originalPartner',
    label: 'Original Partner',
  },
  {
    id: 'createdTimestamp',
    label: 'Created Time',
  },
];

const PatientsTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { sortBy, pages, currentPage } = useSelector(({ patientsList }) => patientsList);
  const totalPages = useSelector(({ patientsList }) => Math.ceil(patientsList.count / 25));
  const patients = pages[currentPage];

  const handleSort = (event) => {
    const sortKey = event.currentTarget?.dataset?.headProperty;

    dispatch(
      sortPatientsList({
        sortBy: {
          sortKey,
          sortOrder: sortBy.sortKey === sortKey && sortBy.sortOrder === 'ASC' ? 'DESC' : 'ASC',
        },
      })
    );
  };

  const handlePageChange = (_, page) => {
    if (currentPage === page) {
      // page didnt change, don't do anything.
      return;
    }

    dispatch(getPatientsByPage({ page }));

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Grid component={Paper} sx={{ py: 1, px: 2 }} container direction='column'>
      <Grid item>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    sortDirection={
                      sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : false
                    }
                  >
                    <TableSortLabel
                      active={sortBy.sortKey === headCell.id}
                      direction={sortBy.sortKey === headCell.id ? toLower(sortBy.sortOrder) : 'asc'}
                      onClick={handleSort}
                      data-head-property={headCell.id}
                    >
                      {headCell.label}
                      {sortBy.sortKey === headCell.id ? (
                        <Box component='span' sx={visuallyHidden}>
                          {toLower(sortBy.sortOrder) === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {map(patients, (patient) => (
                <TableRow key={patient.mpi} hover>
                  <TableCell component='th' scope='row' align='left'>
                    <Link
                      to={`/patients/${patient.mpi}`}
                      style={{ color: theme.palette.text.primary }}
                    >
                      {patient.patientName}
                    </Link>
                  </TableCell>
                  <TableCell>{gender[patient.gender]}</TableCell>
                  <TableCell>
                    {format(utcToZonedTime(new Date(patient.dob), 'utc'), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>{capitalize(patient.status)}</TableCell>
                  <TableCell>{patient.originalPartner?.partnerName || 'N/A'}</TableCell>
                  <TableCell>{format(new Date(patient.createdTime), 'MM/dd/yyyy')}</TableCell>
                </TableRow>
              ))}
            </TableBody>

            {!isNil(patients) && totalPages > 1 && (
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={6} align='right' sx={{ borderBottom: 'none' }}>
                    <Pagination
                      sx={{ justifyContent: 'flex-end', alignItems: 'flex-end', display: 'flex' }}
                      count={totalPages}
                      page={currentPage}
                      size='small'
                      onChange={handlePageChange}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      </Grid>

      {isNil(patients) && (
        <Grid item>
          <LoadingModule />
        </Grid>
      )}

      {!isNil(patients) && isEmpty(patients) && (
        <Grid item>
          <NothingFound />
        </Grid>
      )}
    </Grid>
  );
};

export default PatientsTable;
