import PropTypes from 'prop-types';
import { IconButton, InputBase, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { patientsListActions } from 'store/slices/patientsListSlice';
import { getPatientsByPage } from 'store/thunks/patientsListThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';
import { ordersListActions } from 'store/slices/ordersListSlice';
import { getOrdersByPage } from 'store/thunks/ordersListThunk';

const PatientSearch = ({ searchType }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { InfoDialog, showInfoDialog } = useInfoDialog();

  const handleSearch = (event) => {
    event.preventDefault();
    const searchText = event.target.elements.search.value.trim();

    if (searchText?.length < 3) {
      return showInfoDialog({
        title: 'Error',
        message: 'Minimum 3 characters needed for search',
      });
    }

    if (searchType === 'Patient') {
      dispatch(patientsListActions.updateFilter({ filter: { flatSearch: searchText } }));
      navigate('/patients');
      return dispatch(getPatientsByPage({ page: 1 }));
    }

    dispatch(ordersListActions.updateFilter({ filter: { flatSearch: searchText } }));
    navigate('/orders');
    return dispatch(getOrdersByPage({ page: 1 }));
  };

  return (
    <Paper
      component='form'
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 340,
        marginRight: theme.spacing(1),
      }}
      onSubmit={handleSearch}
    >
      <InfoDialog />
      <InputBase
        inputProps={{
          autoComplete: 'off',
          'aria-label': 'search',
        }}
        sx={{ border: 'none', px: theme.spacing(3), minWidth: 300 }}
        type='text'
        placeholder='Search...'
        name='search'
      />
      <IconButton type='submit' aria-label='search'>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default PatientSearch;

PatientSearch.propTypes = {
  searchType: PropTypes.string,
};

PatientSearch.defaultProps = {
  searchType: 'Patient',
};
