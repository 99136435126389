import moment from 'moment';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';

import { filterOrdersList } from 'store/thunks/ordersListThunk';
import { validateMinLength } from 'common/forms/formValidations';
import TextField from 'common/forms/TextField';
import DateField from 'common/forms/DateField';
import SelectField from 'common/forms/SelectField';
import FacilityFilterField from 'modules/WorkQueue/components/FacilityFilterField';

const OrdersFilterButton = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const filter = useSelector(({ ordersList }) => ({
    ...ordersList.filter,
    createdAt: ordersList.filter.createdAt && new Date(ordersList.filter.createdAt),
    updatedAt: ordersList.filter.updatedAt && new Date(ordersList.filter.updatedAt),
  }));

  const popoverId = anchorEl ? 'OrdersList-OrderFilterButton-Popover' : undefined;

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterOrdersList({
        filter: {
          ...formData,
          createdAt: formData.createdAt && moment(formData.createdAt).format('YYYY-MM-DD'),
          updatedAt: formData.updatedAt && moment(formData.updatedAt).format('YYYY-MM-DD'),
        },
      })
    );
  };

  return (
    <>
      <Button
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 300 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <FacilityFilterField />

                  <TextField
                    label='Order Id'
                    name='orderId'
                    id='OrdersList-OrderFilterButton-orderId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Reference Id'
                    name='referenceId'
                    id='OrdersList-OrderFilterButton-referenceId-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <TextField
                    label='Rx Number'
                    name='rxNumber'
                    id='OrdersList-OrderFilterButton-rxNumber-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <SelectField
                    label='Status'
                    name='status'
                    id='OrdersList-OrderFilterButton-status-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    <MenuItem value='OPEN'>Open</MenuItem>
                    <MenuItem value='ORDER_VERIFICATION'>Order Verification</MenuItem>
                    <MenuItem value='READY_FOR_SHIPMENT'>Ready For Shipment</MenuItem>
                    <MenuItem value='CANCELED'>Canceled</MenuItem>
                    <MenuItem value='ADDED_TO_SHIPMENT'>Added To Shipment</MenuItem>
                    <MenuItem value='SHIPPED'>Shipped</MenuItem>
                    <MenuItem value='HOLD'>Hold</MenuItem>
                  </SelectField>

                  <DateField
                    label='Created At'
                    name='createdAt'
                    id='OrdersList-OrderFilterButton-createdAt-dateField'
                    maxDate={new Date()}
                  />

                  <DateField
                    label='Updated At'
                    name='updatedAt'
                    id='OrdersList-OrderFilterButton-updatedAt-dateField'
                    maxDate={new Date()}
                  />

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default OrdersFilterButton;
