import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { omit } from 'lodash';

import { upsertDrugProfile } from 'store/thunks/drugThunks';

import ManageDrugProfileModal from './ManageDrugProfileModal';

const DrugOptions = ({ drugProfileId }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const menuOpen = !!anchorEl;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const updateDrugProfile = (formData) => {
    setFormLoading(true);
    dispatch(
      upsertDrugProfile({
        ...omit(formData, ['createdAt', 'updatedAt', 'drugNameHash']),
        drugProfileId,
      })
    )
      .then((response) => {
        if (response) {
          enqueueSnackbar('Drug profile updated successfully', { variant: 'success' });
          handleMenuClose();
          setShowModal(false);
          setFormLoading(false);
        }
      })
      .finally(() => setFormLoading(false));
  };

  return (
    <>
      <IconButton
        id={`DrugManagement-${drugProfileId}options-button`}
        aria-label={`DrugManagement options ${drugProfileId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`DrugManagement-${drugProfileId}options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleOpenModal}>Edit</MenuItem>
      </Menu>

      {showModal && (
        <ManageDrugProfileModal
          showModal={showModal}
          loading={formLoading}
          drugProfileId={drugProfileId}
          handleCloseModal={handleCloseModal}
          manageDrugProfile={updateDrugProfile}
        />
      )}
    </>
  );
};

export default DrugOptions;

DrugOptions.propTypes = {
  drugProfileId: PropTypes.string.isRequired,
};
