import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import { map } from 'lodash';
import { enqueueSnackbar } from 'notistack';

import shippingTypes from 'common/constants/shippingTypes';
import { createOrderShipment } from 'store/thunks/patientThunks';
import SelectField from 'common/forms/SelectField';
import useRoles from 'common/hooks/useRoles';

const SendToShippingButton = ({ mpi, orderId, orderStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const hasAccess = useRoles();

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  const handleSendToShipment = ({ shipmentType }) => {
    setIsLoading(true);
    dispatch(createOrderShipment({ mpi, orderId, shipmentType })).then((success) => {
      if (success) {
        enqueueSnackbar('Successfully send to shipment', { variant: 'success' });
      }

      setIsLoading(false);
      handleCloseModal();
    });
  };

  return (
    <>
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='SendToShipping-Dialog-header'
        fullWidth
      >
        <DialogTitle id='SendToShipping-Dialog-header'>Send to Shipping</DialogTitle>

        <Form
          onSubmit={handleSendToShipment}
          initialValues={{ shipmentType: shippingTypes.USPS }}
          render={({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <SelectField
                  id='SendToShipping-shippingType-dropdown'
                  label='Select Shipping Method'
                  name='shipmentType'
                >
                  {map(shippingTypes, (shippingType, key) => (
                    <MenuItem value={key} key={shippingType}>
                      {shippingType}
                    </MenuItem>
                  ))}
                </SelectField>
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton loading={isLoading} variant='contained' type='submit'>
                  Send
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>

      {hasAccess.createOrderShipment && (
        <Grid justifyContent='flex-end'>
          <Tooltip
            title={orderStatus === 'HOLD' ? 'Order payment in hold' : ''}
            placement='top'
            arrow
          >
            <span>
              <LoadingButton
                variant='contained'
                onClick={handleOpenModal}
                sx={{ mr: 2 }}
                disabled={orderStatus === 'HOLD'}
              >
                Send to Shipping
              </LoadingButton>
            </span>
          </Tooltip>
        </Grid>
      )}
    </>
  );
};

SendToShippingButton.propTypes = {
  orderId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
  orderStatus: PropTypes.string.isRequired,
};

export default SendToShippingButton;
