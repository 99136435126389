import Autocomplete from '@mui/material/Autocomplete';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import TextField from 'common/forms/TextField';

const AutoCompleteField = ({ name, label, id, required, validations, freeSolo, options }) => {
  const form = useForm();

  const onChange = (_, value) => {
    form.change(name, value);
  };

  const onClose = () => {
    const isValueAnOption = includes(options, form.getState().values[name]);

    if (!freeSolo && !isValueAnOption) {
      form.change(name, '');
    }
  };

  const freeSoloValue = form.getState().values[name] || '';
  const staticValue = includes(options, form.getState().values[name])
    ? form.getState().values[name]
    : null;

  return (
    <Autocomplete
      freeSolo={freeSolo}
      id={`${id}-autosuggest`}
      options={options}
      disableClearable
      value={freeSolo ? freeSoloValue : staticValue}
      onClose={onClose}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            name={name}
            id={id}
            label={label}
            required={required}
            params={params}
            validations={validations}
            value={freeSolo ? freeSoloValue : staticValue}
          />
        );
      }}
    />
  );
};

AutoCompleteField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  freeSolo: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

AutoCompleteField.defaultProps = {
  validations: [],
  required: false,
  freeSolo: false,
};

export default AutoCompleteField;
