import React from 'react';
import { Grid } from '@mui/material';

import DrugsTable from './components/DrugsTable';
import DrugsFilterButton from './components/DrugsFilterButton';
import DrugsFilterStatusBar from './components/DrugsFilterStatusBar';
import AddDrugButton from './components/AddDrugButton';

const DrugManagement = () => {
  return (
    <>
      <Grid
        container
        sx={{ px: 3, py: 2 }}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-end'
      >
        <Grid item sx={{ minHeight: 30, flexGrow: 1 }} alignItems='center'>
          <DrugsFilterStatusBar />
        </Grid>
        <Grid item alignItems='center'>
          <AddDrugButton />
          <DrugsFilterButton />
        </Grid>
      </Grid>

      <Grid item container sx={{ px: 3 }}>
        <DrugsTable />
      </Grid>
    </>
  );
};

export default DrugManagement;
