import { Autocomplete, CircularProgress, ListItem, ListItemText } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-final-form';
import { debounce, forEach, noop } from 'lodash';
import { matchSorter } from 'match-sorter';

import { listKnownMedicalConditionsApi } from 'api/medicalCondition';

import TextField from './TextField';

const MedicalConditionAutoSuggest = ({
  name,
  id,
  label,
  required,
  validations,
  autoFocus,
  prefillFields,
  maxLength,
}) => {
  const form = useForm();

  const [inputValue, setInputValue] = useState(form.getState().values[name] || '');
  const [medicalConditions, setMedicalConditions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onInputChange = (_, value) => setInputValue(value);

  const onChange = (_, medicalCondition) => {
    form.batch(() => {
      form.change(name, medicalCondition[name]);
      forEach(prefillFields, (prefillField) =>
        form.change(prefillField, medicalCondition[prefillField])
      );
    });
  };

  const debouncedSearch = useMemo(() => {
    const searchFunc = (medicalCondition) => {
      setLoading(true);
      listKnownMedicalConditionsApi({ input: medicalCondition })
        .then((results) =>
          setMedicalConditions(matchSorter(results, medicalCondition[name], { keys: [name] }))
        )
        .catch(noop) // intentionally not doing anything
        .finally(() => {
          setLoading(false);
        });
    };
    return debounce(searchFunc, 500, { trailing: true, leading: false });
  }, [name]);

  // start searching after a user entered 3 character
  useEffect(() => {
    setMedicalConditions([]);
    if (inputValue.length >= 3) {
      debouncedSearch({ [name]: inputValue });
    }
  }, [inputValue, debouncedSearch, name]);

  // reset result if input value is less than 3 characters
  useEffect(() => {
    if (medicalConditions.length && inputValue.length < 3) {
      setMedicalConditions([]);
    }
  }, [inputValue, medicalConditions]);

  useEffect(() => {
    const unSubscribe = form.subscribe((formState) => setInputValue(formState.values[name] || ''), {
      values: true,
    });
    return unSubscribe;
  }, [form, name]);

  return (
    <Autocomplete
      freeSolo
      id={`${id}-autosuggest`}
      options={medicalConditions}
      getOptionLabel={(medicalCondition) => {
        return medicalCondition?.[name] || inputValue;
      }}
      renderOption={(props, { medicalConditionName, ICDCode }) => {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <ListItem {...props} key={ICDCode}>
            <ListItemText primary={medicalConditionName} secondary={ICDCode} />
          </ListItem>
        );
      }}
      filterOptions={(options) => options}
      disableClearable
      value={form.getState().values[name] || inputValue || ''}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            name={name}
            id={id}
            label={label}
            required={required}
            params={params}
            endAdornment={loading ? <CircularProgress size={20} /> : undefined}
            validations={validations}
            autoFocus={autoFocus}
            maxLength={maxLength}
          />
        );
      }}
    />
  );
};

MedicalConditionAutoSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  prefillFields: PropTypes.arrayOf(PropTypes.string),
  maxLength: PropTypes.number,
};

MedicalConditionAutoSuggest.defaultProps = {
  validations: [],
  required: false,
  autoFocus: false,
  prefillFields: [],
  maxLength: 50,
};

export default MedicalConditionAutoSuggest;
