/* eslint-disable max-lines */
/* eslint max-lines: ["error", {"max": 300, "skipBlankLines": true}] */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Typography,
} from '@mui/material';
import { lighten, useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { find, get, groupBy, isNaN, map } from 'lodash';
import { differenceInYears, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { LoadingButton } from '@mui/lab';
import { Form } from 'react-final-form';

import { getRequestLineItemVerificationDataApi } from 'api/requests';
import { handleError } from 'store/thunks/errorHandlerThunks';
import gender from 'common/constants/gender';
import allergyTypes from 'common/constants/allergyTypes';
import dawCodes from 'common/constants/dawCodes';
import useInfoDialog from 'common/hooks/useInfoDialog';
import useRoles from 'common/hooks/useRoles';
import { normalizePhoneNumber } from 'common/utils';
import LoadingModule from 'common/components/LoadingModule';
import { verifyRequestLineItem } from 'store/thunks/patientThunks';

import PrescriptionNeedsReviewForm from './PrescriptionNeedsReviewForm';
import { EligibilityConfirmDialog } from '../EligibilityActionItems/EligibilityCheckSubstance';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const ItemLabelValue = ({ value, label }) => {
  return (
    <Grid container>
      <Grid item sx={{ minWidth: 125 }}>
        <Typography sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ minHeight: 20 }}>{value || 'N/A'}</Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};
ItemLabelValue.defaultProps = {
  value: '',
};

const VerifyPrescriptionButton = ({ lineItem, mpi }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [open, setOpen] = useState(false);
  const patientData = useSelector(({ patient }) => patient[mpi]);
  const address = find(patientData?.addresses, { isDefault: true })?.address;
  const requestData = get(patientData.requests, lineItem.requestId);
  const lineItemData = get(requestData.transferRequest, lineItem.requestLineItemId);
  const rxNumber = lineItemData?.qs1RxNumber;
  const [submitting, setSubmitting] = useState(false);
  const hasAccess = useRoles();
  const [openRxNumberAssertForm, setOpenRxNumberAssertForm] = useState(false);

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);
  const openRxNumberAssertionForm = () => setOpenRxNumberAssertForm(true);
  const closeRxNumberAssertionForm = () => setOpenRxNumberAssertForm(false);

  const handlePrescriptionVerification = (formData) => {
    const mergedData = {
      verified: true,
      mpi,
      requestLineItemId: lineItem.requestLineItemId,
      ...formData,
    };

    showInfoDialog({
      title: mergedData.verified ? 'Mark as verified' : 'Needs Review',
      message:
        !prescriptionData.requestLineItem.qs1RxNumber && !rxNumber
          ? 'Provide Rx number before prescription verification'
          : `Please confirm to mark this prescription as ${
              mergedData.verified ? 'verified' : 'needs review'
            }.`,
      cancelable: true,
      isWarning: !mergedData.verified,
      confirmButtonText: 'Confirm',
      callback: () => {
        if (!prescriptionData.requestLineItem.qs1RxNumber && !rxNumber) {
          openRxNumberAssertionForm();
          return;
        }

        setSubmitting(true);
        dispatch(verifyRequestLineItem({ input: mergedData, requestId: lineItem.requestId })).then(
          (success) => {
            if (success) {
              closeDialog();
            }
            setSubmitting(false);
          }
        );
      },
    });
  };

  const handleOpenForm = () => openDialog();

  useEffect(() => {
    if (open) {
      getRequestLineItemVerificationDataApi({ mpi, requestLineItemId: lineItem.requestLineItemId })
        .then(setPrescriptionData)
        .catch((error) => dispatch(handleError({ error })));
    }
  }, [open, dispatch, mpi, lineItem.requestLineItemId]);

  return (
    <>
      <InfoDialog />
      <Button
        variant='contained'
        color='primary'
        size='small'
        fullWidth
        onClick={openDialog}
        disabled={!hasAccess.prescriptionVerification}
      >
        Verify
      </Button>

      <EligibilityConfirmDialog
        mpi={mpi}
        requestLineItemId={lineItem.requestLineItemId}
        requestId={lineItem.requestId}
        showConfirmDialog={openRxNumberAssertForm}
        closeConfirmDialog={closeRxNumberAssertionForm}
        status={lineItem.status}
        openFormEvent={handleOpenForm}
      />

      <Dialog fullScreen open={open} onClose={closeDialog} TransitionComponent={Transition}>
        <Grid container sx={{ px: 2, pt: 1 }} justifyContent='space-between' alignItems='center'>
          <Typography component='h2' variant='h6'>
            Prescription Verification
          </Typography>

          <IconButton onClick={closeDialog} aria-label='Close Dialog'>
            <CloseIcon />
          </IconButton>
        </Grid>

        <DialogContent sx={{ px: 4 }}>
          <Grid container sx={{ mb: 2 }}>
            <Grid item sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid item xs={12} sx={{ display: 'inline-flex' }}>
                  <Typography variant='h5' component='h3' sx={{ fontWeight: 'bold', mr: 1 }}>
                    {patientData.firstName} {patientData.middleName} {patientData.lastName}
                  </Typography>
                  <Typography
                    variant='h6'
                    component='span'
                    sx={{ color: lighten(theme.palette.text.primary, 0.4) }}
                  >
                    {format(utcToZonedTime(new Date(patientData.dob), 'utc'), 'MM/dd/yyyy')}&nbsp;
                    (Age {differenceInYears(new Date(), new Date(patientData.dob))})&nbsp;
                    {gender[patientData.gender]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ py: 0.5 }}>
                  <Typography
                    variant='body1'
                    sx={{ color: lighten(theme.palette.text.primary, 0.4) }}
                  >
                    {address.addressLine1},&nbsp;
                    {address.addressLine2}
                    {!!address.addressLine2 && ', '}
                    {address.city},&nbsp;
                    {address.state}&nbsp;
                    {address.zip}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ display: 'flex', alignItems: 'start' }}>
              <Form
                onSubmit={handlePrescriptionVerification}
                render={({ handleSubmit }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    <LoadingButton
                      loading={submitting}
                      variant='contained'
                      sx={{ minWidth: 130 }}
                      disabled={!prescriptionData}
                      type='submit'
                    >
                      Mark as Verified
                    </LoadingButton>
                  </form>
                )}
              />
            </Grid>
          </Grid>

          {!prescriptionData && <LoadingModule height='25vh' />}

          {!!prescriptionData && (
            <Grid container>
              <Grid item xs={12} md={3}>
                <Grid container direction='column' gap={1}>
                  <Typography variant='h6' component='h4'>
                    Prescriber Information
                  </Typography>

                  <ItemLabelValue label='Name' value={prescriptionData.prescriber?.name} />
                  <ItemLabelValue label='NPI' value={prescriptionData.prescriber?.npi} />
                  <ItemLabelValue label='DEA' value={prescriptionData.prescriber?.dea} />
                  <ItemLabelValue label='DEA State' value={prescriptionData.prescriber?.deaState} />
                  <ItemLabelValue
                    label='Phone Number'
                    value={normalizePhoneNumber(prescriptionData.prescriber?.phoneNumber)}
                  />

                  <Typography variant='h6' component='h4' sx={{ mt: 4 }}>
                    Allergies
                  </Typography>

                  <Box sx={{ mb: 4 }}>
                    {map(groupBy(prescriptionData.allergies, 'allergyType'), (allergy, key) => (
                      <Accordion key={key} disableGutters defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`${key}-content`}
                          id={`${key}-header`}
                          sx={{ bgcolor: lighten(theme.palette.text.primary, 0.95) }}
                        >
                          <Typography>{allergyTypes[key] || key}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <List dense disablePadding>
                            {map(allergy, ({ description, patientAllergyId }, index) => (
                              <ListItem
                                disableGutters
                                divider={index !== allergy.length - 1}
                                key={patientAllergyId}
                              >
                                <ListItemText>{description}</ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Box>
                </Grid>
              </Grid>

              <Divider
                orientation='vertical'
                flexItem
                sx={{ mx: 3, [theme.breakpoints.down('md')]: { display: 'none' } }}
              />

              <Grid item sx={{ flexGrow: 1 }}>
                <Grid container direction='column' gap={1}>
                  <Typography variant='h6' component='h4'>
                    Requested Medication Transfer
                  </Typography>

                  <ItemLabelValue
                    label='Drug Name'
                    value={prescriptionData.requestLineItem.item.patientMedication.name}
                  />

                  <ItemLabelValue
                    label='QS1 Rx Number'
                    value={prescriptionData.requestLineItem.qs1RxNumber || rxNumber}
                  />

                  <ItemLabelValue
                    label='NDC'
                    value={prescriptionData.requestLineItem.item.patientMedication.ndc}
                  />

                  <ItemLabelValue
                    label='DAW Code'
                    value={
                      dawCodes[prescriptionData.requestLineItem.item.patientMedication.dawCode]
                    }
                  />

                  <ItemLabelValue
                    label='Instructions'
                    value={prescriptionData.requestLineItem.item.patientMedication.instructions}
                  />

                  <Grid item sx={{ my: 2 }}>
                    <ItemLabelValue
                      label='Copay'
                      value={
                        !isNaN(parseFloat(prescriptionData.requestLineItem.copay, 10))
                          ? `$${(
                              Math.round(prescriptionData.requestLineItem.copay * 100) / 100
                            ).toFixed(2)}`
                          : null
                      }
                    />
                  </Grid>

                  <PrescriptionNeedsReviewForm
                    onSubmit={handlePrescriptionVerification}
                    submitting={submitting}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

VerifyPrescriptionButton.propTypes = {
  lineItem: PropTypes.shape({
    requestLineItemId: PropTypes.string.isRequired,
    requestId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    qs1RxNumber: PropTypes.string,
  }),
  mpi: PropTypes.string.isRequired,
};

VerifyPrescriptionButton.defaultProps = {
  lineItem: PropTypes.shape({
    qs1RxNumber: null,
  }),
};

export default VerifyPrescriptionButton;
