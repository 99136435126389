import { Badge, Box, Grid, Paper, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';

import useTabContext, { TabProvider } from 'common/hooks/useTabContext';
import useActionItemsCount from 'common/hooks/useActionItemsCount';

import PatientCommentsTab from './CommentsTab/PatientCommentsTab';
import PatientPrescriptionsTab from './PrescriptionsTab/PatientPrescriptionsTab';
import PatientActionItemsTab from './PatientActionItemsTab';
import PatientShippingTab from './ShippingTab/PatientShippingTab';
import PatientInvoicesTab from './Invoices/PatientInvoicesTab';
import ProvidersTab from './ProvidersTab';
import MedicalHistoryTab from './MedicalHistoryTab';
import InsurancesTab from './InsurancesTab';

const ActionItemCountBadge = () => {
  const { mpi } = useParams();
  const count = useActionItemsCount({ mpi });

  return count ? <Badge color='error' badgeContent={count} sx={{ mx: 2 }} /> : null;
};

const TabPanel = ({ children, value }) => {
  const { tab } = useTabContext();
  const hidden = value !== tab;

  return (
    <div
      role='tabpanel'
      hidden={hidden}
      id={`patient-details-tabpanel-${value}`}
      aria-labelledby={`patient-details-tab-${value}`}
    >
      <Box sx={{ py: 3, px: 1 }}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

const a11yProps = (index) => ({
  id: `patient-details-tab-${index}`,
  'aria-controls': `patient-details-tabpanel-${index}`,
});

const tabsDetails = [
  {
    tabName: (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        Action Items
        <ActionItemCountBadge />
      </Box>
    ),
    Component: PatientActionItemsTab,
    tabValue: 'PatientActionItemsTab',
  },
  {
    tabName: 'Medical History',
    Component: MedicalHistoryTab,
    tabValue: 'PatientMedicalHistoryTab',
  },
  {
    tabName: 'Providers',
    Component: ProvidersTab,
    tabValue: 'PatientProvidersTab',
  },
  {
    tabName: 'Insurances',
    Component: InsurancesTab,
    tabValue: 'PatientInsuranceTab',
  },
  {
    tabName: 'Prescriptions',
    Component: PatientPrescriptionsTab,
    tabValue: 'PatientPrescriptionsTab',
  },
  {
    tabName: 'Shipping',
    Component: PatientShippingTab,
    tabValue: 'PatientShippingTab',
  },
  {
    tabName: 'Invoices',
    Component: PatientInvoicesTab,
    tabValue: 'PatientInvoicesTab',
  },
  {
    tabName: 'Comments',
    Component: PatientCommentsTab,
    tabValue: 'PatientCommentsTab',
  },
];

const TabList = () => {
  const { tab, setTab } = useTabContext();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={tab}
        onChange={setTab}
        aria-label='Patient Details Tabs'
        scrollButtons='auto'
        sx={{ maxWidth: '85vw' }}
        variant='scrollable'
      >
        {map(tabsDetails, ({ tabName, tabValue }, key) => (
          <Tab label={tabName} {...a11yProps(key)} key={key} value={tabValue} />
        ))}
      </Tabs>
    </Box>
  );
};

const PatientDetailsTabs = () => {
  return (
    <TabProvider initialValue='PatientActionItemsTab'>
      <Grid container component={Paper} sx={{ px: 2, minHeight: '90vh' }} direction='column'>
        <TabList />

        {map(tabsDetails, ({ Component, tabValue }) => (
          <TabPanel value={tabValue} key={tabValue}>
            <Component />
          </TabPanel>
        ))}
      </Grid>
    </TabProvider>
  );
};

export default PatientDetailsTabs;
