import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { useDispatch } from 'react-redux';

import { prescriberClarificationCheck } from 'store/thunks/patientThunks';

import EligibilityFailureDialog from './EligibilityFailureDialog';

const EligibilityCheckPrescriber = ({ mpi, requestId, requestLineItemId, onClose }) => {
  const [needsClarification, setNeedsClarification] = useState(null);
  const [showFailDialog, setShowFailDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleCheckPrescriberClarification = () => {
    if (needsClarification === 'true') {
      return setShowFailDialog(true);
    }

    setIsLoading(true);

    const checkInput = {
      mpi,
      requestLineItemId,
      needsClarification: false,
    };

    return dispatch(prescriberClarificationCheck({ mpi, requestId, checkInput })).then(() => {
      setIsLoading(false);
    });
  };

  const handleChangeRadio = (event) => {
    setNeedsClarification(event.target.value);
  };

  const closeFailDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowFailDialog(false);
    }
  };

  const confirmFailureDialog = (formData) => {
    const checkInput = {
      needsClarification: true,
      mpi,
      requestLineItemId,
      failureReason: formData,
    };
    return dispatch(prescriberClarificationCheck({ mpi, requestId, checkInput }));
  };

  return (
    <>
      <EligibilityFailureDialog
        confirmFailure={confirmFailureDialog}
        open={showFailDialog}
        onClose={closeFailDialog}
      />

      <Grid container>
        <FormControl>
          <Typography
            variant='h6'
            component={FormLabel}
            id='EligibilityActionItems-EligibilityCheckPrescriber-radioSelect-group'
          >
            Is prescriber clarification needed?
          </Typography>
          <Box sx={{ ml: 2, my: 2 }}>
            <RadioGroup
              aria-labelledby='EligibilityActionItems-EligibilityCheckPrescriber-radioSelect-group'
              value={needsClarification}
              onChange={handleChangeRadio}
            >
              <FormControlLabel value control={<Radio />} label='Yes' />
              <FormControlLabel value={false} control={<Radio />} label='No' />
            </RadioGroup>
          </Box>
        </FormControl>
        <Grid container direction='row-reverse'>
          <LoadingButton
            loading={isLoading}
            variant='contained'
            disabled={!needsClarification}
            sx={{ width: 100 }}
            onClick={handleCheckPrescriberClarification}
          >
            Save
          </LoadingButton>
          <Button
            sx={{ mr: 2, width: 100 }}
            variant='outlined'
            color='secondary'
            size='medium'
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

EligibilityCheckPrescriber.propTypes = {
  onClose: PropTypes.func.isRequired,
  mpi: PropTypes.string.isRequired,
  requestLineItemId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
};

export default EligibilityCheckPrescriber;
