import { Button, Grid, MenuItem, Popover, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { Form } from 'react-final-form';

import SelectField from 'common/forms/SelectField';
import DateField from 'common/forms/DateField';
import TextField from 'common/forms/TextField';
import { filterEvents } from 'store/thunks/eventThunks';
import { validateMinLength } from 'common/forms/formValidations';
import { map } from 'lodash';

const EventsFilterButton = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const filter = useSelector(({ criticalEvents: { events } }) => ({
    ...events.filter,
    createdAfter: events.filter?.createdAfter && new Date(events.filter.createdAfter),
  }));

  const criticalEventTypes = useSelector(({ criticalEvents: { eventTypes } }) => eventTypes);

  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const popoverId = anchorEl ? 'CriticalEvents-eventsFilterButton-Popover' : undefined;

  const handleApplyFilter = (formData) => {
    setAnchorEl(null);

    dispatch(
      filterEvents({
        filter: {
          ...formData,
          createdAfter: formData.createdAfter && formData.createdAfter.toISOString(),
        },
      })
    );
  };

  return (
    <>
      <Button
        sx={{ height: 33 }}
        variant='outlined'
        color='secondary'
        onClick={handleOpen}
        aria-describedby={popoverId}
      >
        Filter
      </Button>

      <Popover
        id={popoverId}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid container sx={{ p: 2, maxWidth: 400 }} spacing={2} direction='column'>
          <Grid item>
            <Typography component='h2'>Filter</Typography>
          </Grid>

          <Grid item>
            <Form
              initialValues={filter}
              onSubmit={handleApplyFilter}
              render={({ handleSubmit, invalid }) => (
                <form onSubmit={handleSubmit} noValidate>
                  <TextField
                    label='Event Actor Name'
                    name='pomUserName'
                    id='CriticalEvents-eventsFilterButton-pomUserName-input'
                    validations={[validateMinLength('Minimum of 3 characters', 3)]}
                  />

                  <DateField
                    label='Event Created After'
                    name='createdAfter'
                    id='CriticalEvents-eventsFilterButton-createdAfter-dateField'
                    maxDate={new Date()}
                  />

                  <SelectField
                    label='Event Type'
                    name='eventType'
                    id='CriticalEvents-eventsFilterButton-eventType-dropdown'
                  >
                    <MenuItem value=''>All</MenuItem>
                    {map(criticalEventTypes, (eventType) => (
                      <MenuItem value={eventType} key={eventType}>
                        {eventType.replace(/_/g, ' ')}
                      </MenuItem>
                    ))}
                  </SelectField>

                  <Grid container justifyContent='flex-end'>
                    <Button variant='contained' type='submit' disabled={invalid}>
                      Apply
                    </Button>
                  </Grid>
                </form>
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
};

export default EventsFilterButton;
