import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Form } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { map } from 'lodash';
import PropTypes from 'prop-types';

import useRoles from 'common/hooks/useRoles';
import SelectField from 'common/forms/SelectField';
import productFillFailureTypes from 'common/constants/productFillFailureTypes';
import { attachProductToNewOrder } from 'store/thunks/patientThunks';

const ProductFulfillFailureReasonDialog = ({ open, onClose, mpi, lineItemId, orderId }) => {
  const dispatch = useDispatch();
  const hasAccess = useRoles();

  const closeDialog = () => onClose();
  const handleCancelButton = () => closeDialog();

  const markProductAsCannotFill = ({ failureReason }) => {
    return dispatch(
      attachProductToNewOrder({
        lineItemId,
        mpi,
        orderId,
        state: 'WILL_NOT_FILL',
        failureReason,
      })
    );
  };

  return (
    <Dialog
      open={open}
      aria-describedby='EligibilityActionItems-ProductFulfillFailureReasonDialog-header'
      fullWidth
    >
      <DialogTitle id='EligibilityActionItems-ProductFulfillFailureReasonDialog-header'>
        Cannot Fill
      </DialogTitle>

      <Form
        onSubmit={markProductAsCannotFill}
        render={({ submitting, handleSubmit, invalid }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent>
              <SelectField
                name='failureReason'
                id='Product-Fill-Failure-Reason-dropdown'
                label='Reason that the item can not be filled'
                required
              >
                {map(productFillFailureTypes, (value, key) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </SelectField>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleCancelButton}
                color='secondary'
                size='medium'
                variant='outlined'
              >
                Cancel
              </Button>
              <LoadingButton
                loading={submitting}
                type='submit'
                variant='contained'
                disabled={!hasAccess.manageProductFulfillmentStatus || invalid}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

ProductFulfillFailureReasonDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  mpi: PropTypes.string.isRequired,
  lineItemId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductFulfillFailureReasonDialog;
