import { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { noop } from 'lodash';
import { LoadingButton } from '@mui/lab';

const useInfoDialog = () => {
  const [content, setContent] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const confirmDialog = useCallback(async () => {
    setSubmitting(true);
    await content.callback();
    setContent(null);
    setSubmitting(false);
  }, [content]);

  const closeDialog = () => setContent(null);

  const showInfoDialog = ({
    title,
    message,
    callback = noop,
    cancelable = false,
    confirmButtonText = 'OK',
    isWarning,
    closeInfo,
  }) => {
    setContent({
      title,
      message,
      callback,
      cancelable,
      confirmButtonText,
      isWarning,
      closeInfo,
    });
  };

  if (content?.closeInfo) {
    closeDialog();
  }

  const InfoDialog = () => {
    if (!content) {
      return null;
    }

    return (
      <Dialog open={!!content} aria-labelledby='useInfoDialog-dialog-title' transitionDuration={0}>
        <DialogTitle id='useInfoDialog-dialog-title' sx={{ fontWeight: 600 }}>
          {content.title}
        </DialogTitle>

        <DialogContent>{content.message}</DialogContent>

        <DialogActions
          sx={{
            flexDirection: content.isWarning ? 'row-reverse' : 'row',
            justifyContent: content.isWarning ? 'flex-start' : 'flex-end',
          }}
        >
          {content.cancelable && (
            <Button
              id='useInfoDialog-cancel-button'
              onClick={closeDialog}
              color='secondary'
              size='medium'
              variant='outlined'
              sx={{ ml: 1 }}
            >
              Cancel
            </Button>
          )}

          <LoadingButton
            loading={submitting}
            id='useInfoDialog-confirm-button'
            onClick={confirmDialog}
            color={content.isWarning ? 'error' : 'primary'}
            size='medium'
            variant='contained'
          >
            {content.confirmButtonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    );
  };

  return { InfoDialog, showInfoDialog };
};

export default useInfoDialog;
