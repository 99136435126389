import { useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';

import useRoles from 'common/hooks/useRoles';
import { updateRequestLineItemState } from 'store/thunks/patientThunks';
import lineItemStatus from 'common/constants/lineItemStatus';
import lineItemEnum from 'enums/lineItem.enum';

const ECommPrescriptionEligibilityButton = ({ mpi, lineItem }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const hasAccess = useRoles();
  const dispatch = useDispatch();

  const modelContent = {
    [lineItemEnum.RX_REQUEST]: {
      header: lineItemStatus.PROVIDER_CONTACTED,
      context: 'Please confirm that provider was contacted.',
    },
    [lineItemEnum.PROVIDER_CONTACTED]: {
      header: lineItemStatus.RX_RECEIVED,
      context: 'Please confirm that the prescription has been received.',
    },
    [lineItemEnum.RX_RECEIVED]: {
      header: 'Entry Complete',
      context: 'Please confirm that entry is completed.',
    },
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleConfirm = () => {
    setLoading(true);

    return dispatch(
      updateRequestLineItemState({
        mpi,
        requestId: lineItem.requestId,
        lineItemId: lineItem.requestLineItemId,
        currentState: lineItem.status,
      })
    ).then(() => {
      handleCloseModal();
      setLoading(false);
    });
  };

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        size='small'
        onClick={handleOpenModal}
        fullWidth
        disabled={!hasAccess.eCommPrescriptionEligibility}
      >
        {modelContent[lineItem.status].header}
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='ProviderContactedButton-Dialog-Header'
      >
        <Grid>
          <DialogTitle id='ProviderContactedButton-Dialog-Header'>
            <Typography sx={{ fontSize: '25px' }}>
              {modelContent[lineItem.status].header}
            </Typography>
          </DialogTitle>

          <DialogContent sx={{ minWidth: '500px' }}>
            <Typography variant='subtitle1' sx={{ m: 2 }}>
              {modelContent[lineItem.status].context}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant='outlined' color='secondary' sx={{ mr: 1 }}>
              Cancel
            </Button>
            <LoadingButton variant='contained' loading={loading} onClick={handleConfirm}>
              Confirm
            </LoadingButton>
          </DialogActions>
        </Grid>
      </Dialog>
    </>
  );
};

ECommPrescriptionEligibilityButton.propTypes = {
  lineItem: PropTypes.shape({
    name: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    instructions: PropTypes.string,
    quantity: PropTypes.string,
    ndc: PropTypes.string,
    status: PropTypes.string,
    requestLineItemId: PropTypes.string,
    requestId: PropTypes.string,
    dawCode: PropTypes.number,
    reviewReason: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

export default ECommPrescriptionEligibilityButton;
