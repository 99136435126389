/* eslint-disable max-lines */
/* istanbul ignore file */ // not targettable
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Popover,
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Editor from '@toast-ui/editor';
import { replace } from 'lodash';

import {
  composeValidations,
  validateEmail,
  validatePhoneNumber,
  validateRequired,
} from './formValidations';
import TextField from './TextField';
import SelectField from './SelectField';

import('@toast-ui/editor/dist/toastui-editor.css');

const LinkPopover = ({ open, anchorEl, onClose, addLink }) => {
  const handleAddLink = ({ linkText, type, url, phone, email }) => {
    const linkUrl = {
      url: `https://${replace(url, /^https?:\/\//i, '')}`,
      email: `mailto:${replace(email, /^mailto:/i, '')}`,
      phone: `tel:${replace(phone, /^tel:/i, '')}`,
    };

    addLink({
      linkUrl: linkUrl[type],
      linkText,
    });

    onClose();
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Form
        initialValues={{ type: 'url' }}
        onSubmit={handleAddLink}
        render={({ handleSubmit, values, invalid }) => (
          <form noValidate onSubmit={handleSubmit} style={{ padding: 16 }}>
            <TextField
              label='Displayed Text'
              name='linkText'
              id='MarkdownField-LinkPopover-linkText-input'
              required
            />

            <SelectField name='type' label='Link Type' id='MarkdownField-LinkPopover-url-input'>
              <MenuItem value='url'>URL</MenuItem>
              <MenuItem value='email'>Email</MenuItem>
              <MenuItem value='phone'>Phone Number</MenuItem>
            </SelectField>

            {values.type === 'url' && (
              <TextField label='URL' name='url' id='MarkdownField-LinkPopover-url-input' required />
            )}

            {values.type === 'email' && (
              <TextField
                label='Email'
                name='email'
                id='MarkdownField-LinkPopover-email-input'
                required
                validations={[validateEmail()]}
              />
            )}

            {values.type === 'phone' && (
              <TextField
                label='Phone'
                name='phone'
                id='MarkdownField-LinkPopover-phone-input'
                required
                validations={[validatePhoneNumber()]}
              />
            )}

            <Grid container direction='row-reverse' gap={1}>
              <Button variant='contained' color='primary' type='submit' disabled={invalid}>
                Link
              </Button>
              <Button variant='outlined' color='secondary' onClick={onClose}>
                Cancel
              </Button>
            </Grid>
          </form>
        )}
      />
    </Popover>
  );
};

LinkPopover.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func.isRequired,
  addLink: PropTypes.func.isRequired,
};

LinkPopover.defaultProps = {
  anchorEl: null,
};

const MarkdownEditor = ({ id, autoFocus, height, onChange, value, onBlur, onFocus }) => {
  const editor = useRef();
  const [linkPopover, setLinkPopover] = useState(false);

  const openLinkPopover = () => setLinkPopover(true);
  const closeLinkPopover = () => setLinkPopover(false);

  const addLink = ({ linkUrl, linkText }) => editor.current.exec('addLink', { linkUrl, linkText });

  const createEditorInstance = useCallback(() => {
    const createButton = () => {
      const button = document.createElement('button');

      button.className = 'link toastui-editor-toolbar-icons';
      button.style.margin = '0';
      button.ariaLabel = 'Insert Link';
      button.type = 'button';
      button.id = `common-forms-MarkDownEditor-${id}-link-button`;
      button.addEventListener('click', openLinkPopover);

      return button;
    };

    editor.current = Editor.factory({
      el: document.querySelector(`#${id}-editor`),
      previewStyle: 'vertical',
      height,
      initialValue: value,
      placeholder: 'Type something',
      initialEditType: 'wysiwyg',
      usageStatistics: false,
      hideModeSwitch: true,
      autofocus: autoFocus,
      events: {
        change: () => {
          onChange(editor.current.getMarkdown());
        },
        blur: onBlur,
        focus: onFocus,
      },
      toolbarItems: [
        ['heading', 'bold', 'italic'],
        ['hr', 'quote'],
        ['ul', 'ol', 'indent', 'outdent'],
        [
          'table',
          {
            el: createButton(),
            tooltip: 'Insert Link',
          },
        ],
        ['code', 'codeblock'],
      ],
    });
  }, [autoFocus, height, id, onBlur, onChange, onFocus, value]);

  useEffect(() => {
    if (!editor.current) {
      createEditorInstance();
    }
  }, [createEditorInstance]);

  useEffect(() => {
    if (!value) {
      editor.current?.destroy();
      createEditorInstance();
      editor.current?.focus();
    }
  }, [value, createEditorInstance]);

  return (
    <Box
      sx={{
        mt: '1.3em !important',
        '& .toastui-editor-ok-button': {
          bgcolor: (theme) => `${theme.palette.primary.main} !important`,
        },
      }}
    >
      <LinkPopover
        open={linkPopover}
        anchorEl={document.querySelector(`#common-forms-MarkDownEditor-${id}-link-button`)}
        onClose={closeLinkPopover}
        addLink={addLink}
      />
      <div id={`${id}-editor`} />
    </Box>
  );
};

MarkdownEditor.defaultProps = {
  height: '300px',
  autoFocus: false,
};

MarkdownEditor.propTypes = {
  id: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  height: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
};

const MarkdownField = ({
  name,
  id,
  label,
  validations,
  autoFocus,
  height,
  helperText,
  required,
  disabled,
}) => {
  const validate = composeValidations([
    ...validations,
    ...(required ? [validateRequired('Required')] : []),
  ]);

  return (
    <Field
      name={name}
      id={id}
      validate={validate}
      render={({ input, meta }) => {
        // showError needs to be a boolean, material type check this value
        const showError = !!meta.error && !!meta.touched;
        const displayedHelperText = (!!meta.touched && meta.error) || helperText;

        return (
          <FormControl error={showError} variant='standard' fullWidth>
            <InputLabel shrink htmlFor={id} required={required} sx={{ fontSize: '1.2em' }}>
              {label}
            </InputLabel>

            <MarkdownEditor
              name={name}
              id={id}
              autoFocus={autoFocus}
              height={height}
              onChange={input.onChange}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              value={input.value || ''}
              disabled={disabled}
            />

            <FormHelperText>{displayedHelperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

MarkdownField.defaultProps = {
  validations: [],
  autoFocus: false,
  required: false,
  helperText: '',
  height: '300px',
  disabled: false,
};

MarkdownField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  height: PropTypes.string,
  validations: PropTypes.arrayOf(PropTypes.func),
};

export default MarkdownField;
