/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React, { forwardRef, useState } from 'react';
import JsonView from '@uiw/react-json-view';
import { capitalize, isEmpty, isObject, map, startCase } from 'lodash';
import { lighten, useTheme } from '@mui/material/styles';
import {
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { getEventDescription } from 'common/constants/getEventDescription';
import userRoleAbbreviations from 'common/constants/userRoleAbbreviations';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const JsonViewDialog = ({ jsonData, open, closeModel }) => {
  const [copied, setCopied] = useState(false);
  const closeDialog = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      closeModel();
    }
  };

  const handleResetCopyJson = () => setCopied(false);

  const handleCopyJson = () => {
    setCopied(true);
    const jsonString = JSON.stringify(jsonData, null, 2);
    navigator.clipboard.writeText(jsonString);
  };

  return (
    <Dialog
      open={!!open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      aria-labelledby='JsonViewDialog-title'
      transitionDuration={{ exit: 0 }}
    >
      <DialogTitle id='JsonViewDialog-title'>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h6' sx={{ mb: 2 }} gutterBottom>
              Event Payload
            </Typography>
          </Grid>

          <Grid item>
            {copied ? (
              <Tooltip title='Copied'>
                <IconButton
                  sx={{ display: 'flex' }}
                  size='small'
                  onClick={() => handleResetCopyJson()}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <IconButton sx={{ display: 'flex' }} size='small' onClick={() => handleCopyJson()}>
                <ContentCopyIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent sx={{ minWidth: 600 }}>
        <JsonView
          collapsed={250}
          value={isObject(jsonData) ? jsonData : { jsonData }}
          enableClipboard={false}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} size='small' variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

JsonViewDialog.propTypes = {
  jsonData: PropTypes.shape({}).isRequired,
  open: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
};

const TimeLineView = ({ timeLineData }) => {
  const theme = useTheme();
  const [expandedIds, setExpandedIds] = useState([]);
  const [jsonToView, setJsonToView] = useState();

  const handleToggle = (id) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id) ? prevIds.filter((itemId) => itemId !== id) : [...prevIds, id]
    );
  };

  const closeModel = () => setJsonToView();

  const getUserRoleAbbreviations = (roles) => {
    const roleAbbreviations = roles.map((role) => userRoleAbbreviations[role]);

    return roleAbbreviations.join(', ');
  };

  return (
    <>
      {!isEmpty(jsonToView) && (
        <JsonViewDialog open={!isEmpty(jsonToView)} jsonData={jsonToView} closeModel={closeModel} />
      )}

      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {!isEmpty(timeLineData) &&
          map(
            timeLineData,
            ({ id, requestDetails, actionItemType, timestamp, eventPayload, isProcessSuccess }) => {
              const colorCode = theme.palette[isProcessSuccess ? 'success' : 'error']?.light;
              const isOpen = expandedIds.includes(id);
              const currentUser = requestDetails?.user;
              const currentRoles = currentUser?.userRoles;
              const userLastName = currentUser?.lastName ? ` ${currentUser?.lastName.trim()}` : '';
              const userFirstName = currentUser?.firstName;
              const userFullName = capitalize(userFirstName) + capitalize(userLastName);
              const userRoles = currentRoles ? getUserRoleAbbreviations(currentRoles) : '';
              const capitalizedSystemUser = capitalize('System User'.replace(/\s+/g, ''));
              const capitalizedUserName = capitalize(userFullName.replace(/\s+/g, ''));

              return (
                <TimelineItem key={id}>
                  <TimelineOppositeContent>
                    <Grid direction='column' display='flex' justifyContent='flex-start' container>
                      <Grid item>
                        <Typography
                          style={{ flex: 0.2, fontWeight: '600', color: theme.palette.info.dark }}
                          color='text.info'
                        >
                          {timestamp}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {capitalizedSystemUser === capitalizedUserName ? 'Auto' : userFullName}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>{userRoles}</Typography>
                      </Grid>
                    </Grid>
                  </TimelineOppositeContent>

                  <TimelineSeparator>
                    <TimelineDot sx={{ fontWeight: 600, background: colorCode }} />
                    <TimelineConnector sx={{ background: colorCode }} />
                  </TimelineSeparator>

                  <TimelineContent>
                    <Grid container alignItems='flex-start'>
                      <Grid item>
                        <Typography sx={{ fontWeight: 600, color: colorCode }}>
                          {startCase(actionItemType)?.toUpperCase()?.replace(/_/g, ' ')}
                        </Typography>
                      </Grid>
                      {!isEmpty(eventPayload) && (
                        <Grid item>
                          <Button
                            variant='text'
                            sx={{
                              p: 0,
                              ml: 1,
                              textDecoration: 'underline',
                              color: theme.palette.common.black,
                            }}
                            onClick={() => handleToggle(id)}
                          >
                            {isOpen ? 'View Less' : 'View More'}
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    <Collapse in={isOpen}>
                      <Card
                        elevation={0}
                        sx={{
                          width: 800,
                          my: 2,
                          border: `1px solid ${lighten(theme.palette.common.black, 0.8)}`,
                        }}
                      >
                        <CardContent sx={{ minHeight: 125 }}>
                          <Grid container direction='column'>
                            <Typography sx={{ mb: 2 }} gutterBottom>
                              Event Description :
                            </Typography>

                            <Typography sx={{ ml: 4 }}>
                              {getEventDescription(requestDetails)}
                            </Typography>

                            <Grid item container justifyContent='flex-end'>
                              <Button
                                size='small'
                                variant='outlined'
                                color='primary'
                                sx={{ mt: 3 }}
                                onClick={() => setJsonToView(eventPayload)}
                              >
                                View Json
                              </Button>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Collapse>
                  </TimelineContent>
                </TimelineItem>
              );
            }
          )}
      </Timeline>
    </>
  );
};

export default TimeLineView;

TimeLineView.propTypes = {
  timeLineData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      timestamp: PropTypes.string,
      eventPayload: PropTypes.shape({}),
      requestDetails: PropTypes.shape({}),
      isProcessSuccess: PropTypes.bool,
      actionItemType: PropTypes.string.isRequired,
    })
  ),
};

TimeLineView.defaultProps = {
  timeLineData: undefined,
};
