import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { find, first } from 'lodash';
import { useSelector } from 'react-redux';

const FacilityFilterStatusChip = ({ selectedFacilities, removeFilter }) => {
  const fulfillingPharmacies = useSelector(({ auth }) => auth.fulfillingPharmacies);

  const getFilterValue = (fulfillingPharmacyId) => {
    return find(fulfillingPharmacies, { fulfillingPharmacyId })?.fulfillingPharmacyName;
  };

  return selectedFacilities?.length === 1 ? (
    <Chip
      label={`Facility = ${getFilterValue(first(selectedFacilities))}`}
      variant='contained'
      onDelete={removeFilter('fulfillingPharmacyIds')}
    />
  ) : null;
};

export default FacilityFilterStatusChip;

FacilityFilterStatusChip.propTypes = {
  selectedFacilities: PropTypes.arrayOf(PropTypes.string),
  removeFilter: PropTypes.func.isRequired,
};

FacilityFilterStatusChip.defaultProps = {
  selectedFacilities: [],
};
