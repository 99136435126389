import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import ForbiddenAccess from 'common/components/ForbiddenAccess';

const RequireAuth = ({ allow, children }) => {
  const isAuthorizedUser = useSelector(({ auth }) => !isEmpty(auth.currentTenantRoles));
  const showAuthorizedComponent = allow && isAuthorizedUser;

  return showAuthorizedComponent ? children : <ForbiddenAccess />;
};

RequireAuth.defaultProps = {
  allow: true,
};

RequireAuth.propTypes = {
  allow: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RequireAuth;
