import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

import smartScriptsLogo from 'assets/img/smartScriptsLogo.png';
import GoogleIcon from 'assets/img/googleIcon.png';
import { encodeB64 } from 'common/utils';

const SignInForm = () => {
  const theme = useTheme();
  const location = useLocation();
  const fromLocation = location.state?.from || '/';

  /* istanbul ignore next */
  const handleGoogleSignIn = () =>
    window.location.assign(
      `${process.env.REACT_APP_GOOGLE_OAUTH_URL}/auth?state=${encodeB64(fromLocation)}`
    );

  return (
    <Grid
      container
      sx={{ p: 2 }}
      direction='column'
      justifyContent='center'
      alignItems='center'
      gap={2}
    >
      <Grid item>
        <img src={smartScriptsLogo} alt='SmartScripts' height={30} />
      </Grid>

      <Grid item>
        <Typography component='h1' variant='h3' textAlign='center'>
          SmartOS
        </Typography>
      </Grid>

      <Grid item sx={{ mt: 2 }}>
        <Button
          variant='outlined'
          color='secondary'
          size='large'
          sx={{ fontSize: '1.1em' }}
          onClick={handleGoogleSignIn}
        >
          <img
            src={GoogleIcon}
            alt='google'
            style={{ marginRight: theme.spacing(1) }}
            aria-hidden
          />
          Sign in with Google
        </Button>
      </Grid>
    </Grid>
  );
};

export default SignInForm;
