import React from 'react';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { MenuItem } from '@mui/material';
import { useForm } from 'react-final-form';

import SelectField from 'common/forms/SelectField';

const FacilityFilterField = () => {
  const form = useForm();
  const fulfillingPharmacies = useSelector(({ auth }) => auth.fulfillingPharmacies);
  const displayValue =
    fulfillingPharmacies?.length === 1 ? fulfillingPharmacies[0]?.fulfillingPharmacyId : undefined;

  const onChange = (value) => {
    form.change('fulfillingPharmacyIds', value ? [value] : '');
  };

  return (
    <SelectField
      label='Facility'
      name='fulfillingPharmacyIds'
      id='filter-facility-dropdown'
      disabled={!!displayValue}
      staticDisplayValue={displayValue}
      onChange={(e) => onChange(e.target.value)}
    >
      <MenuItem value=''>All</MenuItem>
      {map(fulfillingPharmacies, ({ fulfillingPharmacyId, fulfillingPharmacyName }) => (
        <MenuItem key={fulfillingPharmacyId} value={fulfillingPharmacyId}>
          {fulfillingPharmacyName}
        </MenuItem>
      ))}
    </SelectField>
  );
};

export default FacilityFilterField;
