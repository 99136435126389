import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import useRoles from 'common/hooks/useRoles';

import NewPharmacyButton from './NewPharmacyButton';
import PatientPharmaciesTable from './PatientPharmaciesTable';

const PatientPharmaciesTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Pharmacies
        </Typography>
        {hasAccess.addPharmacy && <NewPharmacyButton mpi={mpi} />}
      </Grid>

      <Grid item>
        <PatientPharmaciesTable mpi={mpi} />
      </Grid>
    </Grid>
  );
};

export default PatientPharmaciesTab;
