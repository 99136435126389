import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { visuallyHidden } from '@mui/utils';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { isUndefined } from 'lodash';

import useRoles from 'common/hooks/useRoles';
import LoadingModule from 'common/components/LoadingModule';

import UpdateAuthorizationButton from './UpdateAuthorizationButton';

const PatientPermissionRow = ({ name, value }) => (
  <TableRow>
    <TableCell sx={{ py: 1.5 }}>{name}</TableCell>
    <TableCell align='center'>
      {value && (
        <>
          <CheckIcon />
          <Typography sx={visuallyHidden}>Yes</Typography>
        </>
      )}
    </TableCell>
  </TableRow>
);

PatientPermissionRow.propTypes = {
  value: PropTypes.bool,
  name: PropTypes.string.isRequired,
};
PatientPermissionRow.defaultProps = {
  value: false,
};

const PatientAuthorizationTab = () => {
  const { mpi } = useParams();
  const hasAccess = useRoles();
  const authorization = useSelector(({ patient }) => patient[mpi]?.authorization);

  return (
    <Grid container direction='column' spacing={2}>
      <Grid item container justifyContent='space-between'>
        <Typography variant='h6' component='h2'>
          Authorizations
        </Typography>
        {hasAccess.updateAuthorizations && <UpdateAuthorizationButton mpi={mpi} />}
      </Grid>

      {isUndefined(authorization) ? (
        <LoadingModule height='25vh' />
      ) : (
        <>
          <Grid item sx={{ mb: 2 }}>
            <TableContainer>
              <Table sx={{ maxWidth: 450 }} aria-label='Patient Authorizations' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align='center'>Response</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  <PatientPermissionRow
                    name='Permission to Transfer'
                    value={authorization?.permissionToTransfer}
                  />
                  <PatientPermissionRow
                    name='Mandatory Disclosure'
                    value={authorization?.mandatoryDisclosure}
                  />
                  <PatientPermissionRow
                    name='Child Safety Waiver'
                    value={authorization?.childSafetyWaiver}
                  />
                  <PatientPermissionRow
                    name='Carebox Authorization'
                    value={authorization?.careboxAuthorization}
                  />
                  <PatientPermissionRow
                    name='Texting Authorization'
                    value={authorization?.textingAuthorization}
                  />
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {authorization?.authTimestamp && (
            <Grid item container>
              <Typography sx={{ minWidth: 120 }}>Authorized Time:</Typography>
              <Typography sx={{ fontWeight: '500' }}>
                {format(new Date(authorization.authTimestamp), 'hh:mmaaa MM/dd/yyyy')}
              </Typography>
            </Grid>
          )}

          {authorization?.auth && (
            <Grid item container>
              <Typography sx={{ minWidth: 120 }}>Authorized By:</Typography>
              <Typography sx={{ fontWeight: '500' }}>
                {authorization.auth.firstName} {authorization.auth.middleName}{' '}
                {authorization.auth.lastName}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default PatientAuthorizationTab;
