import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { Box, Button, Dialog, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { lighten } from '@mui/material/styles';

import useRoles from 'common/hooks/useRoles';

import ReviewControlledSubstance from './ReviewControlledSubstance';
import ReviewPrescriberClarification from './ReviewPrescriberClarification';
import ReviewClaimProfitability from './ReviewClaimProfitability';
import ReviewClaimPay from './ReviewClaimPay';

const ItemLabelValue = ({ value, label }) => {
  const usedValue = value || 'N/A';

  return (
    <Grid container spacing={2} columns={3}>
      <Grid item xs={1}>
        <Typography sx={{ color: (theme) => lighten(theme.palette.text.primary, 0.3) }}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ fontWeight: '500', minHeight: 20 }}>{usedValue}</Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
};
ItemLabelValue.defaultProps = {
  value: '',
};

const ReviewButton = ({ mpi, lineItem }) => {
  const [showModal, setShowModal] = useState(false);
  const hasAccess = useRoles();

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  return (
    <>
      <Button
        color='primary'
        variant='contained'
        onClick={handleOpenModal}
        size='small'
        fullWidth
        disabled={!hasAccess.reviewLineItem}
      >
        Review
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='ReviewItems-ReviewButton-header'
        fullWidth
      >
        <Grid container sx={{ px: 3, py: 2 }} justifyContent='space-between' alignItems='center'>
          <Typography variant='h6' component='h2' id='ReviewItems-ReviewButton-header'>
            Review
          </Typography>
          <IconButton onClick={handleCloseModal} aria-label='close dialog'>
            <CloseIcon />
          </IconButton>
        </Grid>
        <DialogContent>
          <Box>
            <ItemLabelValue label='Medication name:' value={lineItem.name} />
            <ItemLabelValue label='Strength:' value={lineItem.strength} />
            <ItemLabelValue label='Quantity:' value={lineItem.quantity} />
            <ItemLabelValue label='Form:' value={lineItem.form} />
            <ItemLabelValue label='NDC:' value={lineItem.ndc} />
            <ItemLabelValue label='DAW:' value={lineItem.dawCode} />
            <ItemLabelValue label='Instructions:' value={lineItem.instructions} />
          </Box>
          <Box sx={{ my: 3 }}>
            <Typography variant='h6' component='p'>
              Reasons for Needing Review:
            </Typography>
            <Typography>{lineItem.reviewReason?.join(', ')}</Typography>
          </Box>

          {lineItem.status === 'CONTROLLED_SUBSTANCE_CHECK' && (
            <ReviewControlledSubstance
              mpi={mpi}
              lineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}

          {lineItem.status === 'PRESCRIPTION_CLARIFICATION_CHECK' && (
            <ReviewPrescriberClarification
              mpi={mpi}
              lineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}

          {lineItem.status === 'CLAIM_PAY_CHECK' && (
            <ReviewClaimPay
              mpi={mpi}
              lineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}

          {lineItem.status === 'CLAIM_PROFITABLE_CHECK' && (
            <ReviewClaimProfitability
              mpi={mpi}
              lineItemId={lineItem.requestLineItemId}
              requestId={lineItem.requestId}
              onClose={handleCloseModal}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
ReviewButton.propTypes = {
  lineItem: PropTypes.shape({
    name: PropTypes.string,
    strength: PropTypes.string,
    form: PropTypes.string,
    instructions: PropTypes.string,
    quantity: PropTypes.string,
    ndc: PropTypes.string,
    status: PropTypes.string,
    requestLineItemId: PropTypes.string,
    requestId: PropTypes.string,
    dawCode: PropTypes.number,
    reviewReason: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  mpi: PropTypes.string.isRequired,
};

export default ReviewButton;
