import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress, ListItem, ListItemText } from '@mui/material';

import TextField from './TextField';
import { validateMinLength } from './formValidations';

const AutoSuggest = ({
  id,
  name,
  label,
  autoFocus,
  loading,
  options,
  fetchOptions,
  handleSelect,
}) => {
  const form = useForm();
  const [inputValue, setInputValue] = useState(form.getState().values[name] || '');

  const onInputChange = (_, value) => setInputValue(value);

  const onChange = (_, value) => {
    handleSelect(value?.id);
    form.batch(() => form.change(name, value));
  };

  useEffect(() => {
    if (inputValue.length >= 3) {
      fetchOptions(inputValue);
    } else {
      handleSelect('');
    }
  }, [inputValue, handleSelect]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Autocomplete
      freeSolo
      id={`${id}-autosuggest`}
      options={options}
      getOptionLabel={(option) => option?.label || inputValue}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ListItem {...props} key={option?.id}>
          <ListItemText primary={option?.label} />
        </ListItem>
      )}
      filterOptions={(drugs) => drugs}
      disableClearable
      value={inputValue || ''}
      onInputChange={onInputChange}
      onChange={onChange}
      renderInput={(params) => {
        return (
          <TextField
            name={name}
            id={id}
            label={label}
            required
            params={params}
            endAdornment={loading ? <CircularProgress size={20} /> : undefined}
            autoFocus={autoFocus}
            validations={[validateMinLength('Minimum of 3 characters', 3)]}
          />
        );
      }}
    />
  );
};

AutoSuggest.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  fetchOptions: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

AutoSuggest.defaultProps = { autoFocus: false, loading: false, options: [] };

export default AutoSuggest;
