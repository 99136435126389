import Editor from '@toast-ui/editor';
import { Box } from '@mui/material';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import '@toast-ui/editor/dist/toastui-editor-viewer.css';

const RichTextViewer = ({ value, id }) => {
  const customHHTMLSanitizer = (html) => {
    const sanitized = DOMPurify.sanitize(html.replace(/\\n\s+/, ''), {
      FORBID_TAGS: ['br'],
      FORBID_ATTR: ['style', 'dir', 'align', 'data-nodeid'],
      ADD_ATTR: ['target'],
    });
    return sanitized;
  };

  useEffect(() => {
    const viewer = Editor.factory({
      el: document.querySelector(`#${id}-rich-text-viewer`),
      initialValue: value,
      viewer: true,
      customHTMLSanitizer: customHHTMLSanitizer,
      linkAttributes: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    });

    return () => {
      viewer.destroy();
    };
  }, [id, value]);

  return <Box id={`${id}-rich-text-viewer`} sx={{ pb: 1 }} />;
};

RichTextViewer.propTypes = {
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default RichTextViewer;
