import { createSlice } from '@reduxjs/toolkit';
import { assign, find, values } from 'lodash';

const initialState = {};

/* eslint no-param-reassign: ["error", { "ignorePropertyModificationsFor": ["state"] }] */
const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    resetPartnerState(state) {
      Object.assign(state, initialState);
    },
    loadPartners(state, action) {
      const { partners, currentTenantId } = action.payload;
      state[currentTenantId] = partners;
    },
    loadPartnerPractices(state, action) {
      const { currentTenantId, practices, partnerId } = action.payload;

      const partner = find(state[currentTenantId], { partnerUuid: partnerId });

      assign(partner, { ...partner, partnerPractices: practices });

      state[currentTenantId] = values(state[currentTenantId]);
    },
  },
});

export const partnerActions = partnerSlice.actions;
export default partnerSlice.reducer;
