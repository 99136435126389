import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { composeValidations, validateRequiredCheckbox } from './formValidations';

const CheckboxField = ({
  name,
  id,
  label,
  required,
  validations,
  helperText,
  labelPlacement,
  disableHelperText,
  disabled,
}) => {
  const validate = composeValidations([
    ...validations,
    ...(required ? [validateRequiredCheckbox('Required')] : []),
  ]);

  return (
    <Field
      name={name}
      id={id}
      validate={validate}
      required={required}
      type='checkbox'
      render={({ input, meta }) => {
        // showError needs to be a boolean, material type check this value
        const showError = !!meta.error && (!!meta.touched || !!meta.modified);
        const displayedHelperText =
          ((!!meta.touched || !!meta.modified) && meta.error) || helperText;

        return (
          <FormControl
            required={required}
            error={showError}
            fullWidth
            sx={{ alignItems: labelPlacement === 'start' ? 'flex-start' : undefined }}
          >
            <FormControlLabel
              sx={{
                color: (theme) => (showError ? theme.palette.error.main : 'inherit'),
              }}
              required={required}
              label={label}
              labelPlacement={labelPlacement}
              control={
                <Checkbox
                  sx={{
                    color: (theme) => (showError ? theme.palette.error.main : 'inherit'),
                  }}
                  id={id}
                  name={name}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...input}
                  required={required}
                  disabled={disabled}
                />
              }
            />
            {!disableHelperText && (
              <FormHelperText sx={{ m: 0 }}>{displayedHelperText}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  required: PropTypes.bool,
  helperText: PropTypes.string,
  labelPlacement: PropTypes.string,
  disableHelperText: PropTypes.bool,
  disabled: PropTypes.bool,
};

CheckboxField.defaultProps = {
  validations: [],
  required: false,
  helperText: '',
  labelPlacement: 'end',
  disableHelperText: false,
  disabled: false,
};

export default CheckboxField;
