import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { some, trim } from 'lodash';

import { addPatientMedicalHistory } from 'store/thunks/patientThunks';
import useInfoDialog from 'common/hooks/useInfoDialog';
import MedicalConditionAutoSuggest from 'common/forms/MedicalConditionAutoSuggest';
import TextField from 'common/forms/TextField';

const AddMedicalConditionButton = ({ mpi }) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const { InfoDialog, showInfoDialog } = useInfoDialog();
  const patientName = useSelector(({ patient }) => patient[mpi]?.firstName);
  const medicalConditions = useSelector(
    ({ patient }) => patient[mpi]?.medicalHistory?.medicalConditions
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleAddMedicalCondition = (formData) => {
    const identicalMCExists = some(
      medicalConditions,
      (mc) =>
        new RegExp(`^${trim(formData.medicalConditionName)}$`, 'i').test(mc.medicalConditionName) ||
        (mc.ICDCode && new RegExp(`^${trim(formData.ICDCode)}$`, 'i').test(mc.ICDCode))
    );

    if (identicalMCExists) {
      return showInfoDialog({
        title: 'Identical Medical Condition Found',
        message: 'A medical condition with the same ICD Code or name already exist.',
      });
    }

    return dispatch(
      addPatientMedicalHistory({
        newMedicalHistory: {
          mpi,
          medicalCondition: [formData],
        },
      })
    ).then((success) => {
      if (success) {
        handleCloseModal();
      }
    });
  };

  return (
    <>
      <InfoDialog />
      <Button
        variant='contained'
        disabled={!patientName}
        onClick={handleOpenModal}
        aria-label='add medical condition'
      >
        Add
      </Button>

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='PatientView-MedicalHistoriesTab-AddMedicalConditionButton-header'
        fullWidth
      >
        <DialogTitle id='PatientView-MedicalHistoriesTab-AddMedicalConditionButton-header'>
          Add Medical Condition
        </DialogTitle>

        <Form
          onSubmit={handleAddMedicalCondition}
          render={({ handleSubmit, invalid, submitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogContent>
                <MedicalConditionAutoSuggest
                  id='PatientView-MedicalHistoriesTab-AddMedicalConditionButton-medicalConditionName-input'
                  name='medicalConditionName'
                  label='Medical Condition Name'
                  autoFocus
                  required
                  prefillFields={['ICDCode']}
                />

                <MedicalConditionAutoSuggest
                  id='PatientView-MedicalHistoriesTab-AddMedicalConditionButton-icdCode-input'
                  name='ICDCode'
                  label='ICD Code'
                  prefillFields={['medicalConditionName']}
                  maxLength={15}
                />

                <TextField
                  id='PatientView-MedicalHistoriesTab-AddMedicalConditionButton-description-input'
                  name='description'
                  label='Description'
                />
              </DialogContent>

              <DialogActions>
                <Button variant='outlined' color='secondary' onClick={handleCloseModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={submitting}
                  variant='contained'
                  disabled={invalid}
                  type='submit'
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          )}
        />
      </Dialog>
    </>
  );
};

AddMedicalConditionButton.propTypes = {
  mpi: PropTypes.string.isRequired,
};

export default AddMedicalConditionButton;
