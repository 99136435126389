import { Button, Chip, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { compact, isEmpty, map, values, without } from 'lodash';

import { filterOrdersForShipment } from 'store/thunks/workQueueThunks';
import orderStatus from 'common/constants/orderStatus';

const ReadyToShipFilterStatusBar = () => {
  const dispatch = useDispatch();
  const filter = useSelector(({ workQueue }) => workQueue.readyToShip?.filter);

  const removeFilter = (filterName) => () => {
    const removedStatusFilter = without(filter.orderStatus, filterName);

    dispatch(
      filterOrdersForShipment({
        filter: {
          ...filter,
          [filterName]: undefined,
          orderStatus: isEmpty(removedStatusFilter) ? undefined : removedStatusFilter,
        },
      })
    );
  };

  const removeAllFilter = () => dispatch(filterOrdersForShipment({ filter: {} }));

  return (
    <Stack direction='row' spacing={1}>
      {filter.orderId && (
        <Chip
          label={`Order Id = ${filter.orderId}`}
          variant='contained'
          onDelete={removeFilter('orderId')}
        />
      )}
      {filter.needsByDate && (
        <Chip
          label={`Needs By Date = ${format(new Date(filter.needsByDate), 'MM/dd/yyyy')}`}
          variant='contained'
          onDelete={removeFilter('needsByDate')}
        />
      )}
      {filter.patientName && (
        <Chip
          label={`Name = ${filter.patientName}`}
          variant='contained'
          onDelete={removeFilter('patientName')}
        />
      )}

      {filter.orderStatus &&
        map(filter.orderStatus, (status) => (
          <Chip
            key={status}
            label={`Status = ${orderStatus[status]}`}
            variant='contained'
            onDelete={removeFilter(status)}
          />
        ))}

      {!isEmpty(compact(values(filter))) && <Button onClick={removeAllFilter}>Remove All</Button>}
    </Stack>
  );
};

export default ReadyToShipFilterStatusBar;
