import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import {
  listFailedAdjdicatioNotes,
  updateRefillAdjudicationState,
} from 'store/thunks/workQueueThunks';
import AdjudicationState from 'common/constants/adjudication';

import AddNoteDialog from './ReviewAdjudicationAddNoteDialog';
import NoteDialog from './ReviewAdjudicationNoteDialog';

const ReviewAdjudicationOptions = ({ mpi, contextId, contextType, rxRequestId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddNoteDialog, setShowAddNoteDialog] = useState(false);
  const [showNotesDialog, setShowNotesDialog] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const menuOpen = !!anchorEl;
  const dispatch = useDispatch();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleAddNoteDialog = () => {
    setAnchorEl(null);
    setShowAddNoteDialog(true);
  };

  const handleViewNoteDialog = () => {
    setShowNotesDialog(true);
    setAnchorEl(null);

    dispatch(listFailedAdjdicatioNotes({ contextId, contextType, mpi }));
  };

  const closeViewNoteDialog = () => setShowNotesDialog(false);

  const handleCloseNoteDialog = () => setShowAddNoteDialog(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = (_, reason) => {
    /* istanbul ignore next */
    if (reason !== 'backdropClick') {
      setShowModal(false);
    }
  };

  const handleRefillAdjudicationState = () => {
    setLoading(true);
    dispatch(
      updateRefillAdjudicationState({
        rxRequestId,
        state: AdjudicationState.ADJUDICATION_PASSED,
      })
    ).then(() => {
      enqueueSnackbar('Manually Adjudicated Successfully', { variant: 'success' });
      handleCloseModal();
    });
  };

  return (
    <>
      <AddNoteDialog
        contextType={contextType}
        contextId={contextId}
        mpi={mpi}
        open={showAddNoteDialog}
        onClose={handleCloseNoteDialog}
      />

      <NoteDialog
        open={showNotesDialog}
        onClose={closeViewNoteDialog}
        contextId={contextId}
        mpi={mpi}
      />

      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        aria-describedby='ManualAdjudication-Dialog-Header'
        maxWidth='sm'
      >
        <DialogTitle sx={{ m: 0, p: 2, fontWeight: 600 }} id='ManualAdjudication-Dialog-Header'>
          Manually Adjudicated
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, maxWidth: '90%' }}>
            <Typography variant='subtitle1'>
              Please confirm that prescription was manually adjudicated
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleRefillAdjudicationState}
            variant='contained'
            loading={loading}
          >
            Confirm
          </LoadingButton>
          <Button onClick={handleCloseModal} variant='outlined' color='secondary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <IconButton
        id={`WorkQueue-ReviewAdjudicationOptions-${contextId}options-button`}
        aria-label={`ReviewAdjudication options ${contextId}`}
        aria-controls={
          menuOpen ? `WorkQueue-ReviewAdjudicationOptions-${contextId}options-menu` : undefined
        }
        aria-haspopup='true'
        aria-expanded={menuOpen ? 'true' : undefined}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`WorkQueue-ReviewAdjudicationOptions-${contextId}options-menu`}
        aria-labelledby={`WorkQueue-ReviewAdjudicationOptions-${contextId}options-button`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleOpenModal}>Adjudicated</MenuItem>
        <MenuItem onClick={handleAddNoteDialog}>Add Note</MenuItem>
        <MenuItem onClick={handleViewNoteDialog}>View Notes</MenuItem>
      </Menu>
    </>
  );
};

ReviewAdjudicationOptions.propTypes = {
  mpi: PropTypes.string.isRequired,
  contextId: PropTypes.string.isRequired,
  contextType: PropTypes.string.isRequired,
  rxRequestId: PropTypes.string.isRequired,
};

export default ReviewAdjudicationOptions;
