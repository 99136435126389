import { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import stc from 'string-to-color';

import { signOut } from 'store/thunks/authThunks';
import UserIcon from 'common/components/UserIcon';

const UserAvatar = () => {
  const dispatch = useDispatch();
  const { firstName, lastName } = useSelector(({ auth }) => auth);
  const fullName = `${firstName} ${lastName}`;
  const [anchorEl, setAnchorEl] = useState(null);
  const bgcolor = stc(fullName);

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const handleSignOut = () => dispatch(signOut());

  return (
    <>
      <IconButton aria-label={fullName} onClick={handleOpenMenu} id='UserAvatar-button'>
        <UserIcon bgcolor={bgcolor} firstName={firstName} lastName={lastName} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-label': 'User Menu',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleSignOut} dense sx={{ minWidth: 200 }}>
          <Typography>Sign Out</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default UserAvatar;
