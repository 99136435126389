import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PatientPrescriptionDialog from './PatientPrescriptionDialog';

const PatientPrescriptionOptions = ({ prescriptionId, mpi }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewOpen, setViewOpen] = useState(false);
  const menuOpen = !!anchorEl;

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const closePrescriptionModel = () => setViewOpen(false);
  const openPrescriptionModel = () => setViewOpen(true);

  return (
    <>
      {viewOpen && (
        <PatientPrescriptionDialog
          contextId={prescriptionId}
          mpi={mpi}
          open={viewOpen}
          closeDialog={closePrescriptionModel}
        />
      )}

      <IconButton
        id={`PatientView-PrescriptionOptions-${prescriptionId}-button`}
        aria-label={`Prescription Options ${prescriptionId}`}
        onClick={handleMenuOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`Prescription-${prescriptionId}options-menu`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={openPrescriptionModel}>View</MenuItem>
      </Menu>
    </>
  );
};

export default PatientPrescriptionOptions;

PatientPrescriptionOptions.propTypes = {
  prescriptionId: PropTypes.string.isRequired,
  mpi: PropTypes.string.isRequired,
};
