import moment from 'moment';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { lighten, useTheme } from '@mui/material/styles';
import { Grid, IconButton, Typography } from '@mui/material';
import { noop } from 'lodash';

export const ItemLabelValue = ({
  value,
  label,
  fontSize,
  labelHeight,
  opacity,
  isOverrideData,
}) => {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item sx={{ width: '30%' }}>
        <Typography
          variant={fontSize || 'body1'}
          sx={{
            color: isOverrideData
              ? theme.palette.info.main
              : lighten(theme.palette.text.primary, opacity),
          }}
        >
          {label || ''}
        </Typography>
      </Grid>
      <Grid item sx={{ width: '70%' }}>
        <Typography
          sx={{
            ml: 3,
            minHeight: labelHeight || 18,
            overflow: 'hidden',
            color: isOverrideData
              ? theme.palette.info.main
              : lighten(theme.palette.text.primary, opacity),
            ...(!!value && label === 'QS1Patient ID' && { fontWeight: 'bold' }),
          }}
          variant={fontSize || 'body1'}
        >
          {value || 'N/A'}
        </Typography>
      </Grid>
    </Grid>
  );
};

ItemLabelValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  fontSize: PropTypes.string,
  labelHeight: PropTypes.string,
  opacity: PropTypes.number,
  isOverrideData: PropTypes.bool,
};

ItemLabelValue.defaultProps = {
  label: '',
  value: '',
  fontSize: null,
  labelHeight: null,
  opacity: null,
  isOverrideData: false,
};

export const OverrideItemLabelValue = ({
  showEditOption,
  handleModel,
  isHidden,
  labelName,
  labelValue,
  updatedTime,
  pharmacistName,
}) => {
  const theme = useTheme();

  return (
    <Grid container alignItems='flex-start' justifyContent='flex-start'>
      <Grid container direction='row' sx={{ width: '30%' }} justifyContent='space-between'>
        <Grid item sx={{ width: '90%' }}>
          <Typography sx={{ color: theme.palette.info.main }}>{labelName}</Typography>
          {!!pharmacistName && (
            <Typography variant='caption' display='block' sx={{ color: theme.palette.info.main }}>
              {pharmacistName === 'System User' ? 'Auto' : pharmacistName}
            </Typography>
          )}
          {!!updatedTime && (
            <Typography variant='caption' display='block' sx={{ color: theme.palette.info.main }}>
              {moment.utc(updatedTime).local().format('MM/DD/YYYY hh:mm:ss A')}
            </Typography>
          )}
        </Grid>

        <Grid item sx={{ width: '10%' }}>
          {showEditOption && (
            <IconButton
              onClick={handleModel}
              hidden={isHidden}
              aria-label={`${labelName} Edit Icon`}
            >
              <EditIcon className='EditIcon' />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <Grid item sx={{ pl: 3, width: '70%' }}>
        <Typography sx={{ color: theme.palette.info.main }}>{labelValue}</Typography>
      </Grid>
    </Grid>
  );
};

OverrideItemLabelValue.propTypes = {
  isHidden: PropTypes.bool,
  showEditOption: PropTypes.bool,
  handleModel: PropTypes.func,
  labelName: PropTypes.string.isRequired,
  labelValue: PropTypes.string.isRequired,
  updatedTime: PropTypes.string,
  pharmacistName: PropTypes.string,
};

OverrideItemLabelValue.defaultProps = {
  isHidden: false,
  handleModel: noop,
  showEditOption: false,
  pharmacistName: '',
  updatedTime: '',
};
