import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import useActionItemsCount from 'common/hooks/useActionItemsCount';
import { PatientStatus } from 'enums/patient.enum';
import ActionItemPatientQualification from './PatientQualificationActionItems/ActionItemPatientQualification';
import ActionItemEligibility from './EligibilityActionItems/ActionItemEligibility';

const PatientActionItemsTab = () => {
  const { mpi } = useParams();

  const actionItemsCount = useActionItemsCount({ mpi });
  const currentStatus = useSelector(({ patient }) => patient[mpi]?.status);

  return (
    <Grid container direction='column' spacing={2}>
      {currentStatus === 'ONBOARDED' && (
        <Grid item>
          <ActionItemPatientQualification mpi={mpi} />
        </Grid>
      )}

      {currentStatus === 'QUALIFIED' && (
        <Grid item>
          <ActionItemEligibility />
        </Grid>
      )}

      {currentStatus && actionItemsCount === 0 && (
        <Grid item container justifyContent='center' alignItems='center' sx={{ minHeight: '20vh' }}>
          <Typography sx={{ fontWeight: '500' }}>
            {currentStatus === PatientStatus.PROFILE_INCOMPLETE
              ? 'Complete patient profile to see action Items'
              : 'No Action Items To Display...'}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PatientActionItemsTab;
