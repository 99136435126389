/* eslint-disable max-lines */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { every, filter, find, includes, isEmpty, map, noop, some, uniq, without } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  Badge,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import Lottie from 'lottie-react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

import LoadingModule from 'common/components/LoadingModule';
import Download from 'assets/illustrations/downloadIcon.json';
import { bulkFillApi, generateBulkLabelsApi, markLineItemsPrintedApi } from 'api/requests';
import { handleError } from 'store/thunks/errorHandlerThunks';
import lineItemEnum from 'enums/lineItem.enum';
import useRoles from 'common/hooks/useRoles';

const PrintLabelContext = createContext([]);

const PrintLabelProvider = ({ initialValue, children }) => {
  const [labelItemIds, setLabelItemIds] = useState(initialValue);

  const updateLabelItemIds = useCallback(({ isSelected, labelItemId }) => {
    setLabelItemIds((prev) => (isSelected ? [...prev, labelItemId] : without(prev, labelItemId)));
  }, []);

  const clearLabelItems = useCallback(() => {
    setLabelItemIds([]);
  }, []);

  const memoizedValue = useMemo(
    () => ({ labelItemIds, updateLabelItemIds, clearLabelItems, setLabelItemIds }),
    [labelItemIds, clearLabelItems, updateLabelItemIds, setLabelItemIds]
  );

  useEffect(() => {
    setLabelItemIds((prev) => filter(prev, (id) => includes(initialValue, id)));
  }, [initialValue]);

  return <PrintLabelContext.Provider value={memoizedValue}>{children}</PrintLabelContext.Provider>;
};

PrintLabelProvider.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

const usePrintLabelContext = () => useContext(PrintLabelContext);

const PrintLabelCheckBox = ({ lineItemId, isDisabled }) => {
  const { labelItemIds, updateLabelItemIds } = usePrintLabelContext();

  const onChangeHandler = (event) => {
    const isSelected = event.target.checked;
    const labelItemId = event.target.id;

    updateLabelItemIds({ isSelected, labelItemId });
  };

  return (
    <Tooltip title={isDisabled ? 'Product in prescription review' : ''} placement='left' arrow>
      <span>
        <Checkbox
          id={lineItemId}
          inputProps={{ 'aria-label': `mark ${lineItemId} to be printed` }}
          size='small'
          sx={{ py: 0 }}
          checked={includes(labelItemIds, lineItemId)}
          onChange={onChangeHandler}
          disabled={isDisabled}
        />
      </span>
    </Tooltip>
  );
};

PrintLabelCheckBox.propTypes = {
  lineItemId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

PrintLabelCheckBox.defaultProps = {
  isDisabled: false,
};

const PrintLabelButton = ({ onSuccess, onWorkQueue }) => {
  const workQueueState = useSelector(({ workQueue }) => workQueue);
  const preset = workQueueState?.preset || '';
  const bulkLabelsQueue = workQueueState[preset] || '';
  const pages = bulkLabelsQueue?.pages || {};
  const currentPage = bulkLabelsQueue?.currentPage || 1;
  const items = pages[currentPage] || [];

  const hasAccess = useRoles();
  const { labelItemIds, clearLabelItems } = usePrintLabelContext();
  const [open, setOpen] = useState(false);
  const [bulkLabelUrl, setBulkLabelUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [markItemsFilled, setMarkItemsFilled] = useState(false);
  const dispatch = useDispatch();

  const filledCheckboxClicked = () => setMarkItemsFilled(!markItemsFilled);

  const needsFulfillmentItems = filter(items, ({ lineItemStatus, lineItemId }) =>
    find(
      labelItemIds,
      (itemId) => itemId === lineItemId && lineItemStatus === lineItemEnum.PRODUCT_FULFILLMENT
    )
  );

  const closeDialog = () => {
    setOpen(false);
    setBulkLabelUrl(null);
  };

  const handleGenerateBulkLabels = () => {
    setOpen(true);
    generateBulkLabelsApi({ requestLineItemIds: labelItemIds })
      .then(setBulkLabelUrl)
      .catch((error) => {
        setOpen(false);
        dispatch(handleError({ error }));
      });
  };

  const handleMarkAsPrinted = () => {
    setMarkItemsFilled(false);
    setLoading(true);
    clearLabelItems();
    markLineItemsPrintedApi({
      lineItemIds: labelItemIds,
      ...(markItemsFilled && { markItemsFilled }),
    })
      .then(() => {
        closeDialog();
        onSuccess();
      })
      .catch((error) => dispatch(handleError({ error })))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Badge color='error' badgeContent={labelItemIds.length} sx={{ mx: 1 }}>
        <Button
          disabled={isEmpty(labelItemIds) || !hasAccess.generateBulkLabels}
          variant='contained'
          color='primary'
          onClick={handleGenerateBulkLabels}
        >
          Print Labels
        </Button>
      </Badge>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        aria-labelledby='WorkQueue-bulkLabel-download-header'
      >
        <DialogTitle id='WorkQueue-bulkLabel-download-header'>Bulk Labels Download</DialogTitle>
        <DialogContent>
          {!bulkLabelUrl && <LoadingModule height='25vh' />}
          {bulkLabelUrl && (
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              sx={{ p: 6 }}
            >
              <Lottie animationData={Download} loop={false} style={{ height: 200, padding: 10 }} />
              <Button
                component={Link}
                href={bulkLabelUrl}
                target='_blank'
                variant='outlined'
                color='secondary'
              >
                Download Labels
              </Button>
            </Grid>
          )}
        </DialogContent>

        <DialogActions>
          {onWorkQueue &&
            !isEmpty(needsFulfillmentItems) &&
            hasAccess.manageProductFulfillmentStatus && (
              <FormControlLabel
                label={`Also mark ${needsFulfillmentItems.length} product(s) as Filled`}
                control={<Checkbox onChange={filledCheckboxClicked} />}
              />
            )}

          <LoadingButton
            variant='contained'
            loading={loading}
            disabled={!bulkLabelUrl || !hasAccess.markLineItemsPrinted}
            onClick={handleMarkAsPrinted}
          >
            Mark as Printed
          </LoadingButton>
          <Button onClick={closeDialog} variant='outlined' color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const MarkAsFilledButton = ({ onSuccess }) => {
  const workQueueState = useSelector(({ workQueue }) => workQueue);
  const preset = workQueueState?.preset || '';
  const bulkLabelsQueue = workQueueState[preset] || '';
  const pages = bulkLabelsQueue?.pages || {};
  const currentPage = bulkLabelsQueue?.currentPage || 1;
  const items = pages[currentPage] || [];

  const hasAccess = useRoles();
  const { labelItemIds } = usePrintLabelContext();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const needsFulfillmentItems = filter(items, ({ lineItemStatus, lineItemId }) =>
    find(
      labelItemIds,
      (itemId) => itemId === lineItemId && lineItemStatus === lineItemEnum.PRODUCT_FULFILLMENT
    )
  );

  const closeDialog = () => setOpen(false);

  const handleMarkAsFilled = () => {
    setLoading(true);
    const lineItemIds = map(needsFulfillmentItems, 'lineItemId');

    return bulkFillApi({ lineItemIds })
      .then(() => {
        enqueueSnackbar(`Marked ${lineItemIds.length} product(s) as filled!`, {
          variant: 'success',
        });
        closeDialog();
        onSuccess();
      })
      .catch(() => enqueueSnackbar('Failed to mark product(s) as filled!', { variant: 'error' }))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenOpenDialog = () => setOpen(true);

  return (
    <>
      <Badge color='error' badgeContent={needsFulfillmentItems.length} sx={{ mx: 1 }}>
        <Button
          disabled={isEmpty(needsFulfillmentItems) || !hasAccess.manageProductFulfillmentStatus}
          variant='contained'
          color='primary'
          onClick={handleOpenOpenDialog}
        >
          Mark as Filled
        </Button>
      </Badge>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth
        aria-labelledby='WorkQueue-bulkLabels-BatchFill-header'
      >
        <DialogTitle id='WorkQueue-bulkLabels-BatchFill-header'>Fill Product</DialogTitle>
        <DialogContent>
          <Typography>{`Are you sure you want to mark ${needsFulfillmentItems.length} product(s) as filled? `}</Typography>
        </DialogContent>

        <DialogActions>
          <LoadingButton
            variant='contained'
            loading={loading}
            disabled={!hasAccess.manageProductFulfillmentStatus}
            onClick={handleMarkAsFilled}
          >
            Mark as Filled
          </LoadingButton>
          <Button onClick={closeDialog} variant='outlined' color='secondary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SelectAllCheckBox = ({ allLineItemIds, isDisabled }) => {
  const { labelItemIds, setLabelItemIds } = usePrintLabelContext();

  const includeSomeIds = some(allLineItemIds, (lineItemId) => includes(labelItemIds, lineItemId));
  const allIdsChecked = every(allLineItemIds, (lineItemId) => includes(labelItemIds, lineItemId));

  const handleClickEvent = (event) => {
    const { checked } = event.target;

    if (checked) {
      setLabelItemIds((prev) => uniq([...allLineItemIds, ...prev]));
    } else {
      setLabelItemIds((prev) => without(prev, ...allLineItemIds));
    }
  };

  return (
    <span>
      <Checkbox
        size='small'
        onClick={handleClickEvent}
        indeterminate={!allIdsChecked && includeSomeIds}
        checked={allIdsChecked && !isEmpty(labelItemIds)}
        inputProps={{ 'aria-label': `select all line items to be printed` }}
        disabled={isDisabled}
      />
    </span>
  );
};

SelectAllCheckBox.propTypes = {
  allLineItemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDisabled: PropTypes.bool,
};

SelectAllCheckBox.defaultProps = {
  isDisabled: false,
};

PrintLabelButton.propTypes = {
  onSuccess: PropTypes.func,
  onWorkQueue: PropTypes.bool,
};

PrintLabelButton.defaultProps = {
  onSuccess: noop,
  onWorkQueue: false,
};

MarkAsFilledButton.propTypes = {
  onSuccess: PropTypes.func,
};

MarkAsFilledButton.defaultProps = {
  onSuccess: noop,
};

export default usePrintLabelContext;
export {
  PrintLabelProvider,
  PrintLabelCheckBox,
  PrintLabelButton,
  SelectAllCheckBox,
  MarkAsFilledButton,
};
