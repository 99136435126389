import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import TextField from 'common/forms/TextField';
import { isEmpty, pick } from 'lodash';
import { Form } from 'react-final-form';
import { useContext } from 'react';
import PropTypes from 'prop-types';

import { validateQuantity } from 'common/forms/formValidations';
import { unMatchedFields } from 'common/utils';
import { PrescriptionReviewPayloadContext } from 'common/hooks/usePrescriptionReviewPayloadContext';

const PrescriptionResolveModal = ({ open, onClose, editableOrderDetails }) => {
  const { payload, setPayload } = useContext(PrescriptionReviewPayloadContext);

  const handleResolvePrescription = (formData) => {
    const overRidePrescriptionDetails = unMatchedFields(editableOrderDetails, formData);

    const overridePrescriptionPayload = !isEmpty(payload)
      ? {
          ...payload,
          prescriptionDetails: {
            ...payload.prescriptionDetails,
            ...pick(formData, overRidePrescriptionDetails),
          },
        }
      : {
          prescriptionDetails: pick(formData, overRidePrescriptionDetails),
        };

    setPayload(overridePrescriptionPayload);

    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle>Resolve prescription</DialogTitle>

      <Form
        initialValues={editableOrderDetails}
        onSubmit={handleResolvePrescription}
        render={({ handleSubmit, invalid, dirtyFields }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogContent sx={{ minWidth: 600 }}>
              <TextField
                id='PrescriptionResolveModal-prescriber-name-input'
                label='Prescriber Name'
                name='prescriberName'
                required
              />

              <TextField
                id='PrescriptionResolveModal-quantity-input'
                label='Quantity'
                name='quantity'
                required
                validations={[validateQuantity()]}
              />

              <TextField
                id='PrescriptionResolveModal-refill-input'
                label='Refills'
                name='refills'
                required
              />
            </DialogContent>

            <DialogActions>
              <Button variant='outlined' color='secondary' onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                variant='contained'
                disabled={isEmpty(dirtyFields) || invalid}
              >
                Confirm
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

PrescriptionResolveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editableOrderDetails: PropTypes.shape({
    refills: PropTypes.string,
    quantity: PropTypes.string,
    prescriberName: PropTypes.string,
  }).isRequired,
};

export default PrescriptionResolveModal;
