import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { filter, isEmpty, isNil, map } from 'lodash';
import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import LoadingModule from 'common/components/LoadingModule';
import NothingFound from 'common/components/NothingFound';
import { NoteState } from 'common/constants/notes';

import DraggableNote from './DraggableNote';
import NoteItem from './NoteItem';

const Transition = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Slide direction='up' ref={ref} {...props} timeout={300} />
));

const NotesDialog = ({ open, onClose, itemNotes, onUpdateNoteStatus }) => {
  const theme = useTheme();

  const pinnedNotes = filter(itemNotes, ({ noteState }) => noteState === NoteState.PIN);
  const unPinnedNotes = filter(itemNotes, ({ noteState }) => noteState === NoteState.UNPIN);

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <Grid
        container
        sx={{ px: 2, p: 1, bgcolor: 'primary.light' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography component='h2' variant='h6'>
          Notes
        </Typography>

        <IconButton onClick={onClose} aria-label='Close Dialog'>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        {isNil(itemNotes) && <LoadingModule height='25vh' />}
        {!isNil(itemNotes) && isEmpty(itemNotes) && <NothingFound height='25vh' />}
        <Grid container>
          <Grid item xs={12} md={8}>
            <Grid container spacing={3}>
              {isEmpty(pinnedNotes) && <NothingFound height='25vh' />}

              {map(pinnedNotes, ({ noteId, noteContent }) => (
                <DraggableNote
                  key={noteId}
                  noteContent={noteContent}
                  noteId={noteId}
                  onUpdateNoteStatus={onUpdateNoteStatus}
                />
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            {!isEmpty(pinnedNotes) && <Grid sx={{ pb: 1, fontWeight: 'bold' }}>Pinned Notes</Grid>}

            {!isEmpty(pinnedNotes) && (
              <List sx={{ pb: 2 }}>
                {map(pinnedNotes, (note) => (
                  <ListItem
                    disablePadding
                    key={note.noteId}
                    sx={{ border: 1, borderColor: theme.palette.divider, minWidth: 500 }}
                  >
                    <NoteItem note={note} onUpdateNoteStatus={onUpdateNoteStatus} />
                  </ListItem>
                ))}
              </List>
            )}

            {!isEmpty(unPinnedNotes) && (
              <Grid sx={{ pb: 1, fontWeight: 'bold' }}>UnPinned Notes</Grid>
            )}

            {!isEmpty(unPinnedNotes) && (
              <List>
                {map(unPinnedNotes, (note) => (
                  <ListItem
                    disablePadding
                    key={note.noteId}
                    sx={{ border: 1, borderColor: theme.palette.divider, minWidth: 500 }}
                  >
                    <NoteItem note={note} onUpdateNoteStatus={onUpdateNoteStatus} />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

NotesDialog.propTypes = {
  itemNotes: PropTypes.arrayOf(
    PropTypes.shape({
      note: PropTypes.shape({
        noteId: PropTypes.string.isRequired,
        noteState: PropTypes.string.isRequired,
        noteCreatedAt: PropTypes.string.isRequired,
        noteContent: PropTypes.string.isRequired,
        noteAuthor: PropTypes.shape({
          firstName: PropTypes.string.isRequired,
          lastName: PropTypes.string.isRequired,
          pomUserId: PropTypes.string.isRequired,
        }).isRequired,
      }),
    })
  ),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateNoteStatus: PropTypes.func.isRequired,
};

NotesDialog.defaultProps = {
  itemNotes: undefined,
};

export default NotesDialog;
