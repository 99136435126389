import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Lottie from 'lottie-react';
import visuallyHidden from '@mui/utils/visuallyHidden';
import PropTypes from 'prop-types';

import forbiddenAccess from 'assets/illustrations/forbiddenAccess.json';

const ForbiddenAccess = ({ height }) => {
  const theme = useTheme();

  return (
    <Grid container justifyContent='center' sx={{ height: '100%', px: 2 }}>
      <Grid container direction='column' sx={{ maxWidth: theme.maxWidth / 2, mt: '5vh' }}>
        <Grid item>
          <Typography component='h1' sx={visuallyHidden}>
            Unauthorized Access
          </Typography>
        </Grid>

        <Grid item sx={{ p: 3 }}>
          <Lottie
            animationData={forbiddenAccess}
            loop={false}
            style={{
              height,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ForbiddenAccess.propTypes = {
  height: PropTypes.string,
};

ForbiddenAccess.defaultProps = {
  height: '50vh',
};

export default ForbiddenAccess;
